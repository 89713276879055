import {createSlice} from "@reduxjs/toolkit";
import {totalAccountBudgetHolderInit} from "../../types/charts/chartTypes";
import {UserInfo} from "../../types/account/AccountTypes";
import {getAccountParentFromAccessCode} from "../../service/account/accountHelper";

export const ReportSlice = createSlice({
  name: 'reports',
  initialState: totalAccountBudgetHolderInit,
  reducers: {
    setAccountBudgetHolder(state, action) {
      // state.monthlyBudgetsStacked = action.payload.holder.monthlyBudgetsStacked;
      // state.annualBudgetsByAccount = action.payload.holder.annualBudgetsByAccount;
      // state.accountBudgets = action.payload.holder.accountBudgets;
      // state.parentTotalBudget = action.payload.holder.parentTotalBudget;
      // state.monthlyBudgetsLine = action.payload.holder.monthlyBudgetsLine;
      state.taskHourExpensesCharts = {...action.payload.holder.taskHourExpensesCharts};
      state.totalExpensesCharts = {...action.payload.holder.totalExpensesCharts};
      state.materialsExpensesByMonthChart = [...action.payload.holder.materialsExpensesByMonthChart]
    },
    setAnnualBudgets(state, action) {
      //state.annualBudgets = action.payload.annualBudgets;
    },
    setOverUnderCharts(state, action) {
      state.overUnderTaskCategoryStats = [...action.payload.holder.overUnderTaskCategoryStats];
      state.overUnderTaskLocationStats = [...action.payload.holder.overUnderTaskLocationStats];

      let workerStats:UserInfo[] = [...action.payload.holder.overUnderWorkerStats];
      workerStats.forEach(worker => worker.id = worker.userId);
      workerStats.forEach(worker => worker.accountName =
          getAccountParentFromAccessCode(worker.accessCode, action.payload.orgInfo, action.payload.accounts).name);
      state.overUnderWorkerStats = workerStats;
    },
    setAnnualCategoryBudgetsSingleAccount(state, action) {
      state.taskHourExpensesCharts.annualCategoryBudgetsSingleAccount =
          [...action.payload.holder.taskHourExpensesCharts.annualCategoryBudgetsSingleAccount];
      state.totalExpensesCharts.annualCategoryBudgetsSingleAccount =
          [...action.payload.holder.totalExpensesCharts.annualCategoryBudgetsSingleAccount];
    },
    setAnnualLocationExpensesSingleAccount(state, action) {
      state.taskHourExpensesCharts.annualLocationExpensesSingleAccount =
          [...action.payload.holder.taskHourExpensesCharts.annualLocationExpensesSingleAccount];
      state.totalExpensesCharts.annualLocationExpensesSingleAccount =
          [...action.payload.holder.totalExpensesCharts.annualLocationExpensesSingleAccount];
    },
    setFullTaskExpensesSpreadsheetReport(state, action) {
      state.fullTaskExpensesSpreadsheetReport = action.payload.value;
    },
    resetToInit(state) {
      state = totalAccountBudgetHolderInit;
    }
  }
});