import {indigo} from "@mui/material/colors";


export interface InboxNotification {
  title: string,
  descrip: string,
  imagePath:string | null,
  active: boolean,
  inApp: boolean,
  text: boolean,
  email: boolean,

  inboxAlertId: string,
  message: string,
  thread:string | null,
  historicalThreads: string[],
  timestamp: string,
  seen: boolean,
  starred: boolean,

  fromName: string,
  fromId: string,
  recipientIds: string[],
  attachment: Blob | null
}

export const notificationInit: InboxNotification = {
  title: '',
  descrip: '',
  imagePath: null,
  active: true,
  inApp: true,
  text: false,
  email: false,

  inboxAlertId: '',
  message: '',
  thread: null,
  historicalThreads: [],
  timestamp: '',
  seen: false,
  starred: false,

  fromName: '',
  fromId: '',
  recipientIds: [],
  attachment: null

}

export const notificationValid = {
  title: false,
  message: false
}
