
import dateFormat from "dateformat";
import moment from "moment";

export const formatDate = (date: Date | string | null) => {
    if (date && (date as string).length === 10) {
        date = date + ' ' + '10:30';
    }
    return basicFormatDate(date, 'yyyy-mm-dd');
}

export const formatDateNew = (date: Date | string | null) => {
    if (date && (date as string).length === 10) {
        date = date + ' ' + '10:30';
    }
    return basicFormatDate(date, 'mm-dd-yyyy');
}

export const formatDateTime = (date: Date | string | null) => {
    let dtString = basicFormatDate(date, 'isoDateTime')!;
    if (dtString) {
        let lastpart = dtString.substring(dtString.length - 2);
        return dtString.substring(0, dtString.length - 2) + ":" + lastpart;
    }
    return null;
}

const basicFormatDate = (date: Date | string | null, mask: string) => {
    if (date) {
        try {
            date = new Date(date);
            let formattedDate = dateFormat(date, mask);
            return formattedDate;
        } catch (e) {}
    }
    return null;
}

export const formatDateOnEditableField = (date: Date | string | null) => {
    if (date) {
        return moment(formatDate(date!) + ' ' + '10:30').toDate().toString();
    }
    return null;
}

export const getLastDayInCurrentWeek = (date: Date | string) => {
    date = new Date(date);
    date?.setHours(0,0,0,0);
    let prevMonday = new Date(date.getFullYear(), date.getMonth(), (date.getDate() - date.getDay()) + 1);
    let lastDayOfWeek = new Date(date.getFullYear(), date.getMonth(), prevMonday.getDate() + 6);
    return lastDayOfWeek;
}

export const areDatesEqual = (date1: Date | string, date2: Date | string) => {
    if ((date1 as string).includes('-') && (date1 as string).length === 10) {
        date1 = new Date(
            Number((date1 as string).substring(0, 4)),
            Number((date1 as string).substring(5, 7)) - 1, //months are zero based
            Number((date1 as string).substring(8, 10)),
        );
    } else {
        date1 = new Date(date1);
    }
    return date1.toDateString() === new Date(date2).toDateString();
}

export const currencyFormat = (num: number | string) => {
    let isNum = true;
    if (isNaN(Number(num))) {
        isNum = false;
    }

    let formatted = Number(num) === 0 ? '0' : Number(num).toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    if (isNum) {
        formatted = '$' + formatted;
    }
    if (formatted.includes('-')) {
        formatted = formatted.replace('-', '');
        formatted = '-' + formatted;
    }
    return formatted;
}