import {Action, AnyAction, AsyncThunkAction, configureStore, ThunkAction, ThunkDispatch} from "@reduxjs/toolkit";
import {authSlice} from "./account/authSlice";
import {alertSlice} from "./alertSlice";
import {accountSlice} from "./account/accountSlice";
import {userInfoSlice} from "./account/userInfoSlice";
import {orgInfoSlice} from "./account/orgInfoSlice";
import {notificationSlice} from "./notificationSlice";
import {taskInfoSlice} from "./task/taskInfoSlice";
import {adminSlice} from "./account/AdminSlice";
import {playbooksSlice} from "./task/PlaybooksSlice";
import {useDispatch} from "react-redux";
import {BudgetSlice} from "./task/BudgetSlice";
import {ReportSlice} from "./task/ReportSlice";
import {experienceSlice} from "./account/experienceSlice";
import {ScheduleSlice} from "./task/ScheduleSlice";
import {JobBoardSlice} from "./task/JobBoardSlice";
import {CompanyDirectorySlice} from "./CompanyDirectorySlice";
import {mapsSlice} from "./mapsSlice";


const store = configureStore({
  reducer: {
    alert: alertSlice.reducer,
    notifications: notificationSlice.reducer,
    auth: authSlice.reducer,
    account: accountSlice.reducer,
    userInfo: userInfoSlice.reducer,
    taskInfo: taskInfoSlice.reducer,
    orgInfo: orgInfoSlice.reducer,
    admin: adminSlice.reducer,
    playbooks: playbooksSlice.reducer,
    budgets: BudgetSlice.reducer,
    reports: ReportSlice.reducer,
    experience: experienceSlice.reducer,
    schedule: ScheduleSlice.reducer,
    jobBoard: JobBoardSlice.reducer,
    companyDirectory: CompanyDirectorySlice.reducer,
    maps: mapsSlice.reducer,
  }
});

export const alertActions = alertSlice.actions;
export const notificationActions = notificationSlice.actions;
export const authActions = authSlice.actions;
export const accountActions = accountSlice.actions;
export const userInfoActions = userInfoSlice.actions;
export const taskInfoActions = taskInfoSlice.actions;
export const orgInfoActions = orgInfoSlice.actions;
export const adminActions = adminSlice.actions;
export const playbooksActions = playbooksSlice.actions;
export const budgetsActions = BudgetSlice.actions;
export const reportsActions = ReportSlice.actions;
export const experienceActions = experienceSlice.actions;
export const scheduleActions = ScheduleSlice.actions;
export const jobBoardActions = JobBoardSlice.actions;
export const companyDirectoryActions = CompanyDirectorySlice.actions;
export const mapsActions = mapsSlice.actions;

export default store;

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch;

export type ThunkDispatchPromise = ThunkDispatch<RootState, Promise<void>, Action>;

export type AppThunkDefault = ThunkAction<void, RootState, unknown, AnyAction>
export type AppThunkPromise = ThunkAction<Promise<void>, RootState, unknown, AnyAction>

