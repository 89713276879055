import React from "react";
import {Checkbox, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import Box from "@mui/material/Box";
import {visuallyHidden} from "@mui/utils";
import {TableOptions} from "./CustomTable";

export interface EnhancedTableProps {
  headCells: any[];
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof TableOptions) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  noSelection?:boolean,
}

export type Order = 'asc' | 'desc';

const EnhancedTableHead = (props: EnhancedTableProps) => {

  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort } = props;

  const createSortHandler = (property: keyof TableOptions) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
      <TableHead>
        <TableRow>
          {props.noSelection !== true &&
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                />
            </TableCell>
          }
          {props.headCells.map((headCell) => (
              <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
          ))}
        </TableRow>
      </TableHead>
  );
}

export default EnhancedTableHead;