import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button, Dialog, DialogActions, DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import DeleteIcon from "@mui/icons-material/Delete";
import {CommentInfoValid, Comments, EmployeeListResponse, TaskInfo, TaskResponse} from "../../types/task/TaskTypes";
import ConfirmDialog from "../general/ConfirmDialog";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CustomTextField from "../general/CustomTextField";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {accountActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {displayAlert} from "../../store/alertSlice";
import {useDispatch, useSelector} from "react-redux";
import {CommentsInfoInit, taskInfoInit} from "../../store/task/taskInfoSlice";
import CommentDialog from "./CommentDialog";
import CompleteDialog from "./CompleteDialog";
import {useHistory} from "react-router";
import {determineIfTokenError, logoutAndClearAllSlices} from "../../store/account/authSlice";
import { useAppThunkDispatch } from "../../store/storeHooks";
import TaskForm from "./TaskForm";
import {Edit} from "@mui/icons-material";
import EditTaskDialog from "./EditTaskDialog";
import {notificationService} from "../../service/notificationService";
import {NotificationState, updateNotificationGlobalState} from "../../store/notificationSlice";

type Props = {
    task: TaskInfo,
    selectedDate: Date | null,
    taskDialogOpen: boolean,
    setTaskDialogOpen: (open: boolean) => void,
    onlyShowEditDelete?: boolean,
    handleDialogActionClose?: (id:string) => void,
    selectedSubjectId?: string,
    noScheduleNeeded?:boolean,
    getMonthlyTasks?: (ac: AbortController) => void,
    getJobBoardUtil?: () => void,
    getTaskPdfs?:()=>void,
}

const TaskActions:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  let history = useHistory();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const tasks = useSelector((state: RootState) => state.taskInfo);

  const [openCompleteModal, setOpenCompleteModal] = React.useState(false);
  const handleCompleteModalOpen = () => setOpenCompleteModal(true);
  const handleCompleteModalClose = () => setOpenCompleteModal(false);

  const [openCompleteDialog, setOpenCompleteDialog] = React.useState(false);
  const [openCommentDialog, setOpenCommentDialog] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [isCompleteFormValid, setIsCompleteFormValid] = React.useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = React.useState(false);


  const handleDeleteModalClose = () => setOpenDeleteModal(false);
  const handleCompleteDialogClose = () => setOpenCompleteDialog(false);

  /*useEffect(() => {
    let show = isWindowLarge;
    if (props.taskDialogOpen || openCommentDialog || openCompleteDialog || openDeleteModal) {
      show = false;
    }
    console.log("task actions")
    console.log(props.task)
    //dispatch(taskInfoActions.setShowSideMenu({show: show}));
  }, [props.taskDialogOpen, openCommentDialog, openCompleteDialog, openDeleteModal]);*/

  //for deleting locations and categories
  useEffect(() => {
    if (props.handleDialogActionClose && props.selectedSubjectId) {
      props.handleDialogActionClose(props.selectedSubjectId);
    }
  }, [openEditModal, openDeleteModal])

  useEffect(() => {
    if(props?.getTaskPdfs){
      props?.getTaskPdfs()
    }
  },[tasks?.monthlyTasks])

  const handleDelete = (taskId:String) => {
    taskManagementService.deleteTask(taskId, formatDate(props.selectedDate), allSelectedAccessCodes!)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
          handleDeleteModalClose();
          dispatch(displayAlert(true, "Successfully Deleted"));
        }).catch((e: Error) => {
          dispatch(determineIfTokenError(e));
        });
  }

  const handleComment = (taskId:string, comment:Comments) => {
    setIsSubmitLoading(true);
    taskManagementService.commentOnTask(taskId, formatDate(props.selectedDate), comment, allSelectedAccessCodes!)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
          dispatch(displayAlert(true, "Successfully added comment"));
          getNotifications();
        }).catch(e => {
          dispatch(determineIfTokenError(e));
        }).finally(() => {
          setOpenCommentDialog(false);
          setIsSubmitLoading(false)
        });
  }

  const getNotifications = () => {
    notificationService.getNotifications()
        .then((inbox: NotificationState) => {
          dispatch(updateNotificationGlobalState(inbox));
        })
        .catch(e => dispatch(determineIfTokenError(e)));
  }

  const getRetrieveMembersResponse = () => {
    taskManagementService.getEmployeeListResponse(props.task.accessCode)
        .then((employeeListResp: EmployeeListResponse) => {
          dispatch(accountActions.setOrgMembers({orgMembers:employeeListResp.employeeList}))
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const handleOpenCompleteDialog = () => {
    getRetrieveMembersResponse();
    setOpenCompleteDialog(true);
  }

  const handleComplete = (task:TaskInfo) => {
    if (isCompleteFormValid) {
      taskManagementService.completeTask(task, formatDate(props.selectedDate), allSelectedAccessCodes!)
          .then((tasks: TaskResponse) => {
            dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
            dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
            handleCompleteModalClose();
            dispatch(displayAlert(true, "Successfully Completed"));
          }).catch(e => {
            dispatch(determineIfTokenError(e));
          });
    }
  }

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
      <Fragment>
        { !props.onlyShowEditDelete &&
            <Fragment>
              <Tooltip title="Click to Complete Task">
                <IconButton aria-label="settings" disabled={props.task.completed}
                            onClick={handleOpenCompleteDialog}>
                    <TaskAltIcon color={props.task.completed ? "success" : "error"}/>
                </IconButton>
              </Tooltip>
              <Tooltip title="Add Comment">
                <IconButton aria-label="settings"
                            onClick={() => setOpenCommentDialog(true)}>
                  <ChatBubbleOutlineIcon color={"primary"}/>
                </IconButton>
              </Tooltip>
            </Fragment>
        }
        { checkUserNotOrdinaryWorker(userInfo.userInfo) &&
            <Fragment>
                <Tooltip title="Edit">
                    <IconButton aria-label="settings" disabled={false}
                                onClick={() => setOpenEditModal(true)}>
                        <Edit/>
                    </IconButton>
                </Tooltip>
              <Tooltip title="Delete">
                <IconButton aria-label="settings" disabled={props.task.completed}
                            onClick={() => setOpenDeleteModal(true)}>
                    <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </Fragment>
        }

        <ConfirmDialog handleSubmit={handleDelete}
                       title={"Delete Task"}
                       id={props.task.assignedTaskId}
                       isOpen={openDeleteModal} setIsOpen={setOpenDeleteModal} />

        <CommentDialog handleSubmit={handleComment}
                       title={"Comment On Task"}
                       id={props.task.assignedTaskId}
                       commentString={""}
                       isOpen={openCommentDialog} setIsOpen={setOpenCommentDialog}
                       isSubmitLoading={isSubmitLoading} />

        <CompleteDialog handleSubmit={handleComplete}
                        title={"Complete Task"}
                        task={props.task}
                        isOpen={openCompleteDialog}
                        setIsOpen={setOpenCompleteDialog}
                        setIsFormValid={setIsCompleteFormValid}
                        noScheduleNeeded={props.noScheduleNeeded}
                        getMonthlyTasks={props.getMonthlyTasks}
                        getJobBoardUtil={props.getJobBoardUtil}
        />

        <EditTaskDialog openEditModal={openEditModal}
                        setOpenEditModal={setOpenEditModal}
                        task={props.task} />

      </Fragment>
  );
}

export default TaskActions;