import {Button} from "@mui/material";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";

type Props = {
  text:string,
  handleSubmit: () => void,
  isFormValid: () => boolean,
  width?:string,
  color?:string,
  borderSquare?:boolean,
  sx?: {},
  disabled?:boolean,
}

const ValidButton = (props: Props) => {

  return (
      <Button type={'button'} variant={props.isFormValid() ? "contained" : "outlined"}
              size="large"
              onClick={props.handleSubmit}
              disabled={props.disabled}
              style={{
                width: props.width ? props.width : 'inherit',
                cursor: props.isFormValid() ? 'pointer' : 'not-allowed',
                backgroundColor: props.isFormValid() ? (props.color ? props.color : LIME_GREEN) : grey[200],
                color: props.isFormValid() ? DARK_NAVY : grey[800],
                fontWeight:"bold",
                borderColor: props.isFormValid() ? "none" : grey[800],
                borderRadius: props.borderSquare ? '0' : "inherit"
              }}
              sx={{...props.sx}}
      >
        {props.text}
      </Button>
  );
}

export default ValidButton;