import {createSlice} from "@reduxjs/toolkit";

export interface Experience {
  isOrgAdminRegistration:boolean,
  showOrgAccountsTutorial:boolean,
  skippedOrgAccounts:boolean,
  showOrgMembersTutorial:boolean,
  showLaborDashboardTutorial:boolean,
}

export const experienceInit:Experience = {
  isOrgAdminRegistration:false,
  showOrgAccountsTutorial:false,
  skippedOrgAccounts:false,
  showOrgMembersTutorial:false,
  showLaborDashboardTutorial:false,
}

export const experienceSlice = createSlice({
  name: 'experience',
  initialState: experienceInit,
  reducers: {
    setIsOrgAdminRegistration(state, action) {
      state.isOrgAdminRegistration = action.payload.value;
    },
    setShowOrgAccountsTutorial(state, action) {
      state.showOrgAccountsTutorial = action.payload.value;
    },
    setSkippedOrgAccounts(state, action) {
      state.skippedOrgAccounts = action.payload.value;
    },
    setShowOrgMembersTutorial(state, action) {
      state.showOrgMembersTutorial = action.payload.value;
    },
    setShowLaborDashboardTutorial(state, action) {
      state.showLaborDashboardTutorial = action.payload.value;
    }
  }
});
