import {AppLogPrintUrl} from "./PlaybooksTypes";
import {UserInfo} from "../account/AccountTypes";
import {paginationInit, PaginationUtil} from "../PageSortTypes";
import {JobBoardTableUtil, ScheduleLineChartData} from "../charts/chartTypes";
import {DateObject} from "react-multi-date-picker";

export interface FullMonthResponse {
    monthList: FullWeekResponse[],
    nameOfMonthDisplay: string,
    yearDisplay:string
}

export interface CopyDayObjectUtilResponse {
    test: string
}

export interface ExtraMonthResponse {
    extraMonthList: FullMonthResponse[];
}

export interface FullWeekResponse {
    weekList: TaskResponse[]
}

export interface TaskCategoriesPage {
    taskCategories: TaskCategories[],
    pagination: PaginationUtil
}

export interface TaskLocationsPage {
    taskLocations: TaskLocations[],
    pagination: PaginationUtil
}

export const taskCategoriesPageInit = {
    taskCategories: [],
    pagination: paginationInit
}

export const taskLocationsPageInit = {
    taskLocations: [],
    pagination: paginationInit
}

export interface TaskResponse {
    taskList: TaskInfo[],
    taskTemplates: TaskTemplate[],
    allTaskCategoriesForAccount: TaskCategories[],
    taskCategories: TaskCategories[],
    taskLocations: TaskLocations[],
    scheduledEmployeesList: ScheduleObject[],
    dayStats: DayStats,
    date:string,

    weekNameDisplay: string, //all these need to be added into an calendar object
    dayInWeekDisplayName: string,
    dayNumber: string,
    showSelectedOutline: boolean,
    showCurrentDateOutline: boolean,
    selectedMonthDisplay: string,
    numberOfUncompletedTasks: string,
    numberOfCompletedTasks: string,
    numberOfUncompletedHours: string,
    numberOfCompletedHours: string,
    dayInMonth: boolean
}

export interface CheckPointUtil {
    tag: string,
    compliant: boolean,
    hazard: boolean,
}

export interface PlayGroundSafetyFormResponseUtil {
    formId: string,
    taskId: string,
    accountId: string,
    location: string,
    inspectionDate: string | Date | null,
    inspectionTime: string,
    inspectedBy: string[],
    ageAppropriate: string,
    safetyCheckPoints: CheckPointUtil[],
    equipmentCheckPoints:CheckPointUtil[],
    comments: string,
    imageName: string,
}

export interface PlaygroundSafetyInspectionFormutil {
    location: string,
    inspectionDate: string | Date | null,
    inspectionTime: string,
    inspectedBy: string[],
    ageAppropriate: string,
    ss1Compliant: boolean,
    ss1Hazard:boolean,
    ss2Compliant: boolean,
    ss2Hazard:boolean,
    ss3Compliant: boolean,
    ss3Hazard:boolean,
    ss4Compliant: boolean,
    ss4Hazard:boolean,
    ss5Compliant: boolean,
    ss5Hazard:boolean,
    ss6Compliant: boolean,
    ss6Hazard:boolean,
    ss7Compliant: boolean,
    ss7Hazard:boolean,
    ss8QuestionLabel: string,
    ss8Compliant: boolean,
    ss8Hazard:boolean,

    e9Compliant: boolean,
    e9Hazard:boolean,
    e10Compliant: boolean,
    e10Hazard:boolean,
    e11Compliant: boolean,
    e11Hazard:boolean,
    e12Compliant: boolean,
    e12Hazard:boolean,
    e13Compliant: boolean,
    e13Hazard:boolean,
    e14Compliant: boolean,
    e14Hazard:boolean,
    e15Compliant: boolean,
    e15Hazard:boolean,
    e16Compliant: boolean,
    e16Hazard:boolean,
    e17Compliant: boolean,
    e17Hazard:boolean,
    e18Compliant: boolean,
    e18Hazard:boolean,
    e19Compliant: boolean,
    e19Hazard:boolean,
    e20Compliant: boolean,
    e20Hazard:boolean,
    e21Compliant: boolean,
    e21Hazard:boolean,
    e22QuestionLabel: string,
    e22Compliant: boolean,
    e22Hazard:boolean,
    comments: string,
}

export interface TaskPdfResponse {
    taskPdfList: PlaygroundSafetyInspectionFormutil[],
}

export interface DayStats {
    numberOfTasks:number,
    numberOfScheduledWorkers:number,
    numberOfSubTasks:number,
    numberOfAppLogs:number,
    numberOfCompletedHours:string,
    numberOfUnCompletedHours:string,
    numberOfCompletedTasks:string,
    numberOfUnCompletedTasks:string
}

export interface ScheduledWeekObjectResponse {
    scheduledDayObjectList: ScheduledDayObjectResponse[],
    wereUsersToBeScheduledAlreadyScheduled: boolean,
}

export interface ScheduledDayObjectResponse {
    scheduledObjectsList: ScheduleObject[],
    weekNameDisplay: string,
    date: string
}

export interface JobBoardResponse {
    jobBoardUtilList: JobBoardUtil[],
    totalTasksForTheDay: number;
    totalCompletedTasksForTheDay: number;
    totalUncompletedTasksForTheDay: number;
    totalEmployeesScheduled: number;
    displayDate: string,
    displayDateName: string,
    displayYear: string
}

export interface JobBoardTableResponse {
    jobBoardTableList: JobBoardTableUtil[],
    date: string;
}

export interface CompanyDirectoryResponse {
    companyDirectoryList: CompanyDirectoryUtil[],
    totalEmployees: number;
}

export interface CompanyDirectoryUtil {
    id:string,
    employeeId: string,
    employeeFirstName: string,
    employeeLastName: string,
    accountName: string,
    phoneNumber: string,
    userEmail: string,
    role: string,
}

export interface EmployeeListResponse {
    employeeList: UserInfo[],
}

export interface TaskList {
    taskList: TaskInfo[]
}

export interface ScheduleList {
    scheduledList: ScheduleObject[]
}

export interface TaskInfo {
    assignedTaskId: string,
    taskName: string,
    accessCode: string,
    orgName:string,
    completedMonthYear:string,
    categoryName:string,
    categoryId:string,
    locationNames:string[],
    locationIds:string[],
    estCompleteHours: string,
    assignedUserIds: string[],
    pdfIds: string[],
    assignedUserDisplayNames: string[],
    scheduledDate: string | Date | null,
    deadlineTimeString: string,
    taskNotes:string,
    completed: boolean,
    hoursSpentCompleting: string,
    priority: Priority,
    appLog?: AppLogPrintUrl,
    appLogUrl?: string,
    subTaskLabels: SubTaskCheckbox[],
    commentsList?: Comments[],
    saveNewTaskTemplate:boolean,
    overwriteSavedTaskTemplate:boolean,
    templateId:string | null,
}

export interface TaskTemplate {
    templateId:string,
    accessCode:string,
    templateName:string,
    note:string,
    estCompleteHours:string
    priority:Priority,
    subTaskLabels: SubTaskCheckbox[],
    categoryId:string,
    categoryName:string,
    locationsIds:string[],
    locationNames:string[],
}

export interface SubTaskCheckbox {
    label: string,
    completed: boolean,
    touched: boolean
}

export interface Comments {
    commentId: string,
    authorId: string,
    authorName: string,
    commentString: string,
    commentDate: Date,
    imagePath:string | null,
    multipartFile: File | null,
}

export interface LocationIdsUtil {
    locationIds: string[],
    locationNames: string[]
}

export interface CopyToDateUtil {
    dates: DateObject[]
}

export interface ScheduleObjectUtil {
    employeeIds: string[],
    employeeNames:string[],
    scheduleDate: string | Date | null,
    scheduleEndDate: string | Date | null,
    assignedStartTime: string,
    assignedEndTime: string
}

export interface CopyScheduleObjectUtil {
    employeeIds: string[],
    employeeNames:string[],
    toDates: string[],
    fromDate: string | Date | null,
    wholeWeekCheckBox:boolean
    selectedDate:string | Date | null
}
export interface MasterCopyUtil {
    copyTasksCheckBox:boolean,
    copyScheduledWorkers:boolean,
    fromDates:string[],
    toDates:string[],
    accessCodes:string[]
}

export interface CopyDayObjectUtil {
    employeeIds: string[],
    employeeNames:string[],
    taskNames:string[],
    taskIds:string[],
    numberOfTasks:number,
    fromDate: string,
    toDate: string | Date | null,
    scheduleAndAssignWorkers:boolean
    assignedStartTime: string,
    assignedEndTime: string
}

export interface CopyDayObjectUtilResponse {
    copyDayObjectUtil: CopyDayObjectUtil
}

export interface CopyScheduleObjectUtilResponse {
    scheduleObjectUtil: ScheduleObjectUtil
}

export interface ScheduleReportYearlyLineChart {
    lineChartArray:ScheduleLineChartData[],
}

export interface ScheduleReportLineChartData {
    month:string,
    totalMonthlyHours:number,
    totalMonthlyWages:number
}

export interface ScheduleReportMonthlyHourChartData {
    employeeName: string,
    hoursWorked: number
}

export interface ScheduleReportYearlyHourChartData {
    employeeName: string,
    hoursWorked: number
}

export interface ScheduleReportMonthlyWagesChartData {
    employeeName: string,
    wagesPaid: number
}

export interface ScheduleReportYearlyWagesChartData {
    employeeName: string,
    wagesPaid: number
}

export interface ScheduleObject {
    employeeId: string,
    scheduleDate: string | Date | null,
    scheduleEndDate: string,
    employeeName: string,
    assignedStartTime: string,
    assignedEndTime: string,
    displayStartTime: string,
    displayEndTime: string,
    accessCode:string
}

export interface JobBoardUtil {
    employeeId: string,
    employeeName: string,
    accountName: string,
    taskList: TaskInfo[]
}

export interface ScheduleDeleteUtil {
    tasksToUpdateOnDelete: TaskInfo[],
    scheduledObject: ScheduleObject,
}

/*export const scheduleDeleteUtilUnit = {
    tasksToUpdateOnDelete: TaskInfo[],
    scheduledObject: ScheduleObject,
}*/

export interface ScheduleInfoValid {
    employeeSelected: boolean;
    scheduleDate: boolean;
    endDate: boolean;
    startTime: boolean;
    endTime: boolean
}

export interface CopyScheduleInfoValid {
    workersSelected: boolean;
    toDate: boolean;
    wholeWeekCheckBox:boolean;
}

export interface CopyDayInfoValid {
    tasksSelected: boolean;
    workersSelected: boolean;
    toDate: boolean;
    scheduleWorkersCheckbox:boolean;
    startTime:boolean;
    endTime:boolean;
}

export const TaskInfoValid = {
    taskName: false,
    taskNotes: false,
    scheduledDate: true,
    estCompleteHours: false,
    deadlineTime: true,
    category:false,
    location:false,
}

export const CommentInfoValid = {
    commentString: false
}

export const copyDayInfoValidInit:CopyDayInfoValid = {
    tasksSelected: false,
    workersSelected: false,
    toDate:false,
    scheduleWorkersCheckbox:false,
    startTime:false,
    endTime:false
}

export const copyScheduleInfoValidInit:CopyScheduleInfoValid = {
    workersSelected: false,
    toDate:false,
    wholeWeekCheckBox:false,
}

export const scheduleInfoValidInit:ScheduleInfoValid = {
    employeeSelected: false,
    scheduleDate: true,
    endDate:true,
    startTime:false,
    endTime:false
}

export enum Priority {
    HIGH = 'HIGH',
    MID = 'MID',
    LOW = 'LOW',
}

export const laborTabs = [
    'taskManagement',
    'calendar',
    'scheduling',
    'reports',
    'budgeting'
];

export interface TaskCategories {
    categoryId:string,
    accessCode:string,
    accountName:string,
    categoryName:string,
    softDeleteDate: string,
}

export interface TaskLocations {
    locationId:string,
    accessCode:string,
    accountName:string,
    locationName:string,
    softDeleteDate: string,
}
