import {Box, Divider, IconButton, ListItem, ListItemText, Stack} from "@mui/material";
import React, {Fragment, useEffect, useState} from "react";
import {InboxNotification, notificationInit} from "../../types/NotificationTypes";
import StarIcon from "@mui/icons-material/Star";
import DeleteIcon from "@mui/icons-material/Delete";
import {notificationService} from "../../service/notificationService";
import {NotificationState, updateNotificationGlobalState} from "../../store/notificationSlice";
import {useDispatch} from "react-redux";
import {useAppThunkDispatch} from "../../store/storeHooks";
import Moment from "react-moment";
import {Image, ImageOutlined} from "@mui/icons-material";

type Props = {
  notif: InboxNotification,
  inboxType: string,
  notifClickedHandler?: (is:string, type:string) => void,
  viewNotifHandler: (is:string) => void,
  setSelectedNotif: (val:InboxNotification) => void,
}

const NotificationListItem: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Notification List";
  }, []);

  const starHandler = (id: string) => {
    setTimeout(() => {
      notificationService.star(id, props.inboxType)
          .then((inbox: NotificationState) => {
            dispatch(updateNotificationGlobalState(inbox));
          })
          .catch();
      setShowLoading(false);
    }, 600);
  }

  const deleteHandler = (id: string) => {
    setTimeout(() => {
      notificationService.deleteById(id)
          .then((inbox: NotificationState) => {
            dispatch(updateNotificationGlobalState(inbox));
            props.setSelectedNotif(notificationInit);
          })
          .catch();
      setShowLoading(false);
    }, 600);
  }

  const trashHandler = (id: string) => {
    setTimeout(() => {
      notificationService.trash(id, props.inboxType)
          .then((inbox: NotificationState) => {
            dispatch(updateNotificationGlobalState(inbox));
            props.setSelectedNotif(notificationInit);
          })
          .catch();
      setShowLoading(false);
    }, 600);
  }

  const getMessageParts = (message:string):string[] => {
    return message.split(/\r\n|\r|\n/g);
  }

  return (
      <Fragment>
        <ListItem button key={props.notif.inboxAlertId}
                  style={{backgroundColor: props.notif.seen ? 'whitesmoke' : 'lightcyan'}}
                  onClick={() => {
                    props.viewNotifHandler(props.notif.inboxAlertId);
                    if (!props.notif.seen && props.notifClickedHandler) {
                      props.notifClickedHandler(props.notif.inboxAlertId, props.inboxType);
                    }
                  }}
        secondaryAction={
          <Stack
              direction="row"
              spacing={0}
              justifyContent="end"
          >
            {props.inboxType === 'inbox' &&
            <IconButton color={props.notif.starred ? "warning": "default"} aria-label="star"
                        onClick={() => starHandler(props.notif.inboxAlertId)}>
              <StarIcon />
            </IconButton>
            }
            <IconButton color={"default"} aria-label="delete"
                        onClick={() => props.inboxType === 'trash' ?
                            deleteHandler(props.notif.inboxAlertId) : trashHandler(props.notif.inboxAlertId)}>
              <DeleteIcon />
            </IconButton>
          </Stack>
        }>
          <ListItemText
              primary={props.notif.title}
              secondary={
                <span>
                  <span>{props.notif.fromName} | <Moment format={"dddd D of MMM YYYY"}>{props.notif.timestamp}</Moment></span>
                  <span>{(props.notif.thread && getMessageParts(props.notif.thread).length > 0) ?
                      ' | ' + ((getMessageParts(props.notif.thread).length - 1) / 2) + ' threads' : ''}</span>
                  {/*<span>{props.notif.imagePath ? (<ImageOutlined style={{marginBottom:'0'}}/>) : ('')}</span>*/}
                </span>
              }
          />
        </ListItem>
        <Divider/>
      </Fragment>
  );
}

export default NotificationListItem;