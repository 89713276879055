import React, {Fragment, useEffect, useState} from "react";
import Navbar from "../component/nav/Navbar";
import {useHistory} from "react-router";
import {
  Avatar,
  Chip,
  Container,
  Divider,
  Drawer, IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  styled,
  Typography, useMediaQuery
} from "@mui/material";
import {useSelector} from "react-redux";
import {experienceActions, orgInfoActions, RootState, taskInfoActions, userInfoActions} from "../store/storeIndex";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import SecurityIcon from '@mui/icons-material/Security';
import {avatarService} from "../service/generic/avatarService";
import Box from '@mui/material/Box';
import AlertToast from "../component/general/AlertToast";
import LoadingSpinnerFull from "../component/general/LoadingSpinnerFull";
import {checkUserAdmin} from "../service/account/accountService";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {OrgInfoState, Role, UserInfo} from "../types/account/AccountTypes";
import OrgInfoUpdate from "../component/account/OrgInfoUpdate";
import {userInfoService} from "../service/account/userInfoService";
import {userInfoValidInit} from "../types/account/AccountValids";
import UserAccountInfo from "../component/account/UserAccountInfo";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {AdminPanelSettings} from "@mui/icons-material";
import {determineIfTokenError} from "../store/account/authSlice";
import {useAppThunkDispatch} from "../store/storeHooks";
import {GridMenuIcon} from "@mui/x-data-grid";
import {orgInfoService} from "../service/account/orgInfoService";
import Footer from "../component/general/Footer";
import {grey} from "@mui/material/colors";
import CompanyDirectoryComponent from "../component/CompanyDirectory/CompanyDirectoryComponent";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CompanyDirectoryCustom from "../component/CompanyDirectory/CompanyDirectoryCustom";

const AdminPortal = React.lazy(() => import("../component/admin/AdminPortal"));
const AllowedMembers = React.lazy(() => import("../component/account/AllowedMembers"));
const OrgAccountsManage = React.lazy(() => import("../component/account/OrgAccountsManage"));

const drawerWidth = 240;

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

/*export enum accountTabs {
  USERINFO,
  ORGINFO
}*/

const AccountPage: React.FC = () => {
  let dispatch = useAppThunkDispatch();
  let history = useHistory();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const isOrgAdminRegistration = useSelector((state: RootState) => state.experience.isOrgAdminRegistration);
  const showOrgAccountsTutorial = useSelector((state: RootState) => state.experience.showOrgAccountsTutorial);
  const showOrgMembersTutorial = useSelector((state: RootState) => state.experience.showOrgMembersTutorial);
  const skippedOrgAccounts = useSelector((state: RootState) => state.experience.skippedOrgAccounts);

  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);

  const [tabSelected, setTabSelected] = useState("userInfo");
  const [showLoading, setShowLoading] = useState(false);
  const [showEditState, setShowEditState] = useState<boolean>(true);
  const [userInfoState, setUserInfoState] = useState<UserInfo>(userInfo.userInfo);
  const [userInfoStateValid, setUserInfoStateValid] = useState(userInfoValidInit);
  const [userFormTouched, setUserFormTouched] = useState<boolean>(false);
  const [hidePageContent, setHidePageContent] = useState<boolean>(false);


  /*if (userInfo.userInfo.role === Role.ORG_ADMIN && !userInfo.userInfo.accessCode) {
    return <Redirect to={'/manage/org/create'} />
  }*/

  useEffect(() => {
    setUserInfoState(userInfo.userInfo);
  }, [userInfo]);

  useEffect(() => {
    runExperienceTutorialFlow();
  });

  useEffect(() => {
      setHidePageContent(!isWindowLarge && showSideMenu);
  }, [isWindowLarge, showSideMenu])

  const runExperienceTutorialFlow = () => {
    if (userInfo.userInfo.role === Role.ORG_ADMIN) {
      let isAnyAllowedEmailsCreated = orgInfo.allAccountOptions?.filter(option => option.allowedEmails.length !== 0).length !== 0;
      if (!userInfo.userInfo.accessCode) {
        dispatch(experienceActions.setIsOrgAdminRegistration({value:true}));
        setTabSelected('orgInfo');
      } else if (isOrgAdminRegistration && /*!skippedOrgAccounts &&*/
          orgInfo.orgInfo.accountAccessCodes.length == 0) {
        setTabSelected('orgAccounts');
        dispatch(experienceActions.setShowOrgAccountsTutorial({value:true}));
      } else if (orgInfo.orgInfo.accessCode && (showOrgAccountsTutorial ||
          skippedOrgAccounts || !isOrgAdminRegistration) && !isAnyAllowedEmailsCreated) {
        setTabSelected('allowedMembers');
        //dispatch(experienceActions.setIsOrgAdminRegistration({value:true}));
        dispatch(experienceActions.setShowOrgMembersTutorial({value:true}));
      }
    }
  }

  const editFunction = () => {
    setShowEditState(false);
  }

  const editCancelFunction = () => {
    setShowEditState(true);
  }

  const updateInfoHandler = () => {
    if (isUserFormValid()) {
      setShowLoading(true);
      userInfoService.updateUserInfo(userInfoState)
          .then(userInfo => {
            setUserInfoState(userInfo);
            dispatch(userInfoActions.updateSuccess({userInfo: userInfo}));
          })
          .catch(e => dispatch(determineIfTokenError(e)))
          .finally(() => setShowLoading(false));
      editCancelFunction();
      return () => {
      };
    }
  }

  const isUserFormValid = () => {
    return userInfoStateValid.firstName && userInfoStateValid.lastName &&
        userInfoStateValid.phoneNumber && userInfoStateValid.city &&
        userInfoStateValid.state && userInfoStateValid.street && userInfoStateValid.postalCode;
  }

  const toggleSideMenu = () => {
    console.log(7)
    dispatch(taskInfoActions.setShowSideMenu({show:!showSideMenu}));
  }

  const onTabClick = (tab:string) => {
    if (!isWindowLarge) {
      toggleSideMenu();
    }
    setTabSelected(tab);
  }

  return (
      <div>
        {!hidePageContent &&
          <Navbar children={<div></div>}/>
        }
        <Box sx={{ display: 'flex' }}>
          {showSideMenu &&
            <Drawer
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                  },
                  zIndex: 9999
                }}
                variant="permanent"
                anchor="left"
            >
              {/*<Toolbar />*/}
                <List>
                    <ListItem button key={'home'}
                      secondaryAction={
                        <Fragment>
                          { !isWindowLarge &&
                          <IconButton size="large" onClick={toggleSideMenu}>
                              <GridMenuIcon/>
                          </IconButton>
                          }
                        </Fragment>
                      }
                    >
                        <Typography variant="h5" component="div" sx={{flexGrow: 1}} onClick={() => history.push('/home')}>
                            <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-dark.png`}
                                 alt={'Logo Image'}
                                 style={{width:'170px', height: '50px'}}/>
                        </Typography>
                    </ListItem>
                    <ListItem button key={'welcome'} style={{pointerEvents: "none"}}>
                        <ListItemIcon style={{pointerEvents: 'all'}}>
                            <Avatar {...avatarService.stringAvatar(userInfo.userInfo.firstName + ' ' + userInfo.userInfo.lastName)} />
                        </ListItemIcon>
                        <ListItemText primary={'Welcome, ' + userInfo.userInfo.firstName}/>
                    </ListItem>
                  {orgInfo.orgInfo && orgInfo.orgInfo.name &&
                  <ListItem button key={'organization'} style={{pointerEvents: "none"}}>
                      <ListItemText primary={'Org '}/>
                      <Chip label={orgInfo.orgInfo.name} variant="outlined"
                            style={{pointerEvents: 'all', cursor: 'pointer'}}/>
                  </ListItem>
                  }
                  {orgInfo.orgInfo && orgInfo.orgAccounts.length == 1 && orgInfo.orgInfo.accessCode !== userInfo.userInfo.accessCode &&
                  <ListItem button key={'account'} style={{pointerEvents: "none"}}>
                      <ListItemText primary={'Account '}/>
                      <Chip label={orgInfo.orgAccounts[0].name} variant="outlined"
                            style={{pointerEvents: 'all', cursor: 'pointer'}}/>
                  </ListItem>
                  }
                    <ListItem button key={'accessCode'} style={{pointerEvents: "none"}}>
                        <ListItemText primary={'Access Code '}/>
                        <Chip label={userInfo.userInfo.accessCode} variant="outlined"
                              style={{pointerEvents: 'all', cursor: 'pointer'}}/>
                    </ListItem>
                </List>
                <Divider/>
                <List>
                    <ListItem button key={'userInfo'}
                              style={{backgroundColor: tabSelected === 'userInfo' ? 'lightblue' : ''}}
                              onClick={() => onTabClick('userInfo')}>
                        <ListItemIcon>
                            <AccountCircleIcon/>
                        </ListItemIcon>
                        <ListItemText primary={'User Profile'}/>
                    </ListItem>
                  {(userInfo.userInfo.role === Role.ADMIN || userInfo.userInfo.isAdmin) &&
                  <ListItem button key={'admin'}
                            style={{backgroundColor: tabSelected === 'admin' ? 'lightblue' : ''}}
                            onClick={() => onTabClick('admin')}>
                      <ListItemIcon>
                          <AdminPanelSettings/>
                      </ListItemIcon>
                      <ListItemText primary={'Admin Portal'}/>
                  </ListItem>
                  }
                  <ListItem button key={'companydirectory'}
                            style={{backgroundColor: tabSelected === 'companydirectory' ? 'lightblue' : ''}}
                            onClick={() => onTabClick('companydirectory')}>
                    <ListItemIcon>
                      <LibraryBooksIcon/>
                    </ListItemIcon>
                    <ListItemText primary={'Company Directory'}/>
                  </ListItem>
                  {/*<ListItem button key={'twoFactor'}
                            style={{backgroundColor: tabSelected === 'twoFactor' ? 'lightblue' : ''}}
                            onClick={() => setTabSelected('twoFactor')}>
                    <ListItemIcon>
                      <FingerprintIcon/>
                    </ListItemIcon>
                    <ListItemText primary={'2Factor'} />
                  </ListItem>*/}
                </List>
                <Divider/>
                <List>
                    <Fragment>
                      {!(orgInfo.orgInfo && orgInfo.orgInfo.accessCode) && (
                          <ListItem button key={'orgInfo'}
                                    style={{backgroundColor: tabSelected === 'orgInfo' ? 'lightblue' : ''}}
                                    onClick={() => onTabClick('orgInfo')}>
                            <ListItemIcon>
                              <DomainIcon/>
                            </ListItemIcon>
                            <ListItemText primary={'Org Info'}/>
                          </ListItem>
                      )}
                      {orgInfo.orgInfo && orgInfo.orgInfo.accessCode && (
                          <Fragment>
                            <ListItem button key={'orgAccounts'}
                                      style={{backgroundColor: tabSelected === 'orgAccounts' ? 'lightblue' : ''}}
                                      onClick={() => onTabClick('orgAccounts')}>
                              <ListItemIcon>
                                <AccountTreeIcon/>
                              </ListItemIcon>
                              <ListItemText primary={'Org Accounts'}/>
                            </ListItem>
                            {(checkUserAdmin(userInfo.userInfo) || userInfo.userInfo.role === Role.ORG_MANAGER) && (
                                <ListItem button key={'allowedMembers'}
                                          style={{backgroundColor: tabSelected === 'allowedMembers' ? 'lightblue' : ''}}
                                          onClick={() => onTabClick('allowedMembers')}>
                                  <ListItemIcon>
                                    <SecurityIcon/>
                                  </ListItemIcon>
                                  <ListItemText primary={'Allowed Members'}/>
                                </ListItem>
                            )}
                          </Fragment>
                      )}
                    </Fragment>
                </List>
                <Divider/>
              {(orgInfo.orgInfo.allowedEmails.length !== 0 ||
                  orgInfo.orgAccounts.filter(acc => acc.allowedEmails.length !== 0).length > 0) && (
                  <List>
                    <ListItem button key={'tasks'}
                              onClick={() => history.push('/manage/labor')}>
                      <ListItemIcon>
                        <FormatListNumberedIcon/>
                      </ListItemIcon>
                      <ListItemText primary={'Labor Dashboard'}/>
                    </ListItem>
                  </List>
              )}
            </Drawer>
          }

          <Box sx={{p: 3, width: '100%', height: !hidePageContent ? '100%' : '95vh',
              backgroundColor: "lightgrey"}}>
            {!hidePageContent &&
              <Fragment>
                {tabSelected === 'userInfo' && (
                    <UserAccountInfo/>
                )}
                {tabSelected === 'admin' && (
                    <AdminPortal/>
                )}
                {tabSelected === 'orgInfo' && (
                    <OrgInfoUpdate/>
                )}
                {tabSelected === 'companydirectory' && (
                    <CompanyDirectoryCustom/>
                )}
                {tabSelected === 'twoFactor' && (
                    <Container>
                      <p>twoFactor</p>
                    </Container>
                )}
                {(tabSelected === 'orgAccounts') && (
                    <OrgAccountsManage/>
                )}
                {((checkUserAdmin(userInfo.userInfo) || userInfo.userInfo.role === Role.ORG_MANAGER) && tabSelected === 'allowedMembers') && (
                    <AllowedMembers/>
                )}
              </Fragment>
            }

            <AlertToast/>
            <LoadingSpinnerFull showLoading={showLoading}/>
          </Box>
        </Box>

        { !hidePageContent &&
          <Footer/>
        }
      </div>
  );
}

export default AccountPage;