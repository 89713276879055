import React, {Fragment, useEffect, useState} from "react";
import {Route, Switch, useHistory} from "react-router";
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {experienceActions, orgInfoActions, RootState, userInfoActions} from "../store/storeIndex";
import {OrgInfoState, UserInfo} from "../types/account/AccountTypes";
import {userInfoService} from "../service/account/userInfoService";
import {userInfoInit} from "../store/account/userInfoSlice";
import LoadingSpinnerFull from "../component/general/LoadingSpinnerFull";
import AccountPage from "../pages/AccountPage";
import {orgInfoService} from "../service/account/orgInfoService";
import LaborPage from "../pages/LaborPage";
import {determineIfTokenError} from "../store/account/authSlice";
import {useAppThunkDispatch} from "../store/storeHooks";
import {Typography, useMediaQuery} from "@mui/material";
import {checkUserAdmin} from "../service/account/accountService";
import JobBoardTvPage from "../pages/JobBoardTvPage";

const ProtectedRoutes: React.FC = () => {
  let dispatch = useAppThunkDispatch();
  const history = useHistory();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (userInfo === userInfoInit) {
      setShowLoading(true);
      loadUserInformation();
    }
  }, []);

  useEffect(() => {
    if (userInfo && userInfo.accessCode && orgInfo.orgInfo.accessCode.length > 0) {
      handleRoutingAfterInitialDataLoad();
    }
  }, [userInfo, orgInfo.orgInfo])

  const loadUserInformation = () => {
    new Promise<void>(resolve => {
      orgInfoService.getAllAccountData()
          .then((newOrgInfoState: OrgInfoState) => {
            if (newOrgInfoState.orgInfo && newOrgInfoState.orgInfo.accessCode) {
              dispatch(orgInfoActions.updateAccountSuccess({orgInfoState: newOrgInfoState}));
            }
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      });
      userInfoService.fetchUserInfo()
          .then((userObject: UserInfo) => {
            if (userObject !== null && userObject.firstName !== '') {
              dispatch(userInfoActions.updateSuccess({userInfo: userObject}));
            }
          }).catch(e => dispatch(determineIfTokenError(e)))
          .finally(() => resolve());
    }).finally(() => {
      //handleRoutingAfterInitialDataLoad();
      setShowLoading(false);
    });
  }

  const handleRoutingAfterInitialDataLoad = () => {
    if (!window.location.pathname.includes('labor/jobBoardTv')) {
      /*console.log("efefefefefefefefefefefefefeefefefefeef")
      console.log(userInfo.firstName);
      console.log(userInfo.accessCode);
      console.log(orgInfo.orgInfo.allowedEmails);
      console.log(window.location.pathname);*/
      if (userInfo.firstName.length > 0 && userInfo.accessCode.length === 0) {
        history.push('/manage/user/account');
      }
      if (shouldForceRouteToLaborDash(userInfo)) {
        if (!userInfo.seenIntroTutorial) {
          dispatch(experienceActions.setShowLaborDashboardTutorial({value: true}));
        }
        if (!window.location.pathname.includes('labor')) {
          history.push('/manage/labor');
        }
      } else {
        history.push('/manage/user/account');
      }
    }
    setShowLoading(false);
  }

  const shouldForceRouteToLaborDash = (userObject: UserInfo) => {
    return (orgInfo.orgInfo.allowedEmails.length > 0 ||
            orgInfo.orgAccounts.map(acc => acc.allowedEmails).flat(2).length > 0) &&
        !window.location.pathname.includes('user/account');
  }

  if (!loggedIn) {
    return <Redirect to={'/login'} />
  }

  return showLoading ? (
      <Fragment>
        <Typography variant={isWindowLarge ? "h1" : "h3"} component="div" sx={{textAlign:"center", marginTop:'160px'}}>
          <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-dark.png`}
               alt={'Logo Image'}
               style={{width:'400px', height: '110px'}}/>
        </Typography>
        <Typography variant="h5" component="div" sx={{textAlign:"center"}}>
          Apply Top Performance
        </Typography>
        <LoadingSpinnerFull showLoading={showLoading} />
      </Fragment>
  ) : (
      <Switch>

        {(checkUserAdmin(userInfo) && orgInfo.orgInfo.allowedEmails.length === 0 &&
            orgInfo.orgAccounts.filter(acc => acc.allowedEmails.length !== 0).length === 0) &&
          <Redirect exact path="/manage/labor" to="/manage/user/account" />
        }

        <Route exact path="/manage/user/account">
          <AccountPage/>
        </Route>

        <Route exact path="/manage/labor/jobBoardTv/:selectedDate/:accessCodes">
          <JobBoardTvPage/>
        </Route>

        <Route exact path="/manage/labor/:tab?">
          <LaborPage/>
        </Route>

      </Switch>
  );
}

export default ProtectedRoutes;