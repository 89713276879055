import {
  AlertsBadge,
  AppLogPrintUrl,
  GddSinceAppContainer,
  PlaybooksResponse,
  YearlyAndAlertsContainer
} from "./PlaybooksTypes";

export const alertsBadgeInit:AlertsBadge = {
  Number:-1
}

export const yearlyAndAlertsContainerInit: YearlyAndAlertsContainer = {
  "New Alerts Badge":alertsBadgeInit,
  NewAlertsBadge:alertsBadgeInit
}

export const playbooksResponseInit: PlaybooksResponse = {
  appLogPrintUrls: [],
  gddSinceApps: [],
  monthlyNutrients: [],
  yearlyAndAlerts: yearlyAndAlertsContainerInit,
  selectedApplantAccessCode:'',
}