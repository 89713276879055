import {TaskInfo} from "../../types/task/TaskTypes";
import {IconButton, Stack, Tooltip} from "@mui/material";
import TaskActions from "./TaskActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import { PictureAsPdf } from "@mui/icons-material";


type Props = {
    task:TaskInfo,
    handleTaskSelected: (task: TaskInfo) => void,
    taskDialogOpen: boolean,
    setTaskDialogOpen: (value:boolean) => void,
    getMonthlyTasks: (ac: AbortController) => void
    getJobBoardUtil: () => void
}

const TaskActionButtonsStack:React.FC<Props> = (props) => {
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);

  return (
      <Stack direction="row" spacing={0}>
        {props.task?.pdfIds?.length > 0 &&
            <Tooltip title={"Pdf(s) attached"}>
              <PictureAsPdf sx={{p:1}}/>
            </Tooltip>
        }
        <TaskActions
            task={props.task}
            selectedDate={new Date(selectedDate)}
            taskDialogOpen={props.taskDialogOpen}
            setTaskDialogOpen={props.setTaskDialogOpen}
        />
        <IconButton aria-label="more"
                    onClick={() => props.handleTaskSelected(props.task)}>
          <MoreVertIcon color={"action"}/>
        </IconButton>
      </Stack>
  );
}

export default TaskActionButtonsStack;