import {createSlice} from "@reduxjs/toolkit";
import {OrgInfo} from "../../types/account/AccountTypes";
import {orgInfoInit} from "./orgInfoSlice";

export interface AdminAccountState {
  accountsList:AdminAccountsHolder[],
  selectedAccount:OrgInfo
}

export interface AdminAccountsHolder {
  name:string,
  accessCode:string,
  accountParent:boolean
}

export const adminAccountStateInit: AdminAccountState = {
  accountsList:[],
  selectedAccount:orgInfoInit
}

export const adminAccountsHolderInit: AdminAccountsHolder = {
  name:"",
  accessCode:"",
  accountParent:false
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState: adminAccountStateInit,
  reducers: {
    setAllAccounts(state, action) {
      state.accountsList = action.payload.accountsList;
    },
  }
});