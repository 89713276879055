import {Theme} from "@mui/material/styles";


export function getMultiSelectStyles(name: string, arrayStrings: readonly string[], theme: Theme) {
  return {
    fontWeight:
        arrayStrings.indexOf(name) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuMultiSelectProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
