import {createSlice} from "@reduxjs/toolkit";
import {playbooksResponseInit} from "../../types/task/PlaybooksInits";


export const playbooksSlice = createSlice({
  name: 'playbooks',
  initialState: playbooksResponseInit,
  reducers: {
    setAllData(state, action) {
      state.appLogPrintUrls = action.payload.playbooksResponse.appLogPrintUrls;
      state.gddSinceApps = action.payload.playbooksResponse.gddSinceApps;
      state.monthlyNutrients = action.payload.playbooksResponse.monthlyNutrients;
      state.yearlyAndAlerts = action.payload.playbooksResponse.yearlyAndAlerts;
    },
    setSelectedApplantAccessCode(state, action) {
      state.selectedApplantAccessCode = action.payload.value;
    },
    resetToInit(state) {
      state = playbooksResponseInit;
    }
  }
});