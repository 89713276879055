import React, {Fragment, useEffect, useState} from "react";
import {blue, grey} from "@mui/material/colors";
import {Card, CardContent, CardHeader, Divider, Stack, Switch, Typography} from "@mui/material";
import {getPrettyTextFromStringArray} from "../../service/TasksHelper";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {currencyFormat} from "../../service/generic/DateHelper";
import {MonthlyStackedAccountBudgetChartItem, QuickReport} from "../../types/charts/chartTypes";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {taskManagementService} from "../../service/taskManagementService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {avatarService} from "../../service/generic/avatarService";
import {DARK_GREEN, DARK_NAVY, GHOST_WHITE, GREEN, LIGHT_GREY_BLUE} from "../../service/generic/ColorScheme";


type Props = {

}

const QuickReportWidget: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const orgInfo = useSelector((state: RootState) => state.orgInfo);

  const [quickReport, setQuickReport] = useState<QuickReport | null>(null);
  const [showQuickReportLoading, setShowQuickReportLoading] = useState(false);
  const [monthExpensesChart, setMonthExpensesChart] = useState<any[]>([]);
  const [yearExpensesChart, setYearExpensesChart] = useState<any[]>([]);
  const [viewYearly, setViewYearly] = useState(false);

  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august',
    'september', 'october', 'november', 'december'];

  useEffect(() => {
    if (orgInfo.allSelectedAccessCodes?.length > 0) {
      fetchQuickReport();
    }
  }, [orgInfo.allSelectedAccessCodes])

  const fetchQuickReport = () => {
    setShowQuickReportLoading(true);
    taskManagementService.fetchQuickReport(orgInfo.allSelectedAccessCodes!)
        .then(report => {
          console.log(report)
          setQuickReport(report);
          convertDataToChartConsumable(report.monthlyChartExpenses, setMonthExpensesChart);
          convertDataToChartConsumable(report.yearlyChartExpenses, setYearExpensesChart);
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => setShowQuickReportLoading(false));
  }

  const convertDataToChartConsumable = (data:MonthlyStackedAccountBudgetChartItem[], setter:(val:any[]) => void) => {
    let chartData:any[] = [];
    for (let month of months) {
      let obj: any = {};
      for (let account of data) {
        obj["tag"] = month.slice(0, 3);
        obj[account.accountName] = account[month as keyof MonthlyStackedAccountBudgetChartItem];
        //chartData = [...chartData, obj];
      }
      chartData.push(obj);
    }
    setter(chartData);
  }

  return (
      <Fragment>
        <Card sx={{width:'100%', height:'540px', backgroundColor: GHOST_WHITE}}> {/*display:'flex', justifyContent:'center', alignItems: 'center'*/}
          <CardHeader
              sx={{pb:0}}
              title={
                <Fragment>
                  <Typography variant="h5" component="div" style={{color:DARK_NAVY}}>
                    Reports Quick Look<br/>
                  </Typography>
                  <Typography variant="subtitle1" component="div" style={{color:DARK_NAVY}}>
                    {getPrettyTextFromStringArray(orgInfo.selectedAccounts.flatMap(acc => acc.name)) +
                    (orgInfo.selectedAccounts.length > 1 ? " cumulative" : "")}
                  </Typography>
                </Fragment>
              }
          />
          <CardContent >
            {showQuickReportLoading || orgInfo.selectedAccounts.length < 1 ? <LoadingSpinnerContainer showLoading={showQuickReportLoading}/> :
                <Fragment>
                  <Stack direction={'row'} justifyContent="center" spacing={3} sx={{mb:4}}
                         divider={<Divider orientation={'vertical'} flexItem/>}>
                    <Typography variant="h6" component="div" style={{textAlign: 'right', color:DARK_NAVY}}>
                      MTD
                      {quickReport &&
                      <div style={{color: GREEN, fontSize:'140%', fontWeight:"bold"}}>
                        {currencyFormat(quickReport.monthToDateExpenses)}
                      </div>
                      }
                    </Typography>
                    <Typography variant="h6" component="div" style={{color:DARK_NAVY}}>
                      YTD
                      {quickReport &&
                      <div style={{color: GREEN, fontSize:'140%', fontWeight:"bold"}}>
                        {currencyFormat(quickReport.yearToDateExpenses)}
                      </div>
                      }
                    </Typography>
                  </Stack>

                  <Typography variant="h6" component="div" sx={{textAlign:'center', color:DARK_NAVY}}>
                    Expenses Tracker
                  </Typography>

                  <Stack direction="row" spacing={1} alignItems="center"
                         sx={{display:'flex', justifyContent:'center'}}>
                    <Typography style={{color:DARK_NAVY}}>Individual Monthly</Typography>
                    <Switch
                        checked={viewYearly}
                        color={"success"}
                        onChange={() => setViewYearly(!viewYearly)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography style={{color:DARK_NAVY}}>Accumulated Yearly</Typography>
                  </Stack>

                  <ResponsiveContainer width="100%" height={250}>
                    <LineChart height={250} data={viewYearly ? yearExpensesChart : monthExpensesChart}
                               margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis dataKey={"tag"} />
                        <YAxis/>
                        <Tooltip/>
                        <Legend verticalAlign="top" iconSize={25} height={30}/>

                      { orgInfo.selectedAccounts.map((acc,index) => (
                        <Line key={acc.name + index} type="monotone" dataKey={acc.name}
                              stroke={avatarService.stringToColor(acc.name)}/>
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </Fragment>
            }
          </CardContent>
        </Card>
      </Fragment>
  );
}

export default QuickReportWidget;