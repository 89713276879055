import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {alertActions, RootState} from "../../store/storeIndex";
import {Alert, AlertTitle, Slide, Snackbar, SnackbarOrigin} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import {useAppThunkDispatch} from "../../store/storeHooks";

export const AlertToast = () => {
  let dispatch = useAppThunkDispatch();
  const {showAlert, success, message} =
      useSelector((state: RootState) => state.alert);
  const [showToastState, setShowToastState] = useState(showAlert);

  useEffect(() => {
    setShowToastState(showAlert);
  }, [showAlert]);

  const handleClose = () => {
    dispatch(alertActions.hideAlert());
    setShowToastState(false);
  }

  function TransitionRight(props: TransitionProps) {
    return <Slide {...props} direction="right" />;
  }

  return (
      <Snackbar style={{zIndex:99999}}
                open={showToastState}
                autoHideDuration={10000}
                /*TransitionComponent={TransitionRight}*/
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}>
        <Alert style={{zIndex:21000, border:'3px solid black'}}
               onClose={handleClose} severity={success ? "success" : "error"}>
          <AlertTitle>{success ? "Success" : "Error"}</AlertTitle>
          <b>{message}</b>
        </Alert>
      </Snackbar>
  );
};

export default AlertToast;
