import {createSlice} from "@reduxjs/toolkit";
import {Role} from "../../types/account/AccountTypes";
import {
  accountActions,
  AppDispatch,
  AppThunkPromise,
  authActions,
  notificationActions,
  orgInfoActions,
  taskInfoActions,
  userInfoActions
} from "../storeIndex";
import {history} from "../../routes/historyGlobal";
import {displayAlert} from "../alertSlice";
import {userInfoInit} from "./userInfoSlice";

export interface AuthState {
  loggedIn: boolean,
  username: string,
  pass: string,
  signupUserTypeSelected: Role | null
}
const authState: AuthState = {
  loggedIn: false,
  username: '',
  pass: '',
  signupUserTypeSelected: null
}
export interface AuthError {
  message: string
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: authState,
  reducers: {
    logout(state, action) {
      state.loggedIn = false;
    },
    loginSuccess(state, action) {
      state.loggedIn = true;
    },
    selectUserTypeOnSignup(state, action) {
      state.signupUserTypeSelected = action.payload.userType;
    }
  }
});

export const logoutAndClearAllSlices = (redirectToLogin?:boolean):
    AppThunkPromise => async (dispatch: AppDispatch) => {
  try {
    localStorage.removeItem('user');
    dispatch(authActions.logout({loggedIn:false}));
    dispatch(userInfoActions.resetToInit());
    dispatch(orgInfoActions.resetToInit());
    dispatch(accountActions.resetToInit());
    dispatch(taskInfoActions.resetToInit());
    dispatch(notificationActions.resetToInit());
    if (redirectToLogin === undefined) {
      history.push('/login');
      window.location.reload();
    }
  } catch (e: any) {
    dispatch(displayAlert(false, 'Error logging out'));
  }
}

export const determineIfTokenError = (error: Error):
    AppThunkPromise => async (dispatch: AppDispatch) => {
  if (error.message.toLowerCase().includes("token") && localStorage.getItem('user')) {
    dispatch(logoutAndClearAllSlices()).then(() => {
      dispatch(displayAlert(false, error.message));
    });
  } else {
    // memory leak fixing. Abort Controller now throws this. not sure what is being aborted
    if (!error.message.toLowerCase().includes("aborted a request")) {
      dispatch(displayAlert(false, error.message));
    }
  }
}
