import React, {forwardRef, Fragment} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {
    Box, Button, Checkbox,
    Dialog, DialogActions,
    FormControl, FormLabel,
    Grid,
    styled,
    Tooltip,
    Typography,
    useMediaQuery
} from "@mui/material";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {DARK_NAVY} from "../../service/generic/ColorScheme";
import ValidButton from "../general/ValidButton";
import {PDF_HEIGHT_PIXELS, PDF_WIDTH_PIXELS} from "../../util/constants";

interface CheckPoints {
    tag: string;
    compliant: boolean | null;
    hazard: boolean | null;
}

type Props = {
    taskId: string,
    pgSafetyInspectionFormInfoState: { [key: string]: any; };
    safetyCheckPoints:CheckPoints[];
    equipmentCheckPoints:CheckPoints[];
    isOpen: boolean,
    setIsOpen: (open: false) => void,
    handleSubmitForm: () => void;
}

const UnderlinedText = styled("span")({
    textDecoration: "underline",
});

const PlayGroundSafetyInspectionFormPreview: React.FC<Props> = forwardRef<HTMLDivElement, Props>((props, ref) => {
    let dispatch = useAppThunkDispatch();
    const isWindowSmall = useMediaQuery('(max-width:415px)');

    const [showLoading, setShowLoading] = React.useState(false);
    const { location, inspectionDate, inspectionTime, inspectedBy, ageAppropriate, comments } = props.pgSafetyInspectionFormInfoState;

    const formattedInspectedBy = inspectedBy.join(", ");
    const formattedDate = new Date(inspectionDate).toLocaleDateString();
    const formattedTime = new Date(inspectionTime).toLocaleTimeString();

    const closePreviewDialog = () => {
        props.setIsOpen(false);
    }

    return (
        <Fragment>
            { showLoading ? (
                <Box sx={{marginTop:'200px'}}>
                    <LoadingSpinnerContainer showLoading={showLoading} />
                </Box>
            ) : (
                <Dialog
                    fullScreen
                    open={props.isOpen}
                >
                    <Box sx={{backgroundColor: 'lightgray', overflowY:'hidden'}}>

                        <Box sx={{pb:5}}>
                            <Box ref={ref} sx={{
                                backgroundColor: 'white',
                                width: PDF_WIDTH_PIXELS,
                                height: PDF_HEIGHT_PIXELS,
                                mx: "auto", mt:3,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-end',
                            }}>
                                <Typography variant="h5" align="center" gutterBottom sx={{mt:-15}}>
                                    <b>Frequent Playground Safety Inspection Form</b>
                                </Typography>

                                <Box sx={{ml:10}}>
                                    <Grid container spacing={2} sx={{mt:2, mb:-1}}>
                                        <Grid item xs={12} sx={{mb:-2.5}}>
                                            <Typography variant="body1">
                                                <b>Location:{" "}</b>
                                                <UnderlinedText>{location}</UnderlinedText>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{mb:-2.5}}>
                                            <Typography variant="body1">
                                                <b>Inspection Date:{" "}</b>
                                                <UnderlinedText>{formattedDate}</UnderlinedText>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{mb:-2.5}}>
                                            <Typography variant="body1">
                                                <b>Time:{" "}</b>
                                                <UnderlinedText>{formattedTime}</UnderlinedText>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{mb:-2.5}}>
                                            <Typography variant="body1">
                                                <b>Inspected by:{" "}</b>
                                                <UnderlinedText>{formattedInspectedBy}</UnderlinedText>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{mb:-2.5}}>
                                            <Typography variant="body1">
                                                <b>Age Appropriate:{" "}</b>
                                                <UnderlinedText>{ageAppropriate}</UnderlinedText>
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Typography gutterBottom sx={{ mt: 5, fontSize:'90%'}}>
                                        <b>Priority Rating Scale (1-4)</b>
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:-.5, fontSize:'80%'}}>
                                        1. Permanent disability, loss of life or body part – correct
                                        immediately.
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:-.5, fontSize:'80%'}}>
                                        2. Serious injury resulting in temporary disability – correct as soon
                                        as possible.
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:-.5, fontSize:'80%'}}>
                                        3. Minor (Non-Disabling) Injury – correct when time permits.
                                    </Typography>
                                    <Typography variant="body1" sx={{mb:-.5, fontSize:'80%'}}>
                                        4. Potential for injury Minimal – check to see if worsens.
                                    </Typography>
                                </Box>

                                <Box sx={{ pl: 20, mt:-2.5 }}>
                                    <FormControl component="fieldset" sx={{ mt: 4 }}>
                                        <FormLabel component="legend">
                                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                                SAFETY SURFACINGS
                                            </Typography>
                                        </FormLabel>
                                        {props.safetyCheckPoints.map((item, index) => (
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                                key={index}
                                                sx={{ whiteSpace: "nowrap", mb:-2.4 }}
                                            >
                                                <Grid item xs={1}>
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: "bold" }}>{index + 1}.</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{item.tag}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={{ textAlign: "right", mt:-.45 }}>
                                                    <Checkbox checked={item.compliant ?? false} disabled />
                                                </Grid>
                                                <Grid item xs={1} sx={{ textAlign: "right", mt:-.45 }}>
                                                    <Checkbox checked={item.hazard ?? false} disabled />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </FormControl>

                                    <FormControl component="fieldset" sx={{ mt: 1 }}>
                                        <FormLabel component="legend">
                                            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                                EQUIPMENT
                                            </Typography>
                                        </FormLabel>
                                        {props.equipmentCheckPoints.map((item, index) => (
                                            <Grid
                                                container
                                                spacing={1}
                                                alignItems="center"
                                                key={index}
                                                sx={{ whiteSpace: "nowrap", mb:-2.4 }}
                                            >
                                                <Grid item xs={1}>
                                                    <Typography variant="body1">
                                                        <span style={{ fontWeight: "bold" }}>{index + 9}.</span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Typography>{item.tag}</Typography>
                                                </Grid>
                                                <Grid item xs={1} sx={{ textAlign: "right", mt:-.45 }}>
                                                    <Checkbox checked={item.compliant ?? false} disabled />
                                                </Grid>
                                                <Grid item xs={1} sx={{ textAlign: "right", mt:-.45 }}>
                                                    <Checkbox checked={item.hazard ?? false} disabled />
                                                </Grid>
                                            </Grid>
                                        ))}
                                    </FormControl>
                                </Box>

                                <Box sx={{ml:20, mr:22, mt: 2.5}}>
                                    <Typography variant="h6" gutterBottom>
                                        Required Repairs / Comments:
                                    </Typography>
                                    <Typography>
                                        <UnderlinedText>
                                            {comments}
                                        </UnderlinedText>
                                    </Typography>
                                </Box>

                                <Box sx={{pb:1}}>
                                    <Box sx={{display: 'flex', justifyContent: 'center', mt:3}}>
                                        <Typography sx={{ textDecoration: 'underline' }}>
                                            Compliments of: safe2play.net
                                        </Typography>
                                    </Box>
                                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                        <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/playground-safety-pdf-logo.png`}
                                             alt={'playgroundsafetypdflogo'}
                                             style={{width:'13%', height: '13%'}}/>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <DialogActions>
                        <Button style={{color:DARK_NAVY}} autoFocus onClick={closePreviewDialog}>
                            Cancel
                        </Button>
                        <Tooltip title={isWindowSmall ? "Window size needs to be wider." : "Preview Pdf"}>
                            <ValidButton text={'Submit'}
                                         disabled={isWindowSmall}
                                         handleSubmit={() => props.handleSubmitForm()}
                                         isFormValid={() => !isWindowSmall}
                            />
                        </Tooltip>
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
})

export default PlayGroundSafetyInspectionFormPreview;