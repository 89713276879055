import {AccountInfo, AllowedEmail, OrgInfo, OrgInfoState, Role, UserInfo} from "../../types/account/AccountTypes";
import {httpCommonService} from "../generic/httpCommonService";
import {accountInfoInit, orgInfoInit, orgInfoStateInit} from "../../store/account/orgInfoSlice";
import {AdminAccountState} from "../../store/account/AdminSlice";
import {BudgetUtil} from "../../types/task/BudgetTypes";


export const accountService = {
  addNewAllowedMember,
  removeAllowedMembers,
  editAllowedMember,
  addNewAccount,
  editAccount,
  checkIfEmailAlreadyExists,
}

export const checkUserAdmin =(userInfo: UserInfo) => {
  return userInfo.role === Role.ORG_ADMIN || userInfo.role === Role.ADMIN;
}

export const checkUserNotOrdinaryWorker =(userInfo: UserInfo) => {
  return userInfo.role !== Role.ORG_WORKER;
}

export const checkUserManagerForParentOrg = (userInfo: UserInfo, parentAccessCode:string) => {
  return userInfo.role !== Role.ORG_WORKER && userInfo.accessCode === parentAccessCode;
}

async function handleApiAllowedEmailSuccess(responseAny: any) {
  let response: OrgInfoState = await responseAny.json();
  return response;
}

async function handleApiAccountSuccess(responseAny: any) {
  const responseText = await responseAny.text();
  try {
    let response: OrgInfoState = JSON.parse(responseText);
    return response;
  } catch (e) {
    return orgInfoStateInit;
  }
}

//------------------------------------------------------------------------------

async function addNewAllowedMember(ae: AllowedEmail) {
  try {
    let token = localStorage.getItem('user') as string;
    let response = await addNewAllowedMemberHttpCall(token, ae);
    return response;
  } catch (e) {
    throw e;
  }
}

function addNewAllowedMemberHttpCall(token: string, ae: AllowedEmail): Promise<OrgInfoState> {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(ae),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/allowedMembers/add`, requestOptions)
      .then(httpCommonService.handleApiError)
      .then(handleApiAllowedEmailSuccess)
      .catch(e => {throw e});
}

//----------------------------------------------------------

function removeAllowedMembers(aeIds: string[], codes:string[]): Promise<OrgInfoState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/allowedMembers/remove/${codes.toString()}`, {
      method: 'DELETE',
      body: JSON.stringify({allowedEmailIds: aeIds}),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiAllowedEmailSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

//------------------------------------------------------------


//-------------------------------------------------------------

async function editAllowedMember(ae: AllowedEmail) {
  try {
    let token = localStorage.getItem('user') as string;
    let response = await editAllowedMemberHttpCall(token, ae);
    return response;
  } catch (e) {
    throw e;
  }
}

function editAllowedMemberHttpCall(token: string, ae: AllowedEmail): Promise<OrgInfoState> {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(ae),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    }
  };

  //`${ENV.REACT_APP_BACKEND_URL}/api/updateUserInfo`
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/allowedMembers/edit/`, requestOptions)
      .then(httpCommonService.handleApiError)
      .then(handleApiAllowedEmailSuccess)
      .catch(e => {throw e});
}

//-----------------------------------------------------

function addNewAccount(accountInfo: AccountInfo): Promise<OrgInfoState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account/create`, {
      method: 'POST',
      body: JSON.stringify(accountInfo),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiAccountSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

function editAccount(accountInfo: AccountInfo): Promise<OrgInfoState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account/edit`, {
      method: 'POST',
      body: JSON.stringify(accountInfo),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiAccountSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

async function checkIfEmailAlreadyExists(email:string) {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/email/doesExist/${email}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(async (response: any) => {
          if (response.status !== 200 && response.status !== 400) {
            await httpCommonService.handleApiError(response);
          }
          return response;
        })
        .then(async (response: any) => await !(response.status === 200))
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}