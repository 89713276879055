import React, {Fragment, useEffect, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {useHistory} from "react-router";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader, Chip, Divider,
  Fab,
  Grid, IconButton, Paper,
  Stack as MuiStack,
  Stack,
  Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField, Tooltip, Typography, useMediaQuery
} from "@mui/material";
import {accountActions, companyDirectoryActions, jobBoardActions, RootState, scheduleActions, taskInfoActions} from "../../store/storeIndex";
import {useSelector} from "react-redux";
import {jobBoardService} from "../../service/jobBoardService";
import {currencyFormat, formatDate} from "../../service/generic/DateHelper";
import {CompanyDirectoryResponse, JobBoardResponse} from "../../types/task/TaskTypes";
import {determineIfTokenError} from "../../store/account/authSlice";
import {companyDirectoryService} from "../../service/companyDirectoryService";
import CustomTable from "../general/table/CustomTable";
import {cdHeadCells, CompanyDirectoryTableCells} from "../../types/charts/AccountTableProps";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {DARK_NAVY, GHOST_WHITE} from "../../service/generic/ColorScheme";

type Props = {
}

const CompanyDirectoryComponent:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowXL = useMediaQuery('(min-width:1020px)');

  const companyDirectoryState = useSelector((state:RootState) => state.companyDirectory);

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [showLoading, setShowLoading] = React.useState(false);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);

  useEffect(() => {
    getCompanyDirectory();
    document.title = "Company Directory Page";
  }, [])

  const getCompanyDirectory = () => {
    setShowLoading(true);
    companyDirectoryService.getCompanyDirectory()
        .then((resp: CompanyDirectoryResponse) => {
          resp.companyDirectoryList.forEach(emp => emp.id = Math.random().toString());
          dispatch(companyDirectoryActions.setCompanyDirectoryResponse({value:resp}));
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
    setShowLoading(false);
  }

  const determineRowHidden = (rowId: string, filterText: string): boolean => {
    if (filterText.length > 0) {
      let row = companyDirectoryState.companyDirectoryResponse
          .companyDirectoryList.find(comp => comp.id === rowId);
      if (row) {
        filterText = filterText.toLowerCase();
        if (!(row.accountName?.toLowerCase().includes(filterText) || row.employeeFirstName?.toLowerCase().includes(filterText) ||
            row.employeeLastName?.toLowerCase().includes(filterText) || row.role?.toLowerCase().includes(filterText) ||
            (row.userEmail?.toLowerCase().includes(filterText)) || (row.phoneNumber?.toLowerCase().includes(filterText)))) {
          return true;
        }
      }
    }
    return false;
  }

  return (
      <Fragment>
        { showLoading ? (
            <Box sx={{marginTop:'200px'}}>
              <LoadingSpinnerContainer showLoading={showLoading} />
            </Box>
        ) : (
            <Card style={{backgroundColor:GHOST_WHITE, color:DARK_NAVY}}>
              <CardContent>
                <div style={{ width: isWindowXL ? '100%' : '99%' }}>
                  <CustomTable
                      rows={companyDirectoryState.companyDirectoryResponse?.companyDirectoryList}
                      headCells={cdHeadCells}
                      selectedRows={[]}
                      setSelectedRows={() => {}}
                      TableCellsFunc={CompanyDirectoryTableCells}
                      //rowIdsList={rowIdsList}
                      rowIdsList={companyDirectoryState.companyDirectoryResponse.companyDirectoryList?.map(comp => comp.id)}
                      tableName={'Company Directory'}
                      noSelection={true}
                      determineRowHidden={determineRowHidden} />
                </div>
              </CardContent>
            </Card>
        )}
      </Fragment>
  );
}

export default CompanyDirectoryComponent;