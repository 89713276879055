import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  Stack,
  Switch,
  useMediaQuery
} from "@mui/material";
import {
  DayStats, FullMonthResponse,
  ScheduledWeekObjectResponse,
  ScheduleList,
  ScheduleObject,
  TaskInfo,
  TaskList,
  TaskResponse
} from "../../types/task/TaskTypes";
import Typography from "@mui/material/Typography";
import {
  scheduleInfoInit,
  scheduleListInit,
  scheduleObjectWeekResponseInit,
  taskInfoInit,
  todaysTasksInit
} from "../../store/task/taskInfoSlice";
import TaskDetailDialog from "./TaskDetailDialog";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {format} from "date-fns";
import ScheduleDetailsDialog from "./ScheduleDetailsDialog";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {avatarService} from "../../service/generic/avatarService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {HtmlTooltip} from "../general/HtmlTooltip";
import {grey} from "@mui/material/colors";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import TaskDisplayCard from "./TaskDisplayCard";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {DARK_NAVY, GREEN, LIGHTER_GREY_BLUE, LIME_GREEN} from "../../service/generic/ColorScheme";

type Props = {
  isOpen: boolean,
  taskList: TaskInfo[],
  dayStats: DayStats,
  day:TaskResponse,
  scheduledEmployeeList: ScheduleObject[],
  selectedDayDisplayName: string,
  selectedDayDisplayDate: string,
  setIsOpen: (open: false) => void,
  getFullMonthResponse?: (() => void)
  handleDaySelected?: ((weekDayDisplayName: string, taskList: TaskInfo[], scheduledEmployeeList: ScheduleObject[], dayStats: DayStats, selectedDay: TaskResponse) => void)
}

const CalendarIndividialDayDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const tasks = useSelector((state: RootState) => state.taskInfo);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const [filterCompletedTasks, setFilterCompletedTasks] = useState(true);
  const [filterUnCompletedTasks, setFilterUnCompletedTasks] = useState(true);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const daysTasks = useSelector((state: RootState) => state.taskInfo.daysTasks);
  const daysScheduledEmployees = useSelector((state: RootState) => state.taskInfo.daysScheduledEmployees);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const selectedCalendarDate = useSelector((state: RootState) => state.taskInfo.selectedCalendarDate);
  const [scheduledWeekObjectResponse, setScheduledWeekObjectResponse] = useState<ScheduledWeekObjectResponse>(scheduleObjectWeekResponseInit);
  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const [todaysTasks, setTodaysTasks] = useState<TaskList>(todaysTasksInit);
  const [showLoading, setShowLoading] = React.useState(false);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const scheduledWeekObjectResponseGlobal = useSelector((state: RootState) => state.schedule.scheduledWeekObjectResponse);
  const [scheduleList, setScheduleList] = useState<ScheduleList>(scheduleListInit);

  useEffect(() => {
    document.title = "Individual Calendar Day Dialog";
    getTodaysScheduledEmployees();
  }, []);

  useEffect(() => {
  }, [daysScheduledEmployees]);

  useEffect(() => {
    if (props.isOpen) {
      dispatch(taskInfoActions.setIsModalOpen({value: true}));
      getTodaysScheduledEmployees();
    }
  }, [props.isOpen])

  useEffect(() => {
    getTodaysTasks(filterCompletedTasks, filterUnCompletedTasks);
  }, [filterCompletedTasks, filterUnCompletedTasks]);

  useEffect(() => {
    getTodaysTasks(true, true);
    getTodaysScheduledEmployees();
  }, [tasks.monthlyTasks, scheduledWeekObjectResponseGlobal])

  const handleTaskSelected = (task: TaskInfo) => {
    setSelectedTask(task);
    setTaskDialogOpen(true);
  }
  const [selectedScheduleCard, setSelectedScheduleCard] = React.useState(scheduleInfoInit);
  const [scheduleCardOpen, setScheduleCardOpen] = React.useState(false);
  const handleScheduleCardSelected = (scheduleInfo: ScheduleObject) => {
    setSelectedScheduleCard(scheduleInfo);
    setScheduleCardOpen(true);
  }
  const handleDeleteScheduledEmployee = (scheduleInfo: ScheduleObject) => {
    let deleteUtil = {tasksToUpdateOnDelete: [], scheduledObject: scheduleInfo};
    taskManagementService.deleteScheduledEmployee(deleteUtil, formatDate(selectedDate), orgInfo.allSelectedAccessCodes!)
        .then((resp: ScheduledWeekObjectResponse) => {
          getTodaysScheduledEmployees();
          props.getFullMonthResponse();
          setScheduleCardOpen(false);
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    });
  }

  const getAccountNameFromAccessCode = (accessCode:string) => {
    let account = orgInfo.selectedAccounts.filter(account => account.accessCode === accessCode)[0];
    return account ? account.name : '';
  }

  const getTodaysTasks = (filterCompletedTasks: boolean, filterUnCompletedTasks: boolean) => {
    setShowLoading(true);
    let selectedDayDisplayDate = (props.selectedDayDisplayDate && props.selectedDayDisplayDate.length > 0) ?
        formatDate(props.selectedDayDisplayDate) : props.selectedDayDisplayDate;

    taskManagementService.getTodaysTasks(selectedDayDisplayDate,
        filterCompletedTasks, filterUnCompletedTasks, orgInfo.allSelectedAccessCodes!, formatDate(selectedCalendarDate))
        .then((taskList: TaskList) => {
          dispatch(taskInfoActions.setDaysTasks({daysTasks: taskList.taskList}));
          handleUpdateOfSelectedTask(taskList.taskList);
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  }

  const getTodaysScheduledEmployees = () => {
    setShowLoading(true);
    let selectedDayDisplayDate = (props.selectedDayDisplayDate && props.selectedDayDisplayDate.length > 0) ?
        formatDate(props.selectedDayDisplayDate) : props.selectedDayDisplayDate;
    taskManagementService.getTodaysScheduledEmployees(selectedDayDisplayDate,
              orgInfo.allSelectedAccessCodes!, formatDate(selectedCalendarDate))
        .then((scheduleList: ScheduleList) => {
          dispatch(taskInfoActions.setDaysScheduledEmployees({daysScheduledEmployees: scheduleList.scheduledList}));
          console.log("2223333344141414");
          console.log(scheduleList.scheduledList);
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  }

  const handleUpdateOfSelectedTask = (tasks:TaskInfo[]) => {
    let filteredTask = tasks.filter(task => task.assignedTaskId === selectedTask.assignedTaskId);
    if (filteredTask.length > 0) {
      console.log("update update day")
      setSelectedTask(filteredTask[0]);
    }
  }

  const closeDialog = () => {
    console.log("calendar indiv dialog")
    if (isWindowLarge) {
      dispatch(taskInfoActions.setIsModalOpen({value: false}));
    }
    props.setIsOpen(false)
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '100%'}, maxHeight:'950px' }}
          maxWidth="lg"
          open={props.isOpen}
      >

        <Box sx={{m:1}}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{textAlign:"center", fontWeight:"bold"}}>
                {props.selectedDayDisplayName}
              </div>
              <Typography variant={"caption"}>
                <div style={{textAlign:"center", fontWeight:"bold"}}>
                  {props.selectedDayDisplayDate}
                </div>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div style={{float:"right"}}>
                <HtmlTooltip title={
                  <Fragment>
                    <Typography>Completed Tasks Filter</Typography>
                    <em>Use this switch to hide or show tasks that have been completed.</em>
                  </Fragment>
                }>
                  <Switch
                      color={"success"}
                      checked={filterCompletedTasks}
                      onChange={() => setFilterCompletedTasks(!filterCompletedTasks)}
                      inputProps={{ 'aria-label': 'controlled' }}
                  />
                </HtmlTooltip>
                <HtmlTooltip title={
                  <Fragment>
                    <Typography>UnCompleted Tasks Filter</Typography>
                    <em>Use this switch to hide or show tasks that have not been completed.</em>
                  </Fragment>
                }>
                  <Switch
                      color={"error"}
                      checked={filterUnCompletedTasks}
                      onChange={() => setFilterUnCompletedTasks(!filterUnCompletedTasks)}
                      inputProps={{ 'aria-label': 'controlled' }}
                  />
                </HtmlTooltip>
              </div>
            </Grid>
          </Grid>
        </Box>

        <FormControl component="fieldset">
          <DialogContent dividers style={{}}>
            { showLoading ? (
                <LoadingSpinnerContainer showLoading={showLoading} />
            ) : (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Fragment>
                      {/*<h4 style={{textAlign:"center"}}>Scheduled Workers</h4>*/}
                      {daysScheduledEmployees && daysScheduledEmployees.length > 0 &&
                      daysScheduledEmployees.map((scheduleObject: ScheduleObject, index) => (
                          <Card sx={{mb:1,backgroundColor:LIGHTER_GREY_BLUE,cursor: "pointer",border:"solid 2px",
                            borderColor:avatarService.stringToColor(scheduleObject.employeeName)}}
                                onClick={() => {
                                  if (checkUserNotOrdinaryWorker(userInfo)) {
                                    handleScheduleCardSelected(scheduleObject)
                                  }
                                }}>
                            <div style={{textAlign:"center"}}>
                              <h4 style={{marginTop:'0%',marginBottom:'0%'}}>{scheduleObject.employeeName}</h4>
                            </div>
                            <div style={{textAlign:"center"}}>
                              {allSelectedAccessCodes && allSelectedAccessCodes?.length > 1 &&
                              <h5 style={{color:"white",marginTop:'0%',marginBottom:'0%'}}>{getAccountNameFromAccessCode(scheduleObject.accessCode)}</h5>
                              }
                              <h5 style={{marginTop:'0%',marginBottom:'0%'}}>{format(new Date(scheduleObject.assignedStartTime), "hh:mm a")} </h5>
                              <h5 style={{marginTop:'0%',marginBottom:'0%'}}>{format(new Date(scheduleObject.assignedEndTime), "hh:mm a")} </h5>
                            </div>
                          </Card>
                      ))}
                      {props.scheduledEmployeeList == undefined || props.scheduledEmployeeList.length < 1 &&
                      <p style={{color:'red', textAlign:"center"}}>No one scheduled today.</p>
                      }
                    </Fragment>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={9}>
                    <Fragment>
                      <Fragment>
                        {daysTasks && daysTasks.length > 0 &&
                        <Typography variant="caption" component="div">
                          <Stack direction={"row"} spacing={1} style={{paddingBottom:5}}
                                 divider={<Divider orientation={"vertical"} sx={{backgroundColor:'black'}} />}>
                            {/*<Box sx={{color:LIME_GREEN}}><b>LOW</b></Box>
                            <Box sx={{color:GREEN}}><b>MED</b></Box>
                            <Box sx={{color:DARK_NAVY}}><b>HIGH</b></Box>*/}
                            <Card style={{backgroundColor:LIME_GREEN, width:'12%', textAlign:"center"}}>
                              <b style={{margin:5, color:"white"}}>3rd</b>
                            </Card>
                            <Card style={{backgroundColor:GREEN, width:'12%', textAlign:"center"}}>
                              <b style={{margin:5, color:"white"}}>2nd</b>
                            </Card>
                            <Card style={{backgroundColor:DARK_NAVY, width:'12%', textAlign:"center"}}>
                              <b style={{margin:5, color:"white"}}>1st</b>
                            </Card>
                          </Stack>
                        </Typography>
                        }
                      </Fragment>
                      <Fragment>
                        {daysTasks && daysTasks.length > 0 &&
                        daysTasks.map((task: TaskInfo, index) => (
                            <Box>
                              <TaskDisplayCard
                                  task={task}
                                  handleTaskSelected={handleTaskSelected}
                                  taskDialogOpen={taskDialogOpen}
                                  setTaskDialogOpen={setTaskDialogOpen}
                              />
                            </Box>
                        ))}
                        {props.taskList == undefined || props.taskList.length < 1 &&
                        <p style={{color:'red', textAlign:"center"}}>No Tasks Assigned for today.</p>
                        }
                      </Fragment>
                    </Fragment>
                  </Grid>
                </Grid>
            )}
          </DialogContent>
          <Box sx={{position:'sticky', bottom:0, p:1, bgcolor:grey[400], zIndex:100}}>
            <Button autoFocus onClick={closeDialog} style={{float: 'right', color:DARK_NAVY}}>
              Cancel
            </Button>
          </Box>
        </FormControl>

        <TaskDetailDialog
            task={selectedTask}
            dialogOpen={taskDialogOpen}
            setDialogOpen={setTaskDialogOpen}
            selectedDate={new Date()}
            setSelectedTask={setSelectedTask}
            getJobBoardUtil={()=>{}}
            getFullMonthResponse={props.getFullMonthResponse}
            handleDaySelected={props.handleDaySelected}
            day={props.day}
        />
        <ScheduleDetailsDialog
            handleDelete={handleDeleteScheduledEmployee}
            scheduleInfo={selectedScheduleCard}
            dialogOpen={scheduleCardOpen}
            setDialogOpen={setScheduleCardOpen}
            selectedDate={new Date(selectedDate)}
        />
      </Dialog>

  );

}

export default CalendarIndividialDayDialog;
