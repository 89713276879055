import React, {forwardRef, Fragment} from "react";
import {
    Box,
    Checkbox,
    Chip,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import {useAppThunkDispatch} from "../../store/storeHooks";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {UserInfo} from "../../types/account/AccountTypes";
import {SelectChangeEvent} from "@mui/material/Select";
import CustomTextField from "../general/CustomTextField";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import {formatDateOnEditableField, formatDateTime} from "../../service/generic/DateHelper";
import {LIME_GREEN} from "../../service/generic/ColorScheme";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PlayGroundSafetyInspectionFormPreview from "./PlayGroundSafetyInspectionFormPreview";

type Props = {
    taskId: string;
    showPreview: boolean;
    setShowPreview: (open: false) => void,
    ref: HTMLDivElement | null
    pgSafetyInspectionFormInfoState: { [key: string]: any; };
    setPgSafetyInspectionFormInfoState: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
    safetyCheckPoints: CheckPoints[];
    setSafetyCheckPoints: React.Dispatch<React.SetStateAction<CheckPoints[]>>;
    equipmentCheckPoints: CheckPoints[];
    setEquipmentCheckPoints: React.Dispatch<React.SetStateAction<CheckPoints[]>>;
    handleSubmitForm: () => void;
};

interface CheckPoints {
    order: number,
    tag: string;
    compliant: boolean | null;
    hazard: boolean | null;
}

const PlayGroundSafetyInspectionForm: React.ForwardRefExoticComponent<React.PropsWithoutRef<Props> & React.RefAttributes<HTMLDivElement>> = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const dispatch = useAppThunkDispatch();
    const isWindowSmall = useMediaQuery('(max-width:415px)');
    const [showLoading, setShowLoading] = React.useState(false);
    const [formTouched, setFormTouched] = React.useState(false);
    const orgMembers = useSelector((state: RootState) => state.account.orgMembers);

    const handleCheckPointUpdate = (
        setCheckPoints: React.Dispatch<React.SetStateAction<CheckPoints[]>>,
        checkPoints: CheckPoints[],
        tag: string,
        type: 'compliant' | 'hazard',
        index: number
    ) => {
        const updatedCheckPoints = checkPoints.map((point, i) =>
            i === index ? { ...point, [type]: !point[type] } : point
        );
        setCheckPoints(updatedCheckPoints);
    };


    const isFormValid = () => {
        let valid = true;
        return valid;
    }

    // @ts-ignore
    return (
        <Fragment>
            {showLoading ? (
                <Box sx={{ marginTop: '200px' }}>
                    <LoadingSpinnerContainer showLoading={showLoading} />
                </Box>
            ) : (
                <>
                    <Typography variant="h4" align="center" gutterBottom sx={{ m: 0, mb:1 }}>
                        Frequent Playground Safety Inspection Form
                    </Typography>

                    <form>
                        <CustomTextField
                            valueString={props.pgSafetyInspectionFormInfoState.location}
                            label={"Location"}
                            sx={{mb:2}}
                            onInputUpdate={(newValue) => {
                                setFormTouched(true);
                                let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                newPlayGroundSafetyFormState.location = newValue;
                                props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <div style={{width:'100%'}}>
                                        <DatePicker
                                            label="Inspection Date"
                                            value={formatDateOnEditableField(props.pgSafetyInspectionFormInfoState.inspectionDate)}
                                            onChange={(newValue) => {
                                                setFormTouched(true);
                                                let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                                newPlayGroundSafetyFormState.inspectionDate = newValue;
                                                props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState)
                                            }}
                                            ignoreInvalidInputs={true}
                                            renderInput={(params) => <TextField sx={{width:'100%', borderColor:LIME_GREEN}} {...params} />}
                                        />
                                        {/*                                    { !taskFormValid.scheduledDate &&
                                        <small style={{color: 'red', marginTop: 0}}>Invalid date</small>
                                    }*/}
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Box sx={{mb:2}}>
                                        <TimePicker
                                            label="Inspection Time"
                                            value={props.pgSafetyInspectionFormInfoState.inspectionTime}
                                            onChange={(newValue) => {
                                                setFormTouched(true);
                                                let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                                newPlayGroundSafetyFormState.inspectionTime = formatDateTime(newValue!.toString())!;
                                                props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);
                                            }}
                                            PopperProps={{placement: 'top'}}
                                            inputFormat={"hh:mm a"}
                                            toolbarFormat='yyyy-MM-dd'
                                            renderInput={(params) => <TextField sx={{width:'100%', borderColor: LIME_GREEN}} {...params} />}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </LocalizationProvider>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="inspectedBy-label">Inspected By</InputLabel>
                                    <Select
                                        sx={{ width:'100%'}}
                                        labelId="inspectedBy-label"
                                        id="inspectedBy"
                                        multiple={true}
                                        input={<OutlinedInput id="select-multiple-chip" />}
                                        value={props.pgSafetyInspectionFormInfoState.inspectedBy}
                                        renderValue={(selected: any[]) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        onChange={(event: SelectChangeEvent<typeof props.pgSafetyInspectionFormInfoState.inspectedBy>) => {
                                            setFormTouched(true);
                                            const {target: {value}} = event;
                                            let names = typeof value === 'string' ? value.split(',') : value;
                                            let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                            newPlayGroundSafetyFormState.inspectedBy = names;
                                            props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);
                                        }}
                                        MenuProps={MenuMultiSelectProps}
                                    >
                                        {orgMembers.filter((mem) => mem.role !== 'ORG_CONTRACT').map((member: UserInfo) => (
                                            <MenuItem key={member.userId} value={member.firstName + ' ' + member.lastName}>
                                                <Checkbox checked={props.pgSafetyInspectionFormInfoState.inspectedBy.indexOf(member.firstName + ' ' + member.lastName) > -1} />
                                                <ListItemText primary={member.firstName + ' ' + member.lastName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomTextField
                                    valueString={props.pgSafetyInspectionFormInfoState.ageAppropriate}
                                    label={"Age Appropriate"}
                                    sx={{mb:2}}
                                    onInputUpdate={(newValue) => {
                                        setFormTouched(true);
                                        let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                        newPlayGroundSafetyFormState.ageAppropriate = newValue;
                                        props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', margin: 0 }}>
                                        Priority Rating Scale (1-4)
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ margin: 0 }}>
                                        1. Permanent disability, loss of life or body part – correct immediately.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ margin: 0 }}>
                                        2. Serious injury resulting in temporary disability – correct as soon as possible.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ margin: 0 }}>
                                        3. Minor (Non-Disabling) Injury – correct when time permits.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" style={{ margin: 0 }}>
                                        4. Potential for injury Minimal – check to see if worsens.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Box sx={{ ml: isWindowSmall ? 0 : 5 }}>
                            <Typography variant="body1" sx={{ mt: 2, mb: 4, fontWeight: "bold" }}>
                                SAFETY SURFACING
                            </Typography>
                            {props.safetyCheckPoints.map((question, index) => (
                                <Grid container spacing={2} sx={{ mt: isWindowSmall ? 0 : -3 }} key={index}>
                                    {question.order !== 8 &&
                                        <Grid item xs={12} sm={6} sx={{}}>
                                          <Typography variant="body1">{`${index + 1}. ${question.tag}`}</Typography>
                                        </Grid>
                                    }
                                    {question.order === 8 &&
                                        <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center'}}>
                                          <Typography variant="body1">{`${index + 1}.`}</Typography>
                                          <CustomTextField
                                              valueString={props.pgSafetyInspectionFormInfoState.ss8QuestionLabel}
                                              sx={{mb:2, width:'70%'}}
                                              onInputUpdate={(newValue) => {
                                                  setFormTouched(true);
                                                  let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                                  newPlayGroundSafetyFormState.ss8QuestionLabel = newValue;
                                                  props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);

                                                  if (newValue === "") {
                                                      const updatedSafetyCheckPoints = props.safetyCheckPoints.map((point) =>
                                                          point.order === 8 ? {
                                                              ...point,
                                                              tag: "",
                                                              compliant: false,
                                                              hazard: false
                                                          } : point
                                                      );
                                                      props.setSafetyCheckPoints(updatedSafetyCheckPoints);
                                                  }
                                              }}
                                          />
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={3} sx={{ mt: isWindowSmall ? -3 : -1 }}>
                                        <FormControlLabel
                                            sx={{ mb: 2 }}
                                            label={"Compliant"}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 5 }}
                                                    disabled={props.pgSafetyInspectionFormInfoState.ss8QuestionLabel === "" && index === 7}
                                                    checked={question.compliant ?? false}
                                                    onChange={() =>
                                                        handleCheckPointUpdate(props.setSafetyCheckPoints, props.safetyCheckPoints, question.tag, 'compliant', index)
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} sx={{ mt: isWindowSmall ? -3 : -1 }}>
                                        <FormControlLabel
                                            sx={{ mb: 2 }}
                                            label={"Hazard"}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 5 }}
                                                    disabled={props.pgSafetyInspectionFormInfoState.ss8QuestionLabel === ""  && index === 7}
                                                    checked={question.hazard ?? false}
                                                    onChange={() =>
                                                        handleCheckPointUpdate(props.setSafetyCheckPoints, props.safetyCheckPoints, question.tag, 'hazard', index)
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>

                        <Box sx={{ ml: isWindowSmall ? 0 : 5 }}>
                            <Typography variant="body1" sx={{ mt: 2, mb: 4, fontWeight: "bold" }}>
                                EQUIPMENT
                            </Typography>
                            {props.equipmentCheckPoints.map((question, index) => (
                                <Grid container spacing={2} sx={{ mt: isWindowSmall ? 0 : -3 }} key={index}>
                                    {question.order !== 22 &&
                                        <Grid item xs={12} sm={6} sx={{}}>
                                          <Typography variant="body1">{`${index + 9}. ${question.tag}`}</Typography>
                                        </Grid>
                                    }
                                    {question.order === 22 &&
                                        <Grid item xs={12} sm={6} sx={{display: 'flex', alignItems: 'center'}}>
                                          <Typography variant="body1">{`${index + 9}.`}</Typography>
                                          <CustomTextField
                                              valueString={props.pgSafetyInspectionFormInfoState.e22QuestionLabel}
                                              label={""}
                                              sx={{mb:2, width:'70%'}}
                                              onInputUpdate={(newValue) => {
                                                  setFormTouched(true);
                                                  let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                                  newPlayGroundSafetyFormState.e22QuestionLabel = newValue;
                                                  props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);

                                                  if (newValue === "") {
                                                      const updatedEquipmentCheckPoints = props.equipmentCheckPoints.map((point) =>
                                                          point.order === 22 ? {
                                                              ...point,
                                                              tag: "",
                                                              compliant: false,
                                                              hazard: false
                                                          } : point
                                                      );
                                                      props.setEquipmentCheckPoints(updatedEquipmentCheckPoints);
                                                  }
                                              }}
                                          />
                                        </Grid>
                                    }
                                    <Grid item xs={6} sm={3} sx={{ mt: isWindowSmall ? -3 : -1 }}>
                                        <FormControlLabel
                                            sx={{ mb: 2 }}
                                            label={"Compliant"}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 5 }}
                                                    disabled={props.pgSafetyInspectionFormInfoState.e22QuestionLabel === "" && index === 13}
                                                    checked={question.compliant ?? false}
                                                    onChange={() =>
                                                        handleCheckPointUpdate(props.setEquipmentCheckPoints, props.equipmentCheckPoints, question.tag, 'compliant', index)
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3} sx={{ mt: isWindowSmall ? -3 : -1 }}>
                                        <FormControlLabel
                                            sx={{ mb: 2 }}
                                            label={"Hazard"}
                                            control={
                                                <Checkbox
                                                    sx={{ ml: 5 }}
                                                    disabled={props.pgSafetyInspectionFormInfoState.e22QuestionLabel === "" && index === 13}
                                                    checked={question.hazard ?? false}
                                                    onChange={() =>
                                                        handleCheckPointUpdate(props.setEquipmentCheckPoints, props.equipmentCheckPoints, question.tag, 'hazard', index)
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Box>

                        <Box sx={{ml:20}}>
                            <FormControl fullWidth>
                                <CustomTextField
                                    valueString={props.pgSafetyInspectionFormInfoState.comments}
                                    label={"Required Repairs / Comments"}
                                    sx={{mb:2, width: isWindowSmall ? '100%' : '70%'}}
                                    multiline={3}
                                    onInputUpdate={(newValue) => {
                                        setFormTouched(true);

                                        if(newValue.length < 174){
                                            let newPlayGroundSafetyFormState = {...props.pgSafetyInspectionFormInfoState};
                                            newPlayGroundSafetyFormState.comments = newValue;
                                            props.setPgSafetyInspectionFormInfoState(newPlayGroundSafetyFormState);
                                        }
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </form>
                </>
            )}

            <PlayGroundSafetyInspectionFormPreview
                taskId={props.taskId}
                pgSafetyInspectionFormInfoState={props.pgSafetyInspectionFormInfoState}
                safetyCheckPoints={props.safetyCheckPoints}
                equipmentCheckPoints={props.equipmentCheckPoints}
                isOpen={props.showPreview}
                setIsOpen={props.setShowPreview}
                // @ts-ignore
                ref={ref}
                handleSubmitForm={props.handleSubmitForm}
            />

        </Fragment>
    );
});

export default PlayGroundSafetyInspectionForm;