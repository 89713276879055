import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  Card, CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import AlertToast from "../general/AlertToast";
import LoadingSpinnerFull from "../general/LoadingSpinnerFull";
import {orgInfoActions, RootState, userInfoActions} from "../../store/storeIndex";
import {displayAlert} from "../../store/alertSlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {orgInfoService} from "../../service/account/orgInfoService";
import {MapLatLong, OrgInfo, OrgInfoState, Role, timezones, UserInfo} from "../../types/account/AccountTypes";
import {OrgInfoValid, userInfoValidInit} from "../../types/account/AccountValids";
import {orgInfoInit} from "../../store/account/orgInfoSlice";
import {userInfoService} from "../../service/account/userInfoService";
import {checkUserAdmin, checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import EditIcon from "@mui/icons-material/Edit";
import {CancelOutlined} from "@mui/icons-material";
import WelcomeCreateOrgDialog from "./WelcomeCreateOrgDialog";
import MapsContainer from "../maps/MapsContainer";
import {DARK_NAVY, GHOST_WHITE, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";


const OrgInfoUpdate: React.FC = () => {
  let dispatch = useAppThunkDispatch();
  let history = useHistory();

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);

  const [userInfoStateValid, setUserInfoStateValid] = useState(userInfoValidInit);
  const userInfoGlobal = useSelector((state: RootState) => state.userInfo.userInfo);
  const [userInfoState, setUserInfoState] = useState<UserInfo>(userInfoGlobal);
  const [showEditState, setShowEditState] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [orgInfoLocal, setOrgInfoLocal] = useState<OrgInfo>({...orgInfo.orgInfo});
  const [orgInfoValid, setOrgInfoValid] = useState({...OrgInfoValid});
  const [isCreateOrg, setIsCreateOrg] = useState(true);
  const [isShowWelcomePopup, setIsShowWelcomePopup] = useState(false);
  const [removeCancelButton, setRemoveCancelButton] = useState<boolean>(false);

  useEffect(() => {
    console.log("orginfo");
    if (orgInfo.orgInfo.accessCode) {
      setIsCreateOrg(false);
      resetForm(orgInfo.orgInfo);
      setShowEditState(false);
    } else {
      setIsShowWelcomePopup(true);
      setShowEditState(true);
    }
  }, [orgInfo]);

  useEffect(() => {
      if (orgInfo.orgInfo.name == '') {
        console.log("editstate");
          setShowEditState(true);
          setIsCreateOrg(true);
      }
  }, []);

  const handleCreateSubmit = () => {
    if (isFormValid() && showLoading == false) {
      setShowLoading(true);
      orgInfoService.createOrg(orgInfoLocal)
          .then((orgResp) => {
            if (orgResp && orgResp.name) {
              dispatch(orgInfoActions.manualInitSelectedAfterOrgCreation({
                selectedAccounts:orgResp,
                allSelectedAccessCodes:orgResp.accessCode
              }));

              dispatch(orgInfoActions.createNewOrgSuccess({orgInfo: orgResp}));
              let newUserState = {...userInfo};
              newUserState.accessCode = orgResp.accessCode;
              dispatch(userInfoActions.updateSuccess({userInfo: newUserState}));
              resetForm(orgResp)
              history.push('/manage/user/account');
            }
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          })
          .finally(() => {
            /*orgInfoService.getAllAccountData()
                .then((newOrgInfoState: OrgInfoState) => {
                  if (newOrgInfoState && newOrgInfoState.orgInfo) {
                    dispatch(orgInfoActions.updateAccountSuccess({orgInfoState: newOrgInfoState}));
                  }
                }).catch(e => {
              dispatch(determineIfTokenError(e));
            });*/
            setShowLoading(false)
          });
    }
  }

  const handleUpdateSubmit = () => {
      if (isFormValid()) {
          setShowLoading(true);
          orgInfoService.updateOrgInfo(orgInfoLocal)
              .then(orgInfoResp => {
                dispatch(orgInfoActions.updateOrgSuccess({orgInfo: orgInfoResp}));
                //dispatch(userInfoActions.updateSuccess({userInfo: orgInfoResp}));
                resetForm(orgInfoResp);
              })
              .catch(e => {
                dispatch(determineIfTokenError(e));
              })
              .finally(() => setShowLoading(false));
      }
  }

  const isFormValid = () => {
    return formTouched && orgInfoValid.orgName && orgInfoValid.orgStreet && orgInfoValid.orgCity &&
        orgInfoValid.orgState && orgInfoValid.orgPostalCode && orgInfoValid.timezone;
  }

  const updateOrgMapLatLong = (latLong:MapLatLong) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.mapCenter = latLong;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeCity = (cityValue:string) => {
      setFormTouched(true);
      orgInfoValid.orgCity = cityValue.trim().length > 0;
      setOrgInfoValid(orgInfoValid);
      let newOrgInfoState = {...orgInfoLocal};
      let address = {...orgInfoLocal.address};
      address.city = cityValue;
      newOrgInfoState.address = address;
      setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeStreet = (streetValue:string) => {
      setFormTouched(true);
      orgInfoValid.orgStreet = streetValue.trim().length > 0;
      setOrgInfoValid(orgInfoValid);
      let newOrgInfoState = {...orgInfoLocal};
      let address = {...orgInfoLocal.address};
      address.street = streetValue;
      newOrgInfoState.address = address;
      setOrgInfoLocal(newOrgInfoState);
  }

  const onChangePostalCode = (postalValue:string) => {
      setFormTouched(true);
      orgInfoValid.orgPostalCode = postalValue.trim().length === 5;
      setOrgInfoValid(orgInfoValid);
      let newOrgInfoState = {...orgInfoLocal};
      let address = {...orgInfoLocal.address};
      address.postalCode = postalValue;
      newOrgInfoState.address = address;
      setOrgInfoLocal(newOrgInfoState);
  }

  const onChangePlaybooksId = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.playbooksId = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeDashPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.dashPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeRecordsPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.recordsPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeReportsPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.reportsPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeAlertsPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.alertsPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeLabelSdsPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.labelSdsPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeInventoryPRM = (val:string) => {
    setFormTouched(true);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.inventoryPrm = val;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangePhoneNumber = (pNumberValue:string) => {
      setFormTouched(true);
      orgInfoValid.orgPhoneNumber = pNumberValue.trim().length > 0;
      setOrgInfoValid(orgInfoValid);
      let newOrgInfoState = {...orgInfoLocal};
      newOrgInfoState.phoneNumber = pNumberValue;
      setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeTimezone = (timezone:string) => {
    setFormTouched(true);
    orgInfoValid.timezone = timezone.trim().length > 0;
    setOrgInfoValid(orgInfoValid);
    let newOrgInfoState = {...orgInfoLocal};
    newOrgInfoState.timezone = timezone;
    setOrgInfoLocal(newOrgInfoState);
  }

  const onChangeOrgName = (orgName:string) => {
    setFormTouched(true);
    orgInfoValid.orgName = orgName.trim().length > 0;
    setOrgInfoValid(orgInfoValid);

    let newOrgState = {...orgInfoLocal};
    newOrgState.name = orgName;
    setOrgInfoLocal(newOrgState);
  }

  const resetForm = (orgInfoState:OrgInfo) => {
    let newOrgInfoState = {...orgInfoState};
    let address = {...orgInfoLocal.address};
    address.state = userInfoState.address.state;
    newOrgInfoState.address = address;
    setOrgInfoLocal(newOrgInfoState);

    let valid = {...OrgInfoValid};
    valid.orgCity = orgInfoState.address.city.length > 0;
    valid.orgName = orgInfoState.name.length > 0;
    valid.orgState = orgInfoState.address.state.length > 0;
    valid.orgStreet = orgInfoState.address.street.length > 0;
    valid.orgPostalCode = orgInfoState.address.postalCode.length === 5;
    valid.timezone = orgInfoState.timezone.length > 0;
    setOrgInfoValid(valid);
    setFormTouched(false);
  }

  const editFunction = () => {
    resetForm(orgInfo.orgInfo);
    setShowEditState(!showEditState);
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  /*if (orgInfo.orgInfo.orgId.length > 0) {
    return <Redirect to={'/manage/user/account'} />
  }*/

/*  const onChangeState = (stateValue:string) => {
    setFormTouched(true);
    orgInfoValid.orgState = stateValue.trim().length > 0;
    setOrgInfoValid(orgInfoValid);
    let newOrgInfoState = {...orgInfoLocal};
    let address = {...orgInfoLocal.address};
    address.state = stateValue;
    newOrgInfoState.address = address;
    setOrgInfoLocal(newOrgInfoState);
  }*/

  const handleStateFilterChange = (val: string) => {
    setFormTouched(true);
    orgInfoValid.orgState = val !="State..";
    setOrgInfoValid(orgInfoValid);
    let newOrgInfoState = {...orgInfoLocal};
    let address = {...orgInfoLocal.address};
    address.state = val;
    newOrgInfoState.address = address;
    setOrgInfoLocal(newOrgInfoState);
  };

  const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana',
    'Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada',
    'New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania',
    'Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia',
    'Wisconsin','Wyoming'];

  return(
      <div>
        <Card style={{backgroundColor:GHOST_WHITE}}>
          <CardHeader
            title={
              <Typography variant="h5" component="div">
                Parent Organization
                <hr/>
              </Typography>
            }
            action = {
              <Fragment>
                {checkUserNotOrdinaryWorker(userInfo) && orgInfo.orgInfo.accessCode &&
                  <IconButton aria-label="edit" onClick={editFunction}>
                    {!showEditState && <EditIcon style={{color:DARK_NAVY}}/>}
                    {showEditState && <CancelOutlined style={{color:DARK_NAVY}}/>}
                  </IconButton>
                }
              </Fragment>
            }
          />
          <CardContent>
            { userInfo.role === Role.ORG_ADMIN && userInfo.accessCode.length === 0 &&
            <h2 style={{textAlign: 'center'}}>{isCreateOrg ? 'Create your' : 'Your'} Organization</h2>
            }
            <FormControl component="fieldset">
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <CustomTextField
                            disabled={!showEditState}
                            valueString={orgInfoLocal.name}
                            label={"Organization Name"}
                            variant={userInfo.role !== Role.ORG_ADMIN ? 'outlined' : 'filled'}
                            isValid={orgInfoValid.orgName || !formTouched}
                            validationText={'must be longer'}
                            onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                            onInputUpdate={onChangeOrgName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            disabled={!showEditState}
                            valueString={orgInfoLocal.address?.street}
                            label={"Street"}
                            isValid={orgInfoValid.orgStreet || !formTouched}
                            validationText={'Invalid Street Name'}
                            onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                            onInputUpdate={onChangeStreet}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            disabled={!showEditState}
                            valueString={orgInfoLocal.address?.city}
                            label={"City"}
                            isValid={orgInfoValid.orgCity || !formTouched}
                            validationText={'Invalid City'}
                            onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                            onInputUpdate={onChangeCity}
                        />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <InputLabel id="stateLabel">State</InputLabel>
                        <Select
                            labelId="stateLabel"
                            id="stateSelect"
                            style={{width:'100%'}}
                            disabled={!showEditState}
                            value={orgInfoLocal.address?.state}
                            label="State"
                            onChange={(event: SelectChangeEvent) => handleStateFilterChange(event.target.value)}
                            sx={{float:"right"}}
                        >
                          <MenuItem value={"State.."}>State..</MenuItem>
                          { states.map(state => (
                              <MenuItem value={state} key={state}>{state}</MenuItem>
                          ))}
                        </Select>
                        <small style={{color:'red', marginTop:0}} hidden={orgInfoValid.orgState || !formTouched}>
                          Invalid State
                        </small>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <CustomTextField
                            disabled={!showEditState}
                            valueString={orgInfoLocal.address?.postalCode}
                            label={"Postal Code"}
                            isValid={orgInfoValid.orgPostalCode || !formTouched}
                            validationText={'must be 5 digits long'}
                            onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                            onInputUpdate={onChangePostalCode}
                        />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="timezone">Timezone</InputLabel>
                        <Select
                            disabled={!showEditState}
                            id="timezone"
                            labelId={"timezone"}
                            label="Timezone"
                            required
                            displayEmpty
                            value={orgInfoLocal.timezone}
                            onChange={(event: SelectChangeEvent) => onChangeTimezone(event.target.value)}
                        >
                          <MenuItem disabled value="" key={''}><em></em></MenuItem>
                          { timezones.map(zone => (
                            <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                          ))}
                        </Select>
                        <small hidden={orgInfoValid.timezone || !showEditState || !formTouched}
                               style={{color:'red', marginTop:'2px'}}>
                          {"A timezone must be specified for the account"}
                        </small>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextField
                          disabled={!showEditState}
                          valueString={orgInfoLocal.playbooksId}
                          label={"Applant ID"}
                          isValid={true}
                          onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                          onInputUpdate={onChangePlaybooksId}
                      />
                    </Grid>
                    { userInfo.isAdmin &&
                      <Fragment>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.dashPrm}
                                  label={"Dashboard PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeDashPRM}
                              />
                          </Grid>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.recordsPrm}
                                  label={"Records PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeRecordsPRM}
                              />
                          </Grid>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.reportsPrm}
                                  label={"Reports PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeReportsPRM}
                              />
                          </Grid>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.alertsPrm}
                                  label={"Alerts PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeAlertsPRM}
                              />
                          </Grid>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.labelSdsPrm}
                                  label={"Label/Sds PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeLabelSdsPRM}
                              />
                          </Grid>
                          <Grid item sm={12} md={6}>
                              <CustomTextField
                                  disabled={!showEditState}
                                  valueString={orgInfoLocal.inventoryPrm}
                                  label={"Inventory PRM"}
                                  isValid={true}
                                  onEnterKeyDownSubmitHandler={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}
                                  onInputUpdate={onChangeInventoryPRM}
                              />
                          </Grid>
                      </Fragment>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <MapsContainer
                      editable={showEditState}
                      orgCenter={orgInfoLocal.mapCenter!}
                      updateLatLong={updateOrgMapLatLong} />
                </Grid>
              </Grid>
              <br/>

              { checkUserNotOrdinaryWorker(userInfo) && showEditState &&
              <Button type={'button'} variant={isFormValid() ? "contained" : "outlined"}
                      size="large"
                      style={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                        backgroundColor: isFormValid() ? LIME_GREEN : grey[200],
                        color:DARK_NAVY,
                        fontWeight:"bold",
                        borderColor: isFormValid() ? "none" : LIME_GREEN}}
                      onClick={isCreateOrg ? handleCreateSubmit : handleUpdateSubmit}>
                Submit
              </Button>
              }
            </FormControl>
          </CardContent>
        </Card>

        <WelcomeCreateOrgDialog
            isOpen={isShowWelcomePopup}
            setIsOpen={setIsShowWelcomePopup}
            step={1} />

        <AlertToast/>
        <LoadingSpinnerFull showLoading={showLoading} />
      </div>
  );
}

export default OrgInfoUpdate;