import {httpCommonService} from "./generic/httpCommonService";
import {PlaybooksResponse} from "../types/task/PlaybooksTypes";
import {JobBoardResponse, JobBoardTableResponse, ScheduledDayObjectResponse} from "../types/task/TaskTypes";
import {JobBoardTableUtil} from "../types/charts/chartTypes";

export const jobBoardService = {
  getJobBoardUtil,
  fectJobBoardTable
}

async function fectJobBoardTable(selectedDate: string | null, accessCodes:string[]) {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/fectJobBoardTable/${selectedDate}/${accessCodes}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(async (response: any) => await response.json() as JobBoardTableUtil)
        .catch(e => {
          throw Error(e.message)
        });
  } catch (e) {
    throw e;
  }
}

async function getJobBoardUtil(selectedDate: string | null, accessCodes:string[]) {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/getJobBoardUtil/${selectedDate}/${accessCodes}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(async (response: any) => await response.json() as JobBoardResponse)
        .catch(e => {
          throw Error(e.message)
        });
  } catch (e) {throw e;}
}