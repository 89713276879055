import React, {Fragment, useEffect, useState} from "react";
import {AppBar, Dialog, DialogContent, DialogTitle, IconButton, Toolbar, Typography} from "@mui/material";
import {TaskInfo} from "../../types/task/TaskTypes";
import TaskForm from "./TaskForm";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import CloseIcon from "@mui/icons-material/Close";
import {subTaskCheckboxInit} from "../../store/task/taskInfoSlice";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {UserInfo} from "../../types/account/AccountTypes";
import {determineIfTokenError} from "../../store/account/authSlice";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {useSelector} from "react-redux";
import {FullPageDialogTransition} from "../general/FullPageDialogTransition";

type Props = {
  openEditModal:boolean,
  setOpenEditModal: (value:boolean) => void,
  task: TaskInfo,
}

const EditTaskDialog:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [showPlaybooksLoading, setShowPlaybooksLoading] = useState(false);
  const [task, setTask] = useState(props.task);
  const [showFormLoading, setShowFormLoading] = useState(false);

  useEffect(() => {
    document.title = "Edit Task Dialog";
  }, []);

  useEffect(() => {
    let newTaskToEdit = {...props.task};
    if (!newTaskToEdit.subTaskLabels || newTaskToEdit.subTaskLabels.length === 0) {
      newTaskToEdit.subTaskLabels = [subTaskCheckboxInit];
    }
    setTask(newTaskToEdit);
    /*if (props.openEditModal) {
      console.log(100)
      dispatch(taskInfoActions.setIsModalOpen({value: true}));
    } else {
      dispatch(taskInfoActions.setIsModalOpen({value: false}));
    }*/
  }, [props.openEditModal])

  return (
      <Fragment>
        <Dialog
            PaperProps={{style: {overflowY: 'visible'}}}
            fullScreen
            open={props.openEditModal}
            onClose={() => props.setOpenEditModal(false)}
            TransitionComponent={FullPageDialogTransition}
            /*sx={{zIndex:10000}}*/
            disableEscapeKeyDown
        >
          <AppBar sx={{ position: 'relative', backgroundColor:'darkgray' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Edit Task
              </Typography>
              <IconButton
                  edge="start"
                  color="inherit"
                  sx={{float:"right"}}
                  onClick={() => props.setOpenEditModal(false)}
                  aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <DialogContent sx={{padding: showFormLoading ? '70px' : 0}}>
            { showFormLoading ? (
                <LoadingSpinnerContainer showLoading={showFormLoading} />
            ) : (
                <TaskForm setShowPlaybooksLoading={setShowPlaybooksLoading}
                          showPlaybooksLoading={showPlaybooksLoading}
                          tabSelected={'taskManagement'}
                          isCreate={false}
                          openModal={props.openEditModal}
                          setOpenModal={props.setOpenEditModal}
                          wipeFormDataOnLoad={true}
                          taskToEdit={task}
                />
            )}
          </DialogContent>
        </Dialog>


        {/*<Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="sm"
            open={props.openEditModal}
        >
          <DialogTitle>
            {'Edit Task (' + props.task.taskName + ')'}
            <IconButton sx={{position: 'absolute', right: 11, top: 11}}
                        onClick={() => props.setOpenEditModal(false)}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>

          <DialogContent sx={{padding: showFormLoading ? '50px' : 0}}>
            { showFormLoading ? (
              <LoadingSpinnerContainer showLoading={showFormLoading} />
            ) : (
              <TaskForm setShowPlaybooksLoading={setShowPlaybooksLoading}
                        showPlaybooksLoading={showPlaybooksLoading}
                        tabSelected={'taskManagement'}
                        isCreate={false}
                        openModal={props.openEditModal}
                        setOpenModal={props.setOpenEditModal}
                        wipeFormDataOnLoad={true}
                        taskToEdit={task}
              />
            )}
          </DialogContent>

        </Dialog>*/}
      </Fragment>
  );
}

export default EditTaskDialog;