import {createSlice} from "@reduxjs/toolkit";
import {AppThunkDefault, userInfoActions} from "../storeIndex";
import {Address, Role, UserInfo} from "../../types/account/AccountTypes";

export interface UserInfoApiResponse {
    userInfo: UserInfo
}

export const addressInit: Address = {
    street: '',
    city: '',
    state: '',
    postalCode: ''
}

export const userInfoInit: UserInfo = {
    id:'',
    accountName:'',
    userId: '',
    firstName: '',
    lastName: '',
    username: '',
    password: '',
    address: addressInit,
    phoneNumber:'',
    role: Role.ORG_WORKER,
    isAdmin: false,
    accessCode: '',
    seenIntroTutorial: false,
    signupDate:'',
    overUnderAvgTaskCompletionTime:0,
    numberOfTasksExecuted:0,
    totalTaskHoursWorkedComparedToEstimated:0,
    numberOfSubTasksUncompleted: 0,
    numberOfTasksLateOnDeadline:0,
}

export const userInfoState: UserInfoApiResponse = {
    userInfo: userInfoInit
}

export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState: userInfoState,
    reducers: {
        updateSuccess(state, action) {
            state.userInfo = action.payload.userInfo;
        },
        updateFailure(state) {

        },
        createNewUserSuccess(state, action) {
            state.userInfo = action.payload.userInfo;
        },
        updateAccessCodeOnSignup(state, action) {
            state.userInfo.accessCode = action.payload.accessCode;
        },
        updateRole(state, action) {
            state.userInfo.role = action.payload.value;
        },
        resetToInit(state) {
            state.userInfo = userInfoInit;
        }
    }
});

export const fetchUserInfo = (userObject: UserInfo):
    AppThunkDefault => async (dispatch) => {
        dispatch(userInfoActions.updateSuccess({userInfo: userObject}));
}
