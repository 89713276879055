
export const BudgetUtilInit:BudgetUtil = {
  annualBudgets: [],
  monthlyBudgets: [],
  taskCategoryBudgets: [],

  annualBudgetsByAccountChart: []
}

export const AccountBudgetInit:AccountBudget = {
  id:'',
  amount:'0.0',
  name: '',
  isParent: false,
  monthlyBudgets:[],
  taskCategoryBudgets:[]
}

export interface BudgetUtil {
  annualBudgets: AccountBudget[],
  monthlyBudgets: AccountBudget[],
  taskCategoryBudgets: AccountBudget[],
  annualBudgetsByAccountChart: AccountBudget[],
}

export interface GenericBudget {
  amount:string,
  name: string,
}

export interface AccountBudget {
  id:string,
  amount:string,
  name:string,
  isParent:boolean,
  monthlyBudgets:GenericBudget[],
  taskCategoryBudgets:GenericBudget[]
}