import React, {Fragment, useEffect, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tooltip
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  DayStats,
  PlayGroundSafetyFormResponseUtil,
  ScheduleObject,
  TaskInfo,
  TaskResponse
} from "../../types/task/TaskTypes";
import TaskActions from "./TaskActions";
import TaskInfoAccordian from "./TaskInfoAccordian";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useDispatch, useSelector} from "react-redux";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CloseIcon from "@mui/icons-material/Close";
import TaskPdfDialog from "./TaskPdfDialog";
import TaskPdfComponent from "../reports/TaskPdfComponent";
import {PictureAsPdf} from "@mui/icons-material";

type Props = {
  task:TaskInfo,
  dialogOpen: boolean,
  setDialogOpen: (open:boolean) => void,
  selectedDate: Date | null,
  noScheduleNeeded?:boolean,
  setSelectedTask:React.Dispatch<React.SetStateAction<TaskInfo>>,
  getMonthlyTasks?: (ac: AbortController) => void
  getJobBoardUtil?:()=>void, //for job board
  getFullMonthResponse?:()=>void, // for task management page
  handleTaskSelected?:(task: TaskInfo)=>void, //for job board
  handleDaySelected?: ((weekDayDisplayName: string, taskList: TaskInfo[], scheduledEmployeeList: ScheduleObject[], dayStats: DayStats, selectedDay: TaskResponse) => void), // for monthly calendar
  day?:TaskResponse, // for monthly calendar
}

const TaskDetailDialog:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const [taskLocal, setTaskLocal] = useState(props.task);
  const tasks = useSelector((state: RootState) => state.taskInfo);
  const accountId = useSelector((state: RootState) => state.orgInfo.orgInfo.accountId);
  const [taskPdfList, setTaskPdfList] = useState(null);
  const [isOpenTaskPdfDialog, setIsOpenTaskPdfDialog] = useState(false);
  const [isTaskPdfDialogOpen, setIsTaskPdfDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (props.dialogOpen) {
      setTaskLocal(props.task);
    }
  }, [props.task])

  useEffect(() => {
    getTaskPdfs();
  }, [accountId, taskLocal?.assignedTaskId]);

  const getTaskPdfs = async () => {
    try {
      const token = localStorage.getItem('user') as string;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/get-task-pdfs/${accountId}/${taskLocal.assignedTaskId}`, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + token,
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data: PlayGroundSafetyFormResponseUtil = await response.json();
      setTaskPdfList(data); // Set the response data to TaskPdfList state
    } catch (error) {
      console.error('Error fetching task PDFs:', error);
      // Handle error or throw it for higher-level error handling
    }
  };

  const closeDialog = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    props.getJobBoardUtil();
    props.setDialogOpen(false);
  }

  const openTaskPdfDialog = () => {
    setIsOpenTaskPdfDialog(true);
  }

  return (
      <Fragment>
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', height: 650, zIndex:13000 } }}
            maxWidth="md"
            open={props.dialogOpen}
        >
          <DialogTitle>
            {taskLocal.taskName}
            <IconButton
                edge="start"
                color="inherit"
                onClick={closeDialog}
                aria-label="close"
                sx={{position: 'absolute', right: 8, top: 8}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers sx={{backgroundColor: "lightgrey"}}>
            <TaskInfoAccordian selectedTask={taskLocal}/>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={closeDialog} style={{float:"left"}}>
              Back
            </Button>
            <Button variant="text" sx={{color:'black'}} onClick={() => setIsTaskPdfDialogOpen(true)}>
              Attach PDF
            </Button>
            {taskPdfList?.length > 0 &&
                <Button autoFocus onClick={openTaskPdfDialog} style={{float:"left"}}>
                  <Tooltip title={"Task Pdf(s)"}>
                    <Box sx={{color:'black'}}>
                      <PictureAsPdf sx={{mt:.5}}/>
                    </Box>
                  </Tooltip>
                </Button>
            }
            <TaskActions
                task={taskLocal}
                selectedDate={props.selectedDate}
                taskDialogOpen={props.dialogOpen}
                setTaskDialogOpen={props.setDialogOpen}
                noScheduleNeeded={props.noScheduleNeeded}
                getTaskPdfs={getTaskPdfs}
            />
          </DialogActions>
        </Dialog>

        {/*todo: get the function passed in*/}
        <TaskPdfDialog
            setCompleteDialogOpen={()=>{}}
            accountId={accountId}
            taskId={taskLocal.assignedTaskId}
            isOpen={isTaskPdfDialogOpen}
            setIsOpen={setIsTaskPdfDialogOpen}
            task={props.task}
            getMonthlyTasks={props.getMonthlyTasks}
            getJobBoardUtil={props.getJobBoardUtil}
            handleTaskSelected={props.handleTaskSelected}
            getFullMonthResponse={props.getFullMonthResponse}
            handleDaySelected={props.handleDaySelected}
            day={props.day}
        />

        <TaskPdfComponent
            isOpen={isOpenTaskPdfDialog}
            setIsOpen={setIsOpenTaskPdfDialog}
            pdfList={taskPdfList}
            taskId={taskLocal.assignedTaskId}
            getTaskPdfs={getTaskPdfs}
        />
      </Fragment>
  );
}

export default TaskDetailDialog;