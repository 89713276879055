import React, {useEffect, useState} from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../store/storeIndex";
import {taskInfoInit} from "../store/task/taskInfoSlice";
import LoadingSpinnerFull from "../component/general/LoadingSpinnerFull";
import {useAppThunkDispatch} from "../store/storeHooks";
import CustomTable from "../component/general/table/CustomTable";
import {jbHeadCells, JobBoardTableCells} from "../types/charts/JobBoardTableProps";
import {jobBoardTableInit} from "../types/charts/chartTypes";
import {jobBoardService} from "../service/jobBoardService";
import {formatDate} from "../service/generic/DateHelper";
import {determineIfTokenError} from "../store/account/authSlice";
import {AccountParent, Role} from "../types/account/AccountTypes";
import OrgAccountSelect from "../component/account/OrgAccountSelect";
import {grey} from "@mui/material/colors";
import {useHistory, useParams} from "react-router";
import CloseIcon from "@mui/icons-material/Close";
import {animateScroll} from 'react-scroll';
import Moment from "react-moment";
import {getAccountParentFromAccessCode} from "../service/account/accountHelper";
import {getPrettyTextFromStringArray} from "../service/TasksHelper";
import {DARK_NAVY} from "../service/generic/ColorScheme";
import AlertToast from "../component/general/AlertToast";

interface ParamTypes {
  selectedDate: string
  accessCodes: string
}

const JobBoardTvPage: React.FC = () => {
  let history = useHistory();
  let dispatch = useAppThunkDispatch();
  const isWindowXL = useMediaQuery('(min-width:1020px)');
  const isWindowSmall = useMediaQuery('(max-width:415px)');

  const {selectedDate, accessCodes} = useParams<ParamTypes>();

  //const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const userInfo = useSelector((state: RootState) => state.userInfo);

  const tasks = useSelector((state: RootState) => state.taskInfo);
  const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
  const [showLoading, setShowLoading] = useState(false);
  const [jobBoardTable, setJobBoardTable] = useState({...jobBoardTableInit});
  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const [taskLocal, setTaskLocal] = useState(taskInfoInit);
  const [isBottom, setIsBottom] = useState(false);
  const [isTop, setIsTop] = useState(false);

  useEffect(() => {
    if (isBottom) {
      scrollUp();
    } else if (isTop) {
      scrollDown();
    }
  }, [isBottom, isTop]);

  const scrollUp = () => {
    setIsBottom(false);
    animateScroll.scrollToTop({duration: 14000});
    console.log("scrolling toBottom")
    let timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      console.log("done toBottom")
      setIsTop(true);
    }, 30000);
    /*return () => {
      clearTimeout(timeout);
    }*/
  }

  const scrollDown = () => {
    setIsTop(false);
    animateScroll.scrollToBottom({duration: 14000});
    let timeout: NodeJS.Timeout;
    timeout = setTimeout(() => {
      setIsBottom(true);
    }, 30000);
    /*return () => {
      clearTimeout(timeout);
    }*/
  }

  useEffect(() => {
    if (selectedTask && selectedTask.taskName) {
      let matchingTasks = tasks.monthlyTasks?.filter(mt => mt.assignedTaskId === selectedTask.assignedTaskId);
      if (matchingTasks) {
        setSelectedTask(matchingTasks[0]);
      }
    }
  }, [tasks.monthlyTasks])

  useEffect(() => {
    setTaskLocal(taskInfo);
  }, [taskInfo])

  const sleep = (milliseconds:number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
    document.title = "Job Board Tv Page";
    fectJobBoardTable();
    refresh();
  }, []);

  async function refresh() {
    while (true) {
      await sleep(120000);
      fectJobBoardTable();
    }
  }

  const fectJobBoardTable = () => {
    setShowLoading(true);
    jobBoardService.fectJobBoardTable(formatDate(selectedDate), accessCodes.split(','))
        .then((resp) => {
          setJobBoardTable(resp);
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => {
          setShowLoading(false);
          scrollDown();
        });
  }

  const determineRowHidden = (rowId: string, filterText: string): boolean => {
    if (filterText.length > 0) {
      let row = jobBoardTable.jobBoardList.find(comp => comp.id === rowId);
      if (row) {
        filterText = filterText.toLowerCase();
        if (!(row.taskName?.toLowerCase().includes(filterText) || row.tasklocations?.toLowerCase().includes(filterText) ||
            row.employeeColor?.toLowerCase().includes(filterText) || row.employeeName?.toLowerCase().includes(filterText))) {
          return true;
        }
      }
    }
    return false;
  }

  return (
      <div style={{backgroundColor:''}}>
        <div id={'scrollDivTop'}></div>
        <Box sx={{backgroundColor:DARK_NAVY}}>
          <CardHeader
              title={
                <Typography variant="h6" component="div" sx={{flexGrow: 1, cursor: 'pointer'}}
                            onClick={() => history.push('/home')}>
                  <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-light.png`}
                       style={{width:'170px', height: '50px', marginTop:'10px'}}
                       alt={'Logo Image'}
                  />
                </Typography>
              }
              action={
                <Stack direction={"row"} spacing={5} divider={<Divider orientation={"vertical"} />}>
                  <div>
                    <Typography variant="subtitle1" component="div" sx={{marginTop:'20px', color:"white"}}>
                      <Moment format={"dddd D of MMMM YYYY"}>{selectedDate}</Moment>
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="subtitle1" component="div" sx={{marginTop:'20px', color:"white"}}>
                      {getPrettyTextFromStringArray(accessCodes.split(',')!
                          .map(ac => getAccountParentFromAccessCode(ac, orgInfo.orgInfo, orgInfo.orgAccounts))
                          .flatMap(acc => acc.name))}
                    </Typography>
                  </div>
                  <div>
                    <IconButton
                        onClick={() => {
                          history.push('/manage/labor/jobBoard');
                        }}>
                      <CloseIcon />
                    </IconButton>
                  </div>
                </Stack>
              }
          />
        </Box>

        { jobBoardTable.jobBoardList != null && (
              <div style={{ width: isWindowXL ? '100%' : '100%'}}>
                <CustomTable
                    rows={jobBoardTable.jobBoardList}
                    headCells={jbHeadCells}
                    selectedRows={[]}
                    setSelectedRows={() => {}}
                    TableCellsFunc={JobBoardTableCells}
                    //rowIdsList={rowIdsList}
                    rowIdsList={jobBoardTable.jobBoardList.map(comp => comp.id)}
                    tableName={'Todays Jobs'}
                    noSelection={true}
                    dense={false}
                    pageSize={50}
                    determineRowHidden={determineRowHidden} />
              </div>
        )}
        { jobBoardTable.jobBoardList == null && (
            <Card>
              <CardContent>
                <div style={{ width: isWindowXL ? '100%' : '100%' }}>
                  <Box style={{textAlign:"center"}}>
                    <h3 style={{color:'red'}}>No Tasks Assigned Today.</h3>
                  </Box>
                </div>
              </CardContent>
            </Card>
        )}
        <div id={'scrollDivBottom'}></div>
        <LoadingSpinnerFull showLoading={showLoading}/>
        <AlertToast />
      </div>
  );
}

export default JobBoardTvPage;