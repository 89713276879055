import React, {useEffect, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {copyDayInfoValidInit, TaskInfo} from "../../types/task/TaskTypes";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {FindInPage} from "@mui/icons-material";
import {SelectChangeEvent} from "@mui/material/Select";
import {copyDayUtilInfoInit} from "../../store/task/taskInfoSlice";
import {AppLogPrintUrl} from "../../types/task/PlaybooksTypes";
import {DARK_NAVY} from "../../service/generic/ColorScheme";

type Props = {
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  selectedDate: string
}

const CoverageDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const daysTasks = useSelector((state: RootState) => state.taskInfo.daysTasks);
  const scheduleDayObjectResponse = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
  const [showLoading, setShowLoading] = React.useState(false);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [formTouched, setFormTouched] = React.useState(false);
  const orgMembers = useSelector((state: RootState) => state.account.orgMembers);
  const [copyDayInfoState, setCopyDayInfoState] = useState(copyDayUtilInfoInit);
  const [copyDayInfoStateValid, setCopyDayInfoStateValid] = useState(copyDayInfoValidInit);

  const [selectedAppLog, setSelectedAppLog] = React.useState({});
  const pbAppUrls = useSelector((state: RootState) => state.playbooks.appLogPrintUrls);
  const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
  const [taskInfoState, setTaskInfoState] = useState<TaskInfo>(taskInfo);

  const onChangeAppLog = (event: SelectChangeEvent) => {
    let newTaskState = {...taskInfoState};
    newTaskState.appLogUrl = event.target.value as string;
    newTaskState.appLog = pbAppUrls.filter(appLog => appLog.Url === event.target.value as string)[0];
    setTaskInfoState(newTaskState);
  }

  useEffect(() => {
    document.title = "Applant Dialog";
  }, []);

  const cancelDialog = () => {
    props.setIsOpen(false);
  };

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: isWindowLarge ? '35%' : '80%'} }}
          maxWidth="lg"
          open={props.isOpen}
      >
        <DialogTitle style={{fontWeight:'bold', color:DARK_NAVY}}>Upcoming Applications</DialogTitle>
        <DialogContent style={{minHeight:'285px'}}>

          {pbAppUrls.map(((appLog: AppLogPrintUrl, index) => (
              <Card sx={{m:1,p:1}}>
                <Stack direction={"row"}
                       spacing={1}
                       divider={<Divider orientation="vertical" flexItem/>}>
                  <Tooltip title={'View Application Log'}>
                    <IconButton
                        onClick={() => {
                          let url = appLog.Url;
                          if (url.includes('http:\\c')) {
                            url = url.replace('http:\\c', 'https://c');
                          }
                          window.open(url, "_blank")
                        }}>
                      <FindInPage style={{color:DARK_NAVY}}/>
                    </IconButton>
                  </Tooltip>
                  <div style={{paddingTop:'1.2%', color:DARK_NAVY}}>{appLog.ApplicationAreaLog}</div>
                  <div style={{paddingTop:'1.2%', color:DARK_NAVY}}>{appLog.ApplicationLogDate}</div>
                </Stack>
              </Card>
          )))}

        </DialogContent>
        <DialogActions style={{backgroundColor:'#BDBDBD'}}>
          <Button style={{color:DARK_NAVY}} autoFocus onClick={() => cancelDialog()}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
  );

}

export default CoverageDialog;