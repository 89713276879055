import React, {Fragment, useEffect} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Tooltip
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import ConfirmDialog from "../general/ConfirmDialog";
import {useAppThunkDispatch} from "../../store/storeHooks";
import LoadingSpinnerFull from "../general/LoadingSpinnerFull";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Download } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import PdfFormDisplayDialog from "../task/PdfFormDisplayDialog";
import usePdfDownload from "../../hooks/usePdfDownload";
import {formatDate} from "../../service/generic/DateHelper";

type Props = {
    /*reportYear:Date|number*/
    isOpen: boolean,
    setIsOpen: (open: false) => void,
    pdfList:PlaygroundSafetyForm[],
    taskId: string,
    getTaskPdfs:()=>void,
}

interface PlaygroundSafetyForm {
    formId: string;
    accountId: string;
    ageAppropriate: string;
    comments: string;
    equipmentCheckPoints: any[]; // Update with actual type
    imageName: string;
    inspectedBy: string[];
    inspectionDate: string;
    inspectionTime: string;
    location: string;
    safetyCheckPoints: any[]; // Update with actual type
    taskId: string;
    type: string;
}

const TaskPdfComponent:React.FC<Props> = (props) => {
    let dispatch = useAppThunkDispatch();

    const [showLoading, setShowLoading] = React.useState(false);
    const accountId = useSelector((state: RootState) => state.orgInfo.orgInfo.accountId);
    const [openPdfDeleteModal, setOpenPdfDeleteModal] = React.useState(false);
    const [pdfIdToDelete, setPdfIdToDelete] = React.useState(null);
    const [pdfTypeToDelete, setPdfTypeToDelete] = React.useState(null);
    const [showPdfDisplayDialog, setShowPdfDisplayDialog] = React.useState(false);
    const [pdfToShow, setPdfToShow] = React.useState(null);

    const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
    const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);

    const {downloadFile} = usePdfDownload();

    //Delete Warning logic
    useEffect(()=>{
        if(pdfIdToDelete && pdfTypeToDelete){
            setOpenPdfDeleteModal(true);
        } else {
            setOpenPdfDeleteModal(false);
        }
    },[pdfIdToDelete, pdfTypeToDelete])

    async function deletePdf() {
        setShowLoading(true)
        try {
            let token = localStorage.getItem('user') as string;
            return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/delete-pdf/${formatDate(selectedDate)}/${allSelectedAccessCodes.toString()}/${pdfIdToDelete}/${pdfTypeToDelete}/${props.taskId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            })
                .then(async (response) => {
                    const resp = await response.json();
                    dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: resp?.taskList ?? []}));
                    dispatch(taskInfoActions.setSavedTemplates({taskTemplates: resp?.taskTemplates ?? []}));
                    props.getTaskPdfs();
                    setShowLoading(false);
                    setPdfTypeToDelete(null);
                    setPdfIdToDelete(null);
                })
                .catch(e => {
                    throw Error(e.message)
                });
        } catch (e) {throw e;}
    }

    const formatTime = (isoTime: string): string => {
        const date = new Date(isoTime);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const formatDateUI = (isoDate: string): string => {
        const date = new Date(isoDate);
        return date.toLocaleDateString([], { month: 'short', day: 'numeric', year: 'numeric' });
    };

    const viewPdf = (index:number) => {
        downloadFile(props.pdfList[index]);
    }

    const closeDialog = () => {
        props.setIsOpen(false);
    }

    return (
        <Fragment>
            <LoadingSpinnerFull showLoading={showLoading} />

            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%', height: '60%' } }}
                open={props.isOpen}
            >
                <DialogTitle>
                    Task PDF(s)
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={closeDialog}
                        aria-label="close"
                        sx={{position: 'absolute', right: 8, top: 8}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>

                    <Grid container spacing={2}>
                        {props.pdfList?.map((pdf, index) => (
                            <Grid item xs={12} sm={12} md={12} key={index}>
                                <Card sx={{backgroundColor:'#e6e6e6'}}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} key={index}>
                                                <Box sx={{mt:-1}}>
                                                    <Typography variant="h6" component="h2">
                                                        Location: {pdf?.location}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} key={index}>
                                                <Box sx={{float:'right'}}>
                                                    <Typography>
                                                        Date: {formatDateUI(pdf?.inspectionDate)}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6} key={index}>
                                                <Box sx={{}}>
                                                    <Typography>
                                                        Inspected By: {pdf?.inspectedBy.join(', ')}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} key={index}>
                                                <Box sx={{float:'right'}}>
                                                    <Typography>
                                                        Time: {formatTime(pdf?.inspectionTime)}
                                                    </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6} key={index}>
                                                <Box sx={{}}>
                                                    <Typography >
                                                        Type: {pdf?.type}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} key={index}>
                                                <Box sx={{float:'right'}}>
                                                    {/*<Typography>
                                                        Age Appropriate: {pdf?.ageAppropriate}
                                                    </Typography>*/}
                                                </Box>
                                            </Grid>

                                        </Grid>

                                        <Box sx={{mb:2, float:'right'}}>
                                            <Tooltip title="Download">
                                                <Button sx={{color:'gray'}} onClick={()=>viewPdf(index)}>
                                                    <Download/>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton sx={{px:2}} aria-label="delete-task-pdf"
                                                            onClick={() => {
                                                                setPdfIdToDelete(pdf?.formId);
                                                                setPdfTypeToDelete(pdf?.type)
                                                                setOpenPdfDeleteModal(true)
                                                            }}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>

                </DialogContent>
                <DialogActions>

                </DialogActions>
            </Dialog>

            <ConfirmDialog handleSubmit={deletePdf}
                           title={"Delete Pdf"}
                           id={pdfIdToDelete}
                           isOpen={openPdfDeleteModal} setIsOpen={setOpenPdfDeleteModal} />

            <PdfFormDisplayDialog
                isOpen={showPdfDisplayDialog}
                setIsOpen={setShowPdfDisplayDialog}
                accountId={accountId}
                taskId={pdfToShow?.taskId}
                formId={pdfToShow?.formid}
                name={pdfToShow?.imageName}
                type={pdfToShow?.type}
                getPdfs={props.getTaskPdfs}
            />

        </Fragment>
    );
}

export default TaskPdfComponent;