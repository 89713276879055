import React, {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {Box, Card, CardContent, CardHeader, Grid, IconButton, TextField, Tooltip, useMediaQuery} from "@mui/material";
import {jobBoardActions, RootState, taskInfoActions} from "../../store/storeIndex";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {ArrowBackIosNew, ArrowForwardIos} from "@mui/icons-material";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Role} from "../../types/account/AccountTypes";
import OrgAccountSelect from "../account/OrgAccountSelect";
import {useSelector} from "react-redux";
import {formatDate} from "../../service/generic/DateHelper";
import {JobBoardResponse, JobBoardUtil, TaskInfo, TaskResponse} from "../../types/task/TaskTypes";
import {determineIfTokenError} from "../../store/account/authSlice";
import {jobBoardService} from "../../service/jobBoardService";
import Typography from "@mui/material/Typography";
import {avatarService} from "../../service/generic/avatarService";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import TaskDetailDialog from "../task/TaskDetailDialog";
import {green, red} from "@mui/material/colors";
import CustomTable from "../general/table/CustomTable";
import {jbHeadCells, JobBoardTableCells} from "../../types/charts/JobBoardTableProps";
import {jobBoardTableInit} from "../../types/charts/chartTypes";
import TvIcon from '@mui/icons-material/Tv';
import {
  DARK_NAVY,
  GHOST_WHITE,
  LIGHT_GREY_BLUE,
  LIGHTER_GREY_BLUE,
  LIME_GREEN
} from "../../service/generic/ColorScheme";
import TaskDisplayCard from "../task/TaskDisplayCard";
import {taskManagementService} from "../../service/taskManagementService";

type Props = {
}

const JobBoardComponent:React.FC<Props> = (props) => {
  //let history = useHistory();
  let dispatch = useAppThunkDispatch();
  //const isWindowLarge = useMediaQuery('(min-width:800px)');

  const tasks = useSelector((state: RootState) => state.taskInfo);
  const jobBoardState = useSelector((state:RootState) => state.jobBoard)
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [showLoading, setShowLoading] = React.useState(false);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const [alignment, setAlignment] = React.useState<string>('left');
  const isWindowXL = useMediaQuery('(min-width:1020px)');
  const [jobBoardTable] = useState({...jobBoardTableInit});

  const getJobBoardUtil = useCallback(() => {
    if (orgInfo.allSelectedAccessCodes && orgInfo.allSelectedAccessCodes.length > 0) {
      setShowLoading(true);
      jobBoardService.getJobBoardUtil(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!)
          .then((resp: JobBoardResponse) => {
            dispatch(jobBoardActions.setJobBoardResponse({value: resp}));
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      }).finally(() => setShowLoading(false));
    }
  }, [orgInfo, setShowLoading, selectedDate, dispatch]);

  const runEffect = useRef(false)
  useEffect(() => {
    if (selectedTask && selectedTask.taskName && runEffect.current) {
      let matchingTasks = tasks.monthlyTasks?.filter(mt => mt.assignedTaskId === selectedTask.assignedTaskId);
      if (matchingTasks && matchingTasks.length > 0) {
        console.log(matchingTasks)
        setSelectedTask(matchingTasks[0]);
      }
    }
    return () => { runEffect.current = true };
  }, [selectedTask, tasks.monthlyTasks])

  useEffect(() => {
    getJobBoardUtil();
    document.title = "Job Board Page";
  }, [
      selectedDate,
      orgInfo.allSelectedAccessCodes,
      tasks.monthlyTasks,
      getJobBoardUtil
  ])

  /*const handleAlignment = (
      event: React.MouseEvent<HTMLElement>,
      newAlignment: string,
  ) => {
    setAlignment(newAlignment);
  };*/

  const showTVJobBoard = () => {
    window.open("/manage/labor/jobBoardTv/" + formatDate(selectedDate) + "/" + allSelectedAccessCodes, "_blank");
  }

  const setSelectedDate = (date: Date | string | null) => {
    dispatch(taskInfoActions.setSelectedDate({selectedDate:date?.toString()}));
  }

  const selectPrevDate = () => {
    let currentSelection = new Date(selectedDate);
    currentSelection.setDate(currentSelection.getDate() - 1);
    setSelectedDate(currentSelection);
  }

  const selectNextDate = () => {
    let currentSelection = new Date(selectedDate);
    currentSelection.setDate(currentSelection.getDate() + 1);
    setSelectedDate(currentSelection);
  }

  const handleTaskSelected = (task: TaskInfo) => {
    setSelectedTask(task);
    setTaskDialogOpen(true);
  }

  const getMonthlyTasks = (ac:AbortController) => {
    taskManagementService.getMonthlyTasks(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!, ac)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
          dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage: tasks.taskCategories}));
          dispatch(taskInfoActions.setTaskLocationsPage({locationsPage: tasks.taskLocations}))
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => {});
  }

  const determineRowHidden = (rowId: string, filterText: string): boolean => {
    if (filterText.length > 0) {
      let row = jobBoardTable.jobBoardList.find(comp => comp.id === rowId);
      if (row) {
        filterText = filterText.toLowerCase();
        if (!(row.taskName?.toLowerCase().includes(filterText) || row.tasklocations?.toLowerCase().includes(filterText) ||
            row.employeeColor?.toLowerCase().includes(filterText) || row.employeeName?.toLowerCase().includes(filterText))) {
          return true;
        }
      }
    }
    return false;
  }

  return (
      <Fragment>
        { showLoading ? (
            <Box sx={{marginTop:'200px'}}>
              <LoadingSpinnerContainer showLoading={showLoading} />
            </Box>
        ) : (
            <Fragment>
                <Box sx={{borderBottom:'1px solid black', pb:3, px:0, pt:0, mb:3, mt:0}}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={5}>
                      <h1 style={{display:"flex", justifyContent:"left", margin:0, padding:0, paddingTop:'4px', color:DARK_NAVY}}>Job Board</h1>
                    </Grid>
                    <Grid item sm={12} md={7}>
                      <Grid container spacing={2} sx={{float:'right', width:'100%'}}>
                        <Grid item xs={2}>
                          <Tooltip title={'Back one day'}>
                            <IconButton sx={{backgroundColor:LIME_GREEN, color:DARK_NAVY, float:"right", mt:1}}
                                        onClick={() => selectPrevDate()}>
                              <ArrowBackIosNew/>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={8}>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date"
                                value={selectedDate}
                                onChange={(newValue) => {
                                  setSelectedDate(newValue);
                                }}
                                renderInput={(params) => <TextField sx={{width:'100%'}} {...params} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title={'Forward one day'}>
                            <IconButton sx={{backgroundColor:LIME_GREEN, color:DARK_NAVY, float:"left", mt:1}}
                                        onClick={() => selectNextDate()}>
                              <ArrowForwardIos/>
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                <Grid container>
                  <Grid item lg={10} md={10} sm={9} xs={9}>
                    { userInfo.userInfo.accessCode === orgInfo.orgInfo.accessCode && userInfo.userInfo.role !== Role.ORG_WORKER &&
                    <OrgAccountSelect/>
                    }
                  </Grid>
                  <Grid item lg={2} md={2} sm={3} xs={3}>
                    {/*<ToggleButtonGroup
                        value={alignment}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="text alignment"
                        style={{paddingLeft:'25%'}}
                    >
                      <ToggleButton value="left" aria-label="left">
                        <ChevronLeftIcon/>
                      </ToggleButton>
                      <ToggleButton value="right" aria-label="right">
                        <ChevronRightIcon/>
                      </ToggleButton>
                    </ToggleButtonGroup>*/}
                    <IconButton sx={{mt:1,ml:5,color:DARK_NAVY}}
                                onClick={() => showTVJobBoard()}>
                      <TvIcon/>
                    </IconButton>
                  </Grid>
                </Grid>
                { alignment === 'left' && (
                  <Fragment>
                    <Card sx={{height:'100%', textAlign:"center", backgroundColor:LIGHT_GREY_BLUE, mb:2}}>
                      <CardContent>
                        <Grid container spacing={2} sx={{textAlign:'center', fontWeight:'bold'}}>
                          <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                            <Tooltip title={'Day Selected'}>
                              <Card sx={{ bgcolor: GHOST_WHITE, cursor:'pointer', width:'100%'}} raised={false}>
                                <CardContent>
                                  <h2 style={{margin:0, marginTop:'5px', color:DARK_NAVY}}>
                                    {jobBoardState.jobBoardResponse.displayDateName}
                                  </h2>
                                </CardContent>
                              </Card>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                            <Tooltip title={'Total Tasks Scheduled'}>
                              <Card sx={{ bgcolor: DARK_NAVY, cursor:'pointer', width:'100%'}} raised={false}>
                                <CardContent>
                                  <h2 style={{margin:0, marginTop:'5px', color:"white"}}>
                                    {jobBoardState.jobBoardResponse.totalTasksForTheDay}
                                  </h2>
                                </CardContent>
                              </Card>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                            <Tooltip title={'Completed Tasks'}>
                              <Card sx={{ bgcolor: green[500], cursor:'pointer', width:'100%'}} raised={false}>
                                <CardContent>
                                  <h2 style={{margin:0, marginTop:'5px'}}>
                                    {jobBoardState.jobBoardResponse.totalCompletedTasksForTheDay}
                                  </h2>
                                </CardContent>
                              </Card>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                            <Tooltip title={'Uncompleted Tasks'}>
                              <Card sx={{ bgcolor: red['600'], cursor:'pointer', width:'100%'}} raised={false}>
                                <CardContent>
                                  <h2 style={{margin:0, marginTop:'5px'}}>
                                    {jobBoardState.jobBoardResponse.totalUncompletedTasksForTheDay}
                                  </h2>
                                </CardContent>
                              </Card>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    <Grid container sx={{display:'flex', alignItems:'stretch'}} spacing={2}>
                      { jobBoardState.jobBoardResponse.jobBoardUtilList.map((jobBoardUtil: JobBoardUtil, index) => (
                          <Grid item lg={6} md={6} sm={12} key={index}>
                            <Card key={"jobBoardItem" + index} raised={false}
                                  style={{height:'100%', justifyContent:'space-between',
                                    border:"solid 4px",backgroundColor:LIGHTER_GREY_BLUE,borderColor:avatarService.stringToColor(jobBoardUtil.employeeName)}}>
                              <CardHeader
                                  sx={{pb:0,pt:0, textAlign:"center"}}
                                  title={
                                    <Typography variant="h5" component="div" style={{borderBottom:'2px solid', borderColor:DARK_NAVY,color:DARK_NAVY,fontWeight:"bold"}}>
                                      {jobBoardUtil.employeeName}
                                    </Typography>
                                  }
                                  /*action={
                                    <CircleIcon sx={{pt:1}} style={{color:avatarService.stringToColor(jobBoardUtil.employeeName)}}/>
                                  }*/
                              />
                              <CardContent sx={{mb:0,p:1,maxHeight:'335px', overflowY:"auto"}}>
                                {jobBoardUtil.taskList && jobBoardUtil.taskList.length > 0 &&
                                jobBoardUtil.taskList.map((task: TaskInfo, index) => (
                                    <Box key={task.assignedTaskId}>
                                      <TaskDisplayCard
                                          task={task}
                                          handleTaskSelected={handleTaskSelected}
                                          taskDialogOpen={taskDialogOpen}
                                          setTaskDialogOpen={setTaskDialogOpen}
                                      />
                                    </Box>
                                ))}
                                {(!jobBoardUtil.taskList || jobBoardUtil.taskList.length === 0) &&
                                <Typography variant="subtitle1" component="div">
                                  <h3 style={{color:DARK_NAVY}}>No Tasks Assigned</h3>
                                </Typography>
                                }
                              </CardContent>
                            </Card>
                          </Grid>
                      ))}
                    </Grid>
                    {(!jobBoardState.jobBoardResponse.jobBoardUtilList || jobBoardState.jobBoardResponse.jobBoardUtilList.length === 0) &&
                    <Box style={{textAlign:"center"}}>
                      <h3 style={{color:'red', paddingTop:'8%'}}>Nobody scheduled and no tasks assigned for today.</h3>
                    </Box>
                    }
                  </Fragment>
                )}
              { alignment === 'right' && (
                <Fragment>
                  { jobBoardTable.jobBoardList != null && (
                    <CardContent>
                      <div style={{ width: isWindowXL ? '100%' : '99%'}}>
                        <CustomTable
                            rows={jobBoardTable.jobBoardList}
                            headCells={jbHeadCells}
                            selectedRows={[]}
                            setSelectedRows={() => {}}
                            TableCellsFunc={JobBoardTableCells}
                            //rowIdsList={rowIdsList}
                            rowIdsList={jobBoardTable.jobBoardList.map(comp => comp.id)}
                            tableName={'Todays Jobs'}
                            noSelection={true}
                            dense={true}
                            pageSize={50}
                            determineRowHidden={determineRowHidden} />
                      </div>
                    </CardContent>
                  )}
                  { jobBoardTable.jobBoardList == null && (
                      <CardContent>
                        <div style={{ width: isWindowXL ? '100%' : '99%' }}>
                          <Box style={{textAlign:"center"}}>
                            <h3 style={{color:'red', paddingTop:'8%'}}>No Tasks Assigned Today.</h3>
                          </Box>
                        </div>
                      </CardContent>
                  )}
                </Fragment>
              )}
            </Fragment>
        )}

        <TaskDetailDialog
            task={selectedTask}
            dialogOpen={taskDialogOpen}
            setDialogOpen={setTaskDialogOpen}
            selectedDate={new Date()}
            setSelectedTask={setSelectedTask}
            getMonthlyTasks={getMonthlyTasks}
            getJobBoardUtil={getJobBoardUtil}
            handleTaskSelected={handleTaskSelected}
        />
      </Fragment>
  );

}

export default JobBoardComponent;