import {createSlice} from "@reduxjs/toolkit";
import {companyDirectoryResponseInit} from "./task/taskInfoSlice";

const companyDirectoryStateInit = {
  companyDirectoryResponse: companyDirectoryResponseInit,
}

export const CompanyDirectorySlice = createSlice({
  name: 'schedule',
  initialState: companyDirectoryStateInit,
  reducers: {
    setCompanyDirectoryResponse(state, action) {
      state.companyDirectoryResponse = action.payload.value;
    },
    resetToInit(state) {
      state = companyDirectoryStateInit;
    }
  }
});