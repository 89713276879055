import React, {Fragment, useState} from "react";
import Navbar from "../../component/nav/Navbar";
import {useDispatch, useSelector} from "react-redux";
import {authActions, RootState, userInfoActions} from "../../store/storeIndex";
import {Redirect} from "react-router-dom";
import {Box, Button, FormControl, Grid, InputLabel, Stack} from "@mui/material";
import CustomTextField from "../../component/general/CustomTextField";
import AlertToast from "../../component/general/AlertToast";
import {Role, UserInfo} from "../../types/account/AccountTypes";
import {loginService} from "../../service/account/loginService";
import {displayAlert} from "../../store/alertSlice";
import LoadingSpinnerFull from "../../component/general/LoadingSpinnerFull";
import {emailRegex, userInfoValidInit} from "../../types/account/AccountValids";
import {useHistory} from "react-router";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";
import {userInfoInit} from "../../store/account/userInfoSlice";

const SignupPage: React.FC = () => {
  const {userInfo} = useSelector((state: RootState) => state.userInfo);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  let dispatch = useAppThunkDispatch();
  const history = useHistory();

  const userInfoGlobal = useSelector((state: RootState) => state.userInfo.userInfo);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [userInfoValid, setUserInfoValid] = useState(userInfoValidInit);
  const [showLoading, setShowLoading] = useState(false);
  const [userInfoState, setUserInfoState] = useState<UserInfo>(userInfoInit);
  const [selectedMonth, setSelectedMonth] = React.useState('State..');

  const createUserHandler = () => {
    let timeout: NodeJS.Timeout;
    if (isFormValid() && showLoading == false) {
      setShowLoading(true);

      timeout = setTimeout(() => {
        loginService.signupNewUser(userInfoState)
            .then((token: string) => {
              dispatch(authActions.loginSuccess({token: token}));

              let newUserState = {...userInfoState};
              if (!userInfoState.accessCode || userInfoState.accessCode.length === 0) {
                newUserState.role = Role.ORG_ADMIN;
              }

              dispatch(userInfoActions.createNewUserSuccess({userInfo: newUserState}));
              history.push('/manage/user/account');
              dispatch(displayAlert(true, 'Successfully created. Welcome!'));
            })
            .catch(e => {
              dispatch(determineIfTokenError(e));
            });
        setShowLoading(false);
      }, 500);

      //userInfoState.password = '';
    }

    return () => {
      clearTimeout(timeout);
    };
  };

  const isFormValid = () => {
    return userInfoValid.firstName && userInfoValid.lastName && userInfoValid.username &&
        userInfoValid.password && userInfoValid.street && userInfoValid.city && userInfoValid.state &&
        userInfoValid.postalCode && userInfoValid.phoneNumber;
  }

  const determineUnameValid = (uname: string) => {
    userInfoValid.username = uname.trim().length > 0 && emailRegex.test(uname.toLowerCase());
    setUserInfoValid(userInfoValid);
  }

  const determinePassValid = (pass: string) => {
    userInfoValid.password = pass.trim().length > 5;
    setUserInfoValid(userInfoValid);
  }

  const onChangeUsername = (unameVal:string) => {
    setFormTouched(true);
    determineUnameValid(unameVal);
    let newUserState = {...userInfoState};
    newUserState.username = unameVal;
    setUserInfoState(newUserState);
  }

  const onChangePassword = (passVal:string) => {
    setFormTouched(true);
    determinePassValid(passVal);
    let newUserState = {...userInfoState};
    newUserState.password = passVal;
    setUserInfoState(newUserState);
  }

  const onChangeFirstName = (nameVal:string) => {
    setFormTouched(true);
    userInfoValid.firstName = nameVal.trim().length > 1;
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    newUserState.firstName = nameVal;
    setUserInfoState(newUserState);
  }

  const onChangeLastName = (nameVal:string) => {
    setFormTouched(true);
    userInfoValid.lastName = nameVal.trim().length > 1;
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    newUserState.lastName = nameVal;
    setUserInfoState(newUserState);
  }

  const onChangePhoneNumber = (pNumberValue:string) => {
    setFormTouched(true);
    userInfoValid.phoneNumber = pNumberValue.trim().length === 10 && !isNaN(Number(pNumberValue));
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    newUserState.phoneNumber = pNumberValue;
    setUserInfoState(newUserState);
  }

  const onChangeStreet = (streetValue:string) => {
    setFormTouched(true);
    userInfoValid.street = streetValue.trim().length > 1;
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    let address = {...userInfoState.address};
    address.street = streetValue;
    newUserState.address = address;
    setUserInfoState(newUserState);
  }

  const onChangeCity = (cityValue:string) => {
    setFormTouched(true);
    userInfoValid.city = cityValue.trim().length > 1;
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    let address = {...userInfoState.address};
    address.city = cityValue;
    newUserState.address = address;
    setUserInfoState(newUserState);
  }

  const onChangeState = (stateValue:string) => {
    userInfoValid.state = stateValue.trim().length > 1;
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    let address = {...userInfoState.address};
    address.state = stateValue;
    newUserState.address = address;
    setUserInfoState(newUserState);
  }

  const onChangePostalCode = (postalCodeValue:string) => {
    setFormTouched(true);
    userInfoValid.postalCode = postalCodeValue.trim().length === 5 && !isNaN(Number(postalCodeValue));
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    let address = {...userInfoState.address};
    address.postalCode = postalCodeValue;
    newUserState.address = address;
    setUserInfoState(newUserState);
  }

  const handleMonthFilterChange = (val: string) => {
    setFormTouched(true);
    userInfoValid.state = val !="State..";
    setUserInfoValid(userInfoValid);
    let newUserState = {...userInfoState};
    let address = {...userInfoState.address};
    address.state = val;
    newUserState.address = address;
    setUserInfoState(newUserState);
    setSelectedMonth(val);
  };

  const states = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
    'Florida', 'Georgia', 'Hawaii', 'Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana',
    'Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada',
    'New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania',
    'Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia',
    'Wisconsin','Wyoming'];

  /*if (loggedIn) {
    return <Redirect to={'/login'} />
  }*/

  return (
      <Fragment>
        <Navbar children={<div></div>}/>
        <Grid container>
          <Box component={Grid} item
               lg={6}
               display={{ xs: "none", lg: 'block' }}
          >
            <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/skytree.jpg`}
                 style={{backgroundRepeat:'no-repeat', width:'50%', position:'absolute',
                   backgroundPosition:"left top",height: '100%'}}
                 alt={'Registration Background Image'}
            />
          </Box>
          <Grid item lg={6} md={12} sm={12}>
            <Box sx={{padding:'20px'}}>
              <h1>Create your user account</h1>
              <form onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                createUserHandler();
              }}>
                <Stack spacing={2}>
                  <CustomTextField
                      valueString={userInfoState.username }
                      label={"Email"}
                      isValid={userInfoValid.username || !formTouched}
                      validationText={'must be a valid email'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangeUsername}
                  />
                  <CustomTextField
                      valueString={userInfoState.firstName}
                      isValid={userInfoValid.firstName || !formTouched}
                      label={"First Name"}
                      validationText={'Invalid First Name'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangeFirstName}
                  />
                  <CustomTextField
                      valueString={userInfoState.lastName}
                      isValid={userInfoValid.lastName || !formTouched}
                      label={"Last Name"}
                      validationText={'Invalid Last Name'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangeLastName}
                  />
                  <CustomTextField
                      valueString={userInfoState.phoneNumber}
                      isValid={userInfoValid.phoneNumber || !formTouched}
                      label={"Phone #"}
                      validationText={'Invalid Phone Number'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangePhoneNumber}
                  />
                  <CustomTextField
                      valueString={userInfoState.address.street}
                      isValid={userInfoValid.street || !formTouched}
                      label={"Street"}
                      validationText={'Invalid Street'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangeStreet}
                  />
                  <CustomTextField
                      valueString={userInfoState.address.city}
                      isValid={userInfoValid.city || !formTouched}
                      label={"City"}
                      validationText={'Invalid City'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangeCity}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="stateLabel">State</InputLabel>
                    <Select
                        labelId="stateLabel"
                        id="stateSelect"
                        style={{width:'100%'}}
                        value={userInfoState.address.state}
                        label="State"
                        onChange={(event: SelectChangeEvent) => onChangeState(event.target.value)}
                        sx={{float:"right"}}
                    >
                      <MenuItem disabled={true} value={""}>State..</MenuItem>
                      { states.map(state => (
                          <MenuItem value={state} key={state}>{state}</MenuItem>
                      ))}
                    </Select>
                    <small style={{color:'red', marginTop:0}} hidden={userInfoValid.state || !formTouched}>
                      Invalid State
                    </small>
                  </FormControl>
                  <CustomTextField
                      isValid={userInfoValid.postalCode || !formTouched}
                      valueString={userInfoState.address.postalCode}
                      label={"Postal Code"}
                      validationText={'Invalid Postal Code'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangePostalCode}
                  />

                  <CustomTextField
                      valueString={userInfoState.password}
                      label={"Password"}
                      type={'password'}
                      isValid={userInfoValid.password || !formTouched}
                      validationText={'must be longer than 5 characters'}
                      onEnterKeyDownSubmitHandler={createUserHandler}
                      onInputUpdate={onChangePassword}
                  />

                  <Button type={'submit'} variant={isFormValid() ? "contained" : "outlined"}
                          size="large" style={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                    backgroundColor: isFormValid() ? LIME_GREEN : grey[200],
                    color:DARK_NAVY,
                    fontWeight:"bold",
                    borderColor: isFormValid() ? "none" : LIME_GREEN}}>
                    Create
                  </Button>
                </Stack>
              </form>
            </Box>
          </Grid>

          <AlertToast/>
          <LoadingSpinnerFull showLoading={showLoading} />
        </Grid>
      </Fragment>
  );
}

export default SignupPage;