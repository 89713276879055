import {
  AccountInfo, AccountParent,
  Address,
  AllowedEmail,
  OrgInfo,
  OrgInfoState,
  Role,
  UserInfo
} from "../../types/account/AccountTypes";
import {createSlice} from "@reduxjs/toolkit";
import {addressInit, UserInfoApiResponse, userInfoInit} from "./userInfoSlice";
import {accountStateInit, mapLatLongInit, preferenceConfigInit} from "../../types/account/AccountInits";


export const orgInfoInit: OrgInfo = {
  accountId: '',
  name: '',
  address: addressInit,
  phoneNumber:'',
  accessCode: '',
  accountAccessCodes: [],
  timezone: '',
  orgAdminId: '',
  allowedEmails: [],
  preferenceConfig: preferenceConfigInit,
  playbooksId: '',
  dashPrm:'',
  recordsPrm:'',
  reportsPrm:'',
  alertsPrm:'',
  labelSdsPrm:'',
  inventoryPrm:'',
  mapCenter:null,
}

export const accountInfoInit: AccountInfo = {
  accountId: '',
  name: '',
  address: addressInit,
  phoneNumber:'',
  accessCode: '',
  timezone: '',
  orgAdminId: '',
  allowedEmails: [],
  preferenceConfig: preferenceConfigInit,
  playbooksId:'',
  dashPrm:'',
  recordsPrm:'',
  reportsPrm:'',
  alertsPrm:'',
  labelSdsPrm:'',
  inventoryPrm:'',
}

export const orgInfoStateInit: OrgInfoState = {
  orgInfo: orgInfoInit,
  orgAccounts: [],
  selectedAccounts: [],//accountInfoInit,
  allAccountOptions: [],
  allSelectedAccessCodes: [],
}

export const orgInfoSlice = createSlice({
  name: 'orgInfo',
  initialState: orgInfoStateInit,
  reducers: {
    updateOrgSuccess(state, action) {
      state.orgInfo = action.payload.orgInfo;
    },
    createNewOrgSuccess(state, action) {
      state.orgInfo = action.payload.orgInfo;
    },
    updateAccountSuccess(state, action) {
      let freshRender = !state.orgInfo.accessCode;

      let orgInfo = action.payload.orgInfoState.orgInfo;
      let orgAccounts = action.payload.orgInfoState.orgAccounts;

      if (orgInfo && orgInfo.name) {
        state.orgInfo = orgInfo;
      }
      if (!orgAccounts) {
        orgAccounts = [];
      }

      state.orgAccounts = orgAccounts;
      state.allAccountOptions = [orgInfo, ...orgAccounts];
      //state.allAccountOptions[0].name = state.allAccountOptions[0].name + ' (parent)';

      if (freshRender) {
        if (orgAccounts.length === 1 && orgInfo.accountAccessCodes.length > 1) {
          state.selectedAccounts = [...state.selectedAccounts, orgAccounts[0]];
          state.allSelectedAccessCodes = [...state.allSelectedAccessCodes!, orgAccounts[0].accessCode];
        } else {
          //state.selectedAccounts = [...state.selectedAccounts, orgInfo];
          //state.allSelectedAccessCodes = [...state.allSelectedAccessCodes!, orgInfo.accessCode];
          if (orgAccounts?.length > 1) {
            state.selectedAccounts = [orgInfo, ...orgAccounts];
            let accessCodes: any[] = [orgInfo, ...orgAccounts].flatMap((acc: { accessCode: any; }) => acc.accessCode);
            state.allSelectedAccessCodes = [...accessCodes];
          } else {
            state.selectedAccounts = [...orgAccounts];
            let accessCodes: any[] = [...orgAccounts].flatMap((acc: { accessCode: any; }) => acc.accessCode);
            state.allSelectedAccessCodes = [...accessCodes];
          }
        }
      } else {
        let updatedSelected = state.allAccountOptions.filter(acc => state.allSelectedAccessCodes?.includes(acc.accessCode));
        state.selectedAccounts = updatedSelected;
      }
    },
    createNewAccountSuccess(state, action) {
      state.orgInfo = action.payload.orgInfoState.orgInfo;
      state.orgAccounts = action.payload.orgInfoState.orgAccounts;
      state.allAccountOptions = [state.orgInfo, ...state.orgAccounts];
    },
    setSelectedAccounts(state, action) {
      /*if (state.selectedAccounts?.map(sa => sa.accessCode).includes(action.payload.selectedAccounts[0].accessCode)) {
        state.selectedAccounts = state.selectedAccounts.filter(sa => sa.accessCode !== action.payload.selectedAccounts[0].accessCode);
      } else {
        state.selectedAccounts = state.selectedAccounts.concat(action.payload.selectedAccounts[0]);
      }*/
      state.selectedAccounts = [...action.payload.selectedAccounts];
    },
    setAllSelectedAccessCodes(state, action) {
      /*if (state.allSelectedAccessCodes?.includes(action.payload.accessCodes[0])) {
        state.allSelectedAccessCodes = state.allSelectedAccessCodes?.filter(ac => ac !== action.payload.accessCodes[0]);
      } else {
        state.allSelectedAccessCodes = state.allSelectedAccessCodes?.concat(action.payload.accessCodes[0]);
      }*/
      state.allSelectedAccessCodes = [...action.payload.accessCodes];
    },
    manualInitSelectedAfterOrgCreation(state,action) {
      let selectedArray = [...state.selectedAccounts];
      let accessCodesArray = [...state.allSelectedAccessCodes!];
      selectedArray.push(action.payload.selectedAccounts);
      accessCodesArray.push(action.payload.allSelectedAccessCodes);
      state.selectedAccounts = selectedArray;
      state.allSelectedAccessCodes = accessCodesArray;
    },
    resetToInit(state) {
      state = orgInfoStateInit;
    }
  }
});