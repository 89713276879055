import {AccountBudget, AccountBudgetInit} from "../task/BudgetTypes";
import {TaskInfo} from "../task/TaskTypes";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import {UserInfo} from "../account/AccountTypes";

export interface TotalAccountBudgetHolder {
  taskHourExpensesCharts: AccountBudgetCharts,
  totalExpensesCharts: AccountBudgetCharts,
  materialsExpensesByMonthChart : MonthAccountBudgetLineChartItem[],
  overUnderWorkerStats: UserInfo[],
  overUnderTaskCategoryStats: TaskStats[],
  overUnderTaskLocationStats: TaskStats[],
  fullTaskExpensesSpreadsheetReport: FullSpreadsheet[],
}

export interface TaskStats {
  id:string,
  accountName:string,
  categoryName:string,
  avgHoursToComplete:number,
  totalSumOfHoursWorked:number,
  totalWageToTaskHoursSpent:number,
  totalWageProjectionByEstimatedHours:number,
  numberOfTimesExecuted:number,
  estimatedHoursToCompleteTotalSum:number,
  overUnderAvgCompletionToEstimatedTime:number,
  totalHoursComparedToEstimated:number,
}

export interface AccountBudgetCharts {
  accountBudgets: TotalAccountBudgetChartItem[],
  parentTotalBudget: AccountBudget[],
  annualBudgetsByAccount: AccountBudget[],
  annualCategoryBudgetsSingleAccount: TotalAccountBudgetChartItem[],
  annualLocationExpensesSingleAccount: TotalAccountBudgetChartItem[],
  monthlyBudgetsStacked: MonthlyStackedAccountBudgetChartItem[],
  monthlyBudgetsLine:MonthAccountBudgetLineChartItem[],
}

export interface ScheduleReportHolder {
  year:string,
  scheduleLineChart: ScheduleReportLineChart;
  wholeYearReport: ScheduledEmployeeReport[]
  januaryReport: ScheduledEmployeeReport[]
  februaryReport: ScheduledEmployeeReport[]
  marchReport: ScheduledEmployeeReport[]
  aprilReport: ScheduledEmployeeReport[]
  mayReport: ScheduledEmployeeReport[]
  juneReport: ScheduledEmployeeReport[]
  julyReport: ScheduledEmployeeReport[]
  augustReport: ScheduledEmployeeReport[]
  septemberReport: ScheduledEmployeeReport[]
  octoberReport: ScheduledEmployeeReport[]
  novemberReport: ScheduledEmployeeReport[]
  decemberReport: ScheduledEmployeeReport[]
}

export interface SingleDayScheduleReportHolder {
  date:string,
  dayReport: SingleDayScheduledEmployeeReport[]
}

export interface CompletedTasksHolder {
  year:string,
  taskList: TaskInfo[]
}

export interface CompletedTasksTableUtil {
  year:string,
  month:string,
  monthStartDate:string,
  monthEndDate:string,
  range:string,
  day:string,
  taskList: CompletedTasksUtil[]
}

export interface JobBoardTableUtil {
  date:string,
  jobBoardList:JobBoardTableRows[],
}

export interface JobBoardTableRows {
  id:string,
  employeeColor:string,
  employeeName:string,
  taskName:string,
  tasklocations:string,
  taskNotes:string,
  subTasks:string,
  deadlineTime:string,
  accountName:string,
  completed:boolean
}

export interface PdfFormsUtil {
  showLoading:boolean,
  setShowLoading:()=>void,
  location:string,
  inspectionDate:string,
  inspectionTime:string,
  inspectedBy:string[],
  ageAppropriate:string,
  reportYear:Date|number,
  pdfIdToDelete:string,
  setPdfIdToDelete:()=>void,
  pdfTypeToDelete:string,
  setPdfTypeToDelete:()=>void,
  openPdfDeleteModal:boolean,
  setOpenPdfDeleteModal:()=>void,
  taskId:string,
  getAccountPdfs:()=>void,
  download:()=>void,
  delete:()=>void,
  setAccountPdfList:()=>void,
}

export interface CheckPoints {
  tag:string,
  compliant:boolean,
  hazard:boolean,
}

export interface CompletedTasksUtil {
  id:string,
  taskId:string,
  assignedEmployees:string,
  taskName:string,
  scheduledDate:string,
  locations:string,
  category:string,
  taskNotes:string,
  taskSubTasks:string,
  taskDeadlineTime:string,
  orgName:string,
}

export interface SingleDayScheduledEmployeeReport {
  employeeName:string,
  date:string,
  accountName:string,
  totalHoursWorked:number,
  totalWagesPaid:number,
  totalWagesPaidFormatted:string
}

export interface ScheduledEmployeeReport {
  employeeName:string,
  month:string,
  year:string,
  accountName:string,
  totalMonthlyHours:number,
  totalMonthlyWage:number,
  totalMonthlyWageFormatted:string,
  totalYearlyHours:number,
  totalYearlyWage:number,
  totalYearlyWageFormatted:string
}

export const scheduleLineChartDataInit: ScheduleLineChartData = {
  month:'',
  totalMonthlyHours:0,
  totalMonthlyWages:0
}

export interface ScheduleLineChartData {
  month:string,
  totalMonthlyHours:number,
  totalMonthlyWages:number
}
export interface ScheduleReportLineChart {
  janLineData: ScheduleLineChartData;
  febLineData: ScheduleLineChartData;
  marLineData: ScheduleLineChartData;
  aprLineData: ScheduleLineChartData;
  mayLineData: ScheduleLineChartData;
  junLineData: ScheduleLineChartData;
  julLineData: ScheduleLineChartData;
  augLineData: ScheduleLineChartData;
  sepLineData: ScheduleLineChartData;
  octLineData: ScheduleLineChartData;
  novLineData: ScheduleLineChartData;
  decLineData: ScheduleLineChartData;
}

export interface TotalAccountBudgetChartItem {
  id:string
  accountName:string,
  budget:number,
  spent:number,
  estimated:number
}

export interface MonthlyStackedAccountBudgetChartItem {
  id:string,
  accountName:string,
  january:number,
  february:number,
  march:number,
  april:number,
  may:number,
  june:number,
  july:number,
  august:number,
  september:number,
  october:number,
  november:number,
  december:number
}

export interface MonthAccountBudgetLineChartItem {
  id:string,
  label:string,
  item1:number | any,
  item2:number | any,
  item3:number | any,
  item4:number | any,
  item5:number | any,
  item6:number | any,
  item7:number | any,
  item8:number | any,
  item9:number | any,
  item10:number | any,
}

// ---------------------------------------------

export interface QuickReport {
  monthToDateExpenses:number,
  yearToDateExpenses:number,
  monthlyChartExpenses: MonthlyStackedAccountBudgetChartItem[],
  yearlyChartExpenses: MonthlyStackedAccountBudgetChartItem[],
}


//----------------------------------------------

export interface Spreadsheet {
  total:number,
  materialsTotal:number,
  laborTotal:number,
  nonTaskLaborTotal:number
}

export interface FullSpreadsheet extends Spreadsheet {
  months: MonthSpreadsheet[],
  year:number,
}

export interface MonthSpreadsheet extends Spreadsheet {
  weeks:WeekSpreadsheet[],
  month:string
}

export interface WeekSpreadsheet extends Spreadsheet {
  days:DaySpreadSheet[],
  weekRangeText:string,
}

export interface DaySpreadSheet extends Spreadsheet {
  date:string,
  dateString:string,
  taskHolders: TaskHolderSpreadsheet[],
}

export interface TaskHolderSpreadsheet extends Spreadsheet {
  accessCode:string,
  accountName:string,
  task:TaskInfo,
}

export interface SpreadsheetOptions {
  fromDate: string | Date,
  toDate: string | Date,
  locations: string[],
  categories: string[],
  filterLevel:string,
}

export const spreadsheetOptionsInit:SpreadsheetOptions = {
  fromDate: new Date(new Date().getFullYear(), 0, 1),
  toDate: new Date(new Date().getFullYear(), 11, 31),
  locations:[],
  categories:[],
  filterLevel:'task',
}

export const spreadsheetOptionsCompletedTasksInit:SpreadsheetOptions = {
  fromDate: new Date(new Date()),
  toDate: new Date(new Date()),
  locations:[],
  categories:[],
  filterLevel:'task',
}

//------------------------------------------------------

export const totalAccountBudgetChartItemInit: TotalAccountBudgetChartItem = {
  id:'',
  accountName:'',
  budget:0,
  spent:0,
  estimated:0
}

export const completedTasksHolderInit: CompletedTasksHolder = {
  year:'',
  taskList:[],
}

export const jobBoardTableInit: JobBoardTableUtil = {
  date:'',
  jobBoardList:[],
}

export const completedTasksTableInit: CompletedTasksTableUtil = {
  year:'',
  month:'',
  monthStartDate:'',
  monthEndDate:'',
  range:'',
  day:'',
  taskList:[],
}

export const monthlyStackedAccountBudgetChartItemInit: MonthlyStackedAccountBudgetChartItem = {
  id:'',
  accountName:'',
  january:0,
  february:0,
  march:0,
  april:0,
  may:0,
  june:0,
  july:0,
  august:0,
  september:0,
  october:0,
  november:0,
  december:0
}

export const scheduleReportLineChartInit: ScheduleReportLineChart = {
  janLineData: scheduleLineChartDataInit,
  febLineData: scheduleLineChartDataInit,
  marLineData: scheduleLineChartDataInit,
  aprLineData: scheduleLineChartDataInit,
  mayLineData: scheduleLineChartDataInit,
  junLineData: scheduleLineChartDataInit,
  julLineData: scheduleLineChartDataInit,
  augLineData: scheduleLineChartDataInit,
  sepLineData: scheduleLineChartDataInit,
  octLineData: scheduleLineChartDataInit,
  novLineData: scheduleLineChartDataInit,
  decLineData: scheduleLineChartDataInit
}

export const scheduleReportHolderInit: ScheduleReportHolder ={
  year:'',
  scheduleLineChart:scheduleReportLineChartInit,
  wholeYearReport:[],
  januaryReport:[],
  februaryReport:[],
  marchReport:[],
  aprilReport:[],
  mayReport:[],
  juneReport:[],
  julyReport:[],
  augustReport:[],
  septemberReport:[],
  octoberReport:[],
  novemberReport:[],
  decemberReport:[]
}

export const scheduledEmployeeReportInit: ScheduledEmployeeReport = {
  employeeName:'',
  month:'',
  year:'',
  accountName:'',
  totalMonthlyHours:0,
  totalMonthlyWage:0,
  totalMonthlyWageFormatted:'',
  totalYearlyHours:0,
  totalYearlyWage:0,
  totalYearlyWageFormatted:''
}

export const fullSpreadSheetInit = {
  total:0,
  materialsTotal:0,
  laborTotal:0,
  nonTaskLaborTotal:0,
  months: [],
  year:0,
}

export const accountBudgetChartsInit: AccountBudgetCharts = {
  accountBudgets: [totalAccountBudgetChartItemInit],
  parentTotalBudget: [AccountBudgetInit],
  annualBudgetsByAccount: [AccountBudgetInit],
  annualCategoryBudgetsSingleAccount: [totalAccountBudgetChartItemInit],
  annualLocationExpensesSingleAccount: [totalAccountBudgetChartItemInit],
  monthlyBudgetsStacked: [monthlyStackedAccountBudgetChartItemInit],
  monthlyBudgetsLine: []
}

export const totalAccountBudgetHolderInit: TotalAccountBudgetHolder = {
  totalExpensesCharts: accountBudgetChartsInit,
  taskHourExpensesCharts: accountBudgetChartsInit,
  materialsExpensesByMonthChart: [],
  overUnderTaskCategoryStats: [],
  overUnderWorkerStats: [],
  overUnderTaskLocationStats: [],
  fullTaskExpensesSpreadsheetReport: [],
}

export const chartColors = [
  '#42A5F5',
  '#FF7043',
  '#9CCC65',
  '#FFCA28',
  '#26A69A',
  '#EC407A',

  '#42A5F5',
  '#FF7043',
  '#9CCC65',
  '#FFCA28',
  '#26A69A',
  '#EC407A'
]

