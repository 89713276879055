import React, {useEffect} from "react";
import {Box, Button, Container, Grid, Stack, Typography, useMediaQuery,} from "@mui/material";
import Navbar from "../component/nav/Navbar";
import {useHistory} from "react-router";
import Footer from "../component/general/Footer";
import {blue, grey, red} from "@mui/material/colors";
import {Parallax, ParallaxBanner, useParallax} from "react-scroll-parallax";
import {ParallaxProps} from "react-scroll-parallax/dist/components/Parallax/types";
import {SRLWrapper} from "simple-react-lightbox";
import {ParallaxLayer} from "@react-spring/parallax";
import MapsContainer from "../component/maps/MapsContainer";
import ReactPlayer from "react-player";


const HomePage: React.FC = () => {
  const history = useHistory();
  const isWindowMd = useMediaQuery('(min-width:600px)');

  const propsScroll:ParallaxProps = { speed: 20};
  const propsEasingX:ParallaxProps = { easing: [1, -0.75, 0.5, 1.34],
    translateX: [0, 100] };
  const props360Y:ParallaxProps = {
    rotateY: [180, 520],
  };
  const props360X:ParallaxProps = {
    rotateX: [180, 520],
  };

  const { ref:logoRefScroll } = useParallax<HTMLImageElement>(propsScroll);
  //const { ref:ref360Y } = useParallax<HTMLDivElement>(props360Y);
  //const { ref:ref360Y2 } = useParallax<HTMLDivElement>(props360Y);
  //const { ref:ref360X } = useParallax<HTMLDivElement>(props360X);
  /*const { ref:logoRefEasingX } = useParallax<HTMLDivElement>(propsEasingX);*/

  useEffect(() => {
    document.title = "Home Page";
  }, []);

  const goToPlayBooks = () => {
    window.open("https://goplaybooks.com/","_blank");
  }
  const goToPlayBooksMobile = () => {
    window.open("https://www.covsys.net/Premium/Mobile/Index?courseurl=9754c179-dc77-4048-ad7b-0919ec4e0032","_blank");
  }


  /*<ParallaxLayer offset={1} speed={2} style={{backgroundColor:grey[800] }} />

  <ParallaxLayer
      offset={1}
      speed={2.5}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>*/

  return (
      <div style={{backgroundColor:grey["100"]}}>
        <Navbar children={<div></div>}/>
        {/*<Typography variant={"h2"} sx={{textAlign:'center', my:5}}>Apperform</Typography>*/}
        {/*<div ref={logoRefScroll}
            style={{height:'70%', backgroundImage: `url(${process.env.REACT_APP_RESOURCES_URL_BASE}/images/bridge.jpg)`}}>
          <Container component="main" sx={{p:5}}>
            <Box sx={{display:'flex', justifyContent:'center', my:5}}>
              <img src={'/ap-logo.png'}
                   style={{width:'50%', height: '25%'}}/>
            </Box>
          </Container>
        </div>*/}
        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/skytree.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/skytree.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute', justifyContent:'center', alignItems: 'center', my:5}}>
                      <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-dark.png`}
                           alt={'Apperform Logo'}
                           style={{width: isWindowMd ? '30%' : '50%',
                             height: isWindowMd ? '15%' : '10%'}}/>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        <Container component="main" maxWidth={'lg'}>
          <ReactPlayer url={`${process.env.REACT_APP_RESOURCES_URL_BASE}/videos/ApperForm_Tutorial_Video.mp4`}
                       controls={true}
                       width={'100%'} height={'50vh'} style={{marginTop:'50px'}}
          />

          <Grid container spacing={2} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={false ? logoRefScroll : null} order={isWindowMd ? 1 : 2}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/dash.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/dash.png`}
                         alt={'Dash'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 2 : 1}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Labor Management</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                Easily create and manage tasks to give an agenda to your organization each day.
                This will not only help give structure to your worker's shift, but also allow you
                track progress throughout the work day. Add comments and have conversations around
                important attributes of individual task that will always be there to refer to historically.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container >
          <Grid container spacing={2} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 1 : 1}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Worker Scheduling</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                Manage your workforce and their schedules through the schedule console. Plan by the
                day if the schedules are always changing. Or if you find that shifts are typically the same,
                then create it once and simply duplicate the day or week for the easiest and fastest
                worker shift management. Workers then also have their upcoming schedule digitally for
                convenient access on the go.
              </Typography>
            </Grid>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 2 : 2}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/schedule.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/schedule.png`}
                         alt={'Schedule Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>
            </Grid>
          </Grid>
        </Container>

        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/park-joggers.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/park-joggers.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute', justifyContent:'center', alignItems: 'center', my:5}}>
                      <Typography variant={"h3"}
                                  sx={{textAlign:'center', marginX: isWindowMd ? '75px' : '20px', color: grey[500],
                                    textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>
                        Apperform increases efficiency and provides insight to the operation
                      </Typography>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        {/*<Container component="main" sx={{marginBottom: '100px', marginY: '200px'}}>
          <Grid container spacing={2}> ref={logoRefEasingX}
            <Grid item md={6} sm={12} xs={12}>
              <Button sx={{width:'100%', height:'215px', backgroundColor:grey[800], fontWeight:"bold"}}
                      type={'button'} variant={"outlined"} size="large"
                      onClick={() => history.push('/manage/labor')}>
                Task Management
              </Button>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Button sx={{width:'100%', height:'215px', backgroundColor:grey[500], fontWeight:"bold"}}
                      type={'button'} variant={"outlined"} size="large"
                      onClick={() => goToPlayBooks()}>
                Applant
              </Button>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Button sx={{width:'100%', height:'215px', backgroundColor:grey[500], fontWeight:"bold"}}
                      type={'button'} variant={"outlined"} size="large"
                      onClick={() => history.push('/manage/user/account')}>
                Account Info
              </Button>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Button sx={{width:'100%', height:'215px', backgroundColor:grey[800], fontWeight:"bold"}}
                      type={'button'} variant={"outlined"} size="large"
                      onClick={() => goToPlayBooksMobile()}>
                Applant Mobile
              </Button>
            </Grid>
          </Grid>
        </Container>*/}

        <Container component="main">
          <Grid container spacing={2} ref={logoRefScroll} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 0 : 1}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/calendar.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/calendar.png`}
                         alt={'Calendar Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 1 : 0}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Event Calendar</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                The Calendar is the top level view for you to see what is going by each month.
                You can look for a specific day and see who is being scheduled to work, and what
                tasks are scheduled to be worked vs the tasks that have already been completed.
                Drill down and look at individual task details and complete actions such as
                commenting, editing, and completing tasks.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container component="main">
          <Grid container spacing={2} ref={logoRefScroll} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 1 : 1}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/budget.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/budget.png`}
                         alt={'Budget Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 0 : 0}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Budgeting</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                No Organization has unlimited funds to use whenever and wherever. Spending
                has to be allocated to specific resources and tracked. But most importantly,
                expenses need to stay within their budget. Use the budgeting console to set
                your budgets and as the months and years go on, you will be given true
                insight your spending breakdown and make the right decision based on the data of your operation.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/park-dark.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/park-dark.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute', justifyContent:'center', alignItems: 'center', my:5}}>
                      <Typography variant={"h3"}
                                  sx={{textAlign:'center', marginX:'20px',
                                    textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'}}>
                        Stay compliant with state laws and auditing requirements
                      </Typography>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        <Container component="main">
          <Grid container spacing={2} ref={logoRefScroll} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 0 : 1}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/reports1.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/reports1.png`}
                         alt={'Reports Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 1 : 0}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Budget Reporting</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                View charts and data tables based on your budgets that breakdown your operation
                to give you the current status of funds allocated in the budgeting console.
                See visuals of how you are burning through your resources by month, account,
                and category. Make educated decisions based on your workflow and finally download
                these reports in PDF to present to higher management or HR.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container component="main">
          <Grid container spacing={2} ref={logoRefScroll} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 1 : 1}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/task-archive.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/task-archive.png`}
                         alt={'Completed Tasks Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 0 : 0}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Historical Archives</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                Work is always being done day to day. You may find the need to go back
                and see a specific task or tasks within a particular range of time. Easily find
                anything that has ever been completed using the historical archive that goes all
                the way back to your fist day on Apperform. You can search, sort, and filter easily
                to find whatever you are looking for.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/tractor.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/tractor.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute', justifyContent:'center', alignItems: 'center', my:5}}>
                      <Typography variant={"h3"}
                                  sx={{textAlign:'center', marginX:'20px',
                                    textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'}}>
                        Integrate Application and Product Logging with Applant
                      </Typography>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        <Container component="main">
          <Grid container spacing={2} ref={logoRefScroll} sx={{marginY: '150px'}}>
            <Grid item md={6} sm={12} xs={12} ref={logoRefScroll} order={isWindowMd ? 0 : 1}>
              <SRLWrapper>
                <div>
                  <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/applant-log.png`}>
                    <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/applant-log.png`}
                         alt={'Applant Log Image'}
                         style={{width:'100%', height: '100%'}}/>
                  </a>
                </div>
              </SRLWrapper>

            </Grid>
            <Grid item md={6} sm={12} xs={12} order={isWindowMd ? 1 : 0}>
              <Typography variant={"h3"} sx={{textAlign:'left'}}>Applant Logging</Typography>
              <Typography variant={"body1"} sx={{textAlign:'left'}}>
                Applant is the sister product to Apperform. The integration brings the
                product and application management of your operation to the labor management side.
                Manage your inventory and planned applications of chemicals, materials, and other
                products in Applant, then assign those applications to tasks created in Apperform.
                The two together, give you the comprehensive management of your organization,
                and allow you to attain reports of all of your expenses.
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/soccer-field.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/soccer-field.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute',
                      justifyContent:'center', alignItems: 'center', my:5, marginX:'40px'}}>
                      <Stack direction={'column'} sx={{textAlign:'center'}}>
                        <Typography variant={"h3"}
                                    sx={{textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'}}>
                          Developed in St. Louis, MO USA
                        </Typography>
                        <h2>
                          <span style={{color:red[500], textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'}}>
                            American Made </span>
                          <span style={{color:"white"}}>for the Organizations </span>
                          <span style={{color:blue[700], textShadow: '-1px 0 white, 0 1px white, 1px 0 white, 0 -1px white'}}>
                            that make America.</span></h2>
                        <h3 style={{color:grey[400], textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'}}>
                          We are always available to answer questions and provide help.
                          With Apperform, you become part of our family. Have ideas about how to
                          make our product suite even better? Work with us, we love future thoughts and can build
                          anything.
                        </h3>
                      </Stack>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        <Footer noSideMenu={true} />
      </div>
  );
}

export default HomePage;