import {httpCommonService} from "./generic/httpCommonService";
import {PlaybooksResponse} from "../types/task/PlaybooksTypes";
import {CompanyDirectoryResponse, JobBoardResponse, ScheduledDayObjectResponse} from "../types/task/TaskTypes";

export const companyDirectoryService = {
  getCompanyDirectory
}

async function getCompanyDirectory() {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/getCompanyDirectory`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(async (response: any) => await response.json() as CompanyDirectoryResponse)
        .catch(e => {
          throw Error(e.message)
        });
  } catch (e) {throw e;}
}