

export const sortByAlpha = (textA:string, textB:string) => {
  return textA.toUpperCase().localeCompare(textB.toUpperCase());
}

/*
export const sortByTaskPriority = (objectA:TaskInfo, objectB:TaskInfo) => {
  let comparison = 0;
  if (objectA.priority < objectB.priority) {
    comparison = 1;
  } else if (objectA.priority < objectB.priority) {
    comparison = -1;
  }
  return comparison;
}*/
