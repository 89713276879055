import React, {Fragment, useEffect, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {Box, Dialog, DialogTitle, Grid, IconButton, Tooltip, useMediaQuery} from "@mui/material";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import { Close } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmDialog from "../general/ConfirmDialog";
import {PDF_WIDTH_PIXELS} from "../../util/constants";

type Props = {
    isOpen: boolean,
    setIsOpen: (open: false) => void,
    getPdfs:()=>void,
    accountId: string,
    taskId: string,
    formId: string,
    type: string,
    name: string,
}

const PdfFormDisplayDialog: React.FC<Props> = (props) => {
    let dispatch = useAppThunkDispatch();

    const [showLoading, setShowLoading] = React.useState(false);
    const isWindowSmallerThanCanvas = useMediaQuery(`(max-width:${PDF_WIDTH_PIXELS}px)`);
    const [openPdfDeleteModal, setOpenPdfDeleteModal] = React.useState(false);
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    //Delete Warning logic
    useEffect(()=>{
        if(props.formId && props.type){
            setOpenPdfDeleteModal(true);
        } else {
            setOpenPdfDeleteModal(false);
        }
    },[props.formId, props.type])

    const getPdfUrl = () => {
        return `https://apperform-resources.s3.amazonaws.com/pdfs/accountId/${props.accountId}/taskId/${props.taskId}/${props.name}`
    }

    async function deletePdf() {
        setShowLoading(true)
        try {
            let token = localStorage.getItem('user') as string;
            return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/delete-pdf/${props?.formId}/${props?.type}/${props.taskId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                }
            })
                .then(() =>{props.getPdfs();
                    setShowLoading(false);
                })
                .catch(e => {
                    throw Error(e.message)
                });
        } catch (e) {throw e;}
    }

    const close = () => {
        props.setIsOpen(false);
    }

    // @ts-ignore
    return (
        <Fragment>
            { showLoading ? (
                <Box sx={{marginTop:'200px'}}>
                    <LoadingSpinnerContainer showLoading={showLoading} />
                </Box>
            ) : (
                <Fragment>
                    <Dialog
                        sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 1000 } }}
                        maxWidth="xl"
                        open={props.isOpen}
                    >
                        <DialogTitle>
                            <Grid container spacing={2}>
                                <Grid item sm={3} xs={12}>
                                    <IconButton
                                        disabled={showLoading}
                                        edge="start"
                                        color="inherit"
                                        onClick={() => close()}
                                        aria-label="close"
                                        sx={{position: 'absolute', right: 1, top: 1}}
                                    >
                                        <Close/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </DialogTitle>

                        <Box sx={{
                            backgroundColor: 'white',
                            mx: "auto", mt:1,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                        }}>
                            {/*                            <Document
                                file={fileUrl}
                                loading={<div>Loading...</div>}
                                onLoadSuccess={() => console.log('PDF loaded successfully')}
                            >
                                <Page pageNumber={1} width={600} renderTextLayer={false} />
                            </Document>*/}
                        </Box>

                        <Box sx={{mb:2}}>
                            <Tooltip title="Delete">
                                <IconButton sx={{px:2, float:'right'}} aria-label="delete-task-pdf"
                                            onClick={() => {
                                                setOpenPdfDeleteModal(true)
                                            }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>

                    </Dialog>

                    <ConfirmDialog handleSubmit={deletePdf}
                                   title={"Delete Pdf"}
                                   id={props?.formId}
                                   isOpen={openPdfDeleteModal} setIsOpen={setOpenPdfDeleteModal} />

                </Fragment>
            )}
        </Fragment>
    );
}

export default PdfFormDisplayDialog;