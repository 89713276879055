import React, {ChangeEvent, Fragment, useState} from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import Navbar from "../../component/nav/Navbar";
import {Role} from "../../types/account/AccountTypes";
import {useDispatch, useSelector} from "react-redux";
import {authActions, RootState} from "../../store/storeIndex";
import {useHistory} from "react-router";
import {Redirect} from "react-router-dom";
import {useAppThunkDispatch} from "../../store/storeHooks";


const SignupTypePromptPage: React.FC = () => {
  const userTypeGlobal = useSelector((state: RootState) => state.auth.signupUserTypeSelected);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  let dispatch = useAppThunkDispatch();
  let history = useHistory();

  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [type, setType] = useState<string>('');
  
  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value);
    setIsOptionSelected(true);
  }

  const handleSubmit = () => {
    dispatch(authActions.selectUserTypeOnSignup({userType:type}));
    if (type === Role.ORG_ADMIN) {
      history.push('/signup/create');
    } else {
      history.push('/signup/accessCode');
    }
  }

  if (userTypeGlobal !== null) {
    // thought about a redirect
  }

  /*if (loggedIn) {
    return <Redirect to={'/login'} />
  }*/

  return (
      <Fragment>
        <Navbar children={<div></div>}/>
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 500}}>
            <Stack spacing={2}>
              <h2 style={{textAlign:'center'}}>What type of user are you?</h2>
              <FormControl component="fieldset">
                {/*<FormLabel component="legend">Gender</FormLabel>*/}
                <RadioGroup aria-label="gender" name="row-radio-buttons-group"
                      onChange={(e) => handleRadioChange(e)}>
                  {/*<FormControlLabel value={Role.ORG_WORKER} control={<Radio />}
                                    label="A Worker or Manager signing up under an existing organization" />*/}
                  <FormControlLabel
                      value={Role.ORG_ADMIN}
                      control={<Radio />}
                      label="An Admin, creating a brand new organizational account"
                  />
                </RadioGroup>
                <br/>
                <Button type={'button'} variant={isOptionSelected ? "contained" : "outlined"}
                        size="large" style={{cursor: !(isOptionSelected) ? 'not-allowed' : 'pointer'}}
                        onClick={handleSubmit}>
                  Continue
                </Button>
              </FormControl>
            </Stack>
          </Box>
        </Container>
      </Fragment>
  );
}

export default SignupTypePromptPage;