

export interface PaginationUtil {
  pageSize:number,
  pageNumber:number,
  totalResults:number
}

export const paginationInit = {
  pageSize: 5,
  pageNumber:1,
  totalResults:0
}