import {
  AppBar,
  Box,
  Chip,
  Dialog,
  Divider,
  Drawer,
  Fab, Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide, SpeedDial, SpeedDialAction, SpeedDialIcon,
  Stack,
  Toolbar,
  Typography, useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {InboxNotification, notificationInit} from "../../types/NotificationTypes";
import React, {Fragment, useEffect, useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import InboxIcon from "@mui/icons-material/Inbox";
import StarIcon from "@mui/icons-material/Star";
import SendIcon from "@mui/icons-material/Send";
import DraftsIcon from "@mui/icons-material/Drafts";
import DeleteIcon from "@mui/icons-material/Delete";
import {useDispatch, useSelector} from "react-redux";
import {accountActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {TransitionProps} from "@mui/material/transitions";
import {AccountState} from "../../types/account/AccountTypes";
import {notificationService} from "../../service/notificationService";
import LoadingSpinnerFull from "../general/LoadingSpinnerFull";
import {NotificationState, updateNotificationGlobalState} from "../../store/notificationSlice";
import AlertToast from "../general/AlertToast";
import NotificationListItem from "./NotificationListItem";
import {orgInfoService} from "../../service/account/orgInfoService";
import NotificationCreateDialog from "./NotificationCreateDialog";
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import {useAppThunkDispatch} from "../../store/storeHooks";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import {FormatQuote, History, Menu, NewReleasesOutlined, OpenInFull} from "@mui/icons-material";
import Moment from "react-moment";
import {determineIfTokenError} from "../../store/account/authSlice";
import {SRLWrapper} from "simple-react-lightbox";
import {FullPageDialogTransition} from "../general/FullPageDialogTransition";
import {
  DARK_NAVY,
  GHOST_WHITE,
  LIGHT_GREY_BLUE,
  LIGHTER_GREY_BLUE,
  LIME_GREEN
} from "../../service/generic/ColorScheme";

const actions = [
  { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <SaveIcon />, name: 'Save' },
  { icon: <PrintIcon />, name: 'Print' },
  { icon: <ShareIcon />, name: 'Share' },
];

type Props = {
  alertsOpen: boolean
  setAlertsOpen: (open: boolean) => void
}

const NotificationInboxDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const notifications = useSelector((state: RootState) => state.notifications);
  const orgInfo = useSelector((state: RootState) => state.orgInfo.orgInfo);

  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [tabSelected, setTabSelected] = useState('inbox');
  const [showLoading, setShowLoading] = useState(false);
  const [clickedMessages, setClickedMessages] = useState<string[]>([]);
  const [clickingTimeout, setClickingTimeout] = useState(setTimeout(() => {}, 0));
  const [selectedNotif, setSelectedNotif] = useState<InboxNotification>(notificationInit);
  const [newNotification, setNewNotification] = useState(notificationInit);

  const [hidePageContentAlerts, setHidePageContentAlerts] = useState<boolean>(false);
  const [showSideMenuAlerts, setShowSideMenuAlerts] = useState(true);
  const [expandMessageView, setExpandMessageView] = useState(false);

  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);

  useEffect(() => {
    document.title = "Notification Inbox Dialog";
  }, []);

  useEffect(() => {
    setHidePageContentAlerts(!isWindowLarge && showSideMenuAlerts);
  }, [isWindowLarge, showSideMenuAlerts])

  useEffect(() => {
    setShowSideMenuAlerts(isWindowLarge);
  }, [isWindowLarge])

  useEffect(() => {
    if (orgInfo.accountId && loggedIn) {
      setShowLoading(true);
      let timeout = setTimeout(() => {
        orgInfoService.getOrgMembersData()
            .then((account: AccountState) => {
              dispatch(accountActions.setOrgMembers({orgMembers: account.orgMembers}));
            })
            .catch(e => dispatch(determineIfTokenError(e)))
            .finally(() => setShowLoading(false));
      }, 600);
      return () => {
        clearTimeout(timeout)
      };
    }
  }, [orgInfo]);


  const handleAlertsClose = () => {
    props.setAlertsOpen(false);
  };

  const handleMarkAsSeenUnstableBeta = (id:string, inboxType:string) => {
    //console.log("clicked " + clickedMessages);
    setClickedMessages(state => [...clickedMessages, id]);
    clearTimeout(clickingTimeout);
    setClickingTimeout(setTimeout(() => {
      //console.log("clicked " + clickedMessages);
      notificationService.markNotificationsAsSeen(clickedMessages, inboxType)
          .then((inbox: NotificationState) => {
            dispatch(updateNotificationGlobalState(inbox));
            setClickedMessages([]);
          })
          .catch(e => dispatch(determineIfTokenError(e)))
    }, 2500));
  }

  const handleMarkAsSeen = (id:string, inboxType:string) => {
    notificationService.markNotificationsAsSeen([id], inboxType)
        .then((inbox: NotificationState) => {
          dispatch(updateNotificationGlobalState(inbox));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
  }

  const handleViewSelectedNotification = (id:string) => {
    let allnotifications = notifications.notifications
        .concat(notifications.sentNotifications)
        .concat(notifications.trashedNotifications);
    let notificationsFound = allnotifications.filter(n => n.inboxAlertId === id);
    setSelectedNotif(notificationsFound[0]);
  }

  const handleDraftSelected = (id:string) => {
    let draftSelected = notifications.draftNotifications.filter(draft => draft.inboxAlertId === id)[0];
    setNewNotification(draftSelected);
    setOpenCreateDialog(true);
  }

  const getMessageParts = (message:string):string[] => {
    return message.split(/\r\n|\r|\n/g);
  }

  const toggleSideMenu = () => {
    setShowSideMenuAlerts(!showSideMenuAlerts)
  }

  const onTabClick = (tab:string) => {
    if (!isWindowLarge) {
      toggleSideMenu();
    }
    setTabSelected(tab);
  }

  return (
      <Dialog
          /*fullWidth={true}
          maxWidth={'lg'}*/
          PaperProps={{style: {overflowY: 'visible'}}}
          fullScreen
          open={props.alertsOpen}
          onClose={handleAlertsClose}
          TransitionComponent={FullPageDialogTransition}
          sx={{zIndex:10000}}
          disableEscapeKeyDown
      >
        <AppBar sx={{ position: 'relative', backgroundColor:DARK_NAVY }}>
          <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleAlertsClose}
                aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Notifications
            </Typography>

            { !isWindowLarge &&
              <IconButton
                  size="large"
                  aria-label="tasks"
                  aria-controls="menu"
                  aria-haspopup="true"
                  onClick={toggleSideMenu}
                  color="inherit"
              >
                <Menu/>
              </IconButton>
            }
          </Toolbar>
        </AppBar>

        {!hidePageContentAlerts &&
        <div style={{overflowY: "auto", marginRight: isWindowLarge ? 248 : 0,
          /*height: isWindowLarge ? (expandMessageView ? window.visualViewport.height / 2 : window.visualViewport.height / 1) :
              (expandMessageView ? window.visualViewport.height * .20 : window.visualViewport.height * .60),*/
          marginBottom: selectedNotif.inboxAlertId ? 183 : 0}}>
            <List>
              {tabSelected === 'inbox' &&
              notifications.notifications.map((notif: InboxNotification) => {
                return (
                    <NotificationListItem
                        key={'inbox'}
                        notif={notif}
                        inboxType={'inbox'}
                        viewNotifHandler={handleViewSelectedNotification}
                        notifClickedHandler={handleMarkAsSeen}
                        setSelectedNotif={setSelectedNotif}
                    />
                );
              })}
              {tabSelected === 'starred' &&
              notifications.notifications
                  .filter((notif: InboxNotification) => notif.starred)
                  .map((notif: InboxNotification) => {
                    return (
                        <NotificationListItem
                            key={'starred'}
                            notif={notif}
                            inboxType={'starred'}
                            viewNotifHandler={handleViewSelectedNotification}
                            notifClickedHandler={handleMarkAsSeen}
                            setSelectedNotif={setSelectedNotif}
                        />
                    );
                  })}
              {tabSelected === 'sent' &&
              notifications.sentNotifications.map((notif: InboxNotification) => {
                return (
                    <NotificationListItem
                        key={'sent'}
                        notif={notif}
                        inboxType={'sent'}
                        viewNotifHandler={handleViewSelectedNotification}
                        notifClickedHandler={handleMarkAsSeen}
                        setSelectedNotif={setSelectedNotif}
                    />
                );
              })}
              {tabSelected === 'draft' &&
              notifications.draftNotifications.map((notif: InboxNotification) => {
                return (
                    <NotificationListItem
                        key={'draft'}
                        notif={notif}
                        inboxType={'draft'}
                        viewNotifHandler={handleDraftSelected}
                        setSelectedNotif={setSelectedNotif}
                    />
                );
              })}
              {tabSelected === 'trash' &&
              notifications.trashedNotifications.map((notif: InboxNotification) => {
                return (
                    <NotificationListItem
                        key={'trash'}
                        notif={notif}
                        inboxType={'trash'}
                        viewNotifHandler={handleViewSelectedNotification}
                        notifClickedHandler={handleMarkAsSeen}
                        setSelectedNotif={setSelectedNotif}
                    />
                );
              })}
            </List>
        </div>
        }

        { !hidePageContentAlerts && (selectedNotif.inboxAlertId && tabSelected !== 'draft') &&
          <div style={{
            position: "fixed",
            bottom: 0,
            minHeight: isWindowLarge ? (expandMessageView ? '50%' : '20%') : (expandMessageView ? '70%' : '40%'),
            maxHeight: isWindowLarge ? '180px' : '300px',
            width: '100%',
            marginRight: 280,
            overflowY: 'auto',
            backgroundColor: GHOST_WHITE,
            borderTop: "1px solid gray",
            padding: 20
          }}>
            <Grid direction="row" spacing={2}>
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <Typography variant="h5" component="div">
                          {selectedNotif.title}
                        </Typography>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Stack direction={"row"} spacing={1}>
                          <div style={{marginTop:'4px'}}><small>
                              <Moment format={"ddd D of MMM YYYY @ hh:MM A"}>{selectedNotif.timestamp}</Moment>
                          </small></div>
                          <IconButton size={"small"} onClick={() => setExpandMessageView(!expandMessageView)}>
                              <OpenInFull/>
                          </IconButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
              <Box sx={{mb:2}}>
                <Typography color="text.secondary" >
                  from {selectedNotif.fromName}
                </Typography>
              </Box>
            { getMessageParts(selectedNotif.message).map((part, index) => (
                <Fragment>
                  { index == 0 &&
                  <Stack direction={"row"} spacing={2} sx={{mt:2}}>
                      <div><NewReleasesOutlined/></div>
                      <div>Message Body:</div>
                  </Stack>
                  }
                  <Typography key={part} variant="body2" sx={{width: showSideMenuAlerts ? '70%' : '85%', mt:2}}>
                    {part === '' ? (<br/>) : (
                      <Stack direction={'row'} spacing={1}>
                        <FormatQuote style={{marginBottom:'5px'}}/>
                        <div style={{marginTop:'8px'}}>{part}</div>
                      </Stack>
                    )}
                  </Typography>
                </Fragment>
            ))}
            { selectedNotif.imagePath &&
            <SRLWrapper>
                <a href={`${process.env.REACT_APP_CLIENT_IMAGES_URL_BASE}/${selectedNotif.imagePath}`}>
                    <img src={`${process.env.REACT_APP_CLIENT_IMAGES_URL_BASE}/${selectedNotif.imagePath}`}
                         alt={'Notification Image'} style={{cursor: "pointer", maxHeight:"300px", maxWidth:"300px", marginTop:'20px'}} />
                </a>
            </SRLWrapper>
            }
            { selectedNotif.thread &&
                <Fragment>
                  <Divider orientation={"horizontal"} sx={{my:2}} variant={"middle"}/>
                  { getMessageParts(selectedNotif.thread).map((part,index) => (
                      <Fragment>
                        { index == 0 &&
                          <Stack direction={"row"} spacing={2} sx={{mt:2}}>
                              <div><History/></div>
                              <div>Thread:</div>
                          </Stack>
                        }
                        <Typography key={part} variant="body2" sx={{width: showSideMenuAlerts ? '70%' : '85%', mt:2}}>
                          {part === '' ? (<br/>) : (
                              <Stack direction={'row'} spacing={1}>
                                { ((index + 1) % 2 === 0) &&
                                  <FormatQuote style={{marginBottom: '5px'}}/>
                                }
                                <div style={{marginTop:'8px'}}>{part}</div>
                              </Stack>
                          )}
                        </Typography>
                      </Fragment>
                  ))}
                </Fragment>
            }
          </div>
        }

        { showSideMenuAlerts &&
          <Drawer
              variant="permanent"
              anchor="right"
              sx={{
                zIndex: 0,
                width: 250,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: {width: 250, boxSizing: 'border-box'},
              }}
          >
              <Toolbar/>
              <Box sx={{overflow: 'auto'}}>
                  <List>
                      <ListItem button key={'inbox'}
                                style={{backgroundColor: tabSelected === 'inbox' ? LIGHT_GREY_BLUE : ''}}
                                onClick={() => onTabClick('inbox')}>
                          <ListItemIcon>
                              <InboxIcon/>
                          </ListItemIcon>
                          <ListItemText primary={'Inbox'}/>
                          <Stack direction={'row'} spacing={2}>
                            {notifications.unseen > 0 &&
                            <Chip label={notifications.unseen} size="small" color={"error"}/>
                            }
                            {notifications.notifications.length > 0 &&
                            <Chip label={notifications.notifications.length} size="small"/>
                            }
                          </Stack>
                      </ListItem>
                      <ListItem button key={'starred'}
                                style={{backgroundColor: tabSelected === 'starred' ? LIGHT_GREY_BLUE : ''}}
                                onClick={() => onTabClick('starred')}>
                          <ListItemIcon>
                              <StarIcon/>
                          </ListItemIcon>
                          <ListItemText primary={'Starred'}/>
                        {notifications.notifications.filter(notif => notif.starred).length > 0 &&
                        <Chip label={notifications.notifications.filter(notif => notif.starred).length}
                              size="small"/>
                        }
                      </ListItem>
                      <ListItem button key={'sent'}
                                style={{backgroundColor: tabSelected === 'sent' ? LIGHT_GREY_BLUE : ''}}
                                onClick={() => onTabClick('sent')}>
                          <ListItemIcon>
                              <SendIcon/>
                          </ListItemIcon>
                          <ListItemText primary={'Sent'}/>
                        {notifications.sentNotifications.length > 0 &&
                        <Chip label={notifications.sentNotifications.length} size="small"/>
                        }
                      </ListItem>
                      <ListItem button key={'drafts'}
                                style={{backgroundColor: tabSelected === 'draft' ? LIGHT_GREY_BLUE : ''}}
                                onClick={() => onTabClick('draft')}>
                          <ListItemIcon>
                              <DraftsIcon/>
                          </ListItemIcon>
                          <ListItemText primary={'Drafts'}/>
                        {notifications.draftNotifications.length > 0 &&
                        <Chip label={notifications.draftNotifications.length} size="small"/>
                        }
                      </ListItem>
                  </List>
                  <Divider/>
                  <List>
                      <ListItem button key={'trash'}
                                style={{backgroundColor: tabSelected === 'trash' ? LIGHT_GREY_BLUE : ''}}
                                onClick={() => onTabClick('trash')}>
                          <ListItemIcon>
                              <DeleteIcon/>
                          </ListItemIcon>
                          <ListItemText primary={'Trash'}/>
                        {notifications.trashedNotifications.length > 0 &&
                        <Chip label={notifications.trashedNotifications.length} size="small"/>
                        }
                      </ListItem>
                  </List>
              </Box>

            {/*<Box sx={{ height: 320, transform: 'translateZ(0px)', flexGrow: 1 }}>
              <SpeedDial

                  ariaLabel="SpeedDial basic example"
                  sx={{
                    position: 'absolute',
                    bottom: 128,
                    right: 32,
                    color: "gray"
                  }}
                  icon={<StarIcon />}
              >
                <SpeedDialAction
                    onClick={() => console.log('')}
                    key={'copy'}
                    icon={<FileCopyIcon />}
                    tooltipTitle={'Copy'}
                />
              </SpeedDial>
            </Box>*/}
          </Drawer>
        }

        <Fab color="inherit" aria-label="add"
             onClick={() => setOpenCreateDialog(true)}
             sx={{
               zIndex:2000,
               position: 'fixed',
               bottom: 32,
               right: 60,
               backgroundColor:LIME_GREEN,
               color:DARK_NAVY,
             }}>
          <AddIcon/>
        </Fab>

        <NotificationCreateDialog
            newNotification={newNotification}
            setNewNotification={setNewNotification}
            openCreateDialog={openCreateDialog}
            setOpenCreateDialog={setOpenCreateDialog}/>

        <AlertToast/>
        <LoadingSpinnerFull showLoading={showLoading}/>
      </Dialog>
  );
}

export default NotificationInboxDialog;