import React, {Fragment, useEffect, useState} from "react";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {
  copyDayInfoValidInit, CopyDayObjectUtil, CopyDayObjectUtilResponse,
  DayStats, FullMonthResponse,
  Priority, ScheduledDayObjectResponse,
  ScheduledWeekObjectResponse, scheduleInfoValidInit,
  ScheduleObject,
  TaskInfo,
  TaskList,
  TaskResponse
} from "../../types/task/TaskTypes";
import {
  Alert,
  Box,
  Button,
  Card, CardContent,
  CardHeader, Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent, DialogTitle,
  Divider, FormControl, FormControlLabel, Grid,
  IconButton, ListItemText, OutlinedInput,
  Stack, TextField, Tooltip, useMediaQuery
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState, scheduleActions, taskInfoActions} from "../../store/storeIndex";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate, formatDateTime} from "../../service/generic/DateHelper";
import {determineIfTokenError} from "../../store/account/authSlice";
import Typography from "@mui/material/Typography";
import {
  ArrowBackIosNew,
  ArrowUpward,
  CheckCircleOutlined, HelpOutline,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material";
import TaskActions from "./TaskActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InputLabel from "@mui/material/InputLabel";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {UserInfo} from "../../types/account/AccountTypes";
import {copyDayUtilInfoInit, ScheduleUtilInfoInit} from "../../store/task/taskInfoSlice";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {HtmlTooltip} from "../general/HtmlTooltip";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";
import {displayAlert} from "../../store/alertSlice";

type Props = {
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  selectedDate: string
}

const CopyDayDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const daysTasks = useSelector((state: RootState) => state.taskInfo.daysTasks);
  const scheduleDayObjectResponse = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
  const [showLoading, setShowLoading] = React.useState(false);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [formTouched, setFormTouched] = React.useState(false);
  const orgMembers = useSelector((state: RootState) => state.account.orgMembers);
  const [copyDayInfoState, setCopyDayInfoState] = useState(copyDayUtilInfoInit);
  const [copyDayInfoStateValid, setCopyDayInfoStateValid] = useState(copyDayInfoValidInit);

  useEffect(() => {
    document.title = "Copy Day Dialog";
  }, []);

  /*useEffect(() => {
    console.log("modal is open use effect")
    dispatch(taskInfoActions.setIsModalOpen({value: props.isOpen}));
  }, [props.isOpen])*/

  useEffect(() => {
    console.log(copyDayInfoState.assignedStartTime);
  }, [copyDayInfoState.assignedStartTime])

  const onTaskSelectChange = (event: SelectChangeEvent<typeof copyDayInfoState.taskIds>) => {
    setFormTouched(true);
    let newCopyDayValidState = {...copyDayInfoStateValid};
    const {target: {value}} = event;
    let tasknames = typeof value === 'string' ? value.split(',') : value;
    let newCopyDayInfoState = {...copyDayInfoState};
    newCopyDayInfoState.taskNames = tasknames;

    let taskIds = tasknames
        .map(name => {
          return daysTasks?.filter(task =>
              name.includes(task.taskName))[0];
        })
        .flatMap(task => task.assignedTaskId);
    newCopyDayInfoState.taskIds = taskIds;
    if(tasknames.length < 1){
      newCopyDayInfoState.scheduleAndAssignWorkers = false;
      newCopyDayValidState.scheduleWorkersCheckbox = false;
    }
    setCopyDayInfoState(newCopyDayInfoState);


    newCopyDayValidState.tasksSelected = taskIds.length > 0;
    setCopyDayInfoStateValid(newCopyDayValidState);
  }

  const onChangeToDate = (date: Date | string | null) => {
    let newCopyDayInfoState = {...copyDayInfoState};
    newCopyDayInfoState.toDate = date;
    setCopyDayInfoState(newCopyDayInfoState);

    let valid = isDateValidInRange(date!, date!, date!);
    let newCopyDayValidState = {...copyDayInfoStateValid};
    newCopyDayValidState.toDate = date != null && valid;
    setCopyDayInfoStateValid(newCopyDayValidState);
  }

  const isDateValidInRange = (dateChanged: string | Date | null, startDate: string | Date | null, endDate: string | Date | null) => {
    try {
      let actualDate = new Date(dateChanged!);
      if (actualDate.getFullYear() < 2010 || actualDate.getFullYear() > 2040) {
        return false;
      }
    } catch (e) {
      return false;
    }
    return dateChanged !== null && (dateChanged.toString()).trim().length > 0 && new Date(startDate!) <= new Date(endDate!);
  }

  const isFormValid = () => {
    let valid = false;

    if(copyDayInfoState.scheduleAndAssignWorkers){
      return copyDayInfoStateValid.tasksSelected && copyDayInfoStateValid.startTime && copyDayInfoStateValid.endTime &&
          copyDayInfoStateValid.toDate;
    }
    else{
      return copyDayInfoStateValid.tasksSelected &&
          copyDayInfoStateValid.toDate;
    }
  };

  const cancelDialog = () => {
    let newCopyDayInfoState = {...copyDayInfoState};
    newCopyDayInfoState.toDate = '';
    newCopyDayInfoState.taskNames = [];
    newCopyDayInfoState.taskIds = [];
    newCopyDayInfoState.scheduleAndAssignWorkers = false;
    newCopyDayInfoState.assignedStartTime = '';
    newCopyDayInfoState.assignedEndTime = '';
    setCopyDayInfoState(newCopyDayInfoState);
    let newCopyDayValidState = {...copyDayInfoStateValid};
    newCopyDayValidState.toDate = false;
    newCopyDayValidState.tasksSelected = false;
    newCopyDayValidState.scheduleWorkersCheckbox = false;
    newCopyDayValidState.startTime = false;
    newCopyDayValidState.endTime = false;
    setFormTouched(false);
    setCopyDayInfoStateValid(newCopyDayValidState);

    props.setIsOpen(false);
  };

  const onChangeCheckBox = () => {
    let newCopyDayInfoState = {...copyDayInfoState};
    let newCopyDayValidState = {...copyDayInfoStateValid};
    newCopyDayInfoState.scheduleAndAssignWorkers = !copyDayInfoState.scheduleAndAssignWorkers;
    if(!newCopyDayInfoState.scheduleAndAssignWorkers){
      newCopyDayInfoState.assignedStartTime = '';
      newCopyDayInfoState.assignedEndTime = '';
      newCopyDayValidState.startTime = false;
      newCopyDayValidState.endTime = false;
    }
    setCopyDayInfoState(newCopyDayInfoState);
    newCopyDayValidState.scheduleWorkersCheckbox = !copyDayInfoStateValid.scheduleWorkersCheckbox;
    setCopyDayInfoStateValid(newCopyDayValidState);
  }

  const setScheduledStartTime = (time: Date | string | null) => {
    setFormTouched(true);
    let newCopyDayInfoState = {...copyDayInfoState};
    newCopyDayInfoState.assignedStartTime = formatDateTime(time?.toString())!;
    setCopyDayInfoState(newCopyDayInfoState);

    if(time != null){
      let validStart = isTimeValidInRange(time, time, newCopyDayInfoState.assignedEndTime);
      let validEnd = isTimeValidInRange(newCopyDayInfoState.assignedEndTime, time, newCopyDayInfoState.assignedEndTime);
      let newCopyDayValidState = {...copyDayInfoStateValid};
      newCopyDayValidState.startTime = validStart;
      newCopyDayValidState.endTime = validEnd;
      setCopyDayInfoStateValid(newCopyDayValidState);
    }
  }

  const isTimeValidInRange = (timeChanged: string | Date | null, startTime: string | Date | null, endTime: string | Date | null) => {
    try {
      let actualDate = new Date(timeChanged!);
      if (timeChanged?.toString().toLowerCase().includes('invalid')) {
        return false;
      }
    } catch (e) {
      return false;
    }
    let valid = timeChanged !== null && (timeChanged.toString()).trim().length > 0;
    if (valid && startTime && endTime && (startTime?.toString()).trim().length > 0 && (endTime?.toString()).trim().length > 0) {
      valid = new Date(startTime!) < new Date(endTime!);
    }
    return valid;
  }


  const setScheduledEndTime = (time: Date | string | null) => {
    setFormTouched(true);
    let newCopyDayInfoState = {...copyDayInfoState};
    newCopyDayInfoState.assignedEndTime = formatDateTime(time?.toString())!;
    setCopyDayInfoState(newCopyDayInfoState);

    if(time != null){
      let validEnd = isTimeValidInRange(time, newCopyDayInfoState.assignedStartTime, time);
      let validStart = isTimeValidInRange(newCopyDayInfoState.assignedStartTime, newCopyDayInfoState.assignedStartTime, time);
      let newCopyDayValidState = {...copyDayInfoStateValid};
      newCopyDayValidState.endTime = validEnd;
      newCopyDayValidState.startTime = validStart;
      setCopyDayInfoStateValid(newCopyDayValidState);
    }
  }

  const handleCopyDaySubmit = () => {
    if(isFormValid() && showLoading == false){
      setShowLoading(true);
      copyDayInfoState.toDate = formatDate(copyDayInfoState.toDate)?.toString();
      copyDayInfoState.fromDate = formatDate(selectedDate)?.toString();
      taskManagementService.copyDay(copyDayInfoState, orgInfo.allSelectedAccessCodes!, copyDayInfoState.toDate)
          .then((resp: CopyDayObjectUtil) => {
            dispatch(taskInfoActions.setCopyDayObjectUtil({value:resp}))
            cancelDialog();
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      });
      setShowLoading(false);
      props.setIsOpen(false);
      dispatch(displayAlert(true, 'Successfully Copied. You may need to refresh the page.'));
    }
  }

  return (
      <Fragment>
        { showLoading ? (
            <Box sx={{marginTop:'200px'}}>
              <LoadingSpinnerContainer showLoading={showLoading} />
            </Box>
        ) : (
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: isWindowLarge ? '35%' : '80%'} }}
                maxWidth="lg"
                open={props.isOpen}
            >
              <DialogTitle style={{fontWeight:'bold'}}>Copy Items From - {formatDate(props.selectedDate)}</DialogTitle>
              <DialogContent style={{minHeight:'285px'}}>

                <Alert severity="info" color="info" sx={{mb: 2}}>
                  <Typography variant={'body1'} component={"div"}>
                    Copy tasks from this day to another. The app log and worker(s) assigned to the task(s)
                    will <b>NOT</b> be copied.
                  </Typography>
                </Alert>

                <InputLabel id="taskSelect">Assigned Tasks</InputLabel>
                <Select
                    labelId="taskSelect"
                    id="taskSelect"
                    multiple={true}
                    fullWidth={true}
                    /*isValid={copyDayInfoStateValid.tasksSelected}*/
                    /*validationText={'Must select at least 1 task.'}*/
                    label="Task Select"
                    input={<OutlinedInput id="select-multiple-chip" label="Assigned Tasks" />}
                    sx={{mb:2, color:'red'}}
                    value={copyDayInfoState.taskNames}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                              <Chip key={value} label={value} />
                          ))}
                        </Box>
                    )}
                    onChange={onTaskSelectChange}
                    MenuProps={MenuMultiSelectProps}
                >
                  <MenuItem disabled={true}>Choose...</MenuItem>
                  { daysTasks?.map((task: TaskInfo, index) => (
                      <MenuItem value={task.taskName} key={task.taskName}>
                        <Checkbox checked={copyDayInfoState.taskNames.indexOf(task.taskName) > -1} />
                        <ListItemText primary={task.taskName} />
                      </MenuItem>
                  ))}
                </Select>

                {/*{copyDayInfoStateValid.tasksSelected && (
                    <Fragment>
                      <FormControlLabel sx={{mb:2}}
                          label={'Schedule/Assign Workers of Tasks'}
                          control={
                            <Checkbox
                                checked={copyDayInfoState.scheduleAndAssignWorkers}
                                onChange={() => onChangeCheckBox()}
                            />
                          }
                      />
                    </Fragment>
                )}*/}

                {copyDayInfoStateValid.scheduleWorkersCheckbox && (
                    <Box sx={{mb:2}}>
                      <Alert severity="warning" color="warning" sx={{mb: 2}}>
                        <Typography variant={'body1'} component={"div"}>
                          The worker(s) assigned to the task(s) you are copying will also be
                          scheduled and assigned for the day you are copying them too.
                          Enter in their scheduled times below.
                        </Typography>
                      </Alert>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormControl fullWidth sx={{mb:2}}>
                          <Grid container spacing={2} style={{}}>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <TimePicker
                                  label="Start Time"
                                  value={copyDayInfoState.assignedStartTime}
                                  onChange={(newValue) => {
                                    setScheduledStartTime(newValue);
                                  }}
                                  inputFormat={"hh:mm a"}
                                  toolbarFormat='yyyy-MM-dd'
                                  renderInput={(params) => <TextField {...params} sx={{width:'100%'}} />}
                              />
                              <small style={{color:'red', marginTop:0}} hidden={copyDayInfoStateValid.startTime || !formTouched}>
                                must be a valid time and less than the end time
                              </small>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <TimePicker
                                  label="End Time"
                                  value={copyDayInfoState.assignedEndTime}
                                  onChange={(newValue) => {
                                    setScheduledEndTime(newValue);
                                  }}
                                  inputFormat={"hh:mm a"}
                                  toolbarFormat='yyyy-MM-dd'
                                  renderInput={(params) => <TextField {...params} sx={{width:'100%'}} />}
                              />
                              <small style={{color:'red', marginTop:0}} hidden={copyDayInfoStateValid.endTime || !formTouched}>
                                must be a valid time and greater than the start time
                              </small>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </LocalizationProvider>
                    </Box>
                )}

                <Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormControl fullWidth sx={{mb:2}}>
                      <DatePicker
                          label="To Date"
                          value={copyDayInfoState.toDate}
                          onChange={onChangeToDate}
                          renderInput={(params) => <TextField {...params} />}
                      />
                      <small style={{color:'red', marginTop:0}} hidden={copyDayInfoStateValid.toDate! || !formTouched}>
                        must be a valid date and must be within year range of 2010-2040
                      </small>
                    </FormControl>
                  </LocalizationProvider>
                </Box>


              </DialogContent>
              <DialogActions style={{backgroundColor:'#BDBDBD'}}>
                <Button autoFocus onClick={() => cancelDialog()}>
                  Cancel
                </Button>
                <Button color={"inherit"} onClick={() => handleCopyDaySubmit()}
                        variant={isFormValid() ? "contained" : "text"}
                        sx={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                          bgcolor: isFormValid() ? LIME_GREEN : grey[200],
                          borderColor: isFormValid() ? DARK_NAVY : LIME_GREEN,
                          color: DARK_NAVY
                        }}>
                  Submit
                </Button>

                {/*<Button color={"inherit"}
                        type={'button'}
                        sx={{cursor: !(isFormValid()) ? 'not-allowed' : 'pointer',
                          bgcolor: isFormValid() ? LIME_GREEN : grey[200],
                          borderColor: isFormValid() ? DARK_NAVY : LIME_GREEN,
                          color: DARK_NAVY
                        }}
                        variant={isFormValid() ? "contained" : "outlined"}
                        onClick={handleCopyDaySubmit()}>
                  Submit
                </Button>*/}
              </DialogActions>
            </Dialog>
        )}
      </Fragment>
  );

}

export default CopyDayDialog;