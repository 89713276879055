import React, {Fragment, useEffect, useState} from "react";
import {
  Avatar, Button,
  Divider,
  Drawer, Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader, useMediaQuery
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {avatarService} from "../../service/generic/avatarService";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {
  Assessment, AssignmentInd,
  AssignmentOutlined,
  DescriptionOutlined,
  HomeOutlined, NotificationsOutlined, PriceCheck,
  SdStorageOutlined,
  WebOutlined
} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import {GridMenuIcon} from "@mui/x-data-grid";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {getAccountParentFromAccessCode} from "../../service/account/accountHelper";
import {DARK_NAVY, LIGHT_GREY_BLUE, LIME_GREEN, SIDENAV_GREY} from "../../service/generic/ColorScheme";
import {useHistory} from "react-router";
import AddModifyAllowedMembersDialog from "../account/AddModifyAllowedMembersDialog";
import AddIcon from "@mui/icons-material/Add";
import LeslieButton from "../general/LeslieButton";
import {Role} from "../../types/account/AccountTypes";

type Props = {
  tabSelected: string,
  setTabSelected: (tab:string) => void,
}

const DashboardSideNav:React.FC<Props> = (props) => {
  let history = useHistory();
  let dispatch = useAppThunkDispatch();
  const userRole = useSelector((state: RootState) => state.userInfo.userInfo.role);
  const isWindowLarge = useMediaQuery('(min-width:800px)');
  const drawerWidth = 240;

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);
  const selectedApplantAccessCode = useSelector((state: RootState) => state.playbooks.selectedApplantAccessCode);
  const [applantAccount, setApplantAccount] = useState(getAccountParentFromAccessCode(
      (selectedApplantAccessCode ? selectedApplantAccessCode : orgInfo.allSelectedAccessCodes![0]),
      orgInfo.orgInfo, orgInfo.orgAccounts));
  const [openAddMemberDialog, setOpenAddMemberDialog] = useState(false);

  useEffect(() => {
    setApplantAccount(getAccountParentFromAccessCode(
        (selectedApplantAccessCode ? selectedApplantAccessCode : orgInfo.allSelectedAccessCodes![0]),
        orgInfo.orgInfo, orgInfo.orgAccounts));
  }, [selectedApplantAccessCode, orgInfo.allSelectedAccessCodes])

  const handleMainUrlCall = () => {
    window.open("http://www.covsys.net/Premium/Mobile/Index?courseurl=" + 
        applantAccount.playbooksId, "_blank");
  }

  const handleDashboardUrlCall = () => {
    //window.open("http://www.covsys.net/Premium/Mobile/Dashboard?PRM=LGzAzH9oUE8%3D", "_blank");
    window.open("http://www.covsys.net/Premium/Mobile/Dashboard?PRM=" + applantAccount.dashPrm, "_blank");
  }

  const handleApplicationLogUrlCall = () => {
    window.open("http://www.covsys.net/Premium/ApplicationLog/AddApplicationLog?courseid=" + applantAccount.playbooksId, "_blank");
  }

  const handleRecordsUrlCall = () => {
    //http://www.covsys.net/Premium/Mobile/Spray?PRM=jq10sdOYVzQ%3D
    window.open("http://www.covsys.net/Premium/Mobile/Spray?PRM=" + applantAccount.recordsPrm, "_blank");
  }

  const handleLabelUrlCall = () => {
    window.open("http://www.covsys.net/Premium/Mobile/Label?PRM=LGzAzH9oUE8%3D" + applantAccount.labelSdsPrm, "_blank");
  }

  const handleAlertsUrlCall = () => {
    window.open("http://www.covsys.net/Premium/Mobile/Notifications?PRM=" + applantAccount.alertsPrm, "_blank");
  }

  const toggleSideMenu = () => {
    console.log(3)
    dispatch(taskInfoActions.setShowSideMenu({show:false}));
  }

  const onTabClick = (tab:string) => {
    if (!isWindowLarge) {
      toggleSideMenu();
    }
    props.setTabSelected(tab);
    history.push("/manage/labor/" + tab);
  }

  return (
      <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
      >
        {/*<Toolbar />*/}
        <List>
          <ListItem button key={'home'}
            secondaryAction={
              <Fragment>
                { !isWindowLarge &&
                  <IconButton size="large" onClick={toggleSideMenu}>
                    <GridMenuIcon/>
                  </IconButton>
                }
              </Fragment>
            }
          >
            <Typography variant="h5" component="div" sx={{flexGrow: 1}}
                        onClick={() => history.push('/home')}>
              <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-dark.png`}
                   style={{width:'170px', height: '50px'}}
                   alt={'Logo Image'}
              />
            </Typography>
          </ListItem>
          {/*<ListItem button key={'welcome'} style={{pointerEvents: "none"}}>
            <ListItemIcon style={{pointerEvents: 'all'}}>
              <Avatar {...avatarService.stringAvatar(userInfo.userInfo.firstName + ' ' + userInfo.userInfo.lastName)} />
            </ListItemIcon>
            <ListItemText primary={'Welcome, ' + userInfo.userInfo.firstName}/>
          </ListItem>*/}
        </List>
        <Divider/>
        <List>
          {checkUserNotOrdinaryWorker(userInfo.userInfo) &&
          <ListItem button key={'taskManagement'}
                    style={{backgroundColor: props.tabSelected === 'taskManagement' ? LIGHT_GREY_BLUE : ''}}
                    onClick={() => onTabClick('taskManagement')}>
            <ListItemIcon sx={{color: props.tabSelected === 'taskManagement' ? "white" : SIDENAV_GREY}}>
              <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText sx={{color: props.tabSelected === 'taskManagement' ? "white" : SIDENAV_GREY}}
                          primary={'Task Management'}/>
          </ListItem>
          }

          <ListItem button key={'calendar'}
                    style={{backgroundColor: props.tabSelected === 'calendar' ? LIGHT_GREY_BLUE : ''}}
                    onClick={() => onTabClick('calendar')}>
            <ListItemIcon sx={{color:props.tabSelected === 'calendar' ? "white" : SIDENAV_GREY}}>
              <CalendarTodayIcon/>
            </ListItemIcon>
            <ListItemText sx={{color:props.tabSelected === 'calendar' ? "white" : SIDENAV_GREY}} primary={'Calendar'}/>
          </ListItem>

          <ListItem button key={'scheduling'}
                    style={{backgroundColor: props.tabSelected === 'scheduling' ? LIGHT_GREY_BLUE : ''}}
                    onClick={() => onTabClick('scheduling')}>
            <ListItemIcon sx={{color:props.tabSelected === 'scheduling' ? "white" : SIDENAV_GREY}}>
              <AssignmentIcon/>
            </ListItemIcon>
            <ListItemText sx={{color:props.tabSelected === 'scheduling' ? "white" : SIDENAV_GREY}} primary={'Scheduling'}/>
          </ListItem>

          <ListItem button key={'jobBoard'}
                    style={{backgroundColor: props.tabSelected === 'jobBoard' ? LIGHT_GREY_BLUE : ''}}
                    onClick={() => onTabClick('jobBoard')}>
            <ListItemIcon sx={{color:props.tabSelected === 'jobBoard' ? "white" : SIDENAV_GREY}}>
              <AssignmentInd/>
            </ListItemIcon>
            <ListItemText sx={{color:props.tabSelected === 'jobBoard' ? "white" : SIDENAV_GREY}} primary={'Job Board'}/>
          </ListItem>

          { checkUserNotOrdinaryWorker(userInfo.userInfo) &&
            <ListItem button key={'reports'}
                      style={{backgroundColor: props.tabSelected === 'reports' ? LIGHT_GREY_BLUE : ''}}
                      onClick={() => onTabClick('reports')}>
                <ListItemIcon sx={{color:props.tabSelected === 'reports' ? "white" : SIDENAV_GREY}}>
                    <Assessment/>
                </ListItemIcon>
                <ListItemText sx={{color:props.tabSelected === 'reports' ? "white" : SIDENAV_GREY}} primary={'Reports'}/>
            </ListItem>
          }

          {checkUserNotOrdinaryWorker(userInfo.userInfo) &&
            <ListItem button key={'budgeting'}
                      style={{backgroundColor: props.tabSelected === 'budgeting' ? LIGHT_GREY_BLUE : ''}}
                      onClick={() => onTabClick('budgeting')}>
                <ListItemIcon sx={{color:props.tabSelected === 'budgeting' ? "white" : SIDENAV_GREY}}>
                    <PriceCheck/>
                </ListItemIcon>
                <ListItemText sx={{color:props.tabSelected === 'budgeting' ? "white" : SIDENAV_GREY}} primary={'Budgeting'}/>
            </ListItem>
          }
          {userRole !== Role.ORG_WORKER && orgInfo.allSelectedAccessCodes?.length == 1 &&
              <LeslieButton text={'Add Worker'}
                            width={'100%'}
                            height={"short"}
                            handleSubmit={() => setOpenAddMemberDialog(true)}
              />
          }
        </List>
        <Divider/>

        <List subheader={
          <ListSubheader component="div" id="playbooks-sub">
            <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/applant-dark.png`}
                 style={{width:'160px', height: '50px'}}
                 alt={'Logo Image'}
            />
          </ListSubheader>
        }>
          { applantAccount.playbooksId &&
            <ListItem button key={'playbooksMain'}
                      onClick={handleMainUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                <HomeOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'Main'}/>
            </ListItem>
          }

          {applantAccount.dashPrm &&
          <ListItem button key={'playbooksdash'}
                    onClick={handleDashboardUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                  <WebOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'Dashboard'}/>
          </ListItem>
          }

          {applantAccount.playbooksId &&
          <ListItem button key={'playbooksapp'}
                    onClick={handleApplicationLogUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                  <AssignmentOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'New Application Log'}/>
          </ListItem>
          }

          {applantAccount.recordsPrm &&
          <ListItem button key={'playbooksrec'}
                    onClick={handleRecordsUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                  <SdStorageOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'Records'}/>
          </ListItem>
          }

          {applantAccount.labelSdsPrm &&
          <ListItem button key={'playbooksdocs'}
                    onClick={handleLabelUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                  <DescriptionOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'Label/Sds Docs'}/>
          </ListItem>
          }

          {applantAccount.alertsPrm &&
          <ListItem button key={'playbooksalert'}
                    onClick={handleAlertsUrlCall}>
              <ListItemIcon sx={{color:SIDENAV_GREY}}>
                  <NotificationsOutlined/>
              </ListItemIcon>
              <ListItemText sx={{color:SIDENAV_GREY}} primary={'Alerts'}/>
          </ListItem>
          }
        </List>
        <Divider/>
        <AddModifyAllowedMembersDialog
            isOpen={openAddMemberDialog}
            setIsOpen={setOpenAddMemberDialog}
            action={'Add'} />
      </Drawer>
  );
}

export default DashboardSideNav;