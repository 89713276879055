import {CompletedTasksUtil, JobBoardTableRows, JobBoardTableUtil} from "./chartTypes";
import {CompletedTasksHeadCell} from "./CompletedTasksTableProps";
import {TableCellFuncProps} from "../../component/general/table/CustomTable";
import React, {Fragment} from "react";
import {TableCell} from "@mui/material";
import {avatarService} from "../../service/generic/avatarService";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";

export interface JobBoardHeadCell {
  disablePadding: boolean;
  id: keyof JobBoardTableRows;
  label: string;
  numeric: boolean;
}

export const jbHeadCells: JobBoardHeadCell[] = [
  {
    id: 'employeeColor',
    numeric: false,
    disablePadding: true,
    label: '',
  },
  {
    id: 'employeeName',
    numeric: false,
    disablePadding: true,
    label: 'Employee Name',
  },
  {
    id: 'taskName',
    numeric: false,
    disablePadding: true,
    label: 'Task Name',
  },
  {
    id: 'accountName',
    numeric: false,
    disablePadding: true,
    label: 'Account',
  },
  {
    id: 'tasklocations',
    numeric: false,
    disablePadding: true,
    label: 'Location(s)',
  },
  {
    id: 'deadlineTime',
    numeric: false,
    disablePadding: true,
    label: 'Deadline Time',
  },
  {
    id: 'subTasks',
    numeric: false,
    disablePadding: true,
    label: 'Sub-Tasks',
  },
  {
    id: 'taskNotes',
    numeric: false,
    disablePadding: true,
    label: 'Notes',
  },
  {
    id: 'completed',
    numeric: false,
    disablePadding: true,
    label: 'Completed',
  },
];

export const JobBoardTableCells = (props: TableCellFuncProps) => {
  let row = props.row as JobBoardTableRows;
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  return (
      <Fragment>
        <TableCell id={props.labelId} align="left" style={{width:'3.8%', backgroundColor:avatarService.stringToColor(row.employeeColor)}}>

        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.employeeName}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.taskName}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.accountName}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.tasklocations}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.deadlineTime}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.subTasks}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.taskNotes}
        </TableCell>
        <TableCell id={props.labelId} align="left" style={{backgroundColor: row.completed ? "green" : "red"}}>

        </TableCell>
      </Fragment>
  );
}