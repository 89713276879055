import {createSlice} from "@reduxjs/toolkit";
import {BudgetUtilInit} from "../../types/task/BudgetTypes";

export const BudgetSlice = createSlice({
  name: 'budgets',
  initialState: BudgetUtilInit,
  reducers: {
    setBudgetUtil(state, action) {
      state.annualBudgets = action.payload.budget.annualBudgets;
      state.monthlyBudgets = action.payload.budget.monthlyBudgets;
      state.taskCategoryBudgets = action.payload.budget.taskCategoryBudgets;
    },
    setAnnualBudgets(state, action) {
      state.annualBudgets = action.payload.annualBudgets;
    },
    setMonthlyBudgets(state, action) {
      state.monthlyBudgets = action.payload.monthlyBudgets;
    },
    setTaskCategoryBudgets(state, action) {
      state.taskCategoryBudgets = action.payload.taskCategoryBudgets;
    },
    setAnnualBudgetsByAccountChart(state, action) {
      state.annualBudgetsByAccountChart = action.payload.annualBudgetsByAccountChart;
    },
    resetToInit(state) {
      state = BudgetUtilInit;
    }
  }
});