import React, {Fragment, useEffect, useState} from "react";
import Navbar from "../../component/nav/Navbar";
import {useSelector} from "react-redux";
import {authActions, RootState} from "../../store/storeIndex";
import {Redirect} from "react-router-dom";
import {
  Box,
  Button, Card, CardContent, CardHeader,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid, IconButton,
  Stack,
  Typography
} from "@mui/material";
import CustomTextField from "../../component/general/CustomTextField";
import AlertToast from "../../component/general/AlertToast";
import {useHistory} from "react-router";
import LoadingSpinnerFull from "../../component/general/LoadingSpinnerFull";
import {loginService} from "../../service/account/loginService";
import {emailRegex} from "../../types/account/AccountValids";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import PasswordResetForm from "../../component/account/PasswordResetForm";
import {Close} from "@mui/icons-material";
import {ParallaxBanner} from "react-scroll-parallax";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import ValidButton from "../../component/general/ValidButton";


const LoginPage: React.FC = () => {
  let dispatch = useAppThunkDispatch();
  let history = useHistory();

  const {username, pass, loggedIn} = useSelector((state: RootState) => state.auth);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);

  const [usernameState, setUsername] = useState<string>(username);
  const [passState, setPassword] = useState<string>(pass);
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [isUnameValid, setIsUnameValid] = useState<boolean>(false);
  const [isPassValid, setIsPassValid] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [isForgettenPassDialogOpen, setIsForgettenPassDialogOpen] = useState(false);

  useEffect(() => {
    document.title = "Login Page";
  }, []);

  const loginHandler = () => {
    let timeout: NodeJS.Timeout;
    const ac = new AbortController();

    if (isUnameValid && isPassValid) {
      setShowLoading(true);

      timeout = setTimeout(() => {
        loginService.login(usernameState.trim(), passState)
            .then((jwt) => {
              if (jwt !== '') {
                dispatch(authActions.loginSuccess({token:jwt}));
                history.push('/manage/labor');
                setPassword('');
                setFormTouched(false);
                //dispatch(displayAlert(true, "Welcome back!"));
              } else {
                dispatch(authActions.logout);
              }
            }).catch(e => {
              dispatch(determineIfTokenError(e));
              setShowLoading(false);
            });
      }, 700);
    } else {
      determineUnameValid(usernameState);
      determinePassValid(passState);
    }

    return () => {
      setShowLoading(false);
      clearTimeout(timeout);
      ac.abort();
    };
  };

  const isFormValid = () => {
    return isUnameValid && isPassValid;
  }

  const determineUnameValid = (uname: string) => {
    setIsUnameValid(uname.trim().length > 0 && emailRegex.test(uname.toLowerCase()));
  }

  const determinePassValid = (pass: string) => {
    setIsPassValid(pass.trim().length > 5);
  }

  const onChangeUsername = (unameVal:string) => {
    setFormTouched(true);
    determineUnameValid(unameVal);
    setUsername(unameVal);
  }

  const onChangePassword = (passVal:string) => {
    setFormTouched(true);
    determinePassValid(passVal);
    setPassword(passVal);
  }

  if (loggedIn) {
    return <Redirect to={'/home'} />
  }

  return (
      <Fragment>
        <Navbar children={<div></div>}/>

        {/* for the left half pic and right half form*/}
        {/*<Grid container>
          <Box component={Grid} item
              md={6}
              display={{ xs: "none", md: 'block' }}
          >
            <img src={`https://d2vc87ouw1q565.cloudfront.net/images/bunch-grass.jpg`}
                 style={{backgroundRepeat:'no-repeat', width:'50%', position:'absolute',
                   backgroundPosition:"left top",height: '100%'}}
                 alt={'Sign In Background Image'}/>
          </Box>
          <Divider orientation="vertical" flexItem>
            VERTICAL
          </Divider>
          <Grid item md={6} sm={12}>
            <Box sx={{padding:'20px'}}>
            </Box>
          </Grid>
        </Grid>*/}

        <ParallaxBanner
            layers={[
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/skytree.jpg`, speed: -20 },
              { image: `${process.env.REACT_APP_RESOURCES_URL_BASE}/images/skytree.jpg`, speed: -10 },
              {
                speed: -35,
                children: (
                    <Box sx={{display:'flex', inset:0, position:'absolute',
                        justifyContent:'center', alignItems: 'center'}}>
                      <Card>
                        <CardHeader
                            title={
                              <Box sx={{display:'flex', justifyContent:'center'}}>
                                <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-dark.png`}
                                     style={{width:'80%', height: '30%'}}
                                     alt={'Logo Image'}
                                />
                              </Box>
                            }
                        />
                        <CardContent>
                          <form onSubmit={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            loginHandler();
                          }}>
                            <Stack spacing={2}>
                              <CustomTextField
                                  valueString={usernameState}
                                  label={"Username"}
                                  isValid={isUnameValid || !formTouched}
                                  validationText={'must be a valid email'}
                                  onEnterKeyDownSubmitHandler={loginHandler}
                                  onInputUpdate={onChangeUsername}
                              />
                              <CustomTextField
                                  valueString={passState}
                                  label={"Password"}
                                  type={'password'}
                                  isValid={isPassValid || !formTouched}
                                  validationText={'must be longer'}
                                  onEnterKeyDownSubmitHandler={loginHandler}
                                  onInputUpdate={onChangePassword}
                              />

                              <Box textAlign={"center"}>
                                <Stack
                                    direction="row"
                                    divider={<Divider orientation="vertical" flexItem />}
                                    spacing={2}
                                    justifyContent="center"
                                >
                                  {/*<Typography>Don't Have an Account?
                                    <Button variant="text" onClick={() => history.push('/signup/type')}>Signup</Button>
                                  </Typography>*/}
                                  <Button onClick={() => setIsForgettenPassDialogOpen(true)}>Forgot Password</Button>
                                </Stack>
                              </Box>

                              <ValidButton text={'Login'} handleSubmit={loginHandler}
                                           isFormValid={isFormValid} />
                            </Stack>
                          </form>
                        </CardContent>
                      </Card>
                    </Box>
                ),
              },
            ]}
            style={{ aspectRatio: '2 / 1', height:'100vh' }}
        >
        </ParallaxBanner>

        <AlertToast/>

        <LoadingSpinnerFull showLoading={showLoading} />

        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 500 } }}
            maxWidth="sm"
            open={isForgettenPassDialogOpen}
        >
          <DialogTitle>
            Reset Current or Forgotten Password
            <IconButton
                aria-label="close"
                onClick={() => setIsForgettenPassDialogOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  backgroundColor:LIME_GREEN,
                  color:DARK_NAVY,
                }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <PasswordResetForm
                  isLoggedIn={false}
                  setDialogOpen={setIsForgettenPassDialogOpen} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Fragment>
  );
};

export default LoginPage;