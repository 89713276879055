import ValueType from "react-select";

// for react-select
export type OptionType = {label: string, value: string};
/**
 * Resolves a ValueType into an array of OptionType.
 * @param value The ValueType to resolve.
 */
export function resolveOptionType(value: OptionType): OptionType[] {
  let optionTypes: OptionType[];
  if (value == null) {
    optionTypes = [];
  } else if (Array.isArray(value)) {
    optionTypes = value;
  } else {
    optionTypes = [value];
  }
  return optionTypes;
}