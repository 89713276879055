import {TaskInfoApiResponse} from "../store/task/taskInfoSlice";

export enum CalendarViews {
  MONTHLY = "Month's",
  WEEKLY = "Week's",
  DAILY = "Today's",
}

export const getTaskToDisplay = (viewSelection: CalendarViews, tasks: TaskInfoApiResponse) => {
  return viewSelection === CalendarViews.DAILY ? [...tasks.daysTasks!] :
      viewSelection === CalendarViews.WEEKLY ? [...tasks.weeklyTasks!] :
          viewSelection === CalendarViews.MONTHLY ? [...tasks.monthlyTasks!] : [];
}

export const getPrettyTextFromStringArray = (array:string[]) => {
  return getPrettyTextFromStringArrayWithDelim(array, ", ");
}

export const getPrettyTextFromStringArrayWithDelim = (array:string[], delimiter:string) => {
  let text = "";
  for (let i = 0; i < array.length; i++) {
    text += array[i];
    if (i < array.length - 1) {
      text += delimiter;
    }
  }
  return text;
}