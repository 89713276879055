import {httpCommonService} from "./generic/httpCommonService";
import {
    Comments,
    CopyDayObjectUtil,
    CopyDayObjectUtilResponse,
    EmployeeListResponse,
    FullMonthResponse,
    FullWeekResponse,
    ScheduledDayObjectResponse,
    ScheduleDeleteUtil,
    ScheduledWeekObjectResponse,
    ScheduleObjectUtil,
    TaskCategories,
    TaskInfo,
    TaskList,
    TaskLocations,
    TaskResponse, ScheduleList, ExtraMonthResponse
} from "../types/task/TaskTypes";
import {formatDate} from "./generic/DateHelper";
import {PlaybooksResponse} from "../types/task/PlaybooksTypes";
import {UserInfo} from "../types/account/AccountTypes";
import {PaginationUtil} from "../types/PageSortTypes";
import {OpenWeatherResponse} from "../types/generic/WeatherTypes";
import {QuickReport} from "../types/charts/chartTypes";

export const taskManagementService = {
    submitTask,
    updateExistingTask,
    getMonthlyTasks,
    fetchQuickReport,
    getFullWeekResponse,
    getEmployeeListResponse,
    getFullMonthResponse,
    getExtraMonthResponse,
    deleteTask,
    deleteTemplate,
    completeTask,
    commentOnTask,
    scheduleEmployee,
    copyDay,
    getAvailablePlayBooksApplications,
    getScheduledWeekObjectResponse,
    deleteScheduledEmployee,
    getTasksAssignedToWorkerOnDay,
    retrieveAvailableMembersToAssignOnSelectedDay,
    addNewTaskCategory,
    getTaskCategories,
    getAllTaskCategoriesForAccount,
    getAllTaskLocationsForAccount,
    getTaskLocations,
    deleteCategory,
    deleteLocation,
    getTodaysTasks,
    getTodaysScheduledEmployees,
    getScheduledOjects,
    addNewTaskLocation,
    changeLocationName,
    changeCategoryName,
    fetchWeatherReport,
    getAllTasksWithAssignedLocation,
    getAllTasksWithAssignedCategory,
    getMiniCalendarResponse,
}

async function handleApiSuccess(responseAny: any) {
    let response: TaskResponse = await responseAny.json();
    return response;
}
//-------------------------------------------------------------

/*async function getApplicationReportAPI(selectedDate: string | null) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/Report.svc/GetPrintURL/getApplicationReportAPI/9754c179-dc77-4048-ad7b-0919ec4e0032`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}*/
async function deleteScheduledEmployee(scheduleUtil: ScheduleDeleteUtil, selectedDate: string | null, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/schedule/deleteScheduledEmployee/${selectedDate}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(scheduleUtil),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ScheduledWeekObjectResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function retrieveAvailableMembersToAssignOnSelectedDay(selectedDate: string | null, accessCodes:string[], ac:AbortController) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/schedule/getScheduledWorkers/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            signal: ac.signal
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as UserInfo[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getAllTaskCategoriesForAccount(accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getAllTaskCategoriesForAccount/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskCategories[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getAllTaskLocationsForAccount(accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getAllTaskLocationsForAccount/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskLocations[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getAvailablePlayBooksApplications(selectedDate: string | null, accessCode: string, useOldCache:boolean) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/playbooks/${useOldCache}/getAllApis/${selectedDate}/${accessCode}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => {
                let rawResp = await response.json();
                let pbResp = rawResp as PlaybooksResponse;
                pbResp.yearlyAndAlerts.NewAlertsBadge = rawResp.yearlyAndAlerts[0]["New Alerts Badge"];
                return pbResp;
            })
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function deleteTask(taskId:String ,selectedDate: string | null, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deleteTask/${taskId}/${selectedDate}/${accessCodes.toString()}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function deleteTemplate(templateId:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/deleteTemplate/${templateId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getScheduledOjects(scheduleDate: string | null, accessCodes: string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getScheduledWorkersForSelectedDay/${scheduleDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ScheduledDayObjectResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function copyDay(copyDayInfoState: CopyDayObjectUtil, accessCodes:string[], date: string | null) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/copyDay/${date}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(copyDayInfoState),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as CopyDayObjectUtil)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function scheduleEmployee(scheduleObject: ScheduleObjectUtil, selectedDate: string | null, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/scheduleEmployee/${selectedDate}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(scheduleObject),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ScheduledWeekObjectResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function commentOnTask(taskId:String ,selectedDate: string | null, comment:Comments, accessCodes:string[]) {
    const formData = new FormData();
    if (comment.multipartFile) {
        console.log('file is present')
        console.log(comment.multipartFile)
        formData.append('file', comment.multipartFile);
    }
    formData.append('commentString', comment.commentString);
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/commentOnTask/${taskId}/${selectedDate}/${accessCodes.toString()}`, {
            method: 'POST',
            body: formData,
            headers: {
                'Authorization': 'Bearer ' + token,
                //'Content-Type': 'multipart/form-data',
                //'Content-Type': 'multipart/mixed; boundary="customFormBoundary"',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function completeTask(task:TaskInfo ,selectedDate: string | null, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/completeTask/${selectedDate}/${accessCodes.toString()}`, {
            method: 'PUT',
            body: JSON.stringify(task),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getMonthlyTasks(selectedDate: string | null, accessCodes:string[], ac:AbortController) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/retrieveTodaysTasks/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            signal: ac.signal
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function fetchQuickReport(accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/reports/quickLook/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as QuickReport)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getTodaysTasks(selectedDate: string | null, filterCompletedTasks : boolean, filterUnCompletedTasks : boolean, accessCodes:string[], globalDate: string | null) {
    try {
        let token = localStorage.getItem('user') as string;
        if(selectedDate == ""){
            selectedDate = globalDate;
        }
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getTodaysTasks/${selectedDate}/${filterCompletedTasks}/${filterUnCompletedTasks}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskList)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getTodaysScheduledEmployees(selectedDate: string | null, accessCodes:string[], globalDate: string | null) {
    try {
        let token = localStorage.getItem('user') as string;
        if(selectedDate == ""){
            selectedDate = globalDate;
        }
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getTodaysScheduledEmployees/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ScheduleList)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getEmployeeListResponse(accessCode:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getEmployeeList/${accessCode}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as EmployeeListResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getFullWeekResponse(selectedDate: string | null, filterCompletedTasks : boolean, accessCodes: string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getFullWeekResponse/${selectedDate}/${filterCompletedTasks}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as FullWeekResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getScheduledWeekObjectResponse(selectedDate: string | null, accessCodes: string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getScheduledWeekObjectResponse/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ScheduledWeekObjectResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getMiniCalendarResponse(selectedDate: string | null, accessCodes: string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getMiniCalendarResponse/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as FullMonthResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getExtraMonthResponse(selectedDate: string | null, accessCodes: string[], howManyExtraMonthsToShow: number) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getExtraMonthResponse/${howManyExtraMonthsToShow}/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as ExtraMonthResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getFullMonthResponse(selectedDate: string | null, accessCodes: string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/getFullMonthResponse/${selectedDate}/${accessCodes.toString()}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as FullMonthResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

//--------------------------------------------------------------

async function submitTask(task: TaskInfo | undefined, selectedDate: string | null, accessCodes:string[]) {
    try {
        task.scheduledDate = formatDate(task?.scheduledDate as Date);
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit/task/${selectedDate}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(task),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(handleApiSuccess)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function updateExistingTask(task: TaskInfo | undefined, selectedDate: string | null, accessCodes:string[]) {
    try {
        task.scheduledDate = formatDate(task?.scheduledDate as Date);
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/update/task/${selectedDate}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(task),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

//---------------------------------------------------------------------------

async function addNewTaskLocation(name: string, pagination:PaginationUtil, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/location/add/${name}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function addNewTaskCategory(name: string, pagination:PaginationUtil, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/category/add/${name}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getTaskCategories(pagination:PaginationUtil, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/categories/retrieve/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getTaskLocations(pagination:PaginationUtil, accessCodes:string[]) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/locations/retrieve/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function deleteCategory(pagination:PaginationUtil, accessCodes:string[], id:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/categories/delete/${accessCodes.toString()}/${id}`, {
            method: 'DELETE',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getAllTasksWithAssignedCategory(accessCodes:string[], id:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/locations/getAllTasksWithAssignedCategory/${accessCodes.toString()}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskInfo[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getAllTasksWithAssignedLocation(accessCodes:string[], id:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/locations/getAllTasksWithAssignedLocation/${accessCodes.toString()}/${id}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskInfo[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function deleteLocation(pagination:PaginationUtil, accessCodes:string[], id:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/locations/delete/${accessCodes.toString()}/${id}`, {
            method: 'DELETE',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function changeCategoryName(pagination:PaginationUtil, accessCodes:string[], newName:string, id:string, date:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/category/update/${id}/${newName}/${date}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function changeLocationName(pagination:PaginationUtil, accessCodes:string[], newName:string, id:string, date:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/locations/update/${id}/${newName}/${date}/${accessCodes.toString()}`, {
            method: 'POST',
            body: JSON.stringify(pagination),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function getTasksAssignedToWorkerOnDay(date:string, userId:string) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task/assignedOnDay/worker/${userId}/${date}`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as TaskInfo[])
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function fetchWeatherReport() {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weather`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as OpenWeatherResponse)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

