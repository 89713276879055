import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Box,
  Card, CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton, List, ListItem,
  Pagination,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {Add, ArrowForward, CancelOutlined, Delete, DeleteOutlined, FilterAlt} from "@mui/icons-material";
import {AccountBudget} from "../../types/task/BudgetTypes";
import CustomTextField from "../general/CustomTextField";
import {runInNewContext} from "vm";
import {useSelector} from "react-redux";
import {alertActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {taskManagementService} from "../../service/taskManagementService";
import {determineIfTokenError} from "../../store/account/authSlice";
import SearchIcon from "@mui/icons-material/Search";
import {Role} from "../../types/account/AccountTypes";
import {paginationInit} from "../../types/PageSortTypes";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {displayAlert} from "../../store/alertSlice";
import ConfirmDialog from "../general/ConfirmDialog";
import {CalendarViews} from "../../service/TasksHelper";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {HtmlTooltip} from "../general/HtmlTooltip";
import {TaskInfo} from "../../types/task/TaskTypes";
import {formatDate} from "../../service/generic/DateHelper";
import ChangeLocationsOnTasksDialog from "../general/ChangeLocationsOnTasksDialog";
import ChangeCategoryOnTasksDialog from "../general/ChangeCategoryOnTasksDialog";
import {sortByAlpha} from "../../service/generic/sortService";
import EditLocationDialog from "../general/EditLocationDialog";
import EditCategoryDialog from "../general/EditCategoryDialog";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {DARK_NAVY, GHOST_WHITE, LIGHT_NAVY} from "../../service/generic/ColorScheme";

type Props = {
  showMonthlyTasksLoading: boolean
}

const TaskCategoriesConfig:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const userRole = useSelector((state: RootState) => state.userInfo.userInfo.role);
  const taskCategoriesPageGlobal = useSelector((state: RootState) => state.taskInfo.taskCategories);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);

  const [showLoading, setShowLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [addEnabled, setAddEnabled] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [addValid, setAddValid] = useState(false);
  const [newCategory, setNewCategory] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [categoryIdToDelete, setCategoryIdToDelete] = useState('');
  const [isOpenChangeTaskCategoryDialog, setIsOpenChangeTaskCategoryDialog] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [tasksToChangeCategory, setTasksToChangeCategory] = React.useState<TaskInfo[]>([]);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [categoryNameToChange, setCategoryNameToChange] = useState('');
  const [categoryEditDialogOpen, setCategoryEditDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    setShowLoading(props.showMonthlyTasksLoading);
  }, [props.showMonthlyTasksLoading])

  useEffect(() => {
    setPageNum(taskCategoriesPageGlobal.pagination.pageNumber);
  }, [taskCategoriesPageGlobal.pagination.pageNumber])

  const handleAddEnable = () => {
    if (addEnabled) {
      initValid();
    }
    setFilterEnabled(false);
    setAddEnabled(!addEnabled);
  }

  const toggleFilter = () => {
    setFilterEnabled(!filterEnabled);
    setAddEnabled(false);
  }

  const initValid = () => {
    setAddValid(false);
    setFormTouched(false);
    setNewCategory('');
  }

  const onAddChange= (val:string) => {
    setFormTouched(true);
    setAddValid(val.length > 1);
    setNewCategory(val);
  }

  const getCategoriesListFiltered = () => {
    return taskCategoriesPageGlobal.taskCategories.filter(category => category.categoryName.toLowerCase().includes(searchValue.toLowerCase()) ||
        category.accountName.toLowerCase().includes(searchValue.toLowerCase()));
  }

  const submitNewCategory = () => {
    if (addValid) {
      setShowLoading(true);
      taskManagementService.addNewTaskCategory(newCategory, taskCategoriesPageGlobal.pagination, allSelectedAccessCodes!)
          .then(resp => {
            dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage:resp.taskCategories}));
            initValid();
            setAddEnabled(false);
          })
          .catch(e => dispatch(determineIfTokenError(e)))
          .finally(() => setShowLoading(false));
    }
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setShowLoading(true);
    setPageNum(value);
    let newPage = {...taskCategoriesPageGlobal.pagination}
    newPage.pageNumber = value;
    taskManagementService.getTaskCategories(newPage, allSelectedAccessCodes!)
        .then(resp => {
          dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage:resp.taskCategories}));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  };

  const onCategoryDelete = (id:string) => {
    setShowLoading(true);
    setIsOpenDeleteConfirm(false);
    let newPage = {...taskCategoriesPageGlobal.pagination}
    newPage.pageNumber = pageNum;
    taskManagementService.getAllTasksWithAssignedCategory(allSelectedAccessCodes!, id)
        .then(resp => {
          setTasksToChangeCategory(resp);
          if(resp.length == 0){
            taskManagementService.deleteCategory(newPage, allSelectedAccessCodes!, id)
              .then(resp => {
                dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage:resp.taskCategories}));
                dispatch(displayAlert(true, 'task category successfully deleted'));
              })
              .catch(e => dispatch(determineIfTokenError(e)))
              .finally(() => {
                setShowLoading(false);
                setIsOpenChangeTaskCategoryDialog(false);
              });
          }
          else{
            if(!isCancel){
              setIsOpenChangeTaskCategoryDialog(true);
            }
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
  }

  return (
      <Fragment>
        {checkUserNotOrdinaryWorker(userInfo) &&
        <Card sx={{backgroundColor:GHOST_WHITE, width:'100%', flex: 1,  minHeight:'100%'}}>
          <CardHeader
              title={
                <Typography variant="h5" component="div" style={{color:DARK_NAVY, fontWeight:"bold"}}>
                  Task Categories
                  <hr/>
                </Typography>
              }
              action = {
                <Stack direction={'row'} spacing={0}>
                  {!showLoading && allSelectedAccessCodes?.length === 1 && userRole !== Role.ORG_WORKER &&
                  <Box>
                    <HtmlTooltip title={
                      <Fragment>
                        <em>Add Category</em>
                      </Fragment>
                    }>
                      <IconButton hidden={showLoading} aria-label="edit" style={{color:DARK_NAVY}} onClick={handleAddEnable}>
                        {!addEnabled && <Add/>}
                        {addEnabled && <CancelOutlined/>}
                      </IconButton>
                    </HtmlTooltip>
                  </Box>
                  }
                  <HtmlTooltip title={
                    <Fragment>
                      <em>Filter Categories</em>
                    </Fragment>
                  }>
                    <IconButton hidden={showLoading} aria-label="edit" style={{color:DARK_NAVY}} onClick={toggleFilter}>
                      {!filterEnabled && <FilterAlt/>}
                      {filterEnabled && <CancelOutlined/>}
                    </IconButton>
                  </HtmlTooltip>
                </Stack>
              }
          />
          <CardContent sx={{pt:0, maxHeight:'400px', overflowY:"auto"}}>
            {!showLoading &&
            <Fragment>
              {addEnabled &&
              <Box sx={{mb: 2}}>
                <CustomTextField
                    isValid={addValid || !formTouched}
                    validationText={"Must be a valid category name"}
                    valueString={newCategory}
                    label={"New Category Name"}
                    onEnterKeyDownSubmitHandler={submitNewCategory}
                    onInputUpdate={(val) => onAddChange(val)}
                    endAdornment={true}
                >
                  <Tooltip title={'Submit'}>
                    <IconButton sx={{backgroundColor: addValid ? LIGHT_NAVY : "ghostwhite"}}
                                style={{cursor: addValid ? 'pointer' : 'not-allowed'}}
                                onClick={() => submitNewCategory()}>
                      <ArrowForward/>
                    </IconButton>
                  </Tooltip>
                </CustomTextField>
              </Box>
              }

              {filterEnabled &&
              <List>
                <ListItem>
                  <CustomTextField
                      sx={{mb: 2,pt:0}}
                      valueString={searchValue}
                      label={'Search'}
                      onInputUpdate={setSearchValue}
                      variant={"outlined"}
                      startAdornment={true}
                  >
                    <SearchIcon/>
                  </CustomTextField>
                </ListItem>
              </List>
              }

              {!showLoading && allSelectedAccessCodes?.length !== 1 && userRole !== Role.ORG_WORKER &&
              <Fragment>
                <Alert severity="info" color="info" sx={{mb: 2}}>
                  <Typography variant={'body1'} component={"div"}>
                    You can only have one organization selected to add, edit, or delete a category.
                  </Typography>
                </Alert>
              </Fragment>
              }

              <List>
                {getCategoriesListFiltered()
                    .sort((a,b) => sortByAlpha(a.categoryName, b.categoryName))
                    .map((category, index) => (
                        <Fragment>
                          <ListItem key={'cat' + index}
                            secondaryAction={
                              <Stack direction={"row"}>
                                {!showLoading && allSelectedAccessCodes?.length === 1 && userRole !== Role.ORG_WORKER &&
                                  <Box>
                                    <HtmlTooltip title={
                                      <Fragment>
                                        <em>Edit Location</em>
                                      </Fragment>
                                    }>
                                      <IconButton style={{color:DARK_NAVY}} onClick={() => {
                                        setCategoryNameToChange(category.categoryName);
                                        setCategoryIdToDelete(category.categoryId);
                                        setCategoryEditDialogOpen(true);
                                      }}>
                                        <EditIcon/>
                                      </IconButton>
                                    </HtmlTooltip>
                                    <HtmlTooltip title={
                                      <Fragment>
                                        <em>Delete Category</em>
                                      </Fragment>
                                    }>
                                      <IconButton style={{color:DARK_NAVY}} onClick={() => {
                                        setCategoryIdToDelete(category.categoryId);
                                        setIsOpenDeleteConfirm(true)
                                      }}>
                                        <Delete/>
                                      </IconButton>
                                    </HtmlTooltip>
                                  </Box>
                                }
                              </Stack>
                            }
                          >
                            <Stack direction={'row'} spacing={3} divider={<Divider orientation={'vertical'} flexItem/>}>
                              <Typography variant="subtitle1" style={{color:DARK_NAVY}} component="div">
                                {category.categoryName}
                                {allSelectedAccessCodes && allSelectedAccessCodes?.length > 1 &&
                                <Typography variant="caption" style={{color:DARK_NAVY}} component="div">
                                  {category.accountName}
                                </Typography>
                                }
                              </Typography>
                              {/*{category.s

                            }*/}
                            </Stack>
                          </ListItem>
                          <Divider component="li" sx={{my: 1}}/>
                        </Fragment>
                    ))}
              </List>
            </Fragment>
            }

            <LoadingSpinnerContainer showLoading={showLoading} />
          </CardContent>
          {/*<Box sx={{position:'relative'}}>*/}
          <Box sx={{display:'flex', justifyContent:'center', py:2 /*position:"absolute", bottom:0*/}}>
            <Pagination count={Math.ceil(taskCategoriesPageGlobal.pagination.totalResults / taskCategoriesPageGlobal.pagination.pageSize)}
                        page={taskCategoriesPageGlobal.pagination.pageNumber}
                        onChange={handlePageChange} variant="outlined" color="primary" />
          </Box>
          {/*</Box>*/}
        </Card>
        }

        <ConfirmDialog handleSubmit={onCategoryDelete}
                       title={"Delete Task Category"}
                       id={categoryIdToDelete}
                       isOpen={isOpenDeleteConfirm} setIsOpen={setIsOpenDeleteConfirm} />

        <ChangeCategoryOnTasksDialog
            title={"Change Category"}
            selectedDate={formatDate(selectedDate)!}
            taskList={tasksToChangeCategory}
            selectedId={categoryIdToDelete}
            isCancel={isCancel}
            setIsCancel={setIsCancel}
            isOpen={isOpenChangeTaskCategoryDialog} setIsOpen={setIsOpenChangeTaskCategoryDialog}
            handleRefreshTaskList={onCategoryDelete}
        />

        <EditCategoryDialog
            title={"Edit Category"}
            selectedDate={formatDate(selectedDate)!}
            name={categoryNameToChange}
            id={categoryIdToDelete}
            isOpen={categoryEditDialogOpen} setIsOpen={setCategoryEditDialogOpen} />

      </Fragment>
  );
}

export default TaskCategoriesConfig;