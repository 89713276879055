import {createSlice} from "@reduxjs/toolkit";
import {alertActions, AppDispatch, AppThunkDefault, AppThunkPromise, RootState} from "./storeIndex";
import {useSelector} from "react-redux";

export interface AlertState {
  showAlert: boolean,
  success: boolean,
  message: string,
}
const alertStateInit: AlertState = {
  showAlert: false,
  success: true,
  message: '',
}

export const alertSlice = createSlice({
  name: 'alertSlice',
  initialState: alertStateInit,
  reducers: {
    showAlert(state, action) {
      state.showAlert = true;
      state.success = action.payload.success;
      state.message = action.payload.message;
    },
    hideAlert(state) {
      state.showAlert = false;
    }
  }
});

export const displayAlert = (success: boolean, message: string):
    AppThunkPromise => async (dispatch: AppDispatch) => {
  //const alertState = useSelector((state: RootState) => state.alert);
  //if (!alertState.showAlert) {
    dispatch(alertActions.showAlert({
      success: success,
      message: message
    }));
  //}
}

