import React, {useEffect} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";


type Props = {
  handleSubmit: (id:string) => void,
  title: string,
  id: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void
}

const ConfirmDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  useEffect(() => {
    document.title = "Confirmation Dialog";
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      console.log('close now')
      dispatch(taskInfoActions.setIsModalOpen({value: true}));
    }
  }, [props.isOpen])

  const close = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    props.setIsOpen(false)
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={props.isOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          <Stack direction={'row'}>
            <WarningIcon color={"warning"}/>
            <Box sx={{ml:2}}>Are you sure? This action will be permanent.</Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Cancel
          </Button>
          <Button onClick={() => props.handleSubmit(props.id)}>Ok</Button>
        </DialogActions>
      </Dialog>
  );
}

export default ConfirmDialog;