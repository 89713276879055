import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import React, {ChangeEvent, Fragment, useEffect, useState} from "react";
import {TaskInfo} from "../../types/task/TaskTypes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {CheckCircleOutlined, FindInPage, NotInterestedOutlined, WarningAmberOutlined} from "@mui/icons-material";
import {currencyFormat} from "../../service/generic/DateHelper";
import Moment from "react-moment";
import {grey} from "@mui/material/colors";
import { SRLWrapper } from "simple-react-lightbox";
import moment from "moment";

type Props = {
  selectedTask: TaskInfo,
};

const TaskInfoAccordian: React.FC<Props> = (props) => {
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [task, setTask] = useState(props.selectedTask);

  useEffect(() => {
    setTask(props.selectedTask);
  }, [props.selectedTask])

  const handleAccordianChange = (panel: string) =>
      (event: ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
      };

  const formatPlaybooksUrl = (url:string) => {
    url = url
        .replace("\\", "/")
        .replace("//", "/");
    if (!url.includes("http://") && !url.includes("https://")) {
      url = url.includes("https:") ? url.replace("https:/", "https://") :
          url.replace("http:/", "http://");
    }
    return url;
  }

  return (
      <Fragment>
        <Accordion expanded={expanded === 'panel1'}
                   onChange={handleAccordianChange('panel1')}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
            <Typography >General Task Info</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div>
              <Grid container alignItems="center">
                {/*<Divider variant="middle" />*/}
                <Grid item xs={6}>
                  <Typography variant={'h6'} component={"div"}>Task name</Typography>
                </Grid>
                <Grid item xs>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      {task.taskName}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6} sx={{backgroundColor:grey[100], pl:1}}>
                  <Typography variant={'h6'} component={"div"}>Scheduled Date</Typography>
                </Grid>
                <Grid item xs sx={{backgroundColor:grey[100], pr:1, paddingY:'2px'}}>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      <Moment format={"dddd D of MMM YYYY"}>{task.scheduledDate!}</Moment>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant={'h6'} component={"div"}>Category</Typography>
                </Grid>
                <Grid item xs>
                  <Box textAlign={"right"}>
                    {task.categoryName}
                  </Box>
                </Grid>

                <Grid item xs={6} sx={{backgroundColor:grey[100], pl:1}}>
                  <Typography variant={'h6'} component={"div"}>Location</Typography>
                </Grid>
                <Grid item xs sx={{backgroundColor:grey[100], pr:1, paddingY:'2px'}}>
                  <Box textAlign={"right"}>
                    {task.locationNames}
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant={'h6'} component={"div"}>Status</Typography>
                </Grid>
                <Grid item xs>
                  <Box textAlign={"right"} color={task.completed ? 'forestgreen' : 'red'}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      {task.completed ? 'Completed' : 'Uncompleted'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6} sx={{backgroundColor:grey[100], pl:1}}>
                  <Typography variant={'h6'} component={"div"}>Workers</Typography>
                </Grid>
                <Grid item xs sx={{backgroundColor:grey[100], pr:1, paddingY:'2px'}}>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      {task.assignedUserDisplayNames &&
                          task.assignedUserDisplayNames.map((name, index) => (
                        <span>
                          {name + ((task.assignedUserDisplayNames.length - 1 !== index) ? ", " : "")}
                        </span>
                      ))}
                    </Typography>
                    { (!task.assignedUserDisplayNames || task.assignedUserDisplayNames.length === 0) &&
                      <Stack direction={'row'} spacing={2} justifyContent={'end'}>
                          <WarningAmberOutlined color={'warning'}/>
                          <div>
                              <Typography variant={'subtitle1'} component={"div"}>
                                  None Assigned
                              </Typography>
                          </div>
                      </Stack>
                    }
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant={'h6'} component={"div"}>Est. Completion Hours</Typography>
                </Grid>
                <Grid item xs>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      {task.estCompleteHours + ' Hrs'}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6} sx={{backgroundColor:grey[100], pl:1}}>
                  <Typography variant={'h6'} component={"div"}>Assignment Order</Typography>
                </Grid>
                <Grid item xs sx={{backgroundColor:grey[100], pr:1, paddingY:'2px'}}>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      {task.priority}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant={'h6'} component={"div"}>Deadline</Typography>
                </Grid>
                <Grid item xs>
                  <Box textAlign={"right"}>
                    <Typography variant={'subtitle1'} component={"div"}>
                      <Moment format={"hh:mm a"}>{task.deadlineTimeString}</Moment>
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Divider>
                    <Chip label="Application Log" />
                  </Divider>
                </Grid>
                <Grid item md={12} alignItems="center">
                  <Box sx={{mt:1}}>
                    { (!task.appLog || !task.appLog?.ApplicationAreaLog) &&
                        <p style={{textAlign:"center"}}>No Application Log Assigned</p>
                    }
                    {(task.appLog && task.appLog?.ApplicationAreaLog) &&
                      <Stack direction={"row"} spacing={3} justifyContent="center"
                             divider={<Divider orientation={"vertical"} flexItem/>}>
                          <div>{task.appLog?.ApplicationAreaLog}</div>
                          <div>{task.appLog?.ApplicationLogDate}</div>
                          <Tooltip title={'View/Print Playbooks Application Log'}>
                            {/*"https://covsys.net/Premium/ApplicationLog/PrintApplicationLog?PRM=tQHphtLCQZU="*/}
                              <IconButton
                                  onClick={() => window.open(formatPlaybooksUrl(task.appLog?.Url!), "_blank")}>
                                  <FindInPage/>
                              </IconButton>
                          </Tooltip>
                      </Stack>
                    }
                  </Box>
                </Grid>
                { (task.appLog && task.appLog.TotalCost) &&
                  <Grid item md={12} alignItems="center">
                    <Box sx={{mt: 1, textAlign:'center'}}>
                        <Grid container>
                          <Grid item sm={6}>
                            <Typography variant={'h6'} component={"div"} sx={{float:'left'}}>
                              {"product expense"}
                            </Typography>
                          </Grid>
                          <Grid item sm={6}>
                            <Typography variant={'h6'} component={"div"} sx={{float:'right'}}>
                              {currencyFormat(task.appLog.TotalCost)}
                            </Typography>
                          </Grid>
                        </Grid>
                    </Box>
                  </Grid>
                }

              </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'note'}
                   onChange={handleAccordianChange('note')}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header">
            <Typography >
              <Stack direction={"row"} spacing={1}>
                <Fragment>
                  { task.taskNotes.length > 0 && <CheckCircleOutlined/> }
                  { task.taskNotes.length === 0 && <NotInterestedOutlined/> }
                </Fragment>
                <div>Notes</div>
              </Stack>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {(task.taskNotes.length === 0) &&
                <p>There are no notes on this task</p>
              }
              {task.taskNotes}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'subtask'}
                   onChange={handleAccordianChange('subtask')}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header">
            <Typography >
              <Stack direction={"row"} spacing={1}>
                <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                  <div style={{border:'2px solid black', borderRadius:'50%',
                    backgroundColor: task.subTaskLabels ? 'cyan' : 'white'}}
                  >
                    <Box sx={{mx:1}}>
                      <b>{task.subTaskLabels ?
                          task.subTaskLabels.length : '0'}</b>
                    </Box>
                  </div>
                </Box>
                <div>SubTasks</div>
              </Stack>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(!task.subTaskLabels || task.subTaskLabels?.length === 0) &&
              <p>There are no subtasks on this task</p>
            }
            <Stack>
              { task.subTaskLabels && task.subTaskLabels.map((labelCheckbox, index) => (
                <FormControlLabel
                    label={labelCheckbox.label}
                    control={
                      <Checkbox
                          style={{color: task.completed ? (labelCheckbox.completed ? "green" : "red") : "lightgray"}}
                          disabled={true}
                          checked={labelCheckbox.completed}
                          indeterminate={labelCheckbox.touched}
                      />
                    }
                />
              ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'comments'}
                   onChange={handleAccordianChange('comments')}>
          <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4bh-content"
              id="panel4bh-header">
            <Typography >
              <Stack direction={"row"} spacing={1}>
                <Box sx={{display: 'flex', alignItems: 'flex-end'}}>
                  <div style={{border:'2px solid black', borderRadius:'50%',
                    backgroundColor: task.commentsList?.length ? 'greenyellow' : 'white'}}
                  >
                    <Box sx={{mx:1}}>
                      <b>{(task.commentsList && task.commentsList?.length > 0) ?
                          task.commentsList.length : '0'}</b>
                    </Box>
                  </div>
                </Box>
                <div>Comments</div>
              </Stack>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {(!task.commentsList || task.commentsList?.length === 0) &&
                <p>There are no comments yet</p>
              }
              { (task.commentsList && task.commentsList?.length > 0) &&
                <Fragment>
                  { task.commentsList?.map((comment, index) => (
                      <Box sx={{my:2}}>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                          { comment.authorName + " | " + moment(comment.commentDate).format("ddd D of MMM YYYY @ hh:MM A")
                          }
                        </Typography>
                        <p>{comment.commentString}</p>
                        { comment.imagePath &&
                        <SRLWrapper>
                            <a href={`${process.env.REACT_APP_CLIENT_IMAGES_URL_BASE}/${comment.imagePath}`}>
                              <img src={`${process.env.REACT_APP_CLIENT_IMAGES_URL_BASE}/${comment.imagePath}`}
                                   alt={'Commented Image'} style={{cursor: "pointer", maxHeight:"300px", maxWidth:"300px"}} />
                            </a>
                        </SRLWrapper>
                        }
                        {index < task.commentsList?.length - 1 &&
                        <Divider orientation={"horizontal"}/>
                        }
                      </Box>
                  ))}
                </Fragment>
              }
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Fragment>
  );
};

export default TaskInfoAccordian;