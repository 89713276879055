import {createSlice} from "@reduxjs/toolkit";
import {accountStateInit} from "../../types/account/AccountInits";

export const accountSlice = createSlice({
  name: 'account',
  initialState: accountStateInit,
  reducers: {
    updateUser(state, action) {
      state.userInfo = action.payload.userInfo;
    },
    setOrgMembers(state, action) {
      state.orgMembers = action.payload.orgMembers;
    },
    resetToInit(state) {
      console.log("reset to init in account slice");
      state = accountStateInit;
    }
  }
});