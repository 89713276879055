import {ApiErrorResponse} from "../../types/ApiErrorResponse";
import {UserInfo} from "../../types/account/AccountTypes";

export const loginService = {
  login,
  signupNewUser,
  checkAccessCodeValid
}

async function login(username: string, password: string) {
  const body = JSON.stringify({username:username, password:password});
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: body
  };

  return await fetch(`${process.env.REACT_APP_BACKEND_URL}/authenticate`, requestOptions)
      .then(handleApiError)
      .then(handleApiSuccess)
      .catch(e => {throw e});
}

//--------------------------------------------------------------

async function signupNewUser(user: UserInfo) {
  try {
    return await signupNewUserHttpCall(user);
  } catch (e) {
    throw e;
  }
}

function signupNewUserHttpCall(userObject: UserInfo) {
  const requestOptions = {
    method: 'POST',
    //mode: 'no-cors',
    body: JSON.stringify(userObject),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/createUser`, requestOptions)
      .then(handleApiError)
      .then(handleApiSuccess)
      .catch(e => {throw e});
}

//--------------------------------------------------------

async function checkAccessCodeValid(accessCode: string) {
  try {
    return await checkAccessCodeValidHttpCall(accessCode);
  } catch (e) {
    throw e;
  }
}

function checkAccessCodeValidHttpCall(accessCode: string) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/accessCode/isValid/${accessCode}`, requestOptions)
      .then(handleApiError)
      .then(handleAccessCodeCheckSuccess)
      .catch(e => {throw e});
}

async function handleAccessCodeCheckSuccess(responseAny: any) {
  if (responseAny.ok) {
    return true;
  }
}

//---------------------------------------------------------

async function handleApiSuccess(responseAny: any) {
  let response: ApiTokenResponse = await responseAny.json();
  const jwtString = JSON.stringify(response.jwttoken);
  localStorage.setItem('user', jwtString.replace(/['"]+/g, ''));
  return jwtString;
}

async function handleApiError(response: any) {
  if (response.status !== 200) {
    if (response.status === 404) {
      throw Error("There was an unexpected network failure");
    }

    let errorResponse: ApiErrorResponse = await response.json();
    if (errorResponse.status === 401) {
      console.log(401);
    }
    throw Error(errorResponse.message);
  }
  return response;
}

interface ApiTokenResponse extends Response {
  jwttoken: string
}