import {AccountInfo, AccountParent, OrgInfo} from "../../types/account/AccountTypes";
import {accountParentInit} from "../../types/account/AccountInits";


export const getAccountParentFromAccessCode = (accessCode: string, orgInfo: OrgInfo, accounts: AccountInfo[]) => {
  let genericAccount: AccountParent = accountParentInit;
  if (orgInfo.accessCode === accessCode) {
    genericAccount = orgInfo;
  } else {
    let accountInfo = accounts.find(acc => acc.accessCode === accessCode);
    if (accountInfo) {
      genericAccount = accountInfo;
    }
  }
  return genericAccount;
}