import React, {Fragment, useEffect, useState} from "react";
import {EmployeeListResponse, FullMonthResponse, scheduleInfoValidInit, TaskInfo} from "../../types/task/TaskTypes";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    InputLabel, LinearProgress,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack
} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import {TaskCompleteValidInit} from "../../types/task/TaskValids";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {MenuMultiSelectProps} from "../../types/generic/MaterialUISelect";
import {Role, UserInfo} from "../../types/account/AccountTypes";
import {SelectChangeEvent} from "@mui/material/Select";
import {WarningAmberOutlined} from "@mui/icons-material";
import ScheduleDialog from "./ScheduleDialog";
import {employeeListResponseInit} from "../../store/task/taskInfoSlice";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {determineIfTokenError} from "../../store/account/authSlice";
import {amber} from "@mui/material/colors";
import ValidButton from "../general/ValidButton";
import TaskPdfDialog from "./TaskPdfDialog";

type Props = {
    handleSubmit: (task:TaskInfo) => void,
    title: string,
    task: TaskInfo,
    isOpen: boolean,
    setIsOpen: (open: false) => void,
    setIsFormValid: (valid:boolean) => void,
    noScheduleNeeded?: boolean,
    getMonthlyTasks: (ac: AbortController) => void,
    getJobBoardUtil: () => void
}

const CompleteDialog: React.FC<Props> = (props) => {
    let dispatch = useAppThunkDispatch();
    const userRole = useSelector((state: RootState) => state.userInfo.userInfo.role);

    const taskListToChangeLocationGlobal = useSelector((state: RootState) => state.taskInfo.tasksToChangeLocationList);
    const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
    const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
    const accountId = useSelector((state: RootState) => state.orgInfo.orgInfo.accountId);
    const orgInfo = useSelector((state: RootState) => state.orgInfo);
    const orgMembers = useSelector((state: RootState) => state.account.orgMembers);
    const availableWorkersToAssignOnSelectedDay = useSelector((state: RootState) => state.taskInfo.availableWorkersToAssignOnSelectedDay);
    const [task, setTask] = useState({...props.task});
    const [completeFormTouched, setCompleteFormTouched] = useState<boolean>(false);
    const [taskCompleteValid, setTaskCompleteValid] = useState({...TaskCompleteValidInit});
    const [showWorkersLoading, setShowWorkersLoading] = useState(true);
    const [workersOnSchedule, setWorkersOnSchedule] = useState<UserInfo[] | null>(null);
    const locationIdToDeleteGlobal = useSelector((state: RootState) => state.taskInfo.locationIdToDelete);
    const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
    const [isTaskPdfDialogOpen, setIsTaskPdfDialogOpen] = useState<boolean>(false);

    //Only For Schedule Dialog
    const scheduleDayObjectResponse = useSelector((state: RootState) => state.schedule.scheduleDayObjectResponse);
    const scheduledWeekObjectResponseGlobal = useSelector((state: RootState) => state.schedule.scheduledWeekObjectResponse);
    const [openScheduleDialog, setOpenScheduleDialog] = React.useState(false);
    const [employeeListState, setEmployeeListState] = useState<EmployeeListResponse>(employeeListResponseInit);
    const [scheduleInfoStateValid, setScheduleInfoStateValid] = useState(scheduleInfoValidInit)

    useEffect(() => {
        document.title = "Complete Task Dialog";
    }, []);

    useEffect(() => {
        const ac = new AbortController();
        if (props.isOpen) {
            dispatch(taskInfoActions.setIsModalOpen({value: true}));
        }
        if (props.isOpen) {
            retrieveAvailableMembersToAssignOnSelectedDay(ac);

            setTask(props.task);

            let newValid = {...taskCompleteValid};
            newValid.assignees = props.task.assignedUserIds.length > 0;
            setTaskCompleteValid(newValid);
        }
        return () => {ac.abort()};
    }, [props.isOpen])

    useEffect(() => {
        const ac = new AbortController();
        setShowWorkersLoading(true)
        retrieveAvailableMembersToAssignOnSelectedDay(ac);
    }, [orgMembers, scheduledWeekObjectResponseGlobal])

    const retrieveAvailableMembersToAssignOnSelectedDay = (ac:AbortController) => {
        if (!props.noScheduleNeeded) {
            taskManagementService.retrieveAvailableMembersToAssignOnSelectedDay(formatDate(task.scheduledDate), [task.accessCode], ac)
                .then((workers: UserInfo[]) => {
                    let newEmpListState = {...employeeListState};
                    newEmpListState.employeeList = workers;
                    setEmployeeListState(newEmpListState);

                    setWorkersOnSchedule(workers);
                    dispatch(taskInfoActions.setAvailableWorkersToAssignOnSelectedDay({workers: workers}));
                }).catch(e => {
                dispatch(determineIfTokenError(e));
            }).finally(() => setShowWorkersLoading(false));
        }
    }

    const onChangeSubTask = (index:number) => {
        let newTaskState = {...task};
        let newLabels = [...newTaskState.subTaskLabels];
        let newSubTaskLabel = {...newLabels[index]};
        if (!newSubTaskLabel.touched) {
            newSubTaskLabel.touched = true;
        } else {
            newSubTaskLabel.completed = !newSubTaskLabel.completed;
        }
        newLabels[index] = newSubTaskLabel;
        newTaskState.subTaskLabels = newLabels;
        setTask(newTaskState);

        setCompleteFormTouched(true);
        taskCompleteValid.subTasks = newTaskState.subTaskLabels.filter(subTask => !subTask.touched).length === 0;
        setTaskCompleteValid(taskCompleteValid);
    }

    const onChangeCompletionHours = (value:string) => {
        let newTaskState = {...task};
        newTaskState.hoursSpentCompleting = value;
        setTask(newTaskState);

        setCompleteFormTouched(true);
        taskCompleteValid.hoursSpent = value.trim().length > 0 && Number(value) >= 0;
        setTaskCompleteValid(taskCompleteValid);
    }

    const onAssigneesChange = (event: SelectChangeEvent<typeof taskInfo.assignedUserDisplayNames>) => {
        const {target: {value}} = event;
        let names = typeof value === 'string' ? value.split(',') : value;
        let newTaskState = {...task};
        newTaskState.assignedUserDisplayNames = names;

        if (workersOnSchedule) {
            let userIds = newTaskState.assignedUserDisplayNames
                .map(name => {
                    return workersOnSchedule.filter(member =>
                        name.includes(member.firstName) && name.includes(member.lastName))[0];
                })
                .flatMap(member => member.userId);
            newTaskState.assignedUserIds = userIds;
            setTask({...newTaskState});
        }

        setCompleteFormTouched(true);
        taskCompleteValid.assignees = newTaskState.assignedUserIds.length > 0;
        setTaskCompleteValid(taskCompleteValid);
    }

    const isFormValid = () => {
        let valid = true;
        if (task.subTaskLabels && task.subTaskLabels.length > 0) {
            valid = valid && taskCompleteValid.subTasks;
        }
        valid = valid && taskCompleteValid.hoursSpent;
        valid = valid && taskCompleteValid.assignees;
        props.setIsFormValid(valid);
        return valid;
    }

    const handleEnterSubmit = () => {
        if (isFormValid()) {
            props.handleSubmit({...task});
            closeDialog();
            if(locationIdToDeleteGlobal !== ''){
                getUpdatedTaskLocations(locationIdToDeleteGlobal);
            }
        }
    }

    const getUpdatedTaskLocations = (locId:string) => {
        taskManagementService.getAllTasksWithAssignedLocation(allSelectedAccessCodes!, locId)
            .then(resp => {
                // set list to global state
                dispatch(taskInfoActions.setTasksToChangeLocationList({value:resp}));
            });
    }

    const closeDialog = () => {
        props.setIsOpen(false);
        setTaskCompleteValid({...TaskCompleteValidInit});
        setCompleteFormTouched(false);
        let newTaskState = {...task};
        newTaskState.assignedUserIds = [];
        newTaskState.assignedUserDisplayNames = [];
        newTaskState.hoursSpentCompleting = "";

        if (newTaskState.subTaskLabels && newTaskState.subTaskLabels.length > 0) {
            let newSubTasks = [...newTaskState.subTaskLabels];
            newTaskState.subTaskLabels = [];
            for (let subtask of newSubTasks) {
                let newSubtask = {...subtask};
                newSubtask.completed = false;
                newSubtask.touched = false;
                newTaskState.subTaskLabels.push(newSubtask);
            }
        }

        setTask(newTaskState);
    }

    const getFullMonthResponse = () => {
        taskManagementService.getFullMonthResponse(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!)
            .then((monthList: FullMonthResponse) => {
                dispatch(taskInfoActions.setFullMonthResponse({fullMonthResponse: monthList}));
            })
            .catch(e => dispatch(determineIfTokenError(e)))
            .finally(() => {});
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="sm"
            open={props.isOpen}
        >
            <DialogTitle>
                {props.title + ' : ' + task.taskName}
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{mb:2}}>
                    { true &&
                        <FormControl fullWidth>
                            <InputLabel id="assignees">Assignees</InputLabel>
                            <Select
                                labelId="assignees"
                                id="assigneesSelect"
                                multiple={true}
                                input={<OutlinedInput id="select-multiple-chip" label="assignees"/>}
                                value={task.assignedUserDisplayNames}
                                renderValue={(selected) => (
                                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value}/>
                                        ))}
                                    </Box>
                                )}
                                onChange={onAssigneesChange}
                                MenuProps={MenuMultiSelectProps}
                            >
                                {!showWorkersLoading && workersOnSchedule && workersOnSchedule?.map((member: UserInfo) => (
                                    <MenuItem
                                        key={member.userId}
                                        value={member.firstName + ' ' + member.lastName}
                                    >
                                        <Checkbox
                                            checked={task.assignedUserDisplayNames.indexOf(member.firstName + ' ' + member.lastName) > -1}/>
                                        <ListItemText primary={member.firstName + ' ' + member.lastName}/>
                                    </MenuItem>
                                ))}
                            </Select>
                            <Box sx={{ width: '100%' }}>
                                { showWorkersLoading && <LinearProgress />}
                            </Box>
                            <small style={{color: 'goldenrod'}}
                                   hidden={showWorkersLoading || (workersOnSchedule !== null &&
                                       workersOnSchedule?.length > 0) || task.assignedUserDisplayNames.length > 0}>
                                <WarningAmberOutlined/> No workers are scheduled. You will need to schedule
                                workers before assigning and completing tasks.
                            </small>
                        </FormControl>
                    }
                </Box>
                <Box sx={{mb:2}}>
                    <CustomTextField
                        valueString={task.hoursSpentCompleting}
                        label={"Hours Spent Completing"}
                        isValid={taskCompleteValid.hoursSpent || !completeFormTouched}
                        validationText={'Invalid Input for Hours Spent To Complete'}
                        onEnterKeyDownSubmitHandler={handleEnterSubmit}
                        onInputUpdate={onChangeCompletionHours}
                    />
                </Box>

                { task.subTaskLabels && task.subTaskLabels.length > 0 && task.subTaskLabels.map((labelCheckbox, index) => (
                    <Fragment key={'stlabel' + index}>
                        { index === 0 && <div>SubTasks</div> }
                        <Stack>
                            <FormControlLabel
                                label={labelCheckbox.label}
                                control={
                                    <Checkbox
                                        checked={labelCheckbox.completed}
                                        indeterminate={!labelCheckbox.touched}
                                        onChange={() => onChangeSubTask(index)}
                                    />
                                }
                            />
                        </Stack>
                        { index === task.subTaskLabels.length - 1 &&
                        <small hidden={taskCompleteValid.subTasks || !completeFormTouched}
                               style={{color:'red', marginTop:'2px'}}>
                            {"All subtasks must be specified as to whether they were addressed or not"}
                        </small>
                        }
                    </Fragment>
                ))}
            </DialogContent>
            <DialogActions>
                {userRole !== Role.ORG_WORKER && orgInfo.allSelectedAccessCodes?.length == 1 &&
                    <Button sx={{color: amber[600]}} variant={"text"}
                            onClick={() => setOpenScheduleDialog(true)}>
                      Schedule Worker
                    </Button>
                }
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button variant="text" onClick={() => setIsTaskPdfDialogOpen(true)}>
                        Attach PDF
                    </Button>
                </Box>
                <Button autoFocus variant={"text"}
                        onClick={closeDialog}>
                    Cancel
                </Button>
                <ValidButton text={'Complete'}
                    handleSubmit={handleEnterSubmit} isFormValid={isFormValid}
                />
            </DialogActions>

            <TaskPdfDialog
                accountId={accountId}
                taskId={task.assignedTaskId}
                isOpen={isTaskPdfDialogOpen}
                setIsOpen={setIsTaskPdfDialogOpen}
                setCompleteDialogOpen={props.setIsOpen}
                getMonthlyTasks={props.getMonthlyTasks}
                getJobBoardUtil={props.getJobBoardUtil}
                getFullMonthResponse={getFullMonthResponse}
            />

            <ScheduleDialog title={"Schedule Employee"}
                            scheduledObjects={scheduleDayObjectResponse}
                            selectedDate ={selectedDate}
                            taskDate={task.scheduledDate}
                            isOpen={openScheduleDialog} setIsOpen={setOpenScheduleDialog}
                            employeeList={employeeListState}
                            scheduleInfoValid={scheduleInfoStateValid}
                            setShowDependantParentLoading={setShowWorkersLoading}
                            accessCodeToUse={props.task.accessCode}
            />

        </Dialog>
    );
}

export default CompleteDialog;