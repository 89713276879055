import fileDownload from "js-file-download";
import {PlayGroundSafetyFormResponseUtil} from "../types/task/TaskTypes";

type Props = {
}

const usePdfDownload = () => {

    const downloadFile = async (pdf: PlayGroundSafetyFormResponseUtil) => {
        let fileUrl = `${process.env.REACT_APP_RESOURCES_URL_BASE}/pdfs/accountId/${pdf.accountId}/taskId/${pdf.taskId}/${pdf.imageName}`
        console.log(fileUrl)
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            await fileDownload(blob, 'downloadedFile.pdf');
        } catch (error) {
            console.error('There was an error downloading the file:', error);
        }
    }

    return {downloadFile};
}

export default usePdfDownload