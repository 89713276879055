import React, {Fragment, Suspense, useEffect, useState} from 'react';
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/registration/LoginPage";
import {Route, Switch, useHistory} from "react-router";
import {authActions, experienceActions, mapsActions, RootState, taskInfoActions} from "./store/storeIndex";
import {useDispatch, useSelector} from "react-redux";
import {isExpired} from "react-jwt";
import SignupPage from "./pages/registration/SignupPage";
import {QueryParamProvider} from 'use-query-params';
import SignupTypePromptPage from "./pages/registration/SignupTypePromptPage";
import SignupAccessCodePrompt from "./pages/registration/SignupAccessCodePrompt";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import LoadingSpinnerFull from "./component/general/LoadingSpinnerFull";
import {Redirect} from "react-router-dom";
import {CircularProgress, useMediaQuery} from "@mui/material";
import {logoutAndClearAllSlices} from "./store/account/authSlice";
import {useAppThunkDispatch} from "./store/storeHooks";
import {ErrorBoundary} from "react-error-boundary";
import ErrorFallback from "./component/general/error/ErrorFallback";
import {getWithExpiry, setWithExpiry} from "./component/general/error/InvalidChunkErrorHelper";
import {ParallaxProvider} from "react-scroll-parallax";
import SimpleReactLightbox from 'simple-react-lightbox';
import {useJsApiLoader} from "@react-google-maps/api";
import JobBoardTvPage from "./pages/JobBoardTvPage";
import {DARK_NAVY} from "./service/generic/ColorScheme";

function App() {
  let dispatch = useAppThunkDispatch();
  const history = useHistory();
  const isWindowLarge = useMediaQuery('(min-width:800px)');

  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);
  const isModalOpen = useSelector((state: RootState) => state.taskInfo.isModalOpen);
  const laborTutorial = useSelector((state: RootState) => state.experience.showLaborDashboardTutorial);

  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (!loggedIn) {
      let token = localStorage.getItem("user") as string;
      if (token !== null) {
        if (!isExpired(token)) {
          dispatch(authActions.loginSuccess(token));
        } else if (isExpired(token)) {
          dispatch(logoutAndClearAllSlices());
          history.push('/login');
          window.location.reload();
        }
      }
    }
    setShowLoading(false);
  },[loggedIn]);

  useEffect(() => {
    if (!isModalOpen) {
      console.log(1)
      dispatch(taskInfoActions.setShowSideMenu({show: isWindowLarge ? true : false}));
    }
  }, [isWindowLarge])


  /*google maps init*/
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDuauJHJ-JZF8XgxzmGNaHZ-wM6uP37wF4"
  });

  useEffect(() => {
    if (isLoaded) {
      dispatch(mapsActions.setIsLoaded({value:true}));
    }
  }, [isLoaded])

  return showLoading ? (<LoadingSpinnerFull showLoading={showLoading} />) : (
      <div style={{color:DARK_NAVY + " !important"}}>
        <QueryParamProvider ReactRouterRoute={Route}>
          <ErrorBoundary
              fallbackRender={ErrorFallback}
              onReset={() => {
                if (!getWithExpiry("chunk_failed")) {
                  setWithExpiry("chunk_failed", "true", 10000);
                  window.location.reload()
                }
              }}>
            <Suspense fallback={ <LoadingSpinnerFull showLoading={showLoading} /> }>
              <Switch>
                <ParallaxProvider>
                  <SimpleReactLightbox>
                    {loggedIn !== null && loggedIn !== undefined &&
                      <Fragment>
                        <Route exact path="/login">
                            <LoginPage/>
                        </Route>
                        <Route exact path="/signup/type">
                            <SignupTypePromptPage/>
                        </Route>
                        <Route exact path="/signup/accessCode">
                            <SignupAccessCodePrompt/>
                        </Route>
                        <Route exact path="/signup/create">
                            <SignupPage/>
                        </Route>


                        <Route path="/manage">
                            <ProtectedRoutes/>
                        </Route>

                        <Route exact path="/home">
                            <HomePage/>
                        </Route>

                        {window.location.pathname === '' || window.location.pathname === '/' &&
                        <Redirect exact path="" to="/home"/>
                        }

                        { !history.location.pathname.includes('labor') &&
                          window.location.pathname !== '/login' && window.location.pathname !== '/signup/type' &&
                          window.location.pathname !== '/signup/accessCode' && window.location.pathname !== '/signup/create' &&
                          window.location.pathname !== '/manage/user/account' &&
                            <Redirect path="*" to={'/home'}/>
                        }
                      </Fragment>
                    }
                  </SimpleReactLightbox>

                </ParallaxProvider>

              </Switch>
            </Suspense>
          </ErrorBoundary>
        </QueryParamProvider>
      </div>
  );
}

export default App;
