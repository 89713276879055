import React, {Fragment, useEffect} from "react";
import {ErrorBoundaryProps, ErrorBoundaryPropsWithFallback, FallbackProps} from "react-error-boundary";
import {getWithExpiry, setWithExpiry} from "./InvalidChunkErrorHelper";
import {Box} from "@mui/material";
import Navbar from "../../nav/Navbar";

const ErrorFallback:React.FC<FallbackProps> = (errorProps) => {

  /*useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    //if (errorProps?.onError().message && chunkFailedMessage.test(errorProps.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
      }
    //}
  }, [errorProps]);*/

  return (
      <Fragment>
        <Navbar children={<div></div>} />
        <Box sx={{textAlign:'center',mt:5}}>
          <p>Something went wrong.</p>
          {/*<pre>{error?.message}</pre>*/}
        </Box>
      </Fragment>
  );
}
export default ErrorFallback;