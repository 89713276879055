import {AccountState, OrgInfo, OrgInfoState} from "../../types/account/AccountTypes";
import {ApiErrorResponse} from "../../types/ApiErrorResponse";
import {httpCommonService} from "../generic/httpCommonService";

export const orgInfoService = {
  createOrg,
  updateOrgInfo,
  fetchOrgInfo,
  getAllAccountData,
  getOrgMembersData,
  getOrgMembersDataForSelectedAccounts
}

async function updateOrgInfo(org: OrgInfo) {
  try {
    let token = localStorage.getItem('user') as string;
    let response = await updateOrgInfoHttpCall(token, org);
    return response;
  } catch (e) {
    throw e;
  }
}

function updateOrgInfoHttpCall(token: string, orgInfo: OrgInfo): Promise<OrgInfo> {
  const requestOptions = {
    method: 'PUT',
    //mode: 'no-cors',
    body: JSON.stringify(orgInfo),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  //`${ENV.REACT_APP_BACKEND_URL}/api/updateUserInfo`
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/update`, requestOptions)
      .then(httpCommonService.handleApiError)
      .then(handleApiSuccess)
      .catch(e => {throw e});
}

async function handleApiSuccess(responseAny: any) {
  let response: OrgInfo = await responseAny.json();
  return response;
}

//------------------------------------------------------------------------

async function createOrg(org: OrgInfo) {
  try {
    let token = localStorage.getItem('user') as string;
    let response = await createOrgHttpCall(token, org);
    return response;
  } catch (e) {
    throw e;
  }
}

function createOrgHttpCall(token: string, org: OrgInfo): Promise<OrgInfo> {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(org),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/create`, requestOptions)
      .then(httpCommonService.handleApiError)
      .then(handleApiSuccess)
      .catch(e => {throw e});
}

//--------------------------------------------------------------------------

async function fetchOrgInfo() {
  try {
    let token = localStorage.getItem('user') as string;
    let response = await fetchOrgInfoHttpCall(token);
    return response;
  } catch (e) {
    throw e;
  }
}

function fetchOrgInfoHttpCall(token: string): Promise<OrgInfo> {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };

  return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/retrieveOrg`, requestOptions)
      .then(httpCommonService.handleApiError)
      .then(handleApiSuccess)
      .catch(e => {throw e});
}

//---------------------------------------------------------------------------------

function getOrgMembersData(): Promise<AccountState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/retrieveMembers`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then((responseAny: any) => {return responseAny.json()})
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

function getOrgMembersDataForSelectedAccounts(accessCodes:string[]): Promise<AccountState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/org/retrieveMembers/${accessCodes.toString()}`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then((responseAny: any) => {return responseAny.json()})
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

async function handleMembersApiSuccess(responseAny: any) {
  let response: AccountState = await responseAny.json();
  return response;
}

//------------------------------------------------------------------------------------

function getAllAccountData(): Promise<OrgInfoState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/account/retrieveAllAccountData`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleAllAccountInfoSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

async function handleAllAccountInfoSuccess(responseAny: any) {
  let response: OrgInfoState = await responseAny.json();
  return response;
}