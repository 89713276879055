import {Backdrop, Box, CircularProgress, Fade} from "@mui/material";
import React, {Fragment} from "react";
import {LIME_GREEN} from "../../service/generic/ColorScheme";

type Props = {
  showLoading: boolean
}

const LoadingSpinnerContainer: React.FC<Props> = (props) => {

  return (
      <Fragment>
        { props.showLoading &&
          <Box sx={{display: 'flex', justifyContent: "center"}}>
            <CircularProgress sx={{color:LIME_GREEN}} />
          </Box>
        }
      </Fragment>
  );
}

export default LoadingSpinnerContainer;