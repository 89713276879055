import React, {useEffect, useRef, useState} from "react";
import {experienceActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {useSelector} from "react-redux";
import {
  AppBar,
  Badge,
  Box,
  Button, createTheme,
  Divider,
  IconButton,
  Menu,
  MenuItem, Slide,
  Stack,
  Toolbar,
  Typography, useMediaQuery, useScrollTrigger
} from "@mui/material";
import {useHistory} from "react-router";
import {AccountCircle, School} from "@mui/icons-material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import {notificationService} from "../../service/notificationService";
import NotificationInboxDialog from "../notification/NotificationInboxDialog";
import {NotificationState, updateNotificationGlobalState} from "../../store/notificationSlice";
import {determineIfTokenError, logoutAndClearAllSlices} from "../../store/account/authSlice";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import {useAppThunkDispatch} from "../../store/storeHooks";
import {displayAlert} from "../../store/alertSlice";
import {ThemeProvider} from "@mui/styles";
import {GridMenuIcon} from "@mui/x-data-grid";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {useTheme} from "@mui/material/styles";

/*declare module '@mui/material/styles' {
  interface Theme {
    palette: {
      mode: string;
      primary: {
        main: string;
      };
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    palette?: {
      mode?: string;
      primary?: {
        main?: string;
      };
    };
  }
}*/

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children: React.ReactElement;
}

const Navbar: React.FC<Props> = (props) => {
  const history = useHistory();
  let dispatch = useAppThunkDispatch();
  const isWindowLarge = useMediaQuery('(min-width:800px)');
  const theme = useTheme()
  const isWindowSmall = useMediaQuery(theme.breakpoints.down('sm'), {noSsr: true});

  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);
  const notificationsUnseen = useSelector((state: RootState) => state.notifications.unseen);
  const isOrgAdminRegistration = useSelector((state: RootState) => state.experience.isOrgAdminRegistration);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [alertsOpen, setAlertsOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [hidePageContent, setHidePageContent] = useState<boolean>(false);

  useEffect(() => {
    if (loggedIn) {
      let timeout: NodeJS.Timeout;
      timeout = setTimeout(() => {
        getNotifications();
        console.log("get new alerts");
      }, 180000); // 3 min
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  useEffect(() => {
    if (loggedIn) {
      getNotifications();
    }
  }, [loggedIn]);

  useEffect(() => {
    console.log("hide page content");
    setHidePageContent(!isWindowLarge && showSideMenu);
  }, [isWindowLarge, showSideMenu])

  const getNotifications = () => {
    notificationService.getNotifications()
        .then((inbox: NotificationState) => {
          dispatch(updateNotificationGlobalState(inbox));
        })
        .catch(e => dispatch(determineIfTokenError(e)));
  }

  const toggleSideMenu = () => {
    console.log(2)
    dispatch(taskInfoActions.setShowSideMenu({show:!showSideMenu}));
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    dispatch(logoutAndClearAllSlices()).then(() => {
      if (userInfo.isAdmin) {
        dispatch(displayAlert(true, 'ATTENTION ' + userInfo.firstName +
            ': You need to refresh the page to reset data if you plan to log into another account. If not you could see bad data.'));
      } else {
        dispatch(displayAlert(true, 'logged out. See you later'));
      }
    });
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

  function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
    );
  }

  return (
      <Box sx={{ flexGrow: 1 }}>
        {/*<ThemeProvider theme={darkTheme}>
          <HideOnScroll {...props} >*/}
        {!hidePageContent &&
          <AppBar position="static" style={{zIndex: 10000, backgroundColor:DARK_NAVY}} enableColorOnDark={false}>
              <Toolbar>
                {/*<IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton>*/}

                {(window.location.pathname.includes('/manage')) &&
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    sx={{mr: isWindowSmall ? 0 : 2}}
                    onClick={toggleSideMenu}
                >
                    <GridMenuIcon/>
                </IconButton>
                }

                  <Typography variant="h6" component="div" sx={{flexGrow: 1, cursor: 'pointer'}}>
                      <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-light.png`}
                           style={{width:'170px', height: '50px', marginTop:'10px', marginLeft:'-20px'}}
                           alt={'Logo Image'}
                           onClick={() => history.push('/home')}
                      />
                  </Typography>


                {!loggedIn && (
                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem/>}
                        spacing={2}
                    >
                      {/*<Button variant="text" style={{color: 'whitesmoke'}}
                              onClick={() => history.push('/signup/type')}>Signup</Button>*/}
                      <Button variant="text" style={{color: 'whitesmoke'}}
                              onClick={() => history.push('/login')}>Login</Button>
                    </Stack>
                )}

                {loggedIn && (
                    <div>
                      {((orgInfo.orgInfo && orgInfo.orgInfo.accessCode || true) && !isOrgAdminRegistration) && (
                          <Button
                              size={isWindowSmall ? 'small' : 'large'}
                              aria-label="tasks"
                              aria-controls="menu"
                              aria-haspopup="true"
                              onClick={() => history.push('/manage/labor')}
                              variant={'text'}
                              sx={{color:LIME_GREEN}}
                          >
                            {/*<Badge badgeContent={notificationsUnseen} color="error"
                                   invisible={notificationsUnseen === 0} max={99}>*/}
                            Labor
                            {/*</Badge>*/}
                          </Button>
                      )}
                      <IconButton
                          size={isWindowSmall ? 'small' : 'large'}
                          aria-label="account of current user"
                          aria-controls="menu"
                          aria-haspopup="true"
                          onClick={() => setAlertsOpen(true)}
                          color="inherit"
                          sx={{mr:1}}
                      >
                        <Badge badgeContent={notificationsUnseen} color="error"
                               invisible={notificationsUnseen === 0} max={99}>
                          <NotificationsIcon sx={{color:'white'}}/>
                        </Badge>
                      </IconButton>
                      <IconButton
                          size={isWindowSmall ? 'small' : 'large'}
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          color="inherit"
                      >
                        <AccountCircle/>
                      </IconButton>
                      <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                      >
                        <MenuItem onClick={() => {
                          handleClose();
                          history.push('/manage/user/account')
                        }}>
                          <PersonOutlineIcon sx={{mr: 2}}/>
                          My Account
                        </MenuItem>
                        {/*{checkUserNotOrdinaryWorker(userInfo) &&
                        <MenuItem onClick={() => {
                          dispatch(experienceActions.setShowLaborDashboardTutorial({value: true}));
                          handleClose();
                          history.push('/manage/labor')
                        }}>
                            <School sx={{mr: 2}}/>
                            Labor Tutorial
                        </MenuItem>
                        }*/}
                        <MenuItem onClick={logoutHandler}>
                          <LogoutIcon sx={{mr: 2}}/>
                          Logout
                        </MenuItem>
                      </Menu>

                      <NotificationInboxDialog
                          alertsOpen={alertsOpen}
                          setAlertsOpen={setAlertsOpen}/>
                    </div>
                )}
              </Toolbar>
          </AppBar>
        }
          {/*</HideOnScroll>
        </ThemeProvider>*/}
      </Box>
  );
}

export default Navbar;