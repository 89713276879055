import {Button} from "@mui/material";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";

type Props = {
  text:string,
  handleSubmit: () => void,
  width?:string,
  color?:string,
  textColor?:string,
  borderSquare?:boolean,
  sx?: {},
  height?: 'short' | 'tall'
}

const ValidButton = (props: Props) => {

  return (
      <Button type={'button'} variant={"contained"}
              size="large"
              onClick={props.handleSubmit}
              style={{
                width: props.width ? props.width : 'inherit',
                backgroundColor: props.color ? props.color : LIME_GREEN,
                color: props.textColor ? props.textColor : DARK_NAVY,
                fontWeight:"bold",
                borderColor: "none",
                borderRadius: props.borderSquare ? '0' : "inherit"
              }}
              sx={{
                ...props.sx,
                py: (props.height ? (props.height === 'tall' ?  2 : 1) : 2)
              }}
      >
        {props.text}
      </Button>
  );
}

export default ValidButton;