import React, {Fragment, useEffect, useState} from "react";
import {
    Alert,
    Button,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem, Stack, Tooltip
} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import {RootState, userInfoActions} from "../../store/storeIndex";
import {Role, SecurityCode, timezones, UserInfo} from "../../types/account/AccountTypes";
import {userInfoValidInit} from "../../types/account/AccountValids";
import {userInfoService} from "../../service/account/userInfoService";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import Typography from "@mui/material/Typography";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import EditIcon from "@mui/icons-material/Edit";
import {ArrowForward, CancelOutlined, Check} from "@mui/icons-material";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {blue, green, grey} from "@mui/material/colors";
import PasswordResetForm from "./PasswordResetForm";
import {DARK_NAVY, GHOST_WHITE, LIME_GREEN} from "../../service/generic/ColorScheme";
import {LoadingButton} from "@mui/lab";


const UserAccountInfo: React.FC = () => {
    let dispatch = useAppThunkDispatch();
    let history = useHistory();

    const userInfoGlobal = useSelector((state: RootState) => state.userInfo.userInfo);
    const orgInfo = useSelector((state: RootState) => state.orgInfo);
    const [tabSelected, setTabSelected] = useState("userInfo");
    const [showLoading, setShowLoading] = useState(false);
    const [showEditState, setShowEditState] = useState<boolean>(false);
    const [userInfoState, setUserInfoState] = useState<UserInfo>(userInfoGlobal);
    const [userInfoStateValid, setUserInfoStateValid] = useState(userInfoValidInit);
    const [userFormTouched, setUserFormTouched] = useState<boolean>(false);
    const [selectedMonth, setSelectedMonth] = React.useState(userInfoState.address.state);

    useEffect(() => {
        document.title = "User Account Page";
    }, []);

    useEffect(() => {
        setUserInfoState(userInfoGlobal);
    }, [userInfoGlobal])

    const editFunction = () => {
        if (showEditState) {
            setUserInfoState(userInfoGlobal);
            setUserFormTouched(false);
        }
        let valid = {...userInfoStateValid};
        valid.firstName = userInfoGlobal.firstName?.trim().length > 1;
        valid.lastName = userInfoGlobal.lastName?.trim().length > 1;
        valid.street = userInfoGlobal.address.street?.trim().length > 1;
        valid.postalCode = userInfoGlobal.address.postalCode?.trim().length === 5 &&
            !isNaN(Number(userInfoGlobal.address.postalCode));
        valid.city = userInfoGlobal.address.city?.trim().length > 1;
        valid.state = userInfoGlobal.address.state?.trim().length > 1;
        valid.phoneNumber = userInfoGlobal.phoneNumber?.trim().length === 10 && !isNaN(Number(userInfoGlobal.phoneNumber));
        setUserInfoStateValid(valid);
        setShowEditState(!showEditState);
    }

    const updateInfoHandler = () => {
        if (isUserFormValid()) {
            setShowLoading(true);
            userInfoService.updateUserInfo(userInfoState).then(userInfo => {
                setUserInfoState(userInfo);
                dispatch(userInfoActions.updateSuccess({userInfo: userInfo}));
                editFunction();
            }).catch(e => {
                dispatch(determineIfTokenError(e));
            }).finally(() => setShowLoading(false));
        }
    }

    const onChangeFirstName = (nameVal:string) => {
        setUserFormTouched(true);
        userInfoStateValid.firstName = nameVal.trim().length > 1;
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        newUserState.firstName = nameVal;
        setUserInfoState(newUserState);
    }

    const onChangeLastName = (nameVal:string) => {
        setUserFormTouched(true);
        userInfoStateValid.lastName = nameVal.trim().length > 1;
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        newUserState.lastName = nameVal;
        setUserInfoState(newUserState);
    }

    const onChangeCity = (cityValue:string) => {
        setUserFormTouched(true);
        userInfoStateValid.city = cityValue.trim().length > 1;
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        let address = {...userInfoState.address};
        address.city = cityValue;
        newUserState.address = address;
        setUserInfoState(newUserState);
    }

    const onChangeStreet = (streetValue:string) => {
        setUserFormTouched(true);
        userInfoStateValid.street = streetValue.trim().length > 1;
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        let address = {...userInfoState.address};
        address.street = streetValue;
        newUserState.address = address;
        setUserInfoState(newUserState);
    }

    const onChangeState = (stateValue:string) => {
        setUserFormTouched(true);
        userInfoStateValid.state = stateValue.trim().length > 1;
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        let address = {...userInfoState.address};
        address.state = stateValue;
        newUserState.address = address;
        setUserInfoState(newUserState);
    }

    const onChangePostalCode = (postalCodeValue:string) => {
        setUserFormTouched(true);
        userInfoStateValid.postalCode = postalCodeValue.trim().length === 5 && !isNaN(Number(postalCodeValue));
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        let address = {...userInfoState.address};
        address.postalCode = postalCodeValue;
        newUserState.address = address;
        setUserInfoState(newUserState);
    }

    const onChangePhoneNumber = (pNumberValue:string) => {
        setUserFormTouched(true);
        userInfoStateValid.phoneNumber = pNumberValue.trim().length === 10 && !isNaN(Number(pNumberValue));
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        newUserState.phoneNumber = pNumberValue;
        setUserInfoState(newUserState);
    }

    const handleMonthFilterChange = (val: string) => {
        setUserFormTouched(true);
        userInfoStateValid.state = val !="State..";
        setUserInfoStateValid(userInfoStateValid);
        let newUserState = {...userInfoState};
        let address = {...userInfoState.address};
        address.state = val;
        newUserState.address = address;
        setUserInfoState(newUserState);
        setSelectedMonth(val);
    };

    const months = ['Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
        'Florida', 'Georgia', 'Hawaii', 'Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana',
        'Maine','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada',
        'New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Ohio','Oklahoma','Oregon','Pennsylvania',
        'Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virginia','Washington','West Virginia',
        'Wisconsin','Wyoming'];

    const isUserFormValid = () => {
        return userFormTouched && userInfoStateValid.firstName && userInfoStateValid.lastName &&
            userInfoStateValid.phoneNumber && userInfoStateValid.city &&
            userInfoStateValid.state && userInfoStateValid.street && userInfoStateValid.postalCode;
    }

    return (
        <Fragment>
            <Card sx={{mb:2, backgroundColor:GHOST_WHITE}}>
                <CardHeader
                    title={
                        <Typography variant="h5" component="div" style={{color:DARK_NAVY, fontWeight:"bold"}}>
                            User Info
                            <hr/>
                        </Typography>
                    }
                    action = {
                        <Fragment>
                            {!showLoading &&
                            <IconButton aria-label="edit" onClick={editFunction}>
                                {!showEditState && <EditIcon style={{color:DARK_NAVY}}/>}
                                {showEditState && <CancelOutlined style={{color:DARK_NAVY}}/>}
                            </IconButton>
                            }
                        </Fragment>
                    }
                />
                <CardContent>
                    { !showLoading ? (
                        <FormControl component="fieldset">
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        valueString={userInfoState.firstName}
                                        isValid={userInfoStateValid.firstName || !userFormTouched}
                                        label={"First Name"}
                                        validationText={'Invalid First Name'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangeFirstName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        valueString={userInfoState.lastName}
                                        isValid={userInfoStateValid.lastName || !userFormTouched}
                                        label={"Last Name"}
                                        validationText={'Invalid Last Name'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangeLastName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={userInfoGlobal.role !== Role.ADMIN || !showEditState}
                                        valueString={userInfoState.username}
                                        label={"Email"}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={(userName) => {
                                            let newUserInfo = {...userInfoState};
                                            newUserInfo.username = userName;
                                            setUserInfoState(newUserInfo);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        valueString={userInfoState.phoneNumber}
                                        isValid={userInfoStateValid.phoneNumber || !userFormTouched}
                                        label={"Phone #"}
                                        validationText={'Invalid Phone Number'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangePhoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        valueString={userInfoState.address.city}
                                        isValid={userInfoStateValid.city || !userFormTouched}
                                        label={"City"}
                                        validationText={'Invalid City'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangeCity}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="stateLabel">State</InputLabel>
                                        <Select
                                            labelId="stateLabel"
                                            id="stateSelect"
                                            style={{width:'100%'}}
                                            disabled={!showEditState}
                                            value={selectedMonth}
                                            label="State"
                                            onChange={(event: SelectChangeEvent) => handleMonthFilterChange(event.target.value)}
                                            sx={{float:"right"}}
                                        >
                                            <MenuItem value={"State.."}>State..</MenuItem>
                                            { months.map(month => (
                                                <MenuItem value={month} key={month}>{month}</MenuItem>
                                            ))}
                                        </Select>
                                        <small style={{color:'red', marginTop:0}} hidden={userInfoStateValid.state || !userFormTouched}>
                                            Invalid State
                                        </small>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        valueString={userInfoState.address.street}
                                        isValid={userInfoStateValid.street || !userFormTouched}
                                        label={"Street"}
                                        validationText={'Invalid Street'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangeStreet}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomTextField
                                        disabled={!showEditState}
                                        isValid={userInfoStateValid.postalCode || !userFormTouched}
                                        valueString={userInfoState.address.postalCode}
                                        label={"Postal Code"}
                                        validationText={'Invalid Postal Code'}
                                        onEnterKeyDownSubmitHandler={updateInfoHandler}
                                        onInputUpdate={onChangePostalCode}
                                    />
                                </Grid>
                            </Grid>
                            { showEditState &&
                                <Button onClick={updateInfoHandler}
                                        variant={isUserFormValid() ? "contained" : "outlined"}
                                        style={{
                                            cursor: !(isUserFormValid()) ? 'not-allowed' : 'pointer',
                                            width: '100%',
                                            marginTop: '15px',
                                            color:DARK_NAVY,
                                            fontWeight:"bold",
                                            backgroundColor: isUserFormValid() ? LIME_GREEN : grey[200],
                                            borderColor: isUserFormValid() ? "none" : LIME_GREEN,
                                        }}>
                                    Submit
                                </Button>
                            }
                        </FormControl>
                    ) : (
                        <LoadingSpinnerContainer showLoading={showLoading}/>
                    )}
                </CardContent>
            </Card>

            <Grid container>
                <Grid item xs={12} sm={12} md={6}>
                    <Card sx={{width:'100%', backgroundColor:GHOST_WHITE}}>
                        <CardHeader title={'Reset Password'} style={{color:DARK_NAVY}}>
                        </CardHeader>
                        <CardContent>
                            <PasswordResetForm isLoggedIn={true} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default UserAccountInfo;