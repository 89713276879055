import React, {Fragment, useState} from "react";
import Navbar from "../../component/nav/Navbar";
import {Box, Button, Container, FormControl, Stack} from "@mui/material";
import CustomTextField from "../../component/general/CustomTextField";
import {RootState, userInfoActions} from "../../store/storeIndex";
import AlertToast from "../../component/general/AlertToast";
import LoadingSpinnerFull from "../../component/general/LoadingSpinnerFull";
import {useDispatch, useSelector} from "react-redux";
import {loginService} from "../../service/account/loginService";
import {displayAlert} from "../../store/alertSlice";
import {useHistory} from "react-router";
import {Redirect} from "react-router-dom";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";


const SignupAccessCodePrompt: React.FC = () => {
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);
  let dispatch = useAppThunkDispatch();
  let history = useHistory();

  const [showLoading, setShowLoading] = useState(false);
  const [accessCode, setAccessCode] = useState<string>('');
  const [formTouched, setFormTouched] = useState<boolean>(false);

  const handleSubmit = () => {
    let timeout: NodeJS.Timeout;
    setShowLoading(true);
    timeout = setTimeout(() => {
      loginService.checkAccessCodeValid(accessCode)
          .then(() => {
            dispatch(userInfoActions.updateAccessCodeOnSignup({accessCode:accessCode}));
            history.push('/signup/create');
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          });
      setShowLoading(false);
    }, 700);

    /*return () => {
      clearTimeout(timeout);
    };*/
  }

  const onChangeAccessCode = (ac:string) => {
    setAccessCode(ac);
    setFormTouched(true);
  }

  /*if (loggedIn) {
    return <Redirect to={'/login'} />
  }*/

  return (
      <Fragment>
        <Navbar children={<div></div>}/>
        <Container>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 500}}>
            <Stack spacing={2}>
              <h2 style={{textAlign:'center'}}>Enter your organization's access code</h2>
              <FormControl component="fieldset">
                <CustomTextField
                    valueString={accessCode}
                    label={"Access Code"}
                    isValid={accessCode.length > 0 || !formTouched}
                    validationText={'must be longer'}
                    onEnterKeyDownSubmitHandler={handleSubmit}
                    onInputUpdate={setAccessCode}
                />
                <br/>
                <Button type={'button'} variant={accessCode.length > 0 ? "contained" : "outlined"}
                        size="large" style={{cursor: !(accessCode.length > 0) ? 'not-allowed' : 'pointer'}}
                        onClick={handleSubmit}>
                  Continue
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'start'}}>
                  <Button type={'button'} variant={"text"} style={{textAlign:'end'}}
                          onClick={() => history.goBack()}>
                    Back
                  </Button>
                </Box>
              </FormControl>
            </Stack>
          </Box>

          <AlertToast/>
          <LoadingSpinnerFull showLoading={showLoading} />
        </Container>
      </Fragment>
  );
}

export default SignupAccessCodePrompt;