import React from "react";
import {AppBar, Box, Button, Divider, Grid, IconButton, Stack, Toolbar, useMediaQuery} from "@mui/material";
import {grey, lightBlue, red} from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import {Twitter, YouTube} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {useHistory} from "react-router";
import {DARK_NAVY, GREEN, LIGHT_NAVY} from "../../service/generic/ColorScheme";
import LeslieButton from "./LeslieButton";

type Props = {
  noSideMenu?: boolean
}

const Footer:React.FC<Props> = (props) => {
  const history = useHistory();
  const isWindowLarge = useMediaQuery('(min-width:800px)');
  //const propsRotate:ParallaxProps = { speed: 0, rotate: [0, 720] };
  //const { ref:logoRefRotate } = useParallax<HTMLImageElement>(propsRotate);
  const loggedIn = useSelector((state: RootState) => state.auth.loggedIn);

  const showSideMenu = useSelector((state: RootState) => state.taskInfo.showSideMenu);

  return (
      <Box sx={{marginTop:'0'}}>
        { !window.location.pathname.includes('manage') &&
          <Box sx={{height:'300px', py:10, px:5, justifyContent:'center',
                backgroundColor:LIGHT_NAVY, display:'flex', alignItems:'center'}}>
            <Box sx={{display:'block', textAlign:'center'}}>
              <Typography sx={{mb:4, fontSize:'xx-large', color:'white'}} variant={'h5'}>
                Don't Wait! Take control of your daily operation and maximize efficiency now.
              </Typography>
              {/*<LeslieButton text={'Register Today'}
                handleSubmit={() => loggedIn ? {} : history.push('/signup/type')}
                sx={{cursor:loggedIn ? 'not-allowed' : 'pointer'}}
              />*/}
            </Box>
          </Box>
        }
        <AppBar position="static"
                sx={{top: 'auto', bottom: 0, backgroundColor:DARK_NAVY, borderTop:'5px solid ' + GREEN}}>
          <Toolbar>
            <Box sx={{marginLeft: (showSideMenu && !props.noSideMenu) ? '240px' : '0', pt: 5, pb: 2, width: '100%'}}>

              { window.location.pathname.includes('manage') ? (
                <Grid container sx={{textAlign:'center', mb:5}}>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Typography sx={{mb:2}} variant={'h6'}>
                      FOLLOW US
                    </Typography>
                    <Stack direction={'row'} spacing={3} sx={{mb:5, display:'flex', justifyContent:'center'}}>
                      <IconButton color={"primary"} sx={{backgroundColor:"white", color:DARK_NAVY}} onClick={() => (null)}>
                        <Twitter/>
                      </IconButton>
                      <IconButton sx={{backgroundColor:"white", color:DARK_NAVY}} onClick={() => (null)}>
                        <YouTube/>
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>

                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Grid container sx={{textAlign:'center', mb:5}}>
                      {/*<Grid item lg={12} md={12} sm={12} xs={12} sx={{my:3}}>
                        <h2>Apperform</h2>
                      </Grid>*/}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Stack direction={"column"} spacing={0} sx={{mb:3}}>
                          <Typography sx={{mb:2}} variant={'h6'}>
                            LEGAL
                          </Typography>
                          <Button variant={"text"} sx={{color:'white'}}>
                            <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/terms.pdf`} target='_blank'
                               rel='noopener noreferrer' style={{textDecoration:"none", color:'white'}}>Terms of Use</a>
                          </Button>
                          <Button variant={"text"} sx={{color:'white'}}>
                            <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/privacy.pdf`} target='_blank'
                               rel='noopener noreferrer' style={{textDecoration:"none", color:'white'}}>Privacy</a>
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Stack direction={"column"} spacing={0}>
                          <Typography sx={{mb:2}} variant={'h6'}>
                            LINKS
                          </Typography>
                          <Button variant={"text"} sx={{color:'white'}} onClick={() => history.push('/manage/labor')}>
                            Labor Dashboard
                          </Button>
                          <Button variant={"text"} sx={{color:'white'}} onClick={() => history.push('/manage/user/account')}>
                            Account Management
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid container sx={{textAlign:'center', mb:5}}>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Box sx={{display: 'flex', justifyContent: 'center', mb: 3}}>
                      <img src={`${process.env.REACT_APP_RESOURCES_URL_BASE}/images/apperform-logo-light.png`}
                          /*ref={logoRefRotate}*/
                           alt={'Logo Image'}
                           style={{width: '80%', height: '25%'}}/>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={12} sm={12} xs={12}>
                    <Typography sx={{mb:2}} variant={'h6'}>
                      FOLLOW US
                    </Typography>
                    <Stack direction={'row'} spacing={3} sx={{mb:5, display:'flex', justifyContent:'center'}}>
                      <IconButton color={"primary"} sx={{backgroundColor:"white", color:DARK_NAVY}} onClick={() => (null)}>
                        <Twitter/>
                      </IconButton>
                      <IconButton sx={{backgroundColor:"white", color:DARK_NAVY}} onClick={() => (null)}>
                        <YouTube/>
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item lg={6} md={12} sm={12} xs={12}>
                    <Grid container sx={{textAlign:'center', mb:5}}>
                      {/*<Grid item lg={12} md={12} sm={12} xs={12} sx={{my:3}}>
                  <h2>Apperform</h2>
                </Grid>*/}
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Stack direction={"column"} spacing={0} sx={{mb:3}}>
                          <Typography sx={{mb:2}} variant={'h6'}>
                            LEGAL
                          </Typography>
                          <Button variant={"text"} sx={{color:'white'}}>
                            <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/terms.pdf`} target='_blank'
                               rel='noopener noreferrer' style={{textDecoration:"none", color:'white'}}>Terms of Use</a>
                          </Button>
                          <Button variant={"text"} sx={{color:'white'}}>
                            <a href={`${process.env.REACT_APP_RESOURCES_URL_BASE}/privacy.pdf`} target='_blank'
                               rel='noopener noreferrer' style={{textDecoration:"none", color:'white'}}>Privacy</a>
                          </Button>
                        </Stack>
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Stack direction={"column"} spacing={0}>
                          <Typography sx={{mb:2}} variant={'h6'}>
                            LINKS
                          </Typography>
                          <Button variant={"text"} sx={{color:'white'}} onClick={() => history.push('/manage/labor')}>
                            Labor Dashboard
                          </Button>
                          <Button variant={"text"} sx={{color:'white'}} onClick={() => history.push('/manage/user/account')}>
                            Account Management
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Divider sx={{width: '100%', mb: 3, mt:5, backgroundColor:grey[500]}}/>
              <Typography sx={{textAlign:'center'}}>
                © 2022 Copyright: Apperform LLC. All Rights Reserved.
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
  );
}

export default Footer;