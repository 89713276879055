import {createSlice} from "@reduxjs/toolkit";
import {googleMapsStateinit} from "../types/generic/mapsTypes";


export const mapsSlice = createSlice({
  name: 'mapsSlice',
  initialState: googleMapsStateinit,
  reducers: {
    setIsLoaded(state, action) {
      state.isLoaded = action.payload.value;
    },
  }
});