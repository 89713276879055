import {TableOptions} from "../../component/general/table/CustomTable";

export interface OrgInfoState {
  orgInfo: OrgInfo,
  orgAccounts: AccountInfo[],
  selectedAccounts: AccountParent[],
  allAccountOptions?: AccountParent[],
  allSelectedAccessCodes?: string[],
}

export interface AccountState {
  userInfo: UserInfo,
  orgMembers: UserInfo[],
}

export interface UserInfo {
  id:string, //for table sorting
  accountName:string, //for table

  userId: string,
  firstName: string,
  lastName: string,
  username: string,
  password: string,
  address: Address,
  phoneNumber:string,
  role: Role,
  isAdmin:boolean,
  accessCode: string,
  seenIntroTutorial: boolean,
  signupDate:string,

  overUnderAvgTaskCompletionTime:number,
  numberOfTasksExecuted:number,
  totalTaskHoursWorkedComparedToEstimated:number
  numberOfSubTasksUncompleted: number,
  numberOfTasksLateOnDeadline:number,
}

export interface Address {
  street: string,
  city: string,
  state: string,
  postalCode: string
}

export interface AccountParent {
  accountId: string,
  name: string,
  address: Address,
  accessCode: string,
  timezone: string,
  phoneNumber: string,
  orgAdminId: string,
  allowedEmails: AllowedEmail[],
  preferenceConfig: PreferenceConfig,
  playbooksId: string,
  dashPrm:string,
  recordsPrm:string,
  reportsPrm:string,
  alertsPrm:string,
  labelSdsPrm:string,
  inventoryPrm:string,
}

export interface MapLatLong {
  lat:number,
  lng:number
}

export interface OrgInfo extends AccountParent {
  accountAccessCodes: string[],
  mapCenter: MapLatLong | null,
}

export interface AccountInfo extends AccountParent {

}

export interface PreferenceConfig {
  requireTaskEstimatedCompletionTime:boolean,
}

export interface AllowedEmail extends TableOptions {
  id: string,
  email: string,
  name:string,
  firstname:string,
  lastname:string,
  contractname:string,
  phoneNumber: string,
  registeredDate: string,
  role: Role | string,
  account: string,
  accountName: string,
  hourlyPay: string,
  contractPay: string
}

export interface SecurityCode {
  email:string,
  code:string,
}

export enum Role {
  ADMIN = "ADMIN",
  ORG_WORKER = "ORG_WORKER",
  ORG_MANAGER = "ORG_MANAGER",
  ORG_ADMIN = "ORG_ADMIN",
  ORG_CONTRACT = "ORG_CONTRACT"
}

export const timezones = [
    "US/Alaska",
    "US/Aleusian",
    "US/Arizona",
    "US/Central",
    "US/East-Indiana",
    "US/Eastern",
    "US/Hawaii",
    "US/Indiana-Starke",
    "US/Michigan",
    "US/Mountain",
    "US/Pacific",
    "US/Pacific-New",
    "US/Samoa"
];


