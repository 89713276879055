import {Address, AllowedEmail} from "./AccountTypes";

export const userInfoValidInit = {
  firstName: false,
  lastName: false,
  username: false,
  password: false,
  street: false,
  city: false,
  state: false,
  postalCode: false,
  phoneNumber: false,

}

export const OrgInfoValid = {
  orgName: false,
  orgPhoneNumber: false,
  orgStreet:false,
  orgCity:false,
  orgState:false,
  orgPostalCode:false,
  timezone:false,
}

export const AccountInfoValid = {
  orgName: false,
  orgPhoneNumber: false,
  orgStreet:false,
  orgCity:false,
  orgState:false,
  orgPostalCode:false,
  timezone:false,
}

export const AddressValid = {
  street: false,
  city: false,
  state: false,
  postalCode: false
}

export type AccountInfoValid = {
  orgStreet: boolean;
  orgCity: boolean;
  orgState: boolean;
  orgPostalCode: boolean;
  orgName: boolean;
  timezone:boolean;
}

export type AeValid = {
  email:boolean,
  firstname:boolean
  lastname:boolean,
  contractname:boolean,
  phoneNumber:boolean,
  role:boolean,
  hourlyPay:boolean,
  contractPay:boolean
}

export const allowedEmailValid = {
  email: false,
  firstname: false,
  lastname: false,
  contractname: false,
  phoneNumber:false,
  role: false,
  account: false,
  hourlyPay: false,
  contractPay: false
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
