import {OrgInfo} from "../types/account/AccountTypes";
import {ApiErrorResponse} from "../types/ApiErrorResponse";
import {NotificationState, notificationStateInit} from "../store/notificationSlice";
import {InboxNotification} from "../types/NotificationTypes";
import {history} from "../routes/historyGlobal";
import store, {authActions} from "../store/storeIndex";
import {httpCommonService} from "./generic/httpCommonService";

export const notificationService = {
  getNotifications,
  createNotification,
  draft,
  markNotificationsAsSeen,
  star,
  trash,
  deleteById
}

async function handleApiSuccess(responseAny: any) {
  const responseText = await responseAny.text();
  try {
    let response: NotificationState = JSON.parse(responseText);
    return response;
  } catch (e) {
    return notificationStateInit;
  }
}

//------------------------------------------------------------------------

function getNotifications(): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/retrieveInbox`, {
      method: 'GET',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

//--------------------------------------------------------------------------

function createNotification(notification: InboxNotification): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/create`, {
      method: 'POST',
      body: JSON.stringify(notification),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

function draft(notification: InboxNotification): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/draft`, {
      method: 'POST',
      body: JSON.stringify(notification),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

//---------------------------------------------------------------------------------

function markNotificationsAsSeen(ids: string[], inboxType:string): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/markSeen`, {
      method: 'PUT',
      body: JSON.stringify({ids:ids, type:inboxType}),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

//--------------------------------

function star(id: string, inboxType:string): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/star/type/${inboxType}/id/${id}`, {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

function trash(id: string, inboxType:string): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/trash/type/${inboxType}/id/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}

function deleteById(id: string): Promise<NotificationState> {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/notifications/delete/id/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    })
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {
          throw e
        });
  } catch (e) {throw e;}
}