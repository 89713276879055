import React, {Fragment, useState} from "react";
import {Alert, Button, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import {blue, green} from "@mui/material/colors";
import {ArrowForward, Check} from "@mui/icons-material";
import {userInfoService} from "../../service/account/userInfoService";
import {determineIfTokenError} from "../../store/account/authSlice";
import {SecurityCode, UserInfo} from "../../types/account/AccountTypes";
import {displayAlert} from "../../store/alertSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {emailRegex, userInfoValidInit} from "../../types/account/AccountValids";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import LeslieButton from "../general/LeslieButton";


type Props = {
  isLoggedIn: boolean,
  setDialogOpen?: (val:boolean) => void,
}

const PasswordResetForm:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const userInfoGlobal = useSelector((state: RootState) => state.userInfo.userInfo);

  const [userInfoState, setUserInfoState] = useState<UserInfo>(userInfoGlobal);
  const [userInfoStateValid, setUserInfoStateValid] = useState(userInfoValidInit);

  const [hideCurrentPassword, setHideCurrentPassword] = useState(false);
  const [hideSendEmail, setHideSendEmail] = useState(false);
  const [showPassLoading, setShowPassLoading] = useState(false);
  const [showCodeEntry, setShowCodeEntry] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [isPassCheckValid, setIsPassCheckValid] = useState<boolean | null>(null);
  const [isSecurityCodeCheckValid, setIsSecurityCodeCheckValid] = useState<boolean | null>(null);
  const [secCode, setSecCode] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const onChangePassword = (val:string) => {
    //setUserFormTouched(true);
    if (showNewPassword) {
      userInfoStateValid.password = val.trim().length > 5;
      setUserInfoStateValid(userInfoStateValid);
    }

    let newUserState = {...userInfoState};
    newUserState.password = val;
    setUserInfoState(newUserState);

    setHideSendEmail(val.length > 0);
  }

  const onChangeSecurityCode = (val:string) => {
    setSecCode(val);
  }

  const onChangeEmail = (val:string) => {
    setEmailSubmitted(false);
    setIsPassCheckValid(null);

    let newUserState = {...userInfoState};
    newUserState.username = val;
    setUserInfoState(newUserState);
  }

  const sendPasswordEmail = () => {
    setHideCurrentPassword(true);

    setShowPassLoading(true);
    userInfoService.sendResetPassEmail(userInfoState.username.trim().toLowerCase()).then(success => {
      setShowCodeEntry(true);
    }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => setShowPassLoading(false));
  }

  const checkSecurityCodeValid = () => {
    setShowPassLoading(true);
    let securityCode:SecurityCode = {code:secCode, email:userInfoState.username};
    userInfoService.checkSecurityCodeValid(securityCode).then(valid => {
      setIsSecurityCodeCheckValid(valid);
      if (valid) {
        let newUserState = {...userInfoState};
        newUserState.password = '';
        setUserInfoState(newUserState);

        setShowNewPassword(true);
      }
      setSecCode('');
    }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => setShowPassLoading(false));
  }

  const checkPassValid = () => {
    setShowPassLoading(true);
    userInfoService.checkPasswordValid(userInfoState).then(valid => {
      setIsPassCheckValid(valid);
      if (valid) {
        let newUserState = {...userInfoState};
        newUserState.password = '';
        setUserInfoState(newUserState);

        setShowNewPassword(true);
      }
    }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => setShowPassLoading(false));
  }

  const updateNewPassword = () => {
    if (showNewPassword && userInfoState.password.length > 5) {
      setShowPassLoading(true);
      userInfoService.updateUserPass(userInfoState).then(userInfo => {
        dispatch(displayAlert(true, 'password successfully reset'));
        resetPassResetForm();
        if (props.setDialogOpen) {
          props.setDialogOpen(false);
        }
      }).catch(e => {
        dispatch(determineIfTokenError(e));
      }).finally(() => setShowPassLoading(false));
    }
  }

  const resetPassResetForm = () => {
    let newUserState = {...userInfoState};
    newUserState.password = '';
    if (!props.isLoggedIn) {
      newUserState.username = '';
    }
    setUserInfoState(newUserState);

    setHideCurrentPassword(false)
    setHideSendEmail(false)
    setShowCodeEntry(false)
    setShowNewPassword(false)
    setIsPassCheckValid(null)
    setIsSecurityCodeCheckValid(null)
    setSecCode('')
    setEmailSubmitted(false);
  }

  /*const determineUnameValid = (uname: string) => {
    setIsUnameValid(uname.trim().length > 0 && emailRegex.test(uname.toLowerCase()));
  }*/

  return (
      <Fragment>
        {!props.isLoggedIn &&
          <CustomTextField
              sx={{width: '100%', my:4}}
              valueString={userInfoState.username}
              label={"Enter your Email"}
              onEnterKeyDownSubmitHandler={() => setEmailSubmitted(true)}
              onInputUpdate={onChangeEmail}
              endAdornment={!emailSubmitted}
          >
              <Tooltip title={'Next'}>
                  <IconButton sx={{backgroundColor: blue[500]}}
                              onClick={() => setEmailSubmitted(true)}>
                      <ArrowForward/>
                  </IconButton>
              </Tooltip>
          </CustomTextField>
        }

        { (props.isLoggedIn || emailSubmitted) &&
          <Fragment>
            {true &&
            <Alert severity="info" sx={{mb: 2}}>Enter your current password or get an email
                with a security code to prove your identity</Alert>
            }
            <Grid container spacing={2}>
              {!hideCurrentPassword && !showNewPassword &&
              <Grid item xs={12} sm={12} md={hideSendEmail ? 7 : 5}>
                  <CustomTextField
                      sx={{width: '100%'}}
                      type={'password'}
                      valueString={userInfoState.password}
                      label={"Enter Current Password"}
                      onEnterKeyDownSubmitHandler={checkPassValid}
                      onInputUpdate={onChangePassword}
                  />
                  <small style={{color: 'red', marginTop: 0}} hidden={isPassCheckValid == null || isPassCheckValid}>
                      The entered password is incorrect
                  </small>
              </Grid>
              }
              {(hideSendEmail && !showNewPassword) &&
              <Grid item xs={12} sm={12} md={5} sx={{textAlign: 'center', pl: 2}}>
                  <Button variant={"contained"} size={'large'}
                          onClick={checkPassValid}
                          sx={{backgroundColor: blue[500], width: '100%', py: 2}}>
                      Submit
                  </Button>
              </Grid>
              }

              {(!hideSendEmail && !hideCurrentPassword) && !showNewPassword &&
              <Grid item xs={12} sm={12} md={2} sx={{textAlign: 'center'}}>
                  <p>OR</p>
              </Grid>
              }
              {!hideSendEmail && !showNewPassword &&
              <Fragment>
                  <Grid item xs={12} sm={12} md={5}>
                      <Button variant={"contained"} size={'large'} color={"inherit"}
                              onClick={sendPasswordEmail}
                              sx={{backgroundColor: LIME_GREEN, width: '100%', py: 2, border:'2px solid', borderColor:DARK_NAVY, color:DARK_NAVY, fontWeight:"bold"}}>
                        {showCodeEntry ? 'Resend Email' : 'Send Email'}
                      </Button>
                    {showCodeEntry &&
                    <Stack direction={'row'} spacing={2}
                           style={{color: green[500], marginTop: 0}}>
                        <Check/>
                        <small>
                            Email sent.
                        </small>
                    </Stack>
                    }
                  </Grid>
                {showCodeEntry &&
                <Grid item xs={12} sm={12} md={7}>
                    <CustomTextField
                        sx={{width: '100%'}}
                        valueString={secCode}
                        label={"Security Code from Email"}
                        onEnterKeyDownSubmitHandler={checkSecurityCodeValid}
                        onInputUpdate={onChangeSecurityCode}
                        endAdornment={true}
                    >
                        <Tooltip title={'Submit'}>
                            <IconButton sx={{backgroundColor:LIME_GREEN,
                                              color:DARK_NAVY,}}
                                        onClick={checkSecurityCodeValid}>
                                <ArrowForward/>
                            </IconButton>
                        </Tooltip>
                    </CustomTextField>
                    <small style={{color: 'red', marginTop: 0}}
                           hidden={isSecurityCodeCheckValid === null || isSecurityCodeCheckValid}>
                        Submitted code is invalid.
                    </small>
                </Grid>
                }
              </Fragment>
              }

              {showNewPassword &&
              <Fragment>
                  <Grid item xs={12} sm={12} md={7}>
                      <CustomTextField
                          sx={{width: '100%'}}
                          type={'password'}
                          isValid={userInfoStateValid.password || userInfoState.password.length === 0}
                          validationText={'Invalid Password'}
                          valueString={userInfoState.password}
                          label={"Enter New Password"}
                          onEnterKeyDownSubmitHandler={updateNewPassword}
                          onInputUpdate={onChangePassword}
                      />
                      <Stack direction={'row'} spacing={2} style={{color: green[500], marginTop: 0}}>
                          <Check/>
                          <small>
                              Thank you. Enter your new password.
                          </small>
                      </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} sx={{textAlign: 'center', pl: 2}}>
                      {/*<Button variant={"contained"} size={'large'}
                              onClick={updateNewPassword}
                              sx={{backgroundColor: blue[500], width: '100%', py: 2}}>
                          Submit Reset
                      </Button>*/}
                    <LeslieButton text={'Submit Reset'}
                                  width={'100%'}
                                  height={"tall"}
                                  handleSubmit={() => updateNewPassword()}
                    />
                  </Grid>
              </Fragment>
              }
            </Grid>
          </Fragment>
        }
      </Fragment>
  );
}

export default PasswordResetForm;