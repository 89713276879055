import {alpha, Box, IconButton, Stack, Toolbar, Tooltip, Typography} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, {Fragment, useState} from "react";
import CustomTextField from "../CustomTextField";
import SearchIcon from '@mui/icons-material/Search';
import {Visibility, VisibilityOff} from "@mui/icons-material";

export interface EnhancedTableToolbarProps {
  numSelected: number;
  tableName?: string;
  searchFilterText?: string;
  setSearchFilterString?: (filterText: string) => void;
  hideFilter?:boolean;
  setShowMasked?:(val:boolean) => void;
  showMasked?:boolean;
  maskFieldsTooltipText?:string;
}

export const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const { numSelected } = props;

  const [searchExpanded, setSearchExpanded] = useState(false);

  const handleChangeFilter = (filterText: string) => {
    if (props.setSearchFilterString) {
      props.setSearchFilterString(filterText);
    }
  }

  const handleExpandSearchBar = () => {
    setSearchExpanded(!searchExpanded);
  }

  return (
      <Toolbar
          sx={{
            backgroundColor:"white",
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) =>
                  alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
      >
        {numSelected > 0 ? (
            <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
              {numSelected} selected
            </Typography>
        ) : (
            <Fragment>
              {props.tableName &&
                <Typography
                    sx={{flex: '1 1 50%'}}
                    variant="h6"
                    id="tableTitle"
                    component="div"
                >
                  {props.tableName}
                </Typography>
              }
            </Fragment>
        )}
        {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton>
                {/*<DeleteIcon />*/}
              </IconButton>
            </Tooltip>
        ) : (
            <Fragment>
              <Stack direction={'row'} spacing={2}>
                {props.maskFieldsTooltipText &&
                  <Box sx={{marginTop: '12px'}}>
                    <Tooltip title={props.maskFieldsTooltipText}>
                      <IconButton onClick={() => props.setShowMasked && props.setShowMasked(!props.showMasked)}>
                        {props.showMasked ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                <div>
                  { !props.hideFilter === true &&
                    <Box sx={{display: 'flex', alignItems: 'flex-end', width: searchExpanded ? '100%' : '30px'}}>
                        <SearchIcon onClick={handleExpandSearchBar}
                            sx={{color: 'action.active', mr: 1, my: 0.5, mb: 1.5, cursor: 'pointer'}}/>
                        <CustomTextField
                            sx={{marginRight: '10px', marginBottom: '10px'}}
                            valueString={props.searchFilterText!}
                            variant={'standard'}
                            label={"Search Filter"}
                            onInputUpdate={handleChangeFilter}
                        />
                    </Box>
                  }
                </div>
              </Stack>

              {/*<Tooltip title="Filter list">
                <IconButton>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>*/}
            </Fragment>
        )}
      </Toolbar>
  );
};

export default EnhancedTableToolbar;