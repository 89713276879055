import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider, IconButton,
  Stack,
  Typography
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CustomTextField from "./CustomTextField";
import {LocationNameValid} from "../../types/task/Location";
import {taskManagementService} from "../../service/taskManagementService";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useSelector} from "react-redux";
import {formatDate, formatDateNew} from "../../service/generic/DateHelper";
import {Priority, TaskInfo} from "../../types/task/TaskTypes";
import {ArrowUpward, CheckCircleOutlined, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import TaskActions from "../task/TaskActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import LoadingSpinnerContainer from "./LoadingSpinnerContainer";
import TaskDetailDialog from "../task/TaskDetailDialog";
import {grey} from "@mui/material/colors";


type Props = {
  title: string,
  taskList:TaskInfo[],
  selectedId: string,
  selectedDate: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  handleRefreshTaskList: (id:string) => void,
}

const ChangeLocationsOnTasksDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const taskListToChangeLocationGlobal = useSelector((state: RootState) => state.taskInfo.tasksToChangeLocationList);

  const [tasksToChangeLocation, setTasksToChangeLocation] = React.useState<TaskInfo[]>([]);
  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);

  useEffect(() => {
    document.title = "Change Tasks Locations Dialog";
    setTasksToChangeLocation(props.taskList);
    console.log(props.taskList);
    console.log('1');
  }, []);

  useEffect(() => {
    setTasksToChangeLocation(taskListToChangeLocationGlobal);
    console.log(taskListToChangeLocationGlobal);
    console.log('2');
  }, [taskListToChangeLocationGlobal]);

  useEffect(() => {
    setTasksToChangeLocation(props.taskList);
    console.log(props.taskList);
    console.log('3');
  }, [props.taskList]);

  const close = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    props.setIsOpen(false)
  }

  const getAccountNameFromAccessCode = (accessCode:string) => {
    return orgInfo.selectedAccounts.filter(account => account.accessCode === accessCode)[0].name;
  }

  const handleTaskSelected = (task: TaskInfo) => {
    setSelectedTask(task);
    setTaskDialogOpen(true);
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 475 } }}
          maxWidth="sm"
          open={props.isOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          { showLoading ? (
              <LoadingSpinnerContainer showLoading={showLoading} />
          ) : (
            <Fragment>
              <Alert severity="info" color="info" sx={{mb: 2}}>
                <Typography variant={'body1'} component={"div"}>
                  The below tasks are currently assigned to the location you are trying to delete.
                  Please edit each task below and change the location, or delete the task.
                </Typography>
              </Alert>

              {tasksToChangeLocation.map((task: TaskInfo, index) => (
                  <Box sx={{mb: 1}}>
                    <Card sx={{mb: 1, backgroundColor: "ghostwhite"}}
                          key={task.assignedTaskId}>
                      <CardHeader
                          style={{color: task.completed ? 'forestgreen' : 'indianred'}}
                          title={
                            <Fragment>
                              <Typography variant="h6" component="div">
                                <div>
                                  {task.taskName}
                                </div>
                                <Typography variant="caption" component="div" style={{color:grey["500"]}}>
                                  {formatDateNew(task.scheduledDate)}
                                </Typography>
                                <Typography variant="subtitle1" component="span"
                                            style={{float: "right", color: 'gray'}}>
                                </Typography>
                              </Typography>
                              <Typography variant="body1" component="div" color={"dimgrey"}>
                                <Stack direction={"row"} spacing={3}>
                                </Stack>
                              </Typography>
                            </Fragment>
                          }
                          action={
                            <Stack direction="row" spacing={0}>
                              <TaskActions
                                  task={task}
                                  selectedDate={new Date(selectedDate)}
                                  taskDialogOpen={taskDialogOpen}
                                  setTaskDialogOpen={setTaskDialogOpen}
                                  onlyShowEditDelete={true}
                                  handleDialogActionClose={props.handleRefreshTaskList}
                                  selectedSubjectId={props.selectedId}
                              />
                              <IconButton aria-label="more"
                                          onClick={() => handleTaskSelected(task)}>
                                <MoreVertIcon color={"action"}/>
                              </IconButton>
                            </Stack>
                          }
                      />
                    </Card>
                  </Box>
              ))}
            </Fragment>
          )}

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Cancel
          </Button>
          {/*<Button style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}
                  variant={isFormValid() ? "contained" : "text"}
                  onClick={handleLocationUpdate}>Submit</Button>*/}
        </DialogActions>
        <TaskDetailDialog
            task={selectedTask}
            dialogOpen={taskDialogOpen}
            setDialogOpen={setTaskDialogOpen}
            selectedDate={new Date()}
            setSelectedTask={setSelectedTask}
            getJobBoardUtil={()=>{}}
        />
      </Dialog>
  );
}

export default ChangeLocationsOnTasksDialog;