import {
  CopyScheduleObjectUtil,
  CopyScheduleObjectUtilResponse, FullMonthResponse, MasterCopyUtil,
  ScheduledWeekObjectResponse,
  TaskResponse
} from "../types/task/TaskTypes";
import {httpCommonService} from "./generic/httpCommonService";

export const scheduleService = {
  copySchedule,
  masterCopySubmit
}

async function handleApiSuccess(responseAny: any) {
  let response: TaskResponse = await responseAny.json();
  return response;
}

async function masterCopySubmit(masterCopyUtil: MasterCopyUtil, accessCodes:string[], date: string | null) {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/masterCopySubmit/${date}/${accessCodes.toString()}`, {
      method: 'POST',
      body: JSON.stringify(masterCopyUtil),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(async (response: any) => await response.json() as FullMonthResponse)
        .catch(e => {
          throw Error(e.message)
        });
  } catch (e) {
    throw e;
  }
}

async function copySchedule(copyScheduleInfoState: CopyScheduleObjectUtil, accessCodes:string[], date: string | null) {
  try {
    let token = localStorage.getItem('user') as string;
    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/copySchedule/${date}/${accessCodes.toString()}`, {
      method: 'POST',
      body: JSON.stringify(copyScheduleInfoState),
      headers: {
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    })
        .then(httpCommonService.handleApiError)
        .then(async (response: any) => await response.json() as ScheduledWeekObjectResponse)
        .catch(e => {
          throw Error(e.message)
        });
  } catch (e) {throw e;}
}