import React, {Fragment, useEffect} from "react";
import {
  Alert, Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {Check, Close} from "@mui/icons-material";
import {green} from "@mui/material/colors";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";


type Props = {
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  step: number
}

const WelcomeCreateOrgDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const orgInfo = useSelector((state: RootState) => state.orgInfo.orgInfo);
  const isOrgAdminRegistration = useSelector((state: RootState) => state.experience.isOrgAdminRegistration);
  const showOrgMembersTutorial = useSelector((state: RootState) => state.experience.showOrgMembersTutorial);

  useEffect(() => {
    document.title = "Welcome/Create Org Dialog";
  }, []);

  /*useEffect(() => {
    console.log("modal is open use effect")
    dispatch(taskInfoActions.setIsModalOpen({value: props.isOpen}));
  }, [props.isOpen])*/

  useEffect(() => {
    if (!isOrgAdminRegistration && !showOrgMembersTutorial) {
      props.setIsOpen(false);
    }
  })

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="md"
          open={props.isOpen}
      >
        <DialogTitle>
          {props.step == 1 ? "Welcome to Apperform!" : "Thanks for that Info, " + (props.step == 2 ? "Almost There" : "So Close") + "!"}
          <Avatar variant="rounded" sx={{position: 'absolute', right: 11, top: 11, bgcolor: green[500]}}>
            {props.step}
          </Avatar>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction={'column'}>
            {props.step == 1 &&
                <Fragment>
                  <Box sx={{my: 1}}>
                      <Alert severity="success" color="success">
                          <Typography variant={'body1'} component={"div"}>
                              <b>We hope you are ready to optimize your team's performance, and fine tune your operation.</b>
                          </Typography>
                      </Alert>
                  </Box>
                  <Box sx={{my:1}}>
                    <Alert severity="info" color="info">
                      <Typography variant={'body1'} component={"div"}>You will serve as the Org Admin for your organization.
                          You will define all of your workers and assign them roles to control what they can see and do in Apperform.
                          You can also break your organization down logically into sub accounts with their own budget and workers.
                      </Typography>
                    </Alert>
                  </Box>
                  <Box sx={{my:2}}>
                      <Typography variant={'body1'} component={"div"}>
                      So, now that you have created your personal account, you have a
                      couple things to complete before you can get working:
                      </Typography>
                  </Box>
                </Fragment>
            }
            <ol>
              <li style={{color: props.step > 1 ? 'green':'inherit'}}>
                { props.step > 1 &&
                  <Check />
                }
                <b>Give us some details about your organization</b>
                <ul>
                  <li>This will be the parent organization</li>
                  <li>ex. Metropolitan Area of Anchorage</li>
                </ul>
              </li>
              <li style={{color: (props.step > 2 && orgInfo.accountAccessCodes.length > 0) ? 'green':'inherit'}}>
                { (props.step > 2 && orgInfo.accountAccessCodes.length > 0) ?
                    (<Check />) : props.step > 2 ? (<b style={{color:'goldenrod'}}>[Skipped]  </b>) : ("")
                }
                <b>Define any sub accounts within the parent organization</b>
                <ul>
                  <li>These accounts will be any smaller and separate departments or teams that exist within the org</li>
                  <li>ex. East Parks, West Parks, etc.</li>
                </ul>
              </li>
              <li><b>Enter the email addresses of your workers</b>
                <ul>
                  <li>You will define the Role each entry should have and configure their hourly wage</li>
                  <li>This is how you can control access to your organization</li>
                  <li>note: if a worker's email is not in an entry, they will not be able to create an account.</li>
                </ul>
              </li>
            </ol>
            {props.step == 1 &&
              <Box sx={{my: 2}}>
                  <Typography variant={'body1'} component={"div"}>This should only take a minute, so lets get to it!
                  </Typography>
              </Box>
            }
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => props.setIsOpen(false)}>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default WelcomeCreateOrgDialog;