import React, {Fragment} from "react";
import {InputAdornment, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  disabled?: boolean,
  readonly?: boolean,
  valueString: string | number,
  label?: string,
  type?: string,
  required?: boolean,
  isValid?: boolean,
  validationText?: string,
  variant?: "standard" | "filled" | "outlined" | undefined,
  onEnterKeyDownSubmitHandler?: () => void,
  onInputUpdate: (inputCurrValue: string) => void
  multiline?: number,
  newLineHandler?: (index:number) => void,
  sx?: {},
  startAdornment?: boolean,
  endAdornment?: boolean,
}

const CustomTextField: React.FC<Props> = (props) => {

  let children = React.Children.toArray(props.children);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter' && props.onEnterKeyDownSubmitHandler) {
      e.preventDefault(); e.stopPropagation();
      props.onEnterKeyDownSubmitHandler();
    }
  }

  return (
      <Fragment>
        <TextField
            required={props.required ? props.required : false}
            disabled={props.disabled}
            type={props.type ? props.type : 'string'}
            id={props.label}
            label={props.label ? props.label : undefined}
            error={props.isValid ? !props.isValid : false}
            multiline={!!props.multiline}
            rows={props.multiline ? props.multiline : 1}
            fullWidth={true}
            //defaultValue={userObjectState.firstName}
            value={props.valueString}
            variant={props.variant ? props.variant : "filled"}
            //onChange={props.onInputUpdate}
            InputProps={{
                onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  let inputCurrentValue = (e.target as HTMLInputElement).value as string;
                  props.onInputUpdate(inputCurrentValue);
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                  let inputCurrentValue = (e.target as HTMLInputElement).value as string;
                  props.onInputUpdate(inputCurrentValue);
                },
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                  onKeyDown(e);
                },
                readOnly: props.readonly ? true : false,
                startAdornment: (
                    <Fragment>
                      { props.startAdornment && (
                        <InputAdornment position="start">
                          {children[0]}
                        </InputAdornment>
                      )}
                    </Fragment>
                ),
                endAdornment: (
                    <Fragment>
                      { props.endAdornment && (
                          <InputAdornment position="end">
                            {children[props.startAdornment ? 1 : 0]}
                          </InputAdornment>
                      )}
                    </Fragment>
                ),
            }}
            sx={{...props.sx}}
        />
        <small hidden={props.isValid} style={{color:'red', marginTop:'2px'}}>{props.validationText}</small>
      </Fragment>
  );
};

export default CustomTextField;