import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import Select from "react-select";
import {OptionType, resolveOptionType} from "../../types/GenericTypes";
import CustomTextField from "../general/CustomTextField";
import React, {useEffect, useState} from "react";
import makeAnimated from "react-select/animated";
import {UserInfo} from "../../types/account/AccountTypes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {InboxNotification, notificationInit, notificationValid} from "../../types/NotificationTypes";
import {notificationService} from "../../service/notificationService";
import {NotificationState, updateNotificationGlobalState} from "../../store/notificationSlice";
import {displayAlert} from "../../store/alertSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {determineIfTokenError} from "../../store/account/authSlice";
import {DARK_NAVY} from "../../service/generic/ColorScheme";

type Props = {
  newNotification: InboxNotification,
  setNewNotification: (notif: InboxNotification) => void,
  openCreateDialog: boolean,
  setOpenCreateDialog: (open: boolean) => void,

}

const NotificationCreateDialog: React.FC<Props> = (props) => {
  const animatedComponents = makeAnimated();
  let dispatch = useAppThunkDispatch();

  const orgMembers: UserInfo[] = useSelector((state: RootState) => state.account.orgMembers);

  const [newNotificationValid, setNewNotificationValid] = useState(notificationValid);
  const [formTouched, setFormTouched] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    document.title = "Create Notification Dialog";
  }, []);

  const isFormValid = () => {
    return newNotificationValid.title && newNotificationValid.message;
  };

  const handleCreateAlertClose = () => {
    setShowLoading(true);
    if (props.newNotification.message.trim() != '') {
      let timeout = setTimeout(() => {
        notificationService.draft(props.newNotification)
            .then((inbox: NotificationState) => {
              dispatch(updateNotificationGlobalState(inbox));
              dispatch(displayAlert(true, "Incomplete notification saved to Drafts"));
            })
            .catch((e) => {
              dispatch(displayAlert(false, e.message));
            });
        setShowLoading(false);
      }, 600);
    }
    props.setNewNotification(notificationInit);
    props.setOpenCreateDialog(false);
  };

  const handleCreateAlertSubmit = () => {
    setShowLoading(true);
    props.setOpenCreateDialog(false);
    let timeout = setTimeout(() => {
      notificationService.createNotification(props.newNotification)
          .then((inbox: NotificationState) => {
            dispatch(updateNotificationGlobalState(inbox));
            dispatch(displayAlert(true, "sent new notification to recipient(s)"));
          })
          .catch((e) => {
            dispatch(determineIfTokenError(e));
          })
          .finally(() => props.setNewNotification(notificationInit));
      setShowLoading(false);
    }, 600);
  };

  const handleRecipientsChange = (selected:OptionType[]) => {
    let newNotificationState = {...props.newNotification};
    newNotificationState.recipientIds = selected.map(option => option.value);
    props.setNewNotification(newNotificationState);
  };

  const handleChangeNewAlertTitle = (title: string) => {
    setFormTouched(true);
    newNotificationValid.title = title.trim().length > 0;
    setNewNotificationValid(newNotificationValid);

    let newNotificationState = {...props.newNotification};
    newNotificationState.title = title;
    props.setNewNotification(newNotificationState);
  }

  const handleChangeNewAlertMessage = (message: string) => {
    setFormTouched(true);
    newNotificationValid.message = message.trim().length > 0;
    setNewNotificationValid(newNotificationValid);

    let newNotificationState = {...props.newNotification};
    newNotificationState.message = message;
    props.setNewNotification(newNotificationState);
  }

  const memberOptions:OptionType[] = orgMembers.map(member =>
      ({label:member.firstName + ' ' + member.lastName, value: member.userId}));

  return (
      <Dialog maxWidth={"md"} fullWidth={true} style={{zIndex:10001}}
              open={props.openCreateDialog} onClose={handleCreateAlertClose}>
        <DialogTitle>Send Notification</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <div style={{zIndex:50000}}>
              <Select
                  isMulti={true}
                  placeholder={'Select recipients to send to...'}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  components={animatedComponents}
                  options={memberOptions}
                  onChange={(selected) =>
                      handleRecipientsChange(resolveOptionType(selected as OptionType))}
              />
            </div>
            <CustomTextField
                valueString={props.newNotification.title}
                label={"Title"}
                isValid={newNotificationValid.title || !formTouched}
                validationText={'please add a title/subject'}
                onEnterKeyDownSubmitHandler={handleCreateAlertSubmit}
                onInputUpdate={handleChangeNewAlertTitle}
            />
            <CustomTextField
                valueString={props.newNotification.message}
                label={"Message"}
                multiline={5}
                isValid={newNotificationValid.message || !formTouched}
                validationText={'a message is required'}
                onInputUpdate={handleChangeNewAlertMessage}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button style={{color:DARK_NAVY}} onClick={handleCreateAlertClose}>Cancel</Button>
          <Button onClick={handleCreateAlertSubmit} variant={isFormValid() ? "contained" : "text"}
                  style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default NotificationCreateDialog;