import React, {FC, Fragment, useEffect, useState} from "react";
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tab,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from "@mui/material";
import {
  Add,
  AirOutlined,
  ArrowBackIosNew,
  ArrowDownward,
  ArrowForwardIos,
  ArrowUpward,
  OpacityOutlined
} from "@mui/icons-material";
import {DatePicker, LocalizationProvider, TabContext, TabList, TabPanel} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {Role, UserInfo} from "../../types/account/AccountTypes";
import OrgAccountSelect from "../account/OrgAccountSelect";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import TaskVerticalDisplay from "./TaskVerticalDisplay";
import PlaybooksCoverageWidget from "./PlaybooksCoverageWidget";
import TaskCategoriesConfig from "./TaskCategoriesConfig";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {taskManagementService} from "../../service/taskManagementService";
import {formatDate} from "../../service/generic/DateHelper";
import {TaskResponse} from "../../types/task/TaskTypes";
import {determineIfTokenError} from "../../store/account/authSlice";
import {blue, green, grey, red, teal} from "@mui/material/colors";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import {CalendarViews, getTaskToDisplay} from "../../service/TasksHelper";
import TaskLocationsConfig from "./TaskLocationsConfig";
import {OpenWeatherResponse} from "../../types/generic/WeatherTypes";
import CloseIcon from "@mui/icons-material/Close";
import {FullPageDialogTransition} from "../general/FullPageDialogTransition";
import QuickReportWidget from "./QuickReportWidget";
import {DARK_NAVY, GHOST_WHITE, LIGHT_GREY_BLUE, LIME_GREEN} from "../../service/generic/ColorScheme";

const TaskForm = React.lazy(() => import("./TaskForm"));
const TaskTemplates = React.lazy(() => import("./TaskTemplates"));

type Props = {
 tabSelected:string
}

const TaskManagement:FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const isWindowLg = useMediaQuery('(min-width:800px)');
  const isWindowSmall = useMediaQuery('(max-width:415px)');

  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const laborTutorial = useSelector((state: RootState) => state.experience.showLaborDashboardTutorial);
  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const tasks = useSelector((state: RootState) => state.taskInfo);
  const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
  const taskTemplates = useSelector((state: RootState) => state.taskInfo.taskTemplates);
  const gddSinceAppsPlaybooksData = useSelector((state: RootState) => state.playbooks.gddSinceApps);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);

  const [value, setValue] = React.useState('2');
  const [showTaskDisplayLoading, setShowTaskDisplayLoading] = useState(false);
  const [showPlaybooksLoading, setShowPlaybooksLoading] = useState(false);
  const [taskLocal, setTaskLocal] = useState(taskInfoInit);
  const [showTooManyOrgsSelectedNotification, setShowTooManyOrgsSelectedNotification] = useState(false);
  const [createTaskOpen, setCreateTaskOpen] = useState(false);
  const [viewSelection, setViewSelection] = React.useState(CalendarViews.DAILY);
  const [weatherReport, setWeatherReport] = useState<OpenWeatherResponse | null>(null);

  const copyDayObjectUtilGlobal = useSelector((state: RootState) => state.taskInfo.copyDayObjectUtil);

  // prevents the window from scrolling down everytime playbooks data is loaded
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Task Management Page";
  }, []);

  useEffect(() => {
    //while (true) {
      //setTimeout(() => {
        fetchWeatherReport();
      //}, 1 * 60000); // 1 minute
    //}
  }, [])

  useEffect(() => {
    window.scrollTo(window.scrollX, window.scrollY)
  }, [gddSinceAppsPlaybooksData])

  useEffect(() => {
    const ac = new AbortController();
    if (orgInfo.allSelectedAccessCodes?.length > 0) {
      if (orgInfo.orgInfo.accessCode) {
        getMonthlyTasks(ac);
      }
      retrieveAvailableMembersToAssignOnSelectedDay(ac);
    }

    return () => {ac.abort()};
  }, [selectedDate, orgInfo.allSelectedAccessCodes, copyDayObjectUtilGlobal]);

  useEffect(() => {
    setTaskLocal(taskInfo);
  }, [taskInfo])

  const getMonthlyTasks = (ac:AbortController) => {
    setShowTaskDisplayLoading(true);
    taskManagementService.getMonthlyTasks(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!, ac)
        .then((tasks: TaskResponse) => {
          dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: tasks.taskList}));
          dispatch(taskInfoActions.setSavedTemplates({taskTemplates: tasks.taskTemplates}));
          dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage: tasks.taskCategories}));
          dispatch(taskInfoActions.setTaskLocationsPage({locationsPage: tasks.taskLocations}))
        }).catch(e => {
      dispatch(determineIfTokenError(e));
    }).finally(() => setShowTaskDisplayLoading(false));
  }

  const fetchWeatherReport = () => {
    if (orgInfo.orgInfo.mapCenter && orgInfo.orgInfo.mapCenter.lat) {
      taskManagementService.fetchWeatherReport()
          .then((weather: OpenWeatherResponse) => {
            setWeatherReport(weather);
          }).catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => {
          });
    }
  }

  const retrieveAvailableMembersToAssignOnSelectedDay = (ac:AbortController) => {
    if (orgInfo.allSelectedAccessCodes! && orgInfo.allSelectedAccessCodes?.length > 0) {
      taskManagementService.retrieveAvailableMembersToAssignOnSelectedDay(formatDate(selectedDate), orgInfo.allSelectedAccessCodes!, ac)
          .then((workers: UserInfo[]) => {
            dispatch(taskInfoActions.setAvailableWorkersToAssignOnSelectedDay({workers: workers}));
          }).catch(e => {
        dispatch(determineIfTokenError(e));
      })
    }
  }

  const handleTabChange = (newValue: string) => {
    setValue(newValue);
  };

  const selectPrevDate = () => {
    let currentSelection = new Date(selectedDate);
    currentSelection.setDate(currentSelection.getDate() - 1);
    setSelectedDate(currentSelection);
  }

  const selectNextDate = () => {
    let currentSelection = new Date(selectedDate);
    currentSelection.setDate(currentSelection.getDate() + 1);
    setSelectedDate(currentSelection);
  }

  const setSelectedDate = (date: Date | string | null) => {
    dispatch(taskInfoActions.setSelectedDate({selectedDate:date?.toString()}));
  }

  const openTaskForm = () => {
    //dispatch(taskInfoActions.setIsModalOpen({value: true}));
    setCreateTaskOpen(true);
  }

  return (
      <Fragment>
        <Box sx={{ml:'-12px'}}>
          <Box sx={{borderBottom:'1px solid black', pb:3, px:0, pt:0, mb:3, mt:0}}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={5}>
                <h1 style={{display:"flex", justifyContent:"left", margin:0, padding:0, color:DARK_NAVY}}>Task Management</h1>
              </Grid>
              <Grid item sm={12} md={7}>
                <Grid container spacing={2} sx={{float:'right', width:'100%'}}>
                  <Grid item xs={2}>
                    <Tooltip title={'Back one day'}>
                      <IconButton sx={{backgroundColor:LIME_GREEN, color:DARK_NAVY, float:"right", mt:1}}
                                  onClick={() => selectPrevDate()}>
                        <ArrowBackIosNew/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                          label="Date"
                          value={selectedDate}
                          onChange={(newValue) => {
                            setSelectedDate(newValue);
                          }}
                          renderInput={(params) => <TextField sx={{width:'100%'}} {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title={'Forward one day'}>
                      <IconButton sx={{backgroundColor:LIME_GREEN, color:DARK_NAVY, float:"left", mt:1}}
                                  onClick={() => selectNextDate()}>
                        <ArrowForwardIos/>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          { orgInfo.allSelectedAccessCodes?.length > 1 &&
          <Fragment>
            <Alert severity="info" color="info" sx={{mb: 2}}>
              <Typography variant={'body1'} component={"div"}>
                You can only have one organization selected to create a task.
              </Typography>
            </Alert>
          </Fragment>
          }

          <Box sx={{width: isWindowSmall ? '99%' : '100%', marginLeft: isWindowSmall ? "1%" : "0"}}>
            { userInfo.userInfo.accessCode === orgInfo.orgInfo.accessCode && userInfo.userInfo.role !== Role.ORG_WORKER &&
              <OrgAccountSelect/>
            }

            <Grid container spacing={2}> {/*alignItems="stretch"*/}
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: 'block'}}>
                <Stack direction={"column"} spacing={2}>
                  <Grid container spacing={2} sx={{textAlign:'center', fontWeight:'bold'}}>
                    <Grid item xs={12} sm={3} md={3} sx={{display: 'flex'}}>
                      <Tooltip title={'Create Task / Template'}>
                        <Button color={"primary"} variant={'outlined'}
                                onClick={() => {
                                  if ((orgInfo.selectedAccounts.length === 1 &&
                                      checkUserNotOrdinaryWorker(userInfo.userInfo))) {
                                    openTaskForm();
                                  }
                                }}
                                sx={{ bgcolor: DARK_NAVY, width:'100%', color:grey[100],
                                  cursor: (orgInfo.selectedAccounts.length === 1 &&
                                      checkUserNotOrdinaryWorker(userInfo.userInfo)) ? 'pointer' : 'not-allowed',
                                  opacity: (orgInfo.selectedAccounts.length === 1 &&
                                      checkUserNotOrdinaryWorker(userInfo.userInfo)) ? 1 : 0.5
                                }}
                        >
                          <Add />
                        </Button>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                      <Tooltip title={'Total Tasks Scheduled'}>
                        <Card sx={{ bgcolor: LIGHT_GREY_BLUE, cursor:'pointer', width:'100%'}}>
                          <CardContent>
                            <h2 style={{margin:0, marginTop:'5px'}}>
                              {getTaskToDisplay(viewSelection, tasks)?.length}
                            </h2>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                      <Tooltip title={'Completed Tasks'}>
                        <Card sx={{ bgcolor: green[500], cursor:'pointer', width:'100%'}}>
                          <CardContent>
                            <h2 style={{margin:0, marginTop:'5px'}}>
                              {getTaskToDisplay(viewSelection, tasks)?.filter(task => task.completed).length}
                            </h2>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={4} sm={3} md={3} sx={{display: 'flex'}}>
                      <Tooltip title={'Uncompleted Tasks'}>
                        <Card sx={{ bgcolor: red['600'], cursor:'pointer', width:'100%'}}>
                          <CardContent>
                            <h2 style={{margin:0, marginTop:'5px'}}>
                              {getTaskToDisplay(viewSelection, tasks)?.filter(task => !task.completed).length}
                            </h2>
                          </CardContent>
                        </Card>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <TaskVerticalDisplay
                      showLoading={showTaskDisplayLoading}
                      viewSelection={viewSelection}
                      setViewSelection={setViewSelection}
                      getMonthlyTasks={getMonthlyTasks}
                  />

                  <Card sx={{width:'100%', backgroundColor: GHOST_WHITE}}>
                    <CardHeader
                        sx={{pb:0}}
                        title={
                          <Typography variant="h5" component="div">
                            Current Weather Forecast<br/>
                            <b>{weatherReport?.orgName}</b>
                          </Typography>
                        }
                        action={
                          <Fragment>
                            {orgInfo.orgInfo.mapCenter?.lat && weatherReport && weatherReport.daily &&
                              <img src={weatherReport.daily[0].weather[0].icon}
                                   style={{float: 'right'}}
                                   alt={'Weather Image'}
                              />
                            }
                          </Fragment>
                        }
                    />
                    <CardContent sx={{pt:0}}>
                      { !orgInfo.orgInfo.mapCenter?.lat &&
                          <div style={{display:'flex', justifyContent:'center', alignItems: 'center'}}>
                            <h3>Update your Organization Coordinates to get a Weather Report</h3>
                          </div>
                      }
                      { orgInfo.orgInfo.mapCenter?.lat && weatherReport &&
                          <Fragment>
                            { weatherReport.daily &&
                                <Fragment>
                                  <Grid container>
                                    <Grid item md={6}>
                                      <Grid container>
                                        <Grid item md={12}>
                                          <Stack direction={'row'} spacing={2}>
                                              <div>{new Date().toLocaleString('en-us', {  weekday: 'long' }) + ','}</div>
                                              <div>{weatherReport.daily[0].weather[0].main}</div>
                                              <div><Divider orientation={"vertical"}/></div>
                                              <div>{weatherReport.daily[0].weather[0].description}</div>
                                          </Stack>
                                        </Grid>
                                        <Grid item md={12} sx={{mt:2, mb:0}}>
                                          <Stack direction={"row"} spacing={1}>
                                              <ArrowUpward sx={{color:"red"}}/>
                                              <div>{weatherReport.daily[0].temp.max}&deg;</div>
                                              <div>/</div>
                                              <div>{weatherReport.daily[0].temp.min}&deg;</div>
                                              <ArrowDownward sx={{color:"blue"}}/>
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} >
                                          <span style={{fontSize:'500%', marginTop:0}}>
                                            {weatherReport.current.temp}&deg;
                                          </span>
                                        </Grid>
                                        <Grid item md={12}>
                                            <span>{'feels like ' + weatherReport.current.feels_like}&deg;</span>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Grid container>
                                        <Grid item lg={6} md={12} sm={6} sx={{textAlign:'center', mt:5}}>
                                          <OpacityOutlined fontSize={'large'} style={{color:blue[300]}}/>
                                          <div>{weatherReport.daily[0].pop}%</div>
                                          <p style={{marginTop:0}}>Precipitation</p>
                                        </Grid>
                                        <Grid item lg={6} md={12} sm={6} sx={{textAlign:'center', mt:5}}>
                                          <AirOutlined fontSize={'large'} style={{color:grey[400]}}/>
                                          <div>{weatherReport.daily[0].wind_speed}</div>
                                          <p style={{marginTop:0}}>mph Wind Speed</p>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    {/*<Grid item md={6}>
                                        <Grid container>
                                            <Stack direction={{xl:'row', lg:'row', md:'column'}} sx={{textAlign:'center', mt:5}}>
                                                <div>
                                                    <OpacityOutlined fontSize={'large'} style={{color:blue[300]}}/>
                                                    <div>{weatherReport.daily[0].pop}%</div>
                                                    <p style={{marginTop:0}}>Precipitation</p>
                                                </div>
                                                <div>
                                                    <AirOutlined fontSize={'large'} style={{color:grey[400]}}/>
                                                    <div>{weatherReport.daily[0].wind_speed}</div>
                                                    <p style={{marginTop:0}}>mph Wind Speed</p>
                                                </div>
                                            </Stack>
                                        </Grid>
                                    </Grid>*/}
                                  </Grid>
                                </Fragment>
                            }
                          </Fragment>
                      }
                    </CardContent>
                  </Card>

                  <Stack direction={{xs: 'column', sm: 'column', md: 'column', lg: 'row' }}
                         spacing={2} sx={{display:'flex', alignItems:"stretch"}}>
                    <TaskCategoriesConfig showMonthlyTasksLoading={showTaskDisplayLoading} />
                    <TaskLocationsConfig showMonthlyTasksLoading={showTaskDisplayLoading} />
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} style={{display: 'flex'}}>
                <Stack direction={"column"} spacing={2} sx={{width:'100%'}}>
                  <PlaybooksCoverageWidget showPlaybooksLoading={showPlaybooksLoading}
                                           setShowPlaybooksLoading={setShowPlaybooksLoading} />

                  <QuickReportWidget />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} style={{display: 'flex'}}>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Dialog
            PaperProps={{style: {overflowY: 'visible'}}}
            fullScreen
            open={createTaskOpen}
            onClose={() => setCreateTaskOpen(false)}
            TransitionComponent={FullPageDialogTransition}
            /*sx={{zIndex:10000}}*/
            disableEscapeKeyDown
        >
          <AppBar sx={{ position: 'relative', backgroundColor:'darkgray' }}>
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Manage Tasks
              </Typography>
              <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setCreateTaskOpen(false)}
                  aria-label="close"
                  sx={{float:"right"}}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                  onChange={(event: React.SyntheticEvent, newValue: string) => handleTabChange(newValue)}
                  aria-label="lab API tabs example" >
                <Tab
                    label={
                      <Fragment>
                        <Stack spacing={1}>
                          {laborTutorial &&
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                              <Avatar variant="rounded" sx={{bgcolor: teal["A400"], color: 'black'}}>
                                  A
                              </Avatar>
                          </div>
                          }
                          <div>{"Create Task"}</div>
                        </Stack>
                      </Fragment>
                    }
                    value="2" />
                <Tab
                    label={
                      <Fragment>
                        <Stack spacing={1}>
                          {laborTutorial &&
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                              <Avatar variant="rounded" sx={{bgcolor: teal["A400"], color: 'black'}}>
                                  B
                              </Avatar>
                          </div>
                          }
                          <div>{"Saved Templates"}</div>
                        </Stack>
                      </Fragment>
                    }
                    value="3" />
              </TabList>
            </Box>
            <TabPanel value="2" style={{maxHeight:'100%', overflowY:"auto", padding:0}} >
              { taskLocal.templateId &&
              <div style={{
                position: "sticky", top: 0, width: "100%", opacity: 1, color:"darkgoldenrod",
                backgroundColor: "lightsteelblue", padding: "15px"
              }}>
                  Selected Template:
                  <span style={{color:"lightgoldenrodyellow", marginLeft:"15px"}}>
                            {taskTemplates.filter(template => template.templateId === taskLocal.templateId)[0]?.templateName}
                        </span>
              </div>
              }
              <div style={{padding:"0"}}>
                <TaskForm
                    setShowPlaybooksLoading={setShowPlaybooksLoading}
                    showPlaybooksLoading={showPlaybooksLoading}
                    tabSelected={props.tabSelected}
                    isCreate={true}
                    openModal={createTaskOpen}
                    setOpenModal={setCreateTaskOpen} />
              </div>
            </TabPanel>
            <TabPanel value="3" style={{maxHeight:'100%', overflowY:"auto"}}>
              <TaskTemplates selectTemplateHandler={handleTabChange} />
            </TabPanel>
          </TabContext>
        </Dialog>
      </Fragment>
  );
}

export default TaskManagement;