import {createSlice} from "@reduxjs/toolkit";
import {scheduleObjectDayResponseInit, scheduleObjectWeekResponseInit} from "./taskInfoSlice";

const scheduleStateInit = {
  scheduledWeekObjectResponse: scheduleObjectWeekResponseInit,
  scheduleDayObjectResponse: scheduleObjectDayResponseInit
}

export const ScheduleSlice = createSlice({
  name: 'schedule',
  initialState: scheduleStateInit,
  reducers: {
    setScheduledObjectResponse(state, action) {
      state.scheduledWeekObjectResponse = action.payload.value;
    },
    setScheduleDayObjectResponse(state, action) {
      state.scheduleDayObjectResponse = action.payload.value;
    },
    resetToInit(state) {
      state = scheduleStateInit;
    }
  }
});