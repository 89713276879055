import List from "@mui/material/List"
import {
  Avatar, FormControl, FormControlLabel, FormLabel,
  InputLabel,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem, Radio, RadioGroup,
  Switch
} from "@mui/material";
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import {green, pink, red} from "@mui/material/colors";
import {useSelector} from "react-redux";
import {RootState, scheduleActions, taskInfoActions} from "../../store/storeIndex";
import CustomTextField from "../general/CustomTextField";
import {CalendarViews, getTaskToDisplay} from "../../service/TasksHelper";
import SearchIcon from "@mui/icons-material/Search";
import React, {Fragment, useEffect, useState} from "react";
import {GREEN} from "../../service/generic/ColorScheme";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {Priority, TaskInfo} from "../../types/task/TaskTypes";
import {ArrowUpward, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";


type Props = {
  filterUnCompleted: boolean,
  setFilterUnCompleted: (on:boolean) => void,
  filterCompleted: boolean,
  setFilterCompleted: (on:boolean) => void,
  searchValue: string,
  setSearchValue: (text:string) => void,
  viewSelection: CalendarViews,
}

const TaskDisplayFilter:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const tasks = useSelector((state: RootState) => state.taskInfo);
  const taskPriorityFilterGlobal = useSelector((state: RootState) => state.taskInfo.taskPriorityFilter);

  const [taskPriorityFilterLocal, setTaskPriorityFilterLocal] = React.useState("");
/*  const taskInfo = useSelector((state: RootState) => state.taskInfo.taskInfo);
  const [taskInfoState, setTaskInfoState] = useState<TaskInfo>(taskInfo);*/

  useEffect(() => {
    setTaskPriorityFilterLocal(taskPriorityFilterGlobal);
  }, [taskPriorityFilterGlobal])

/*  const onChangePriority = (event: React.ChangeEvent<HTMLInputElement>) => {
/!*    let newTaskState = {...taskInfoState};
    newTaskState.priority = (event.target as HTMLInputElement).value as Priority;*!/
    dispatch(taskInfoActions.setTaskPriorityFilter((event.target as HTMLInputElement).value as Priority))
  }*/

  const handlePriorityFilterChange = (value: string) => {
    dispatch(taskInfoActions.setTaskPriorityFilter({value:value}));
  }

  const priorities = [
      {key:'3rd', value:'LOW'},
      {key:'2nd', value: 'MID'},
      {key:'1st', value:'HIGH'}
  ];

  return (
      <List>
        <ListItem>
          <CustomTextField
              valueString={props.searchValue}
              label={'Search'}
              onInputUpdate={props.setSearchValue}
              variant={"outlined"}
              startAdornment={true}
          >
            <SearchIcon />
          </CustomTextField>
        </ListItem>
        <ListItem
            disablePadding
            secondaryAction={
              <Switch checked={props.filterCompleted} color={"success"}
                      onChange={() => props.setFilterCompleted(!props.filterCompleted)} />
            }
        >
          <ListItemButton>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: GREEN }}>
                {getTaskToDisplay(props.viewSelection, tasks)?.filter(task => task.completed).length}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Completed" />
          </ListItemButton>
        </ListItem>

        <ListItem
            disablePadding
            secondaryAction={
              <Switch checked={props.filterUnCompleted} color={"error"}
                      onChange={() => props.setFilterUnCompleted(!props.filterUnCompleted)}/>
            }
        >
          <ListItemButton>
            <ListItemIcon>
              <Avatar sx={{ bgcolor: red[500] }}>
                {getTaskToDisplay(props.viewSelection, tasks)?.filter(task => !task.completed).length}
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Uncompleted" />
          </ListItemButton>
        </ListItem>
        {/*<ListItem>
          <FormControl component="fieldset">
            <FormLabel component="legend">Priority</FormLabel>
            <RadioGroup row aria-label="gender"
                        value={taskPriorityFilterLocal}
                        onChange={onChangePriority}
                        name="row-radio-buttons-group">
              <FormControlLabel value={Priority.LOW} control={<Radio />}
                                label={
                                  <Fragment>
                                    {Priority.LOW}
                                    <KeyboardArrowDown style={{color:'blue'}}/>
                                  </Fragment>
                                } />
              <FormControlLabel value={Priority.MID} control={<Radio />}
                                label={
                                  <Fragment>
                                    {Priority.MID}
                                    <KeyboardArrowUp style={{color:'darkgoldenrod'}}/>
                                  </Fragment>
                                } />
              <FormControlLabel value={Priority.HIGH} control={<Radio />}
                                label={
                                  <Fragment>
                                    {Priority.HIGH}
                                    <ArrowUpward style={{color:'darkred'}}/>
                                  </Fragment>
                                } />
            </RadioGroup>
          </FormControl>
        </ListItem>*/}
        <ListItem>
          <FormControl fullWidth>
            <InputLabel id="stateLabel">Assignment Order</InputLabel>
            <Select
                labelId="stateLabel"
                id="stateSelect"
                style={{width:'100%'}}
                value={taskPriorityFilterLocal}
                label="Assignment Order"
                onChange={(event: SelectChangeEvent) => handlePriorityFilterChange(event.target.value)}
                sx={{float:"right"}}
            >
              <MenuItem value={"State.."}>Assignment Order..</MenuItem>
              { priorities.map(priority => (
                  <MenuItem value={priority.value} key={priority.key}>{priority.key}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
      </List>
  );
}
export default TaskDisplayFilter;