import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton, List, ListItem,
  Pagination,
  Stack,
  Tooltip,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {Add, ArrowForward, CancelOutlined, Delete, DeleteOutlined, FilterAlt} from "@mui/icons-material";
import {AccountBudget} from "../../types/task/BudgetTypes";
import CustomTextField from "../general/CustomTextField";
import {runInNewContext} from "vm";
import {useSelector} from "react-redux";
import {alertActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {taskManagementService} from "../../service/taskManagementService";
import {determineIfTokenError} from "../../store/account/authSlice";
import SearchIcon from "@mui/icons-material/Search";
import {OrgInfo, Role} from "../../types/account/AccountTypes";
import {paginationInit} from "../../types/PageSortTypes";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {displayAlert} from "../../store/alertSlice";
import ConfirmDialog from "../general/ConfirmDialog";
import {CalendarViews} from "../../service/TasksHelper";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {HtmlTooltip} from "../general/HtmlTooltip";
import {OrgInfoValid} from "../../types/account/AccountValids";
import {LocationNameValid} from "../../types/task/Location";
import EditLocationDialog from "../general/EditLocationDialog";
import {formatDate} from "../../service/generic/DateHelper";
import {TaskInfo} from "../../types/task/TaskTypes";
import ChangeLocationsOnTasksDialog from "../general/ChangeLocationsOnTasksDialog";
import {checkUserNotOrdinaryWorker} from "../../service/account/accountService";
import {DARK_NAVY, GHOST_WHITE, LIGHT_NAVY} from "../../service/generic/ColorScheme";

type Props = {
  showMonthlyTasksLoading: boolean
}

const TaskLocationsConfig:React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const userRole = useSelector((state: RootState) => state.userInfo.userInfo.role);
  const taskLocationsPageGlobal = useSelector((state: RootState) => state.taskInfo.taskLocations);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const userInfo = useSelector((state: RootState) => state.userInfo.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);
  const locationIdToDeleteGlobal = useSelector((state: RootState) => state.taskInfo.locationIdToDelete);
  /*const [locationIdToDeleteLocal, setLocationIdToDeleteLocal] = useState('');*/

  const [tasksToChangeLocation, setTasksToChangeLocation] = React.useState<TaskInfo[]>([]);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [locationEditDialogOpen, setLocationEditDialogOpen] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const [addEnabled, setAddEnabled] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [addValid, setAddValid] = useState(false);
  const [newLocation, setNewLocation] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [isOpenDeleteConfirm, setIsOpenDeleteConfirm] = useState(false);
  const [locationIdToDelete, setLocationIdToDelete] = useState('');
  const [locationNameToChange, setLocationNameToChange] = useState('');
  const [isOpenChangeTaskLocationDialog, setIsOpenChangeTaskLocationDialog] = useState(false);

  useEffect(() => {
    setShowLoading(props.showMonthlyTasksLoading);
    console.log("65656565");
    console.log(locationIdToDeleteGlobal);
  }, [props.showMonthlyTasksLoading])

  useEffect(() => {
    setPageNum(taskLocationsPageGlobal.pagination.pageNumber);
  }, [taskLocationsPageGlobal.pagination.pageNumber])

/*  useEffect(() => {
    setLocationIdToDeleteLocal(locationIdToDeleteGlobal);
    console.log("87878787787");
    console.log(locationIdToDeleteGlobal);
  }, [locationIdToDeleteGlobal])*/

  const handleAddEnable = () => {
    if (addEnabled) {
      initValid();
    }
    setFilterEnabled(false);
    setAddEnabled(!addEnabled);
  }

  const toggleFilter = () => {
    setFilterEnabled(!filterEnabled);
    setAddEnabled(false);
  }

  const initValid = () => {
    setAddValid(false);
    setFormTouched(false);
    setNewLocation('');
  }

  const onAddChange= (val:string) => {
    setFormTouched(true);
    setAddValid(val.length > 1);
    setNewLocation(val);
  }

  const getLocationsListFiltered = () => {
    return taskLocationsPageGlobal.taskLocations.filter(location => location.locationName.toLowerCase().includes(searchValue.toLowerCase()) ||
        location.accountName.toLowerCase().includes(searchValue.toLowerCase()));
  }

  const submitNewLocation = () => {
    if (addValid) {
      setShowLoading(true);
      taskManagementService.addNewTaskLocation(newLocation, taskLocationsPageGlobal.pagination, allSelectedAccessCodes!)
          .then(resp => {
            dispatch(taskInfoActions.setTaskLocationsPage({locationsPage:resp.taskLocations}));
            initValid();
            setAddEnabled(false);
          })
          .catch(e => dispatch(determineIfTokenError(e)))
          .finally(() => setShowLoading(false));
    }
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setShowLoading(true);
    setPageNum(value);
    let newPage = {...taskLocationsPageGlobal.pagination}
    newPage.pageNumber = value;
    taskManagementService.getTaskLocations(newPage, allSelectedAccessCodes!)
        .then(resp => {
          dispatch(taskInfoActions.setTaskLocationsPage({locationsPage:resp.taskLocations}));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => setShowLoading(false));
  };

  const onLocationDelete = (id:string) => {
    setShowLoading(true);
    setIsOpenDeleteConfirm(false);
    //set global id here
    dispatch(taskInfoActions.setLocationIdToDelete({value:id}));
    let newPage = {...taskLocationsPageGlobal.pagination}
    newPage.pageNumber = pageNum;
    taskManagementService.getAllTasksWithAssignedLocation(allSelectedAccessCodes!, id)
      .then(resp => {
        setTasksToChangeLocation(resp);
        if(resp.length == 0){
          taskManagementService.deleteLocation(newPage, allSelectedAccessCodes!, id)
            .then(resp => {
              dispatch(taskInfoActions.setTaskLocationsPage({locationsPage:resp.taskLocations}));
              dispatch(displayAlert(true, 'task location successfully deleted'));
            })
            .catch(e => dispatch(determineIfTokenError(e)))
            .finally(() => {
              setShowLoading(false);
              setIsOpenChangeTaskLocationDialog(false);
            });
        }
        else{
          setIsOpenChangeTaskLocationDialog(true);
        }
      })
      .finally(() => {
        setShowLoading(false);
      });
  }

  return (
      <Fragment>
        {checkUserNotOrdinaryWorker(userInfo) &&
        <Card sx={{backgroundColor:GHOST_WHITE, width:'100%', flex: 1}}>
          <CardHeader
              title={
                <Typography variant="h5" component="div" style={{color:DARK_NAVY, fontWeight:"bold"}}>
                  Task Locations
                  <hr/>
                </Typography>
              }
              action = {
                <Stack direction={'row'} spacing={0}>
                  {!showLoading && allSelectedAccessCodes?.length === 1 && userRole !== Role.ORG_WORKER &&
                  <Box>
                    <HtmlTooltip title={
                      <Fragment>
                        <em>Add Location</em>
                      </Fragment>
                    }>
                      <IconButton hidden={showLoading} aria-label="edit" style={{color:DARK_NAVY}} onClick={handleAddEnable}>
                        {!addEnabled && <Add/>}
                        {addEnabled && <CancelOutlined/>}
                      </IconButton>
                    </HtmlTooltip>
                  </Box>
                  }
                  <HtmlTooltip title={
                    <Fragment>
                      <em>Filter Locations</em>
                    </Fragment>
                  }>
                    <IconButton hidden={showLoading} aria-label="edit" style={{color:DARK_NAVY}} onClick={toggleFilter}>
                      {!filterEnabled && <FilterAlt/>}
                      {filterEnabled && <CancelOutlined/>}
                    </IconButton>
                  </HtmlTooltip>
                </Stack>
              }
          />
          <CardContent sx={{pt:0, maxHeight:'400px', overflowY:"auto"}}>
            {!showLoading &&
            <Fragment>
              {addEnabled &&
              <Box sx={{mb: 2}}>
                <CustomTextField
                    isValid={addValid || !formTouched}
                    validationText={"Must be a valid Location name"}
                    valueString={newLocation}
                    label={"New Location Name"}
                    onEnterKeyDownSubmitHandler={submitNewLocation}
                    onInputUpdate={(val) => onAddChange(val)}
                    endAdornment={true}
                >
                  <Tooltip title={'Submit'}>
                    <IconButton sx={{backgroundColor: addValid ? LIGHT_NAVY : "ghostwhite"}}
                                style={{cursor: addValid ? 'pointer' : 'not-allowed'}}
                                onClick={() => submitNewLocation()}>
                      <ArrowForward/>
                    </IconButton>
                  </Tooltip>
                </CustomTextField>
              </Box>
              }

              {filterEnabled &&
                <List>
                  <ListItem>
                    <CustomTextField
                        sx={{mb: 2}}
                        valueString={searchValue}
                        label={'Search'}
                        onInputUpdate={setSearchValue}
                        variant={"outlined"}
                        startAdornment={true}
                    >
                      <SearchIcon/>
                    </CustomTextField>
                  </ListItem>
                </List>
              }

              {!showLoading && allSelectedAccessCodes?.length !== 1 && userRole !== Role.ORG_WORKER &&
              <Fragment>
                <Alert severity="info" color="info" sx={{mb: 2}}>
                  <Typography variant={'body1'} component={"div"}>
                    You can only have one organization selected to add, edit, or delete a location.
                  </Typography>
                </Alert>
              </Fragment>
              }

              <List>
                {getLocationsListFiltered().map((location, index) => (
                    <Fragment>
                      <ListItem key={'cat' + index}
                                secondaryAction={
                                  <Stack direction={"row"}>
                                    {!showLoading && allSelectedAccessCodes?.length === 1 && userRole !== Role.ORG_WORKER &&
                                    <Box>
                                      <HtmlTooltip title={
                                        <Fragment>
                                          <em>Edit Location</em>
                                        </Fragment>
                                      }>
                                        <IconButton style={{color:DARK_NAVY}} onClick={() => {
                                          setLocationNameToChange(location.locationName);
                                          setLocationIdToDelete(location.locationId);
                                          setLocationEditDialogOpen(true);
                                        }}>
                                          <EditIcon/>
                                        </IconButton>
                                      </HtmlTooltip>
                                      <HtmlTooltip title={
                                        <Fragment>
                                          <em>Delete Location</em>
                                        </Fragment>
                                      }>
                                        <IconButton style={{color:DARK_NAVY}} onClick={() => {
                                          setLocationIdToDelete(location.locationId);
                                          setIsOpenDeleteConfirm(true)
                                        }}>
                                          <Delete/>
                                        </IconButton>
                                      </HtmlTooltip>
                                    </Box>
                                    }
                                  </Stack>
                                }
                      >
                        <Stack direction={'row'} spacing={3} divider={<Divider orientation={'vertical'} flexItem/>}>
                          <Typography variant="subtitle1" style={{color:DARK_NAVY}} component="div">
                            {location.locationName}
                            {allSelectedAccessCodes && allSelectedAccessCodes?.length > 1 &&
                            <Typography variant="caption" style={{color:DARK_NAVY}} component="div">
                              {location.accountName}
                            </Typography>
                            }
                          </Typography>
                          {/*{category.s

                            }*/}
                        </Stack>
                      </ListItem>
                      <Divider component="li" sx={{my: 1}}/>
                    </Fragment>
                ))}
              </List>
            </Fragment>
            }

            <LoadingSpinnerContainer showLoading={showLoading} />

          </CardContent>
          <Box sx={{display:'flex', justifyContent:'center', mt:1, mb:2}}>
            <Pagination count={Math.ceil(taskLocationsPageGlobal.pagination.totalResults / taskLocationsPageGlobal.pagination.pageSize)}
                        page={taskLocationsPageGlobal.pagination.pageNumber}
                        onChange={handlePageChange} variant="outlined" color="primary" />
          </Box>
        </Card>
        }

        <ConfirmDialog handleSubmit={onLocationDelete}
                       title={"Delete Task Location"}
                       id={locationIdToDelete}
                       isOpen={isOpenDeleteConfirm} setIsOpen={setIsOpenDeleteConfirm} />

        <EditLocationDialog
                       title={"Edit Location"}
                       selectedDate={formatDate(selectedDate)!}
                       name={locationNameToChange}
                       id={locationIdToDelete}
                       isOpen={locationEditDialogOpen} setIsOpen={setLocationEditDialogOpen} />

        <ChangeLocationsOnTasksDialog
            title={"Change Locations"}
            selectedDate={formatDate(selectedDate)!}
            taskList={tasksToChangeLocation}
            isOpen={isOpenChangeTaskLocationDialog} setIsOpen={setIsOpenChangeTaskLocationDialog}
            handleRefreshTaskList={onLocationDelete}
            selectedId={locationIdToDelete}
        />

      </Fragment>
  );
}

export default TaskLocationsConfig;