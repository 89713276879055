import {AllowedEmail, Role} from "../account/AccountTypes";
import {TableCellFuncProps} from "../../component/general/table/CustomTable";
import React, {Fragment} from "react";
import {Box, IconButton, Stack, TableCell} from "@mui/material";
import {CompanyDirectoryUtil} from "../task/TaskTypes";
import {currencyFormat} from "../../service/generic/DateHelper";
import {Visibility} from "@mui/icons-material";
import {checkUserAdmin} from "../../service/account/accountService";


export interface HeadCell {
  disablePadding: boolean;
  id: keyof AllowedEmail;
  label: string;
  numeric: boolean;
}

export const headCells: HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'name',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: true,
    label: 'email',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: true,
    label: 'Phone Number',
  },
  {
    id: 'registeredDate',
    numeric: false,
    disablePadding: true,
    label: 'registered date',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: true,
    label: 'role',
  },
  {
    id: 'account',
    numeric: false,
    disablePadding: true,
    label: 'account',
  },
  {
    id: 'hourlyPay',
    numeric: false,
    disablePadding: true,
    label: 'hourly pay',
  }
];

export const AllowedMemberTableCells = (props: TableCellFuncProps) => {
  let row = props.row as AllowedEmail;

  let showPay = props.showMaskedField &&
      ((props.user && checkUserAdmin(props.user))
          || row.role === Role.ORG_WORKER || row.email === props.user?.username);

  return (
      <Fragment>
          <TableCell id={props.labelId} align="left">
            {row.name}
          </TableCell>
          <TableCell id={props.labelId} align="left">
            {row.email}
          </TableCell>
          <TableCell id={props.labelId} align="left">
            {row.phoneNumber}
          </TableCell>
          <TableCell align="left">
            {row.registeredDate ? row.registeredDate : 'NOT REGISTERED'}
          </TableCell>
          <TableCell align="left">
            {row.role}
          </TableCell>
          <TableCell align="left">
            {row.accountName}
          </TableCell>
          <TableCell align="left">
            {showPay ? currencyFormat(row.hourlyPay) : '$**.**'}
          </TableCell>
      </Fragment>
  );
}

export interface CompanyDirectoryHeadCell {
  disablePadding: boolean;
  id: keyof CompanyDirectoryUtil;
  label: string;
  numeric: boolean;
}

export const cdHeadCells: CompanyDirectoryHeadCell[] = [
  {
    id: 'employeeFirstName',
    numeric: false,
    disablePadding: false,
    label: 'first name',
  },
  {
    id: 'employeeLastName',
    numeric: false,
    disablePadding: true,
    label: 'last name',
  },
  {
    id: 'accountName',
    numeric: false,
    disablePadding: true,
    label: 'account',
  },
  {
    id: 'userEmail',
    numeric: false,
    disablePadding: true,
    label: 'email',
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: true,
    label: 'phone number',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: true,
    label: 'role',
  }
];

export const CompanyDirectoryTableCells = (props: TableCellFuncProps) => {
  let row = props.row as CompanyDirectoryUtil;
  return (
      <Fragment>
        <TableCell id={props.labelId} align="left">
          {row.employeeFirstName}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.employeeLastName}
        </TableCell>
        <TableCell id={props.labelId} align="left">
          {row.accountName}
        </TableCell>
        <TableCell align="left">
          {row.userEmail}
        </TableCell>
        <TableCell align="left">
          {row.phoneNumber}
        </TableCell>
        <TableCell align="left">
          {row.role}
        </TableCell>
      </Fragment>
  );
}