import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem, Radio, RadioGroup,
  Stack
} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {AccountParent, AllowedEmail, OrgInfo, OrgInfoState, Role} from "../../types/account/AccountTypes";
import React, {Fragment, useEffect, useState} from "react";
import {AeValid, allowedEmailValid, emailRegex} from "../../types/account/AccountValids";
import {useSelector} from "react-redux";
import {experienceActions, orgInfoActions, RootState, taskInfoActions} from "../../store/storeIndex";
import {accountService, checkUserAdmin, checkUserManagerForParentOrg} from "../../service/account/accountService";
import {AttachMoney, Money} from "@mui/icons-material";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useAppThunkDispatch} from "../../store/storeHooks";
import {DARK_NAVY, LIME_GREEN} from "../../service/generic/ColorScheme";
import {grey} from "@mui/material/colors";
import {userInfo} from "os";
import {allowedEmailInit} from "../../types/account/AccountInits";
import {displayAlert} from "../../store/alertSlice";

type Props = {
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  action: string,
  member?: AllowedEmail | undefined
}

const AddModifyAllowedMembersDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();
  const [showLoading, setShowLoading] = useState(false);

  const orgInfoGS = useSelector((state: RootState) => state.orgInfo.orgInfo);
  const orgAccountsGS = useSelector((state: RootState) => state.orgInfo.orgAccounts);
  const userInfoGS = useSelector((state: RootState) => state.userInfo.userInfo);

  const [allowedMember, setAllowedMember] = useState((props.action === 'Edit' && props.member) ? {...props.member} : {...allowedEmailInit});
  const [allowedMemberValid, setAllowedMemberValid] = useState({...allowedEmailValid});
  const [memberFormTouched, setMemberFormTouched] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [accountOptions, setAccountOptions] = useState<AccountParent[]>([...orgAccountsGS]);
  const [emailExistsInSystemAlready, setEmailExistsInSystemAlready] = useState(false);

  useEffect(() => {
    props.action === 'Edit' && props.member && setAllowedMember(props.member)
  }, [props.member, props.action])

  useEffect(() => {
    document.title = "Add/Modify Allowed Members Dialog";
  }, []);

  useEffect(() => {
    if (allowedMemberValid.email && props.action === 'Add' &&
        allowedMember.email && allowedMember.email.length > 0) {
      checkIfEmailAlreadyExists(allowedMember.email)
    }
  }, [allowedMemberValid.email, allowedMember.email])

  useEffect(() => {
    setEmailExistsInSystemAlready(false);
  }, [props.isOpen])

  useEffect(() => {
    if (allowedMember.registeredDate) {
      console.log("allowedMemberValid");
      allowedMemberValid.email = true;
    }
  });

  useEffect(() => {
    if (checkUserAdmin(userInfoGS) && orgAccountsGS.length > 0) {
      if (accountOptions.includes(orgInfoGS)) {
        setAccountOptions([...accountOptions]);
      } else {
        setAccountOptions([orgInfoGS, ...accountOptions]);
      }
    }
  }, [])

  const handleSubmit = () => {
    if (isNewAllowedMemberFormValid()) {
      //if we are adding a new member this fires
      if(props.action === "Add"){
        setShowLoading(true);
        accountService.addNewAllowedMember(allowedMember)
          .then((newOrgInfoState: OrgInfoState) => {
            dispatch(orgInfoActions.updateAccountSuccess({orgInfoState: newOrgInfoState}));
            dispatch(displayAlert(true, 'new allowed member successfully added'));

            dispatch(experienceActions.setIsOrgAdminRegistration({value:false}));
            dispatch(experienceActions.setShowOrgMembersTutorial({value:false}));
            dispatch(experienceActions.setShowOrgAccountsTutorial({value:false}));
            dispatch(experienceActions.setSkippedOrgAccounts({value:false}));

            handleCloseDialog();
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => setShowLoading(false));
      } else {
        //if we are editing an existing member this fires
        setShowLoading(true);
        accountService.editAllowedMember(allowedMember)
          .then((newOrgInfoState: OrgInfoState) => {
            dispatch(orgInfoActions.updateAccountSuccess({orgInfoState: newOrgInfoState}));
            dispatch(displayAlert(true, 'allowed member successfully updated'));
            setSelectedRows([]);
            handleCloseDialog();
          })
          .catch(e => {
            dispatch(determineIfTokenError(e));
          }).finally(() => setShowLoading(false));
      }
    }
  }

  const checkIfEmailAlreadyExists = (email:string) => {
    accountService.checkIfEmailAlreadyExists(email)
        .then(notExists => {
          setEmailExistsInSystemAlready(notExists);
        }).catch((e) => dispatch(determineIfTokenError(e)))
  }

  const isPhoneValid = (val:string) => {
    return val && val?.trim().length === 10 && !isNaN(Number(val))
  }

  const determineHourlyPayValid = (value:string):boolean => {
    value = value.toString()
    return value.length >= 0 &&
        Number(value) > -1 &&
        (!value.includes('.') || value.substring(value.indexOf('.') + 1).length === 2);
  }

  const determineContractPayValid = (value:string):boolean => {
    value = value.toString()
    return value.length >= 0 &&
        Number(value) > -1;
  }

  const determineEmailValid = (email: string) => {
    return  email.trim().length > 0 && emailRegex.test(email.toLowerCase());
  }

  const isNewAllowedMemberFormValid = () => {

    if(allowedMember.role !== "ORG_CONTRACT"){
      return memberFormTouched && determineEmailValid(allowedMember.email) && allowedMember.role.length > 0  && isPhoneValid(allowedMember.phoneNumber) &&
          determineHourlyPayValid(allowedMember.hourlyPay) && allowedMember.firstname && allowedMember.lastname && !emailExistsInSystemAlready;
    }
    else{
      return memberFormTouched && determineEmailValid(allowedMember.email) && allowedMember.role.length > 0  && isPhoneValid(allowedMember.phoneNumber) &&
          determineHourlyPayValid(allowedMember.hourlyPay) && allowedMember.firstname && !emailExistsInSystemAlready;
    }
  }

  const onChangeEmail = (emailVal:string) => {
    setMemberFormTouched(true);
    determineEmailValid(emailVal);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.email = emailVal;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangeFirstname = (val:string) => {
    setMemberFormTouched(true);
    allowedMemberValid.firstname = val.trim().length > 1;
    setAllowedMemberValid(allowedMemberValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.firstname = val;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangeLastname = (val:string) => {
    setMemberFormTouched(true);
    let newValid = {...allowedMemberValid}
    newValid.lastname = val.trim().length > 1;
    setAllowedMemberValid(newValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.lastname = val;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangePhoneNumber = (pNumberValue:string) => {
    setMemberFormTouched(true);
    let newValid = {...allowedMemberValid}
    newValid.phoneNumber = pNumberValue.trim().length === 10 && !isNaN(Number(pNumberValue));
    setAllowedMemberValid(newValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.phoneNumber = pNumberValue;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangeRole = (roleVal:Role) => {
    setMemberFormTouched(true);
    let newValid = {...allowedMemberValid}
    newValid.role = true;
    setAllowedMemberValid(newValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.role = roleVal;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangeAccount = (accessCode:string) => {
    setMemberFormTouched(true);
    let newValid = {...allowedMemberValid}
    newValid.account = true;
    setAllowedMemberValid(newValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.account = accessCode;
    setAllowedMember(newAllowedMemberState);
  }

  const onChangeHourlyPay = (value:string) => {
    setMemberFormTouched(true);
    let newValid = {...allowedMemberValid}
    newValid.hourlyPay = determineHourlyPayValid(value);
    setAllowedMemberValid(newValid);
    let newAllowedMemberState = {...allowedMember};
    newAllowedMemberState.hourlyPay = value;
    setAllowedMember(newAllowedMemberState);
  }

  const handleCloseDialog = () => {
    setAllowedMember((props.action === 'Edit' && props.member) ? {...props.member} : {...allowedEmailInit});
    setAllowedMemberValid({...allowedEmailValid});
    setMemberFormTouched(false);
    props.setIsOpen(false);
  }

  return (
      <Dialog maxWidth={"sm"} fullWidth={true} sx={{zIndex:1300}}
              open={props.isOpen} onClose={props.setIsOpen}>
        <DialogTitle>{props.action} Allowed Member</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <CustomTextField
                disabled={!!allowedMember.registeredDate && userInfoGS.role !== Role.ADMIN}
                valueString={allowedMember.email}
                label={"Email"}
                isValid={determineEmailValid(allowedMember.email) || !memberFormTouched}
                validationText={'must be a valid email'}
                onEnterKeyDownSubmitHandler={handleSubmit}
                onInputUpdate={onChangeEmail}
            />
            <small style={{color:'red', marginTop:0}} hidden={!emailExistsInSystemAlready || !memberFormTouched}>
              This email already exists
            </small>
            <CustomTextField
                valueString={allowedMember.firstname}
                label={allowedMember.role === "ORG_CONTRACT" ? "Contractor Name" : "First Name"}
                isValid={allowedMember?.firstname?.length > 0 || !memberFormTouched}
                validationText={'must be a valid name'}
                onEnterKeyDownSubmitHandler={handleSubmit}
                onInputUpdate={onChangeFirstname}
            />
            {allowedMember.role !== "ORG_CONTRACT" &&
              <CustomTextField
                  valueString={allowedMember.lastname}
                  label={"Last Name"}
                  isValid={allowedMember?.lastname?.length > 0 || !memberFormTouched}
                  validationText={'must be a valid name'}
                  onEnterKeyDownSubmitHandler={handleSubmit}
                  onInputUpdate={onChangeLastname}
              />
            }
            <CustomTextField
                valueString={allowedMember.phoneNumber}
                isValid={isPhoneValid(allowedMember.phoneNumber) || !memberFormTouched}
                label={"Phone #"}
                validationText={'Invalid Phone Number'}
                onEnterKeyDownSubmitHandler={handleSubmit}
                onInputUpdate={onChangePhoneNumber}
            />
            {/*<InputLabel id="newAllowedMemberRoleSelectLabel">Age</InputLabel>*/}

            <Select
                /*labelId="newAllowedMemberRoleSelectLabel"*/
                id="newAllowedMemberRoleSelect"
                required
                displayEmpty
                value={allowedMember.role}
                onChange={(event: SelectChangeEvent) => onChangeRole(event.target.value as Role)}
            >
              <MenuItem disabled value="" key={''}><em>Choose a Role</em></MenuItem>
                {(allowedMember.account !== orgInfoGS.accessCode) &&
                  <MenuItem key={Role.ORG_WORKER} value={Role.ORG_WORKER}>{Role.ORG_WORKER}</MenuItem>
                }
              <MenuItem key={Role.ORG_MANAGER} value={Role.ORG_MANAGER}>{Role.ORG_MANAGER}</MenuItem>
              {/*<MenuItem key={Role.ORG_CONTRACT} value={Role.ORG_CONTRACT}>{Role.ORG_CONTRACT}</MenuItem>*/}
            </Select>
            <small style={{color:'red', marginTop:0}} hidden={allowedMember.role.length > 0 || !memberFormTouched}>
              please select a role
            </small>

            { (checkUserAdmin(userInfoGS) || checkUserManagerForParentOrg(userInfoGS, orgInfoGS.accessCode)) &&
                <Fragment>
                  <Select
                      /*labelId="newAllowedMemberRoleSelectLabel"*/
                      id="newAllowedMemberAccountSelect"
                      required
                      displayEmpty
                      value={allowedMember.account}
                      onChange={(event: SelectChangeEvent) => onChangeAccount(event.target.value as string)}
                  >
                    <MenuItem disabled value=""><em>Choose an Account</em></MenuItem>
                    { accountOptions.map((accountOption: AccountParent) => (
                        <MenuItem key={accountOption.accountId}
                                  value={accountOption.accessCode} disabled={!(accountOption.accessCode !== orgInfoGS.accessCode || allowedMember.role !== Role.ORG_WORKER)}>
                          {accountOption.name}
                        </MenuItem>
                    ))}
                  </Select>
                </Fragment>
            }
            <CustomTextField
                valueString={allowedMember.hourlyPay}
                label={allowedMember.role === "ORG_CONTRACT" ? "Contract Yearly Pay" : "Hourly Pay"}
                isValid={determineHourlyPayValid(allowedMember.hourlyPay) || !memberFormTouched}
                validationText={'must be a valid dollar value'}
                onEnterKeyDownSubmitHandler={handleSubmit}
                onInputUpdate={onChangeHourlyPay}
                startAdornment={true}
            >
              <AttachMoney/>
            </CustomTextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button style={{color:DARK_NAVY}} onClick={handleCloseDialog}>Cancel</Button>

          <Button onClick={handleSubmit} variant={isNewAllowedMemberFormValid() ? "contained" : "text"}
                  style={{cursor: !isNewAllowedMemberFormValid() ? 'not-allowed' : 'pointer',
                    color:DARK_NAVY,
                    fontWeight:"bold",
                    backgroundColor: isNewAllowedMemberFormValid() ? LIME_GREEN : grey[200],
                    borderColor: isNewAllowedMemberFormValid() ? LIME_GREEN : DARK_NAVY,
                    border:"1px solid"
                  }}>
            Submit
          </Button>

        </DialogActions>
      </Dialog>
  );
}

export default AddModifyAllowedMembersDialog;