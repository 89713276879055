import React, {useCallback} from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

type Props = {
    ref: React.RefObject<HTMLElement>
}

const useDownloadPdf = ({ref}: Props) => {

    const downloadPdf = useCallback(async ({asBlob = false, filename = "apperform-download"}) => {
        if (!ref?.current) return
        const doc = new jsPDF({
            orientation: 'portrait',
            unit: 'in',
            format: 'letter'
        });

        let width = doc.internal.pageSize.getWidth();
        let height = doc.internal.pageSize.getHeight();

        const canvas = await html2canvas(ref?.current, {allowTaint: true, useCORS: true});
        await doc.addImage({imageData:canvas, x:0, y:0, width:width, height:height})

        if (!asBlob) {
            doc.save(filename + '.pdf')
        } else {
            return doc.output('blob')
        }
    }, [ref])

    return {downloadPdf}
}

export default useDownloadPdf