import {ApiErrorResponse} from "../../types/ApiErrorResponse";

export const httpCommonService = {
  handleApiError,
}

async function handleApiError(response: any) {
  if (response.status !== 200) {
    if (response.status === 404) {
      throw Error("There was an unexpected network failure");
    }

    let errorResponse: ApiErrorResponse = await response.json();
    if (errorResponse.status === 401) {
      console.log("401 token expired");
      throw Error("Your token is expired. Please sign in.");
    } else if (errorResponse.status === 500) {
      console.log(500);
    }
    throw Error(errorResponse.message);
  }
  return response;
}
