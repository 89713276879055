import React, {Fragment, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip, Typography, useMediaQuery
} from "@mui/material";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {DARK_NAVY} from "../../service/generic/ColorScheme";
import ValidButton from "../general/ValidButton";
import {DayStats, ScheduleObject, TaskInfo, TaskResponse} from "../../types/task/TaskTypes";
import {useSelector} from "react-redux";
import {RootState, taskInfoActions} from "../../store/storeIndex";
import { format } from "date-fns";
import {formatDate} from "../../service/generic/DateHelper";
import {useAppThunkDispatch} from "../../store/storeHooks";
import PlayGroundSafetyInspectionForm from "./PlayGroundSafetyInspectionForm";
import {PlaygroundSafetyFormUtilInfoInit} from "../../store/task/taskInfoSlice";
import {PDF_WIDTH_PIXELS} from "../../util/constants";
import useDownloadPdf from "../../hooks/useDownloadPdf";

type Props = {
    accountId: string,
    taskId: string,
    isOpen: boolean,
    setIsOpen: (open: false) => void,
    task?: TaskInfo,
    setCompleteDialogOpen: (open: false) => void,
    getMonthlyTasks?: (ac: AbortController) => void,
    getJobBoardUtil?: () => void, //for job board
    handleTaskSelected?:(task: TaskInfo) => void, //for job board
    getFullMonthResponse?: () => void, // for task management page
    handleDaySelected?: ((weekDayDisplayName: string, taskList: TaskInfo[], scheduledEmployeeList: ScheduleObject[], dayStats: DayStats, selectedDay: TaskResponse) => void), // for monthly calendar
    day?:TaskResponse, // for monthly calendar
}

interface CheckPoints {
    order: number,
    tag: string;
    compliant: boolean | null;
    hazard: boolean | null;
}

interface DtoObject {
    location: string;
    inspectionDate: string;
    inspectionTime: string;
    inspectedBy: string[];
    ageAppropriate: string;
    type: string;
    safetyCheckPoints: CheckPoints[];
    equipmentCheckPoints: CheckPoints[];
    comments: string;
}

const ssQuestions: CheckPoints[] = [
    { order: 1, tag: "Transition from the path to surfacing is less than .5”.", compliant: false, hazard: false },
    { order: 2, tag: "Surfacing material is adequate and proper depth.", compliant: false, hazard: false },
    { order: 3, tag: "Loose fill is at correct height at Transfer Platform.", compliant: false, hazard: false },
    { order: 4, tag: "Loose fill is at correct depth at Slide exits & Swings.", compliant: false, hazard: false },
    { order: 5, tag: "Surfacing material is free from weeds and trash.", compliant: false, hazard: false },
    { order: 6, tag: "No roots, rocks or objects are a tripping hazard.", compliant: false, hazard: false },
    { order: 7, tag: "Unitary surface retains adequate absorbing abilities.", compliant: false, hazard: false },
    { order: 8, tag: "", compliant: false, hazard: false },
];

const eQuestions: CheckPoints[] = [
    { order: 9, tag: "Warning/information sign and/or labels are in place.", compliant: false, hazard: false },
    { order: 10, tag: "Equipment and site furnishings are free of debris.", compliant: false, hazard: false },
    { order: 11, tag: "No footings are exposed, cracked, or loose.", compliant: false, hazard: false },
    { order: 12, tag: "Clamps and rivets are in place and secure.", compliant: false, hazard: false },
    { order: 13, tag: "All barriers and guardrails are in place and secure.", compliant: false, hazard: false },
    { order: 14, tag: "Decks are level, secure and coated with no metal.", compliant: false, hazard: false },
    { order: 15, tag: "No rust, corrosion or peeling paint.", compliant: false, hazard: false },
    { order: 16, tag: "Plastic is smooth, no cracks or cuts.", compliant: false, hazard: false },
    { order: 17, tag: "Tree limbs are greater than 84\" from equipment.", compliant: false, hazard: false },
    { order: 18, tag: "Border around playground is adequate and secure.", compliant: false, hazard: false },
    { order: 19, tag: "All hardware is in place and secure.", compliant: false, hazard: false },
    { order: 20, tag: "Upright and pipe caps are in place and secure.", compliant: false, hazard: false },
    { order: 21, tag: "No Excessive wear, broken or missing parts.", compliant: false, hazard: false },
    { order: 22, tag: "", compliant: false, hazard: false },
];

const TaskPdfDialog: React.FC<Props> = (props) => {
    let dispatch = useAppThunkDispatch();

    const [showLoading, setShowLoading] = React.useState(false);
    const [formSelected, setFormSelected] = React.useState("playground-safety-inspection");
    const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
    const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);

    const isWindowSmallerThanCanvas = useMediaQuery(`(max-width:${PDF_WIDTH_PIXELS}px)`);
    const [showPreview, setShowPreview] = React.useState(false);
    const ref = useRef<HTMLDivElement>(null)
    const {downloadPdf} = useDownloadPdf({ref:ref})

    const [pgSafetyInspectionFormInfoState, setPgSafetyInspectionFormInfoState] = useState<{ [key: string]: any; }>(PlaygroundSafetyFormUtilInfoInit);
    const [safetyCheckPoints, setSafetyCheckPoints] = useState<CheckPoints[]>(ssQuestions);
    const [equipmentCheckPoints, setEquipmentCheckPoints] = useState<CheckPoints[]>(eQuestions);

    const closeTaskPdfDialog = () => {
        props.setIsOpen(false);
        props.setCompleteDialogOpen(false);
        setShowPreview(false);
        setPgSafetyInspectionFormInfoState(PlaygroundSafetyFormUtilInfoInit);
        setEquipmentCheckPoints(eQuestions);
        setSafetyCheckPoints(ssQuestions)

        console.log('refreshing')
        const ac = new AbortController();

        if(props.getMonthlyTasks){
            console.log('getMonthlyTasks')
            props.getMonthlyTasks(ac)
        }
        if(props.getJobBoardUtil){
            console.log('getJobBoardUtil')
            props.getJobBoardUtil()
            if(props.handleTaskSelected){
                props.handleTaskSelected(props.task)
            }
        }
        if(props.getFullMonthResponse){
            console.log('getFullMonthResponse')
            props.getFullMonthResponse()
            /*            props.handleDaySelected(props.day.weekNameDisplay, props.day.taskList,
                            props.day.scheduledEmployeesList, props.day.dayStats, props.day)*/
        }
    }

    const isFormValid = () => {
        let valid = true;

        return valid;
    }

    const createDtoObject = (): DtoObject => {
        const defaultDate = format(new Date(), 'yyyy-MM-dd');
        const defaultTime = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");

        return{
            location: pgSafetyInspectionFormInfoState.location,
            inspectionDate: pgSafetyInspectionFormInfoState.inspectionDate
                ? format(new Date(pgSafetyInspectionFormInfoState.inspectionDate), 'yyyy-MM-dd')
                : defaultDate,
            inspectionTime: pgSafetyInspectionFormInfoState.inspectionTime
                ? format(new Date(pgSafetyInspectionFormInfoState.inspectionTime), "yyyy-MM-dd'T'HH:mm:ss")
                : defaultTime,
            inspectedBy: pgSafetyInspectionFormInfoState.inspectedBy,
            ageAppropriate: pgSafetyInspectionFormInfoState.ageAppropriate,
            type: "PLAYGROUND_SAFETY",
            safetyCheckPoints: safetyCheckPoints,
            equipmentCheckPoints: equipmentCheckPoints,
            comments: pgSafetyInspectionFormInfoState.comments
        };
    };

    function handleSubmitForm()  {
        let dto = createDtoObject();

        try {
            downloadPdf({asBlob: true, filename: 'playground-form'}).then((pdf) => {
                let token = localStorage.getItem('user') as string;
                return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/task-form/playground-safety-form/submit/${formatDate(selectedDate)}/${allSelectedAccessCodes.toString()}/${props.accountId}/${props.taskId}`, {
                    method: 'POST',
                    body: JSON.stringify(dto),
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Content-Type': 'application/json',
                    }
                })
                    .then(async (response) => {
                        const formSubmitResponse = await response.json();
                        const presignedUrl = formSubmitResponse.url;
                        await fetch(await presignedUrl, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'application/pdf',
                            },
                            body: pdf,
                        })
                        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/playground-safety-form/set-to-public/${props.accountId}/${props.taskId}/${formSubmitResponse.imageName}`, {
                            method: 'PUT',
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json',
                            }
                        }).then(() => {
                            dispatch(taskInfoActions.setMonthlyTasks({monthlyTasks: formSubmitResponse.taskResponse?.taskList ?? []}));
                            dispatch(taskInfoActions.setSavedTemplates({taskTemplates: formSubmitResponse.taskResponse?.taskTemplates ?? []}));
                            closeTaskPdfDialog()
                        })
                    })
                    .catch(e => {
                        throw e
                    });
            })
        } catch (e) {throw e;}
    }

    // @ts-ignore
    return (
        <Fragment>
            { showLoading ? (
                <Box sx={{marginTop:'200px'}}>
                    <LoadingSpinnerContainer showLoading={showLoading} />
                </Box>
            ) : (
                <Dialog
                    sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 1000 } }}
                    maxWidth="xl"
                    open={props.isOpen}
                >
                    <DialogTitle style={{color:DARK_NAVY, fontWeight:"bold"}}>Attach PDF(s)</DialogTitle>

                    <FormControl component="fieldset" sx={{m:2}}>
                        <InputLabel id="acc">Choose Form:</InputLabel>
                        <Select
                            sx={{mb:2, width:'100%'}}
                            labelId="acc"
                            id="acc-select"
                            value={formSelected}
                            label="Select Account"
                            onChange={(e:SelectChangeEvent) => setFormSelected(e.target.value as string)}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            <MenuItem value="playground-safety-inspection">Playground Safety Inspection</MenuItem>
                        </Select>
                    </FormControl>

                    <DialogContent dividers>
                        {formSelected === "" &&
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Typography variant={"h5"} sx={{textAlign:'left', color:'red'}}>No form type selected.</Typography>
                            </Box>
                        }
                        {formSelected === "playground-safety-inspection" &&
                            <PlayGroundSafetyInspectionForm
                                taskId={props.taskId}
                                showPreview={showPreview}
                                setShowPreview={setShowPreview}
                                // @ts-ignore
                                ref={ref}
                                pgSafetyInspectionFormInfoState={pgSafetyInspectionFormInfoState}
                                setPgSafetyInspectionFormInfoState={setPgSafetyInspectionFormInfoState}
                                safetyCheckPoints={safetyCheckPoints}
                                setSafetyCheckPoints={setSafetyCheckPoints}
                                equipmentCheckPoints={equipmentCheckPoints}
                                setEquipmentCheckPoints={setEquipmentCheckPoints}
                                handleSubmitForm={handleSubmitForm}
                            />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button style={{color:DARK_NAVY}} autoFocus onClick={closeTaskPdfDialog}>
                            Cancel
                        </Button>
                        {formSelected &&
                            <Tooltip title={isWindowSmallerThanCanvas ? "Window size needs to be wider." : "Preview Pdf"} placement="top">
                              <Box>
                                <ValidButton text={showPreview ? 'Submit' : 'Preview'}
                                             disabled={isWindowSmallerThanCanvas}
                                             handleSubmit={() => showPreview ? handleSubmitForm() : setShowPreview(true)}
                                             isFormValid={isFormValid}
                                />
                              </Box>
                            </Tooltip>
                        }
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
}

export default TaskPdfDialog;