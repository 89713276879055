

export const LIME_GREEN = "#C7DA32";
export const LIGHT_GREEN = "#97CA40";
export const GREEN = "#57A946";
export const DARK_GREEN = "#307C3D";
export const LIGHTER_GREY_BLUE = "#a7bbbe";
export const LIGHT_GREY_BLUE = "#68888E";
export const DARK_GREY_BLUE = "#52686A";
export const LIGHT_NAVY = "#114559";
export const DARK_NAVY = "#002F40";
export const GHOST_WHITE = "#EEF1F2";

export const SIDENAV_GREY = "#52686a";