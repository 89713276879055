import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CustomTextField from "./CustomTextField";
import {LocationNameValid} from "../../types/task/Location";
import {taskManagementService} from "../../service/taskManagementService";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useSelector} from "react-redux";
import {formatDate} from "../../service/generic/DateHelper";
import {CategoryNameValid} from "../../types/task/CategoryNameValid";


type Props = {
  title: string,
  name: string,
  id: string,
  selectedDate: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void
}

const EditCategoryDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const taskCategoryPageGlobal = useSelector((state: RootState) => state.taskInfo.taskCategories);
  const [categoryNameValid, setCategoryNameValid] = useState({...CategoryNameValid});
  const [categoryNameLocal, setCategoryNameLocal] = useState('');
  const [formTouched, setFormTouched] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const [showLoading, setShowLoading] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');

  useEffect(() => {
    document.title = "Edit Category Dialog";
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      setNewCategoryName(props.name);
      console.log('close now')
      dispatch(taskInfoActions.setIsModalOpen({value: true}));
    }
  }, [props.isOpen])

  const close = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    setFormTouched(false);
    props.setIsOpen(false)
  }

  const onChangeCategoryName = (catName:string) => {
    setFormTouched(true);
    categoryNameValid.categoryName = catName.trim().length > 0;
    setCategoryNameValid(categoryNameValid);
    setNewCategoryName(catName);
  }

  const handleCategoryUpdate = () => {
    props.setIsOpen(false);
    setShowLoading(true);
    let newPage = {...taskCategoryPageGlobal.pagination}
    newPage.pageNumber = pageNum;
    taskManagementService.changeCategoryName(newPage, allSelectedAccessCodes!, newCategoryName!, props.id!, props.selectedDate!)
        .then(resp => {
          dispatch(taskInfoActions.setTaskCategoriesPage({categoriesPage:resp.taskCategories}));
          dispatch(displayAlert(true, 'task location successfully updated'));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => {
          setShowLoading(false);
          dispatch(taskInfoActions.setIsModalOpen({value: false}));
        });
  }

  const isFormValid = () => {
    return categoryNameValid.categoryName;
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={props.isOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          <Stack direction={'column'}>
            <CustomTextField
                disabled={false}
                valueString={newCategoryName}
                isValid={categoryNameValid.categoryName || !formTouched}
                label={"Location Name"}
                validationText={'Invalid Location Name'}
                onInputUpdate={onChangeCategoryName}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Cancel
          </Button>
          <Button style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}
                  variant={isFormValid() ? "contained" : "text"}
                  onClick={handleCategoryUpdate}>Submit</Button>
        </DialogActions>
      </Dialog>
  );
}

export default EditCategoryDialog;