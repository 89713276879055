import {createSlice} from "@reduxjs/toolkit";
import {JobBoardResponse} from "../../types/task/TaskTypes";
import {jobBoardResponseInit, scheduleObjectDayResponseInit, scheduleObjectWeekResponseInit} from "./taskInfoSlice";

const jobBoardStateInit = {
  jobBoardResponse: jobBoardResponseInit,
}

export const JobBoardSlice = createSlice({
  name: 'schedule',
  initialState: jobBoardStateInit,
  reducers: {
    setJobBoardResponse(state, action) {
      state.jobBoardResponse = action.payload.value;
    },
    resetToInit(state) {
      state = jobBoardStateInit;
    }
  }
});