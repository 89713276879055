import React, {useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CustomTextField from "./CustomTextField";
import {LocationNameValid} from "../../types/task/Location";
import {taskManagementService} from "../../service/taskManagementService";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useSelector} from "react-redux";
import {formatDate} from "../../service/generic/DateHelper";


type Props = {
  title: string,
  name: string,
  id: string,
  selectedDate: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void
}

const EditLocationDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const taskLocationsPageGlobal = useSelector((state: RootState) => state.taskInfo.taskLocations);
  const [locationNameValid, setLocationNameValid] = useState({...LocationNameValid});
  const [locationNameLocal, setLocationNameLocal] = useState('');
  const [formTouched, setFormTouched] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const [showLoading, setShowLoading] = useState(false);
  const [newLocationName, setNewLocationName] = useState('');

  useEffect(() => {
    document.title = "Edit Location Dialog";
  }, []);

  useEffect(() => {
    if (props.isOpen) {
      setNewLocationName(props.name);
      console.log('close now')
      dispatch(taskInfoActions.setIsModalOpen({value: true}));
    }
  }, [props.isOpen])

  const close = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    setFormTouched(false);
    props.setIsOpen(false)
  }

  const onChangeLocationName = (locName:string) => {
    setFormTouched(true);
    locationNameValid.locationName = locName.trim().length > 0;
    setLocationNameValid(locationNameValid);
    setNewLocationName(locName);
  }

  const handleLocationUpdate = () => {
    props.setIsOpen(false);
    setShowLoading(true);
    let newPage = {...taskLocationsPageGlobal.pagination}
    newPage.pageNumber = pageNum;
    taskManagementService.changeLocationName(newPage, allSelectedAccessCodes!, newLocationName!, props.id!, props.selectedDate!)
        .then(resp => {
          dispatch(taskInfoActions.setTaskLocationsPage({locationsPage:resp.taskLocations}));
          dispatch(displayAlert(true, 'task location successfully updated'));
        })
        .catch(e => dispatch(determineIfTokenError(e)))
        .finally(() => {
          setShowLoading(false);
          dispatch(taskInfoActions.setIsModalOpen({value: false}));
        });
  }

  const isFormValid = () => {
    return locationNameValid.locationName;
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
          maxWidth="xs"
          open={props.isOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          <Stack direction={'column'}>
              <CustomTextField
                disabled={false}
                valueString={newLocationName}
                isValid={locationNameValid.locationName || !formTouched}
                label={"Location Name"}
                validationText={'Invalid Location Name'}
                onInputUpdate={onChangeLocationName}
              />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Cancel
          </Button>
          <Button style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}
                  variant={isFormValid() ? "contained" : "text"}
                  onClick={handleLocationUpdate}>Submit</Button>
        </DialogActions>
      </Dialog>
  );
}

export default EditLocationDialog;