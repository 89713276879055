import {ApiErrorResponse} from "../../types/ApiErrorResponse";
import {SecurityCode, UserInfo} from "../../types/account/AccountTypes";
import {userInfoInit} from "../../store/account/userInfoSlice";
import {httpCommonService} from "../generic/httpCommonService";
import {BudgetUtil} from "../../types/task/BudgetTypes";


export const userInfoService = {
    updateUserInfo,
    updateUserPass,
    sendResetPassEmail,
    checkPasswordValid,
    checkSecurityCodeValid,
    fetchUserInfo,
    seenIntroTutorial
}

async function handleApiSuccess(responseAny: any) {
    let response: UserInfo = await responseAny.json();
    return response;
}

//------------------------------------------------------------------------

async function seenIntroTutorial() {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/seenIntroTutorial`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as UserInfo)
            .catch(e => {
                throw e
            });
    } catch (e) {throw e;}
}

async function updateUserInfo(user: UserInfo) {
    try {
        let token = localStorage.getItem('user') as string;
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/update`, {
            method: 'PUT',
            body: JSON.stringify(user),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as UserInfo)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function sendResetPassEmail(email:string) {
    try {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/user/password/sendEmail/${email}`, {
            method: 'GET',
            headers: {
            }
        })
            .then(async (response: any) => {
                if (response.status !== 200 && response.status !== 400) {
                    await httpCommonService.handleApiError(response);
                }
                return response;
            })
            .then(async (response: any) => await (response.status === 200))
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function updateUserPass(user: UserInfo) {
    try {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/user/password/update`, {
            method: 'PUT',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(httpCommonService.handleApiError)
            .then(async (response: any) => await response.json() as UserInfo)
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function checkPasswordValid(user: UserInfo) {
    try {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/user/password/checkValid`, {
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response: any) => {
                if (response.status !== 200 && response.status !== 400) {
                    await httpCommonService.handleApiError(response);
                }
                return response;
            })
            .then(async (response: any) => await (response.status === 200))
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

async function checkSecurityCodeValid(secCode: SecurityCode) {
    try {
        return fetch(`${process.env.REACT_APP_BACKEND_URL}/user/securityCode/checkValid`, {
            method: 'POST',
            body: JSON.stringify(secCode),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(async (response: any) => {
                if (response.status !== 200 && response.status !== 400) {
                    await httpCommonService.handleApiError(response);
                }
                return response;
            })
            .then(async (response: any) => await (response.status === 200))
            .catch(e => {
                throw Error(e.message)
            });
    } catch (e) {throw e;}
}

//--------------------------------------------------------------------------------

async function fetchUserInfo(): Promise<UserInfo> {
    try {
        let token = localStorage.getItem('user') as string;
        let response: UserInfo = await getUserInfoHttpCall(token);
        if (response != null && response.firstName !== '') {
            return response;
        }
    } catch (e) {
        throw e;
    }
    return userInfoInit;
}

function getUserInfoHttpCall(token: string): Promise<UserInfo> {
    const requestOptions = {
        method: 'GET',
        //mode: 'no-cors',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*'
        }
    };

    return fetch(`${process.env.REACT_APP_BACKEND_URL}/api/user/retrieveUser`, requestOptions)
        .then(httpCommonService.handleApiError)
        .then(handleApiSuccess)
        .catch(e => {throw e});
}
