import React, {useEffect, useState} from "react";
import {GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";
import {Box, useMediaQuery} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../store/storeIndex";
import {MapLatLong} from "../../types/account/AccountTypes";
import {useTheme} from "@mui/material/styles";

type Props = {
  editable:boolean,
  orgCenter?:MapLatLong,
  updateLatLong?: (value:MapLatLong) => void,
}

const MapsContainer:React.FC<Props> = (props) => {
  const theme = useTheme();
  const midWidth = useMediaQuery(theme.breakpoints.up('lg'));

  const isLoaded = useSelector((state: RootState) => state.maps.isLoaded);
  const [map, setMap] = useState(null)
  const [center, setCenter] = useState(
      (props.orgCenter && props.orgCenter.lat) ? new google.maps.LatLng(props.orgCenter.lat, props.orgCenter.lng) :
          new google.maps.LatLng(39.253465, -97.815738))
  const [markers, setMarkers] = useState<google.maps.LatLng[]>([center]);

  const containerStyle = {
    width: '100%',
    height: !midWidth ? '300px' : '505px'
  };

  useEffect(() => {
    if (isLoaded) {
      setCenter(markers[0]);
    }
  }, [isLoaded])

  /*const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    //map.setCenter(center)
    setMap(map)
  }, [])*/

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const addNewMarker = (e:google.maps.MapMouseEvent) => {
    if (props.editable) {
      let newMarkers = [];
      newMarkers.push(e.latLng);
      setMarkers(newMarkers);

      if (props.updateLatLong) {
        let mapLatLong:MapLatLong = {lat:e.latLng.lat(), lng:e.latLng.lng()}
        props.updateLatLong(mapLatLong);
      }
    }
  }

  return (
      <Box sx={{ display:"flex", justifyContent:'center'}}>
        { isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={5}
                /*onLoad={onLoad}*/
                onUnmount={onUnmount}
                onClick={addNewMarker}
            >
              { markers.map(marker => (
                <Marker
                    /*onLoad={onLoad}*/
                    position={marker}
                />
              ))}
            </GoogleMap>
        ) : (
            <LoadingSpinnerContainer showLoading={isLoaded}/>
        )}
      </Box>
  );
}

export default MapsContainer;