import {AccountState, Address, AllowedEmail} from "./AccountTypes";
import {addressInit, userInfoInit} from "../../store/account/userInfoSlice";

export const accountStateInit:AccountState = {
  userInfo: userInfoInit,
  orgMembers: []
}

export const allowedEmailInit: AllowedEmail = {
  id: '',
  email: '',
  name:'',
  firstname:'',
  lastname:'',
  contractname:'',
  registeredDate: '',
  role: '',
  account: '',
  accountName: '',
  hourlyPay: '',
  contractPay: '',
  phoneNumber:'',
}

export const preferenceConfigInit = {
  requireTaskEstimatedCompletionTime: true
}

export const accountParentInit = {
  accountId: '',
  name: '',
  address: addressInit,
  accessCode: '',
  timezone: '',
  phoneNumber:'',
  orgAdminId: '',
  allowedEmails: [],
  preferenceConfig: preferenceConfigInit,
  playbooksId: '',
  dashPrm:'',
  recordsPrm:'',
  reportsPrm:'',
  alertsPrm:'',
  labelSdsPrm:'',
  inventoryPrm:'',
  annualBudget: '',
}

export const mapLatLongInit = {
  lat:0,
  lng:0
}
