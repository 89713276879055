import {Backdrop, Box, CircularProgress, Fade} from "@mui/material";
import React from "react";
import {LIME_GREEN} from "../../service/generic/ColorScheme";

type Props = {
  showLoading: boolean
}

const LoadingSpinnerFull: React.FC<Props> = (props) => {

  return (
      <Box sx={{ height: 40 }}>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100000 }}
            open={props.showLoading}
            /*onClick={handleClose}*/
        >
          <Fade
              in={props.showLoading}
              style={{
                transitionDelay: props.showLoading ? '200ms' : '0ms',
              }}
              unmountOnExit
          >
            <CircularProgress sx={{zIndex:99000, color:LIME_GREEN}}/>
          </Fade>
        </Backdrop>
      </Box>
  );
}

export default LoadingSpinnerFull;