import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl} from "@mui/material";
import CustomTextField from "../general/CustomTextField";
import {CommentsInfoInit} from "../../store/task/taskInfoSlice";
import {CommentInfoValid, Comments} from "../../types/task/TaskTypes";
import {taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import LoadingSpinnerContainer from "../general/LoadingSpinnerContainer";


type Props = {
    handleSubmit: (id:string, comment:Comments) => void,
    title: string,
    id: string,
    isOpen: boolean,
    commentString: string,
    setIsOpen: (open: false) => void,
    isSubmitLoading: boolean,
}

const CommentDialog: React.FC<Props> = (props) => {
    const imageInputRef = useRef<HTMLInputElement>(null);
    let dispatch = useAppThunkDispatch();

    const [commentsInfoState, setCommentsInfoState] = useState({...CommentsInfoInit});
    const [commentFormValid, setCommentFormValid] = useState(CommentInfoValid);
    const [commentFormTouched, setCommentFormTouched] = useState<boolean>(false);

    const imageurl = '/assets/placeholder.jpg';
    const [imageUploadUrl, setImageUploadUrl] = useState<string>(imageurl);

    useEffect(() => {
        if (props.isOpen) {
            dispatch(taskInfoActions.setIsModalOpen({value: true}));
        }
    }, [props.isOpen])

    const onChangeCommentString = (commentsString:string) => {
        setCommentFormTouched(true);
        commentFormValid.commentString = commentsString.trim().length > 1;
        setCommentFormValid(commentFormValid);

        let newCommentsState = {...commentsInfoState};
        newCommentsState.commentString = commentsString;
        setCommentsInfoState(newCommentsState);
    }

    const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null && event.target.files.length > 0) {
            const file = event.target.files.item(0);
            const imageUrl = URL.createObjectURL(file);

            setImageUploadUrl(imageUrl);
            let newCommentsState = {...commentsInfoState};
            newCommentsState.multipartFile = file;
            setCommentsInfoState(newCommentsState);
        }
    }

    const resetForm = () => {
        commentFormValid.commentString = false;
        setCommentFormValid(commentFormValid);
        let newCommentsState = {...commentsInfoState};
        newCommentsState.commentString = "";
        newCommentsState.multipartFile = null;
        setCommentsInfoState(newCommentsState);
        setCommentFormTouched(false);
        setImageUploadUrl(imageurl);
    }

    const isFormValid = () => {
        return commentFormValid.commentString;
    }

    const handleSubmitComment = (id:string, comments:Comments) => {
        if (isFormValid()) {
            props.handleSubmit(id, comments);
            resetForm();
        }
    }

    const onClose = () => {
        dispatch(taskInfoActions.setIsModalOpen({value: false}));
        resetForm();
        props.setIsOpen(false);
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="sm"
            open={props.isOpen}
        >
            <DialogTitle>{props.title}</DialogTitle>

            { props.isSubmitLoading ? (
                <Box sx={{marginY:'100px'}}>
                    <LoadingSpinnerContainer showLoading={props.isSubmitLoading}/>
                </Box>
            ) : (
                <FormControl component="fieldset">
                    <DialogContent dividers>
                        <CustomTextField
                            valueString={commentsInfoState.commentString}
                            isValid={isFormValid() || !commentFormTouched}
                            label={"Task Comment"}
                            multiline={4}
                            validationText={'Invalid Comment'}
                            onEnterKeyDownSubmitHandler={() => props.handleSubmit(props.id, commentsInfoState)}
                            onInputUpdate={onChangeCommentString}
                        />

                        <p>Add Picture</p>
                        <input type={'file'} accept={'image/*'} hidden={true}
                               onChange={handleImageUpload} ref={imageInputRef} />
                        <img src={imageUploadUrl} alt={'Upload Image'} style={{cursor: "pointer", maxHeight:"300px", maxWidth:"300px"}}
                             onClick={() => imageInputRef?.current?.click()} />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={onClose}>
                            Cancel
                        </Button>
                        <Button type={'button'} size="large"
                                style={{cursor: !(isFormValid() && commentFormTouched) ? 'not-allowed' : 'pointer'}}
                                variant={isFormValid() && commentFormTouched ? "contained" : "outlined"}
                                onClick={() => handleSubmitComment(props.id, commentsInfoState)}>
                            Submit
                        </Button>
                    </DialogActions>
                </FormControl>
            )}
        </Dialog>
    );
}

export default CommentDialog;