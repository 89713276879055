import {createSlice} from "@reduxjs/toolkit";
import {InboxNotification, notificationInit} from "../types/NotificationTypes";
import {AppThunkDefault, notificationActions} from "./storeIndex";

export interface NotificationState {
  notifications: InboxNotification[],
  sentNotifications: InboxNotification[],
  draftNotifications: InboxNotification[],
  trashedNotifications: InboxNotification[],
  unseen: number
}

export const notificationStateInit: NotificationState = {
  notifications: [],
  sentNotifications: [],
  draftNotifications: [],
  trashedNotifications: [],
  unseen: 0
}

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState: notificationStateInit,
  reducers: {
    setNotifications(state, action) {
      state.notifications = action.payload.notifications;
      state.unseen = state.notifications.filter(n => !n.seen).length;
    },
    setSentNotifications(state, action) {
      state.sentNotifications = action.payload.notifications;
    },
    setTrashedNotifications(state, action) {
      state.trashedNotifications = action.payload.notifications;
    },
    setDraftNotifications(state, action) {
      state.draftNotifications = action.payload.notifications;
    },
    resetToInit(state) {
      state = notificationStateInit;
    }
  }
});

export const updateNotificationGlobalState = (inbox: NotificationState):
    AppThunkDefault => async (dispatch) => {
  dispatch(notificationActions.setNotifications({notifications: inbox.notifications}));
  dispatch(notificationActions.setSentNotifications({notifications: inbox.sentNotifications}));
  dispatch(notificationActions.setDraftNotifications({notifications: inbox.draftNotifications}));
  dispatch(notificationActions.setTrashedNotifications({notifications: inbox.trashedNotifications}));
}