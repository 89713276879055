import React, {Fragment, useEffect, useState} from "react";
import {
  Alert,
  Box,
  Button,
  Card,
  CardHeader, Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider, IconButton,
  Stack,
  Typography
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import {RootState, taskInfoActions} from "../../store/storeIndex";
import {useAppThunkDispatch} from "../../store/storeHooks";
import CustomTextField from "./CustomTextField";
import {LocationNameValid} from "../../types/task/Location";
import {taskManagementService} from "../../service/taskManagementService";
import {displayAlert} from "../../store/alertSlice";
import {determineIfTokenError} from "../../store/account/authSlice";
import {useSelector} from "react-redux";
import {formatDate} from "../../service/generic/DateHelper";
import {Priority, TaskInfo} from "../../types/task/TaskTypes";
import {ArrowUpward, CheckCircleOutlined, KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import TaskActions from "../task/TaskActions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {taskInfoInit} from "../../store/task/taskInfoSlice";
import LoadingSpinnerContainer from "./LoadingSpinnerContainer";


type Props = {
  title: string,
  taskList:TaskInfo[],
  selectedId: string,
  selectedDate: string,
  isOpen: boolean,
  setIsOpen: (open: false) => void,
  isCancel: boolean,
  setIsCancel: (open: true) => void,
  handleRefreshTaskList: (id:string) => void,
}

const ChangeCategoryOnTasksDialog: React.FC<Props> = (props) => {
  let dispatch = useAppThunkDispatch();

  const userInfo = useSelector((state: RootState) => state.userInfo);
  const orgInfo = useSelector((state: RootState) => state.orgInfo);

  const [selectedTask, setSelectedTask] = React.useState(taskInfoInit);
  const selectedDate = useSelector((state: RootState) => state.taskInfo.selectedDate);
  const [taskDialogOpen, setTaskDialogOpen] = React.useState(false);
  const allSelectedAccessCodes = useSelector((state: RootState) => state.orgInfo.allSelectedAccessCodes);
  const [showLoading, setShowLoading] = React.useState(false);

  useEffect(() => {
    document.title = "Change Tasks Locations Dialog";
  }, []);

  const close = () => {
    dispatch(taskInfoActions.setIsModalOpen({value: false}));
    props.setIsOpen(false)
    props.setIsCancel(true);
  }

  const getAccountNameFromAccessCode = (accessCode:string) => {
    return orgInfo.selectedAccounts.filter(account => account.accessCode === accessCode)[0].name;
  }

  const handleTaskSelected = (task: TaskInfo) => {
    setSelectedTask(task);
    setTaskDialogOpen(true);
  }

  return (
      <Dialog
          sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 475 } }}
          maxWidth="sm"
          open={props.isOpen}
      >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent dividers>
          { showLoading ? (
              <LoadingSpinnerContainer showLoading={showLoading} />
          ) : (
              <Fragment>
                <Alert severity="info" color="info" sx={{mb: 2}}>
                  <Typography variant={'body1'} component={"div"}>
                    The below tasks are currently assigned to the category you are trying to delete.
                    Please edit each task below and change the location, or delete the task.
                  </Typography>
                </Alert>

                {props.taskList.map((task: TaskInfo, index) => (
                    <Box sx={{mb: 1}}>
                      <Card sx={{mb: 1, backgroundColor: "ghostwhite"}}
                            key={task.assignedTaskId}>
                        <CardHeader
                            style={{color: task.completed ? 'forestgreen' : 'indianred'}}
                            title={
                              <Fragment>
                                <Typography variant="h6" component="div">
                                  {task.taskName}
                                  <Typography variant="subtitle1" component="span"
                                              style={{float: "right", color: 'gray'}}>
                                  </Typography>
                                </Typography>
                                <Typography variant="body1" component="div" color={"dimgrey"}>
                                  <Stack direction={"row"} spacing={3}>
                                  </Stack>
                                </Typography>
                              </Fragment>
                            }
                            action={
                              <Stack direction="row" spacing={0}>
                                <TaskActions
                                    task={task}
                                    selectedDate={new Date(selectedDate)}
                                    taskDialogOpen={taskDialogOpen}
                                    setTaskDialogOpen={setTaskDialogOpen}
                                    onlyShowEditDelete={true}
                                    handleDialogActionClose={props.handleRefreshTaskList}
                                    selectedSubjectId={props.selectedId}
                                    getMonthlyTasks={() => {}}
                                />
                                <IconButton aria-label="more"
                                            onClick={() => handleTaskSelected(task)}>
                                  <MoreVertIcon color={"action"}/>
                                </IconButton>
                              </Stack>
                            }
                        />
                      </Card>
                    </Box>
                ))}
              </Fragment>
          )}

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Cancel
          </Button>
          {/*<Button style={{cursor: !isFormValid() ? 'not-allowed' : 'pointer'}}
                  variant={isFormValid() ? "contained" : "text"}
                  onClick={handleLocationUpdate}>Submit</Button>*/}
        </DialogActions>
      </Dialog>
  );
}

export default ChangeCategoryOnTasksDialog;